import { useIntl } from 'react-intl';
import PlanBillingType from '../../enums/PlanBillingType';
import AddOn from '../../viewModels/AddOn';

export type AddOnHeadingProps = {
    addOn?: AddOn;
    isModal?: boolean;
    currencySymbol: string;
    planBillingType?: PlanBillingType;
};

const AddOnHeading = ({ addOn, isModal, currencySymbol, planBillingType }: AddOnHeadingProps) => {
    const intl = useIntl();

    const displayCost = () => {
        if (addOn == null) return '';

        let month = intl.formatMessage({ id: 'Common.Month' }).toLowerCase();
        let year = intl.formatMessage({ id: `Common.Year` }).toLowerCase();
        let costToDisplay = '';
        if (planBillingType === PlanBillingType.Monthly) {
            costToDisplay = `${currencySymbol}${addOn.cost}/${month}`;
        }
        if (planBillingType === PlanBillingType.Yearly) {
            costToDisplay = `${currencySymbol}${addOn.cost}/${year}`;
        }

        return `${costToDisplay}`;
    };

    return (
        <div className={isModal ? 'addon-heading addon-modal-heading' : 'addon-heading'}>
            {addOn?.logo}
            <div className="addon-info">
                <div className="addon-title">{addOn?.title}</div>
                {displayCost()}
            </div>
        </div>
    );
};

export default AddOnHeading;
