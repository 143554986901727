import { Fragment, useEffect } from 'react';
import ValidationMessage from '../common/ValidationMessage';
import ResDiaryDropdown from './ResDiaryDropdown';
import { Controller } from 'react-hook-form';

export type ValidationDropdownProps = {
    formValidation: any;
    name: string;
    validationRules: any;
    onChange: (value: any) => void;
    defaultValue: string;
    options: Array<HTMLOptionElement>;
    title: string;
    multiSelect?: boolean;
    isSearchable?: boolean;
    selectedValue?: string;
    selectedValues?: Array<string>;
    hideRemoveItemIcon?: boolean;
    onMultiSelectRemoved?: (value: any) => void;
    onClearSelectedValues?: () => void;
    inputGroupClassName?: string;
    isDropdownDisabled?: boolean;
    isLoading?: boolean;
};

const ValidationDropdown = ({
    formValidation,
    name,
    validationRules,
    onChange,
    defaultValue,
    options,
    title,
    multiSelect,
    isSearchable,
    selectedValue,
    selectedValues,
    hideRemoveItemIcon,
    onMultiSelectRemoved,
    onClearSelectedValues,
    inputGroupClassName,
    isDropdownDisabled,
    isLoading,
}: ValidationDropdownProps) => {
    const setValue = formValidation.setValue;

    useEffect(() => {
        const newValue = multiSelect ? selectedValues : selectedValue;
        setValue(name, newValue);
    }, [setValue, name, selectedValue, selectedValues, multiSelect]);

    function onChangeInternal(value: any) {
        onChange(value);
        const newValue = multiSelect ? selectedValues?.concat([value]) : value;
        setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    }

    function onClear() {
        if (onClearSelectedValues) {
            onClearSelectedValues();
            setValue(name, [], { shouldValidate: true, shouldDirty: true });
        }
    }

    function onRemove(value: any) {
        if (onMultiSelectRemoved) {
            onMultiSelectRemoved(value);
        }
        const newValue = selectedValues?.filter((v) => v !== value);
        setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    }

    return (
        <Controller
            control={formValidation.control}
            name={name}
            render={() => (
                <Fragment>
                    <ResDiaryDropdown
                        isLoading={isLoading}
                        isSearchable={isSearchable}
                        onValueChange={onChangeInternal}
                        defaultValue={defaultValue}
                        options={options}
                        selectedValue={selectedValue}
                        selectedValues={selectedValues}
                        title={title}
                        multiSelect={multiSelect}
                        hideRemoveItemIcon={hideRemoveItemIcon}
                        onMultiSelectRemoved={onRemove}
                        onClearSelectedValues={onClear}
                        errors={formValidation.errors}
                        name={name}
                        ariaLabel={title}
                        ariaRequired
                        inputGroupClassName={inputGroupClassName}
                        isDropdownDisabled={isDropdownDisabled}
                    />
                    {formValidation.errors[name] && (
                        <ValidationMessage message={formValidation.errors[name].message} isBlock={false} />
                    )}
                </Fragment>
            )}
            rules={validationRules}
            defaultValue={multiSelect ? selectedValues : selectedValue}
        />
    );
};

export default ValidationDropdown;
