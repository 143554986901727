export type AverageEatDurationSettings = {
    Id: number;
    ServiceId: number;
    AverageTime: number;
    CoversFrom: number;
    CoversTo: number;
    IsCoversRuleEnabled: boolean;
};
class AverageEatDuration {
    id: number;
    serviceId: number;
    label: string;
    averageTime: number;
    coversFrom: number;
    coversTo: number;
    isCoversRuleEnabled: boolean;

    constructor(settings: AverageEatDurationSettings) {
        this.label =
            settings && settings.CoversFrom && settings.CoversTo ? `${settings.CoversFrom}-${settings.CoversTo}` : '';
        this.averageTime = settings && settings.AverageTime ? settings.AverageTime : 0;
        this.coversFrom = settings && settings.CoversFrom ? settings.CoversFrom : 0;
        this.coversTo = settings && settings.CoversTo ? settings.CoversTo : 0;
        this.serviceId = settings && settings.ServiceId ? settings.ServiceId : 0;
        this.id = settings && settings.Id ? settings.Id : 0;
        this.isCoversRuleEnabled = settings?.IsCoversRuleEnabled ?? false;
    }
}
export default AverageEatDuration;
