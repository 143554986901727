class ImageHelper {
    static trimFilenameToDbLimit = (imageFile: any) => {
        if (imageFile && imageFile.name && imageFile.name.toString().length > 100) {
            return new File([imageFile], imageFile.name.toString().slice(-100));
        }
        return imageFile;
    };
}

export default ImageHelper;
