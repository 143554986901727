import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label, NavLink, Spinner } from 'reactstrap';
import { ReactComponent as PlusIcon } from '../../../content/icons/plus.svg';
import BackgroundImageSource from '../../../content/images/bg-areas.jpg';
import Area from '../../../domainObjects/Area';
import trackingEvents from '../../../enums/trackingEvents';
import SettingsHelper from '../../../helpers/SettingsHelper';
import useScreenSize from '../../../hooks/useScreenSize';
import useValidation from '../../../hooks/useValidation';
import { InfoBarProps } from '../../common/InfoBar';
import InfoBars from '../../common/InfoBars';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import VenueAreaItem from './AreaItem';
import rdfOnboardingProgressFlags from '../../../enums/rdfOnboardingProgressFlags';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';

export type AreasPageProps = {
    areas: Area[];
    addOrUpdateArea: (area: Area, updateOnlyState: boolean, callback: (success: boolean) => void) => void;
    deleteArea: (area: Area, callback: (success: boolean) => void) => void;
    updateProgressFlag: (progressFlag: string) => void;
    isUpdating?: boolean;
    isLoading: boolean;
};

const AreasPage = ({
    areas,
    addOrUpdateArea,
    deleteArea,
    updateProgressFlag,
    isUpdating,
    isLoading,
}: AreasPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const [infoBars, setInfoBars] = useState<Array<InfoBarProps>>([]);
    const [isAdding, setIsAdding] = useState(false);
    const [newArea, setNewArea] = useState<Area>({
        ...new Area(null),
        segmentId: parseInt(SettingsHelper.getSegmentId()),
    });
    const { isMobileView, isTabletView } = useScreenSize();
    const [continueDisabled, setContinueDisabled] = useState(false);

    const updateAreasProgressFlag = () => {
        updateProgressFlag(rdfOnboardingProgressFlags.addedAreas);
    };

    const isFormValid = useCallback(() => {
        return isAdding || isUpdating || areas.length === 0 ? false : true;
    }, [isAdding, isUpdating, areas]);

    useEffect(() => {
        setContinueDisabled(!isFormValid());
    }, [isAdding, areas, isFormValid]);

    const resetNewArea = () => {
        const area = new Area(null);
        area.segmentId = parseInt(SettingsHelper.getSegmentId());
        setNewArea(area);
    };

    const showSelectInfoBar = (
        select: 'saveSuccess' | 'saveFail' | 'deleteSuccess' | 'deleteFail' | 'alreadyExists' | 'isEmpty',
        areaName: string
    ) => {
        switch (select) {
            case 'saveSuccess': {
                showInfoBar('OnboardingWizard.AreaSavedSuccessfullyInfoMessage', areaName, 'success');
                break;
            }
            case 'saveFail': {
                showInfoBar('OnboardingWizard.AreaSaveFailedInfoMessage', areaName, 'danger');
                break;
            }
            case 'deleteSuccess': {
                showInfoBar('OnboardingWizard.AreaDeletedSuccessfullyInfoMessage', areaName, 'success');
                break;
            }
            case 'deleteFail': {
                showInfoBar('OnboardingWizard.AreaDeleteFailedInfoMessage', areaName, 'danger');
                break;
            }
            case 'isEmpty': {
                showInfoBar('OnboardingWizard.AreaNameRequiredWarningLabel', areaName, 'danger');
                break;
            }
            case 'alreadyExists': {
                showInfoBar('OnboardingWizard.AreaNameAlreadyExistsWarningMessage', areaName, 'danger');
                break;
            }
        }
    };

    const showInfoBar = (id: string, areaName: string, type: 'success' | 'danger') => {
        setInfoBars((prevState: InfoBarProps[]) => [
            ...prevState,
            {
                id: infoBars.length > 0 ? infoBars[infoBars.length - 1].id + 1 : 0,
                message: intl.formatMessage({ id }, { areaName }),
                type: type,
                onDismiss: () => {},
            },
        ]);
    };

    const validateName = (area: Area): boolean => {
        // Name is required (cannot be empty)
        if (area.name.length <= 0) {
            showSelectInfoBar('isEmpty', area.name);
            return false;
        }

        // Name must be unique
        const noMatchingName = areas.every((a: Area) => {
            return a.id === area.id || a.name !== area.name;
        });

        if (noMatchingName) {
            return true;
        } else {
            showSelectInfoBar('alreadyExists', area.name);
            return false;
        }
    };

    const getFormBody = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <Spinner color="primary" />
                </div>
            );
        }
        return (
            <div className={`areas-page-wrapper ${isMobileView || isTabletView ? 'mobile' : ''}`}>
                <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} isOnboarding />
                <div className="areas-table">
                    <div className="areas-list">
                        {areas
                            .sort((x, y) => x.id - y.id)
                            .map((a) => (
                                <VenueAreaItem
                                    key={a.id}
                                    area={a}
                                    onChangeName={(area: Area, newName: string) => {
                                        addOrUpdateArea({ ...area, name: newName }, true, () => {});
                                    }}
                                    onClickSave={(area: Area) => {
                                        addOrUpdateArea(area, false, (success: boolean) => {
                                            success
                                                ? showSelectInfoBar('saveSuccess', area.name)
                                                : showSelectInfoBar('saveFail', area.name);
                                        });
                                    }}
                                    onToggleIsAvailableOnline={(area: Area) => {
                                        addOrUpdateArea(
                                            { ...area, isAvailableOnline: !area.isAvailableOnline },
                                            false,
                                            (success: boolean) => {
                                                success
                                                    ? showSelectInfoBar('saveSuccess', area.name)
                                                    : showSelectInfoBar('saveFail', area.name);
                                            }
                                        );
                                    }}
                                    onClickDelete={(area: Area) => {
                                        deleteArea(area, (success: boolean) => {
                                            success
                                                ? showSelectInfoBar('deleteSuccess', area.name)
                                                : showSelectInfoBar('deleteFail', area.name);
                                        });
                                    }}
                                    formValidation={formValidation}
                                    validateName={validateName}
                                    setContinueDisabled={setContinueDisabled}
                                />
                            ))}
                        {isAdding && (
                            <VenueAreaItem
                                editOnInit
                                area={newArea}
                                onChangeName={(area: Area, newName: string) => {
                                    setNewArea({ ...newArea, name: newName });
                                }}
                                onToggleIsAvailableOnline={(area: Area) => {
                                    setNewArea({ ...newArea, isAvailableOnline: !area.isAvailableOnline });
                                }}
                                onClickSave={(area: Area) => {
                                    setIsAdding(false);
                                    addOrUpdateArea(area, false, (success) => {
                                        success
                                            ? showSelectInfoBar('saveSuccess', area.name)
                                            : showSelectInfoBar('saveFail', area.name);
                                    });
                                    resetNewArea();
                                }}
                                onClickDelete={() => {
                                    setIsAdding(false);
                                    resetNewArea();
                                }}
                                formValidation={formValidation}
                                validateName={validateName}
                            />
                        )}
                    </div>
                    <NavLink
                        className={`add-area-button ${isMobileView || isTabletView ? 'mobile' : ''}`}
                        role="button"
                        onClick={() => setIsAdding(true)}
                    >
                        <PlusIcon />
                        <Label>
                            <FormattedMessage id="OnboardingWizard.AddAreaButtonLabel" />
                        </Label>
                    </NavLink>
                </div>
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.AreasPageInfoPanelTitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.areasPage}
            isUpdating={false}
            children={getFormBody()}
            formValidation={formValidation}
            updateSettings={updateAreasProgressFlag}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfAreaManagementPage}
            continueDisabled={continueDisabled}
        />
    );
};

export default AreasPage;
