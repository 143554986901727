import React from 'react';

export type TextWithIconProps = {
    icon: JSX.Element;
    text?: string;
    iconClass?: string;
    textClass?: string;
    className?: string;
};

const TextWithIcon = ({ icon, text, iconClass, textClass, className }: TextWithIconProps) => {
    return (
        <div
            className={
                className
                    ? 'd-flex text-with-icon align-items-center' + className
                    : 'd-flex text-with-icon align-items-center'
            }
        >
            <div className={iconClass ? 'icon ' + iconClass : 'icon'}>{icon}</div>
            <div className={textClass ? 'text ' + textClass : 'text'} data-testid="TextWithIcon-text">
                {text}
            </div>
        </div>
    );
};

export default TextWithIcon;
