import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import LocalisedApp from './LocalisedApp';
import './styles/styles.scss';

const baseUrl: string | undefined = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <LocalisedApp />
    </BrowserRouter>,
    rootElement
);
