import React from 'react';
import { ReactComponent as IconSuccess } from '../../content/icons/Icon-Success.svg';

export type CreationWizardProgressIconWrapperProps = {
    notStarted?: boolean;
    isCompleted?: boolean;
};

const CreationWizardProgressIconWrapper = ({ notStarted, isCompleted }: CreationWizardProgressIconWrapperProps) => {
    function getIconClass() {
        let className = 'progress-icon-container';
        if (notStarted && !isCompleted) {
            className += ' not-started';
        } else if (!notStarted && !isCompleted) {
            className += ' incomplete';
        }

        return className;
    }

    return (
        <div className={getIconClass()}>
            <IconSuccess />
        </div>
    );
};

export default CreationWizardProgressIconWrapper;
