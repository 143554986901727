// See for more info https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types

enum PlacesComponentTypes {
    PostalCode = 'postal_code',
    // indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
    PostalTown = 'postal_town',
    // indicates an incorporated city or town political entity.
    Locality = 'locality',
    StreetAddress = 'street_address',
    StreetNumber = 'street_number',
    Route = 'route',
    Country = 'country',
}

export default PlacesComponentTypes;
