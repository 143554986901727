import { Button, ButtonGroup } from 'reactstrap';

export type RadioSelectionWithButtonsProps = {
    selectedValue: string;
    listOfValues: Array<{ value: any; text: string }>;
    onChange: (value?: any) => void;
};

const RadioSelectionWithButtons = ({ selectedValue, listOfValues, onChange }: RadioSelectionWithButtonsProps) => {
    let buttonWidth = { width: `calc(100% / ${listOfValues.length})` };

    return (
        <ButtonGroup className="radio-selection-with-buttons" role="group" aria-label="First group">
            {listOfValues.map((option) => {
                return (
                    <Button
                        key={option.value}
                        onClick={() => onChange(option.value)}
                        style={buttonWidth}
                        className="flex-fill"
                        color={selectedValue === option.value ? 'dark' : 'light'}
                    >
                        {option.text}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};

export default RadioSelectionWithButtons;
