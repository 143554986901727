import axios from 'axios';
import SettingsHelper from '../helpers/SettingsHelper';

class interceptor {
    static setUpInterceptors() {
        axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = 'Bearer ' + SettingsHelper.getJwtToken();
            return config;
        });

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response && error.response.status === 401) {
                    SettingsHelper.clearJwtToken();
                    SettingsHelper.clearUserAccountCountryId();
                    window.location.replace(SettingsHelper.getLoginUrl());
                }
                return error;
            }
        );
    }
}

export default interceptor;
