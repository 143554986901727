import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class UserService {
    static getCurrentUser() {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Tablet/v1/CurrentUser`);
    }
}

export default UserService;
