import PlaceResult, { PlaceResultSettings } from './PlaceResult';

export type PlaceDetailsResponseSettings = {
    Result: PlaceResultSettings;
    Status: string;
};

class PlaceDetailsResponse {
    Result: PlaceResult;
    Status: string;

    constructor(placesDetailsResponseSettings: PlaceDetailsResponseSettings | null) {
        this.Result = placesDetailsResponseSettings?.Result
            ? new PlaceResult(placesDetailsResponseSettings.Result)
            : new PlaceResult(null);
        this.Status = placesDetailsResponseSettings?.Status ? placesDetailsResponseSettings.Status : '';
    }
}

export default PlaceDetailsResponse;
