import ApiHelper from '../helpers/ApiHelper';
import { CountryCode } from '../domainObjects/CountryCode';

class CountryService {
    static getCountries() {
        return ApiHelper.get(`/api/Country/Countries`).then((response) => response.data);
    }

    static getPhoneCountryCodes() {
        return ApiHelper.get(`/api/Country/PhoneCountryCodes`).then(
            (response: { data: Array<CountryCode> }) => response.data
        );
    }
}

export default CountryService;
