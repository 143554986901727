import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Label } from 'reactstrap';
import Area from '../../../domainObjects/Area';
import Table, { TableSettings } from '../../../domainObjects/Table';
import AddServiceButton from '../../common/AddServiceButton';
import { ReactComponent as DropdownUpIcon } from '../../../content/icons/up.svg';
import VenueTable from './VenueTable';
import TableNameInput from './TableNameInput';
import useScreenSize from '../../../hooks/useScreenSize';
import TableSizeInput from './TableSizeInput';
import SettingsHelper from '../../../helpers/SettingsHelper';
import TableControls from './TableControls';

type VenueTableItemProps = {
    area: Area;
    tables: Array<Table>;
    addOrUpdateTable: (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => void;
    deleteTable: (tableToDelete: Table, callback: (success: boolean) => void) => void;
    formValidation: any;
    validateName: (table: Table, showInfoBar: boolean) => boolean;
    isUpdating: boolean;
    showSelectInfoBar: (
        select: 'saveSuccess' | 'saveFail' | 'deleteSuccess' | 'deleteFail' | 'alreadyExists' | 'isEmpty',
        tableNumber: string
    ) => void;
    setContinueDisabled: (val: boolean) => void;
};

const VenueTableItem = ({
    area,
    tables,
    addOrUpdateTable,
    deleteTable,
    formValidation,
    validateName,
    isUpdating,
    showSelectInfoBar,
    setContinueDisabled,
}: VenueTableItemProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const { isMobileView, isTabletView } = useScreenSize();

    const intl = useIntl();

    const defaultTable: TableSettings = {
        Id: 0,
        Number: '',
        Description: '',
        IsEnabled: true,
        Capacity: 2,
        Priority: 1,
        AreaId: area.id,
        ProviderId: parseInt(SettingsHelper.getProviderId()),
        TableType: 0,
        SegmentId: parseInt(SettingsHelper.getSegmentId()),
    };

    const [newTable, setNewTable] = useState(new Table({ ...defaultTable }));

    const addTableOrOpenTableList = (areaId: number) => {
        if (tables.length <= 0) {
            setIsAdding(true);
        }
        setIsOpen(true);
    };

    const createNewTable = (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => {
        addOrUpdateTable(table, setOnlyState, callback);
        setNewTable(new Table({ ...defaultTable }));
    };

    const updateNewTable = (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => {
        setNewTable({ ...newTable, capacity: table.capacity });
    };

    useEffect(() => {
        setContinueDisabled(isAdding);
    }, [isAdding, setContinueDisabled]);

    return (
        <div className="venue-table-item">
            {!isOpen && (
                <AddServiceButton
                    onClick={() => {
                        addTableOrOpenTableList(area.id);
                    }}
                    label={area.name}
                    subLabel={tables && tables.length > 0 ? `(${tables.length} tables)` : null}
                    linkText={intl.formatMessage({
                        id: 'OnboardingWizard.VenueTablesPageAddTableButton',
                    })}
                    isDropdown={tables.length > 0}
                    splitOnMobile={false}
                />
            )}
            {isOpen && (
                <div className="venue-table-wrapper">
                    <Label className="venue-table-label" onClick={() => setIsOpen(false)}>
                        {area.name}
                        {tables && tables.length > 0 && (
                            <Label className="venue-table-sub-label">({tables.length} tables)</Label>
                        )}
                        <DropdownUpIcon />
                    </Label>
                    {tables?.map((table, i) => {
                        return (
                            <VenueTable
                                key={i}
                                table={table}
                                addOrUpdateTable={addOrUpdateTable}
                                deleteTable={deleteTable}
                                formValidation={formValidation}
                                validateName={validateName}
                                isUpdating={isUpdating}
                                showSelectInfoBar={showSelectInfoBar}
                            />
                        );
                    })}
                    {!isAdding && (
                        <AddServiceButton
                            onClick={() => {
                                //addOrUpdateTable(newTable, true, () => {});
                                setIsAdding(true);
                            }}
                            linkText={intl.formatMessage({
                                id: 'OnboardingWizard.VenueTablesPageAddTableButton',
                            })}
                            alignRight
                        />
                    )}
                    {isAdding && (
                        <div
                            className={`table-input-wrapper new-table ${isMobileView || isTabletView ? 'mobile' : ''}`}
                        >
                            <TableNameInput
                                table={newTable}
                                addOrUpdateTable={createNewTable}
                                isEditing={isAdding}
                                setIsEditing={setIsAdding}
                                formValidation={formValidation}
                                validateName={validateName}
                                isUpdating={isUpdating}
                                showSelectInfoBar={showSelectInfoBar}
                            />
                            <TableSizeInput table={newTable} addOrUpdateTable={updateNewTable} />
                            <TableControls
                                tableId={newTable.id}
                                onEdit={() => {}}
                                onDelete={() => {
                                    setIsAdding(false);
                                    setNewTable(new Table({ ...defaultTable }));
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default VenueTableItem;
