import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { ReactComponent as IconSuccess } from '../../content/icons/Icon-Success.svg';
import AddOnHeading from './AddOnHeading';
import AddOn from '../../viewModels/AddOn';
import PlanBillingType from '../../enums/PlanBillingType';

export type AddOnItemProps = {
    isSelected: Function;
    showMoreDetail: Function;
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    addOn: AddOn;
    currencySymbol: string;
    planBillingType?: PlanBillingType;
};

const AddOnItem = ({
    isSelected,
    showMoreDetail,
    selectAddOnItem,
    unselectAddOnItem,
    addOn,
    currencySymbol,
    planBillingType,
}: AddOnItemProps) => {
    const intl = useIntl();

    const containerClass = () => {
        return isSelected(addOn.type) ? 'add-on-item selected' : 'add-on-item';
    };

    const trackClick = (eventName: string) => {
        const trackingProperties = {
            ...AnalyticsHelper.getCommonTrackingProperties(),
            addonName: addOn.title,
            selectedPlan: 'TODO',
        };
        AnalyticsHelper.trackClickWithProperties(eventName, trackingProperties);
    };

    const selectAddOn = () => {
        selectAddOnItem(addOn.type);
        trackClick('Web RDF Add-on Add');
    };

    const unselectAddOn = () => {
        unselectAddOnItem(addOn.type);
        trackClick('Web RDF Add-on Remove');
    };

    return (
        <div className={containerClass()}>
            {isSelected(addOn.type) && (
                <div className="progress-icon-container">
                    <IconSuccess />
                </div>
            )}
            <div className="add-on-item-details" onClick={() => showMoreDetail(addOn.type)}>
                <AddOnHeading addOn={addOn} currencySymbol={currencySymbol} planBillingType={planBillingType} />
                <div className="add-on-item-desc">
                    {addOn.shortDescription}{' '}
                    <span className="learn-more">{intl.formatMessage({ id: 'CreationWizard.LearnMore' })}</span>
                </div>
            </div>
            <div className="add-on-item-actions">
                <Button
                    onClick={() => {
                        isSelected(addOn.type) ? unselectAddOn() : selectAddOn();
                    }}
                >
                    {isSelected(addOn.type)
                        ? intl.formatMessage({ id: 'Common.Remove' })
                        : intl.formatMessage({ id: 'Common.Add' })}
                </Button>
            </div>
        </div>
    );
};

export default AddOnItem;
