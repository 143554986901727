export type AddOnModalColumnProps = {
    logo: JSX.Element;
    title: string;
    description: string;
};

const AddOnModalColumn = ({ logo, title, description }: AddOnModalColumnProps) => {
    return (
        <div className="addon-modal-col">
            <div className="addon-modal-col-logo">{logo}</div>
            <div className="addon-modal-col-title">{title}</div>
            <div className="addon-modal-col-desc">{description}</div>
        </div>
    );
};

export default AddOnModalColumn;
