const storageItem = {
    apiUrl: 'apiUrl',
    loginUrl: 'loginUrl',
    segmentKey: 'segmentKey',
    jwtToken: 'onboardingJwtToken',
    userAccountCountryId: 'userAccountCountryId',
    privacyPolicyUrl: 'privacyPolicyUrl',
    termsConditionsUrl: 'termsConditionsUrl',
    providerId: 'providerId',
    providerName: 'providerName',
    deploymentId: 'deploymentId',
    segmentId: 'segmentId',
    userId: 'userId',
    username: 'username',
    googleApiBrowserKey: 'googleApiBrowserKey',
    googleTagManagerContainerId: 'googleTagManagerContainerId',
    mobilePhoneCode: 'mobilePhoneCode',
    mobilePhoneNumber: 'mobilePhoneNumber',
    countryAlpha2Code: 'countryAlpha2Code',
    firstName: 'firstName',
    lastName: 'lastName',
};

export default storageItem;
