import React, { useState, useReducer } from 'react';
import { Label, FormGroup, Form, CustomInput, Tooltip } from 'reactstrap';
import SettingsHelper from '../../helpers/SettingsHelper';
import ValidationInput from '../common/ValidationInput';
import PhoneNumberInput from '../common/PhoneNumberInput';
import CheckBox from '../common/CheckBox';
import { UserAccount } from '../../domainObjects/UserAccount';
import { CreateAccountResponse } from '../../domainObjects/CreateAccountResponse';
import { Country } from '../../domainObjects/Country';
import { CountryCode } from '../../domainObjects/CountryCode';
import accountSubscriptionService from '../../services/AccountSubscriptionService';
import { useIntl, FormattedMessage } from 'react-intl';
import ClickableText from '../common/ClickableText';
import useScreenSize from '../../hooks/useScreenSize';
import ValidationHelper from '../../helpers/ValidationHelper';

import { ReactComponent as ShowPasswordIcon } from '../../content/icons/password-hide.svg';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';

import CreationWizardProgressBarItem from '../../enums/CreationWizardProgressBarItem';
import CreationWizardFooter from './CreationWizardFooter';
import useValidation from '../../hooks/useValidation';
import ResDiaryDropdown from '../common/ResDiaryDropdown';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import trackingEvents from '../../enums/trackingEvents';

export type CreateAccountFormProps = {
    goToNextPage: Function;
    setUserAccount: Function;
    countries?: Array<Country>;
    countryCodes?: Array<CountryCode>;
};

const CreateAccountForm = ({ goToNextPage, setUserAccount, countries, countryCodes }: CreateAccountFormProps) => {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();
    const [showPassword, toggleShowPassword] = useReducer(toggle, false);
    const [tooltipOpen, setTooltipOpen] = useReducer(toggle, false);

    const [account, setAccount] = useState<UserAccount>({
        FirstName: '',
        LastName: '',
        ProviderName: '',
        CountryId: 0,
        MobilePhoneCode: '',
        MobilePhoneNumber: '',
        EmailAddress: '',
        Password: '',
        LimitedCompanyName: '',
        CountryAlpha2Code: '',
        MobilePhoneCodeCountryId: '',
    });

    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState<boolean>(false);
    const [limitedCompanyMatchesVenueName, setLimitedCompanyMatchesVenueName] = useState<boolean>(true);
    const formValidation = useValidation();

    const getCountryOptions = (): Array<HTMLOptionElement> => {
        return countries
            ? countries.map((country) => {
                  return new Option(country?.Name, country?.Id.toString());
              })
            : [];
    };

    const getCountryCodeOptions = (): Array<HTMLOptionElement | null> => {
        return countryCodes
            ? countryCodes.map((countryCode) => {
                  if (countryCode) {
                      return new Option(`${countryCode?.Name}: +${countryCode.PhoneCode}`, countryCode?.Id.toString());
                  } else {
                      return null;
                  }
              })
            : [];
    };

    function toggle(value: boolean) {
        return !value;
    }

    function validatePassword(password: string) {
        if (password.length < 6 || !hasUpperCase(password)) {
            return intl.formatMessage({ id: 'CreationWizard.PasswordValidationMessage' });
        }
        return true;
    }

    function hasUpperCase(string: string) {
        return string.toLowerCase() !== string;
    }

    const onCountryChange = (value: any) => {
        const countryCode = countryCodes?.find((x) => x.Id === parseInt(value));
        setAccount({
            ...account,
            CountryId: parseInt(value),
            MobilePhoneCodeCountryId: value,
            MobilePhoneCode: countryCode ? countryCode.PhoneCode.toString() : '',
        });
    };

    const onCountryCodeChange = (value: any) => {
        const countryCode = countryCodes?.find((x) => x.Id === parseInt(value));
        if (countryCode != null) {
            const updatedAccountDetails = {
                ...account,
                MobilePhoneCode: countryCode.PhoneCode.toString(),
                MobilePhoneCodeCountryId: countryCode.Id.toString(),
            };
            setAccount(updatedAccountDetails);
        }
    };

    function createAccount() {
        const thisCountryCode = countryCodes?.find((item) => item.Id === account.CountryId);
        account.CountryAlpha2Code = thisCountryCode ? thisCountryCode.IsoTwoLetterCode : '';
        if (limitedCompanyMatchesVenueName) {
            account.LimitedCompanyName = account.ProviderName;
        }
        SettingsHelper.setUserAccount(account);
        accountSubscriptionService.createUserAccount(account).then((result: CreateAccountResponse) => {
            if (
                result &&
                result.AuthenticationResponse &&
                result.AuthenticationResponse.Token &&
                result.AuthenticationResponse.TokenExpiryUtc
            ) {
                SettingsHelper.setJwtToken(result.AuthenticationResponse.Token);
                SettingsHelper.setUserID(result.UserId.toString());
                setUserAccount(account);

                const trackingProperties = {
                    userId: SettingsHelper.getUserId(),
                    email: account.EmailAddress,
                    telephone: `+${countryCodes?.find((_) => _.Id === parseInt(account.MobilePhoneCode))?.PhoneCode} ${
                        account.MobilePhoneNumber
                    }`,
                    providerType: 'RDF',
                    timeStamp: new Date().toISOString(),
                };

                AnalyticsHelper.trackClickWithPageNameAndProperties(
                    trackingEvents.selfServiceSubscriptionAccountCreated,
                    'CreateAccountPage',
                    trackingProperties
                );
                goToNextPage();
            }
        });
    }

    function isUsernameUnique(value: string) {
        return accountSubscriptionService.usernameIsValid(value).then((result: boolean) => {
            if (!result) {
                return intl.formatMessage({ id: 'CreationWizard.ProvideAUniqueEmailAddress' });
            } else {
                return true;
            }
        });
    }

    return (
        <>
            <Form
                className="creation-wizard-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.submit(createAccount);
                }}
            >
                <div className={isMobileView ? 'creation-wizard-form-wrapper mob' : 'creation-wizard-form-wrapper'}>
                    <h2>
                        <FormattedMessage id="CreationWizard.CreateAnAccount"></FormattedMessage>
                    </h2>
                    <FormGroup className="account-details-form-group">
                        <div className="d-flex">
                            <div className="flex-fill mr-4">
                                <Label for="firstName" id="firstName">
                                    {intl.formatMessage({ id: 'CreationWizard.FirstName' })}
                                </Label>
                                <ValidationInput
                                    type="text"
                                    name="firstName"
                                    value={account?.FirstName}
                                    onChange={(e) => setAccount({ ...account, FirstName: e.toString() })}
                                    innerRef={formValidation.register({
                                        required: intl.formatMessage(
                                            { id: 'Common.FieldRequiredGenericMessage' },
                                            { fieldName: intl.formatMessage({ id: 'CreationWizard.FirstName' }) }
                                        ),
                                    })}
                                    errors={formValidation.errors}
                                    ariaLabelledBy={'firstName'}
                                    ariaRequired
                                />
                            </div>
                            <div className="flex-fill">
                                <Label for="lastName" id="lastName">
                                    {intl.formatMessage({ id: 'CreationWizard.LastName' })}
                                </Label>
                                <ValidationInput
                                    type="text"
                                    name="lastName"
                                    value={account?.LastName}
                                    onChange={(e) => setAccount({ ...account, LastName: e.toString() })}
                                    innerRef={formValidation.register({
                                        required: intl.formatMessage(
                                            { id: 'Common.FieldRequiredGenericMessage' },
                                            { fieldName: intl.formatMessage({ id: 'CreationWizard.LastName' }) }
                                        ),
                                    })}
                                    errors={formValidation.errors}
                                    ariaLabelledBy={'lastName'}
                                    ariaRequired
                                />
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className="account-details-form-group">
                        <Label for="venueName" id="venueName">
                            {intl.formatMessage({ id: 'CreationWizard.VenueName' })}
                        </Label>
                        <ValidationInput
                            type="text"
                            name="venueName"
                            value={account?.ProviderName}
                            onChange={(e) => setAccount({ ...account, ProviderName: e.toString() })}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.FieldRequiredGenericMessage' },
                                    { fieldName: intl.formatMessage({ id: 'CreationWizard.VenueName' }) }
                                ),
                            })}
                            errors={formValidation.errors}
                            ariaLabelledBy={'venueName'}
                            ariaRequired
                        />
                    </FormGroup>
                    <FormGroup className="account-details-form-group">
                        <div className="d-flex">
                            <FormattedMessage id="CreationWizard.DoesLimitedCompanyNameMatchVenueName"></FormattedMessage>
                            <InfoIcon id="LimitedCompanyNameTooltip" className="ltd-company-info-icon" />
                            <Tooltip
                                innerClassName="tooltip-style"
                                placement="top"
                                isOpen={tooltipOpen}
                                target="LimitedCompanyNameTooltip"
                                toggle={setTooltipOpen}
                            >
                                <FormattedMessage id="CreationWizard.LimitedCompanyNameTooltipText" />
                            </Tooltip>
                        </div>
                        <div className="checkbox-container">
                            <CustomInput
                                checked={limitedCompanyMatchesVenueName}
                                onChange={() => setLimitedCompanyMatchesVenueName(true)}
                                type="radio"
                                id={'radio_namesMatch'}
                                name={'radioNamesMatch'}
                                label={intl.formatMessage({ id: 'Common.Yes' })}
                            />
                            <CustomInput
                                checked={!limitedCompanyMatchesVenueName}
                                onChange={() => setLimitedCompanyMatchesVenueName(false)}
                                type="radio"
                                id={'radio_namesDontMatch'}
                                name={'radioNamesDontMatch'}
                                className={'ml-4'}
                                label={intl.formatMessage({ id: 'Common.No' })}
                            />
                        </div>
                    </FormGroup>
                    {!limitedCompanyMatchesVenueName && (
                        <FormGroup className="account-details-form-group">
                            <Label for="limitedCompanyName" id="limitedCompanyName">
                                {intl.formatMessage({ id: 'CreationWizard.LimitedCompanyName' })}
                            </Label>
                            <ValidationInput
                                type="text"
                                name="limitedCompanyName"
                                value={account?.LimitedCompanyName}
                                onChange={(e) => setAccount({ ...account, LimitedCompanyName: e.toString() })}
                                innerRef={formValidation.register({
                                    validate: (value: string) => {
                                        if (!value && !limitedCompanyMatchesVenueName)
                                            return intl.formatMessage(
                                                { id: 'Common.FieldRequiredGenericMessage' },
                                                {
                                                    fieldName: intl.formatMessage({
                                                        id: 'CreationWizard.LimitedCompanyName',
                                                    }),
                                                }
                                            );
                                    },
                                })}
                                errors={formValidation.errors}
                                ariaLabelledBy={'limitedCompanyName'}
                                ariaRequired
                            />
                        </FormGroup>
                    )}
                    <FormGroup className="account-details-form-group">
                        <Label for="countryDropdown" id="countryDropdown">
                            {intl.formatMessage({ id: 'CreationWizard.Country' })}
                        </Label>
                        <ResDiaryDropdown
                            options={getCountryOptions()}
                            onValueChange={onCountryChange}
                            selectedValue={account?.CountryId.toString()}
                            isSearchable
                            defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                            hideRemoveItemIcon
                            name={'countryDropdown'}
                        />
                    </FormGroup>
                    <FormGroup className="account-details-form-group">
                        <PhoneNumberInput
                            onChange={(e) => setAccount({ ...account, MobilePhoneNumber: e.toString() })}
                            onCountryCodeChange={onCountryCodeChange}
                            selectedValue={account?.MobilePhoneCodeCountryId}
                            inputValue={account?.MobilePhoneNumber}
                            optionGroups={getCountryCodeOptions()}
                            name="VenuePhoneNumberField"
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.FieldRequiredGenericMessage' },
                                    { fieldName: intl.formatMessage({ id: 'CreationWizard.PhoneNumber' }) }
                                ),
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: intl.formatMessage({
                                        id: 'CreationWizard.ProvideAValidPhoneNumber',
                                    }),
                                },
                            })}
                            errors={formValidation.errors}
                            includeValidation
                            label={intl.formatMessage({ id: 'CreationWizard.PhoneNumber' })}
                            inputClassName={'create-account-phone-input'}
                        />
                    </FormGroup>
                    <FormGroup className="account-details-form-group">
                        <Label for="emailAddress" id="emailAddress">
                            {intl.formatMessage({ id: 'CreationWizard.EmailAddress' })}
                        </Label>
                        <ValidationInput
                            type="text"
                            name="emailAddress"
                            value={account?.EmailAddress}
                            onChange={(e) => setAccount({ ...account, EmailAddress: e.toString() })}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.FieldRequiredGenericMessage' },
                                    { fieldName: intl.formatMessage({ id: 'CreationWizard.EmailAddress' }) }
                                ),
                                validate: (value) => isUsernameUnique(value),
                                pattern: {
                                    value: ValidationHelper.getEmailValidationRegex(),
                                    message: intl.formatMessage({
                                        id: 'CreationWizard.ProvideAValidEmailAddress',
                                    }),
                                },
                            })}
                            errors={formValidation.errors}
                            ariaLabelledBy={'emailAddress'}
                            ariaRequired
                        />
                    </FormGroup>
                    <FormGroup className="account-details-form-group password">
                        <Label for="password" id="password">
                            {intl.formatMessage({ id: 'CreationWizard.Password' })}
                        </Label>
                        <ValidationInput
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={account?.Password}
                            onChange={(e) => setAccount({ ...account, Password: e.toString() })}
                            innerRef={formValidation.register({
                                validate: (value) => validatePassword(value),
                            })}
                            errors={formValidation.errors}
                            ariaLabelledBy={'password'}
                            ariaRequired
                            hideValidationIcon
                        />
                        {!formValidation.errors['password'] && (
                            <div className="password-restrictions-info">
                                <InfoIcon />
                                <div className="ml-1">
                                    <FormattedMessage id="CreationWizard.PasswordValidationMessage" />
                                </div>
                            </div>
                        )}
                        <div className="toggle-password-visible" onClick={() => toggleShowPassword()}>
                            <ShowPasswordIcon />
                        </div>
                    </FormGroup>
                    <FormGroup className="account-details-form-group password">
                        <CheckBox
                            id={'rd-terms-and-conditions-checkbox'}
                            name="termsAndCo"
                            option={{
                                text: intl.formatMessage(
                                    { id: 'CreationWizard.TermsAndConditionsCheckboxLabel' },
                                    {
                                        termsAndConditions: (
                                            <ClickableText
                                                text={intl.formatMessage({ id: 'CreationWizard.TermsAndConditions' })}
                                                redirectUrl={'https://sales.resdiary.com/terms-conditions/'}
                                                className="d-inline"
                                                openInNewTab
                                            />
                                        ),
                                        privacyPolicy: (
                                            <ClickableText
                                                text={intl.formatMessage({ id: 'CreationWizard.PrivacyPolicy' })}
                                                redirectUrl={'https://sales.resdiary.com/privacy-statement/'}
                                                className="d-inline"
                                                openInNewTab
                                            />
                                        ),
                                    }
                                ),
                                value: 0,
                            }}
                            onChange={(e) => setTermsAndConditionsChecked(e)}
                            isChecked={termsAndConditionsChecked}
                            innerRef={formValidation.register({
                                required: intl.formatMessage({ id: 'CreationWizard.TermsAndCoValidationMessage' }),
                            })}
                            errors={formValidation.errors}
                        />
                    </FormGroup>
                </div>
                <CreationWizardFooter
                    currentPage={CreationWizardProgressBarItem.CreateAccountPage}
                    buttonDisabled={(formValidation && formValidation.submitDisabled) || !termsAndConditionsChecked}
                />
            </Form>
        </>
    );
};

export default CreateAccountForm;
