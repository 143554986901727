import { PlanItemDto } from '../domainObjects/PlanItemDto';
import ApiHelper from '../helpers/ApiHelper';

export type GetAddOnsRequest = {
    period: number;
    periodUnit: number;
    alpha2Code: string;
};

export type GetPlanInformationRequest = {
    period: number;
    periodUnit: number;
    alpha2Code: string;
    chargebeePlanId: string;
};

export type GetAvailablePlansRequest = {
    periodUnit: number;
    alpha2Code: string;
};

class AddonsService {
    static getAddons(request: GetAddOnsRequest) {
        return ApiHelper.postJSON(`/api/ChargeBee/GetAddonsForAccount`, JSON.stringify(request)).then(
            (response) => response.data
        );
    }
    static getPlanInformation(request: GetPlanInformationRequest) {
        return ApiHelper.postJSON(`/api/ChargeBee/GetPlanInformation`, JSON.stringify(request)).then(
            (response) => response.data
        );
    }
    static getAvailablePlans(request: GetAvailablePlansRequest): Promise<PlanItemDto[]> {
        return ApiHelper.postJSON(`/api/ChargeBee/GetAvailablePlans`, JSON.stringify(request)).then(
            (response) => response.data
        );
    }
}

export default AddonsService;
