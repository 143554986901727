import { useState } from 'react';
import { ReactComponent as UpIcon } from '../../content/icons/up.svg';
import { ReactComponent as DownIcon } from '../../content/icons/down.svg';
import AccordionItem from '../../domainObjects/AccordionItem';

export type AccordionProps = {
    accordionItems: AccordionItem[];
    style: React.CSSProperties;
};

const Accordion = ({ accordionItems, style }: AccordionProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);

    const updateActiveTabIndex = (index: number) => {
        index !== activeTabIndex ? setActiveTabIndex(index) : setActiveTabIndex(null);
    };

    return (
        <div style={style} className="accordion">
            {accordionItems.map(({ title, content }: AccordionItem, index: number) => {
                let isActive = activeTabIndex === index;
                return (
                    <div key={index} className="accordion-item">
                        <div className="accordion-title" onClick={() => updateActiveTabIndex(index)}>
                            <div>{title}</div>
                            <div>{isActive ? <UpIcon /> : <DownIcon />}</div>
                        </div>
                        {isActive && <div className="accordion-content">{content}</div>}
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion;
