import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import useScreenSize from '../../hooks/useScreenSize';
import AddOn from '../../viewModels/AddOn';
import ColumnLayout from './AddOnModalBodyLayouts/ColumnLayout';
import ImageLayout from './AddOnModalBodyLayouts/ImageLayout';
import AddOnLayout from '../../enums/AddOnLayout';
import DescriptionLayout from './AddOnModalBodyLayouts/DescriptionLayout';
import PlanBillingType from '../../enums/PlanBillingType';

export type AddOnModalProps = {
    isSelected: Function;
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    setIsAddOnModalOpen: Function;
    addOn?: AddOn;
    setModalAddOnType: Function;
    addOns: Array<AddOn>;
    currencySymbol: string;
    planBillingType?: PlanBillingType;
};

const AddOnModal = ({
    isSelected,
    selectAddOnItem,
    unselectAddOnItem,
    setIsAddOnModalOpen,
    addOn,
    setModalAddOnType,
    addOns,
    currencySymbol,
    planBillingType,
}: AddOnModalProps) => {
    const intl = useIntl();
    const { isTabletView } = useScreenSize();
    const [recommendedAddOns, setRecommendedAddOns] = useState<AddOn[]>([]);

    useEffect(() => {
        if (addOn) {
            setRecommendedAddOns(
                addOns.filter((a) => {
                    return a.isRecommendedAddOn === true && a.type !== addOn.type;
                })
            );
        }
    }, [addOns, addOn]);

    function getLayoutType() {
        switch (addOn?.layout) {
            case AddOnLayout.Column:
                return (
                    <ColumnLayout
                        isSelected={isSelected}
                        selectAddOnItem={selectAddOnItem}
                        unselectAddOnItem={unselectAddOnItem}
                        setIsAddOnModalOpen={setIsAddOnModalOpen}
                        addOn={addOn}
                    />
                );
            case AddOnLayout.Image:
                return (
                    <ImageLayout
                        isSelected={isSelected}
                        selectAddOnItem={selectAddOnItem}
                        unselectAddOnItem={unselectAddOnItem}
                        setIsAddOnModalOpen={setIsAddOnModalOpen}
                        addOn={addOn}
                    />
                );
            case AddOnLayout.Description:
                return (
                    <DescriptionLayout
                        isSelected={isSelected}
                        selectAddOnItem={selectAddOnItem}
                        unselectAddOnItem={unselectAddOnItem}
                        setIsAddOnModalOpen={setIsAddOnModalOpen}
                        addOn={addOn}
                    />
                );
            default:
                break;
        }
    }

    return (
        <div className="addon-modal">
            <div>{getLayoutType()}</div>
            <div className={isTabletView ? 'addon-modal-footer mob' : 'addon-modal-footer'}>
                <div className={isTabletView ? 'addon-modal-footer-wrapper mob' : 'addon-modal-footer-wrapper'}>
                    <div className="addon-modal-footer-title">
                        <FormattedMessage id="CreationWizard.RecommendedForYou" />
                    </div>
                    <div
                        className={
                            isTabletView
                                ? 'addon-modal-footer-content-container mobile'
                                : 'd-flex addon-modal-footer-content-container'
                        }
                    >
                        {recommendedAddOns.map((recommendedAddon) => {
                            return (
                                <div
                                    className="recommended-addon"
                                    onClick={() => setModalAddOnType(recommendedAddon.type)}
                                    key={recommendedAddon.type}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="addon-logo">{recommendedAddon.logo}</div>
                                        <div className="add-on-information">
                                            <div className="addon-title font-weight-bold mb-1">
                                                {recommendedAddon.title}
                                            </div>
                                            <div className="addon-cost">
                                                {intl.formatMessage(
                                                    { id: `CreationWizard.BundlePricePerBillingCycle` },
                                                    {
                                                        currencySymbol: currencySymbol,
                                                        bundlePrice: recommendedAddon.cost,
                                                        billingCycle:
                                                            planBillingType === PlanBillingType.Monthly
                                                                ? intl.formatMessage({ id: 'Common.Month' })
                                                                : intl.formatMessage({ id: 'Common.Year' }),
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddOnModal;
