import React, { Fragment } from 'react';
import useScreenSize from '../../../hooks/useScreenSize';
import AddOn from '../../../viewModels/AddOn';
import AddOnModalActions from '../AddOnModalActions';

export type ImageLayoutProps = {
    isSelected: Function;
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    setIsAddOnModalOpen: Function;
    addOn: AddOn;
};

const ImageLayout = ({
    isSelected,
    selectAddOnItem,
    unselectAddOnItem,
    setIsAddOnModalOpen,
    addOn,
}: ImageLayoutProps) => {
    const { isTabletView } = useScreenSize();

    return (
        <Fragment>
            <div className={isTabletView ? 'addon-modal-images mob' : 'addon-modal-images'}>
                <div className="addon-modal-body-header-description">
                    <h6>{addOn?.modalDescription}</h6>
                </div>
                <div className={isTabletView ? 'addon-modal-images-container mob' : 'addon-modal-images-container'}>
                    {addOn.imagesInLayout?.map((val: string, index: number) => (
                        <img src={val} key={index} alt={index.toString()} />
                    ))}
                </div>
            </div>
            <AddOnModalActions
                isSelected={isSelected}
                selectAddOnItem={selectAddOnItem}
                unselectAddOnItem={unselectAddOnItem}
                setIsAddOnModalOpen={setIsAddOnModalOpen}
                addOn={addOn}
            />
        </Fragment>
    );
};

export default ImageLayout;
