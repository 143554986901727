import Geometry, { GeometrySettings } from './Geometry';

export type PlaceResultSettings = {
    PlaceId: string;
    AddressComponents: Array<AddressComponent>;
    Geometry: GeometrySettings;
};

export type AddressComponent = {
    LongName: string;
    Types: Array<string>;
};

class PlaceResult {
    placeId: string;
    addressComponents: Array<AddressComponent>;
    geometry: Geometry;

    constructor(placeResultSettings: PlaceResultSettings | null) {
        this.placeId = placeResultSettings && placeResultSettings.PlaceId ? placeResultSettings.PlaceId : '';
        this.addressComponents =
            placeResultSettings && placeResultSettings.AddressComponents
                ? placeResultSettings.AddressComponents.map((addressComponent) => {
                      return {
                          LongName: addressComponent.LongName,
                          Types: addressComponent.Types ? addressComponent.Types : [],
                      };
                  })
                : [];
        this.geometry =
            placeResultSettings && placeResultSettings.Geometry
                ? new Geometry(placeResultSettings.Geometry)
                : new Geometry(null);
    }
}

export default PlaceResult;
