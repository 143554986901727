import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

type DeleteServiceSetupModalProps = {
    isUpdating: boolean;
    id: number;
    serviceName: string;
    setIsModalOpen: (isModalOpen: boolean) => void;
    deleteServiceSetup: (id: number) => void;
};

const DeleteServiceSetupModal = ({
    isUpdating,
    id,
    serviceName,
    setIsModalOpen,
    deleteServiceSetup,
}: DeleteServiceSetupModalProps) => {
    const intl = useIntl();
    return (
        <div className="service-modal">
            <div className="service-modal-header">
                <p className="service-modal-title">
                    {intl.formatMessage(
                        {
                            id: 'OnboardingWizard.VenueCoversAndIntervalsDeleteConfirmationTitle',
                        },
                        { serviceName: serviceName }
                    )}
                </p>
                <p>
                    {intl.formatMessage({
                        id: 'OnboardingWizard.VenueCoversAndIntervalsDeleteConfirmationText',
                    })}
                </p>
                <Button
                    close
                    color="primary"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="service-modal-close-button"
                />
            </div>
            <div className="service-modal-body">
                <Button
                    color="primary"
                    outline={true}
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                >
                    {intl.formatMessage({ id: 'Common.Cancel' })}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        deleteServiceSetup(id);
                        setIsModalOpen(false);
                    }}
                    disabled={isUpdating}
                >
                    {intl.formatMessage({ id: 'Common.Confirm' })}
                </Button>
            </div>
        </div>
    );
};

export default DeleteServiceSetupModal;
