import { MouseEventHandler } from 'react';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

export type NoPermissionPanelProps = {
    errorMessage: any;
    redirect?: boolean;
    buttonMessage?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

function NoPermissionPanel({ errorMessage, redirect, buttonMessage, onClick }: NoPermissionPanelProps) {
    return (
        <div className="no-permission-section">
            <WarningIcon />
            <p>{errorMessage}</p>
            {redirect && (
                <Button color="primary" className="image-editor-button btn-primary" onClick={onClick}>
                    <FormattedMessage id={buttonMessage} />
                </Button>
            )}
        </div>
    );
}
export default NoPermissionPanel;
