export type TableSettings = {
    Id: number;
    Number: string;
    Description: string;
    IsEnabled: boolean;
    Capacity: number;
    Priority: number;
    AreaId: number;
    ProviderId: number;
    TableType: number;
    SegmentId: number;
};
class Table {
    id: number;
    number: string;
    description: string;
    isEnabled: boolean;
    capacity: number;
    priority: number;
    areaId: number;
    providerId: number;
    tableType: number;
    segmentId: number;
    constructor(settings: TableSettings) {
        this.id = settings && settings.Id ? settings.Id : 0;
        this.isEnabled = settings && settings.IsEnabled ? settings.IsEnabled : true;
        this.number = settings && settings.Number ? settings.Number : '';
        this.description = settings && settings.Description ? settings.Description : '';
        this.capacity = settings && settings.Capacity ? settings.Capacity : 2;
        this.priority = settings && settings.Priority ? settings.Priority : 1;
        this.areaId = settings && settings.AreaId ? settings.AreaId : 0;
        this.providerId = settings && settings.ProviderId ? settings.ProviderId : 0;
        this.tableType = settings && settings.TableType ? settings.TableType : 0;
        this.segmentId = settings && settings.SegmentId ? settings.SegmentId : 0;
    }
}
export default Table;
