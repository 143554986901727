import useMediaQuery from './useMediaQuery';

function useScreenSize() {
    const isTabletView = useMediaQuery('(max-width:900px)');
    const isMobileView = useMediaQuery('(max-width:576px)');

    return { isTabletView, isMobileView };
}

export default useScreenSize;
