import React from 'react';
import loadingIcon from '../../content/images/rd-bowtie-anim-transp.gif';

export type LoadingSpinnerProps = {
    loadingText?: string;
    bottomPadding?: boolean;
    noMargin?: boolean;
};

const LoadingSpinner = ({ loadingText, bottomPadding, noMargin }: LoadingSpinnerProps) => {
    const wrapperClassName = noMargin ? 'rd-spinner-wrapper--no-margin' : 'rd-spinner-wrapper';
    const spinnerClassName = noMargin ? 'rd-spinner--no-margin' : 'rd-spinner';
    return (
        <div className={bottomPadding ? wrapperClassName + ' bottom-padding' : wrapperClassName}>
            <img className={`${spinnerClassName} fade-in-1s`} alt="spinner" src={loadingIcon} />
            {loadingText && <div className="rd-spinner-text">{loadingText}</div>}
        </div>
    );
};

export default LoadingSpinner;
