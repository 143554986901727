import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-dcActivation.jpg';
import DefaultMainImage from '../../../content/images/Desktop_Masthead_banner.png';
import { FormattedMessage, useIntl } from 'react-intl';
import RadioSelectionWithButtons from '../../common/RadioSelectionWithButtons';
import DevicePreviewSelection from '../../../enums/DevicePreviewSelection';
import { useState } from 'react';
import { ReactComponent as PhotosIcon } from '../../../content/icons/photos.svg';
import { ReactComponent as StarIcon } from '../../../content/icons/starZ.svg';
import { ReactComponent as DCLogoIcon } from '../../../content/icons/DClogo.svg';
import { ReactComponent as CoversIcon } from '../../../content/icons/people.svg';
import { ReactComponent as CalendarIcon } from '../../../content/icons/date.svg';
import { ReactComponent as ClockIcon } from '../../../content/icons/time.svg';
import { ReactComponent as CheckedIcon } from '../../../content/icons/checked.svg';
import TextWithIcon from '../../common/TextWithIcon';
import SearchDetails from '../../../domainObjects/SearchDetails';
import NameAddressDetails from '../../../domainObjects/NameAddressDetails';
import { Button, CustomInput, UncontrolledTooltip } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import trackingEvents from '../../../enums/trackingEvents';
import rdfOnboardingProgressFlags from '../../../enums/rdfOnboardingProgressFlags';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import VenueImage from '../../../domainObjects/VenueImage';
import useScreenSize from '../../../hooks/useScreenSize';

type Cuisine = {
    id: string;
    name: string;
};

export type DishCultActivationPageProps = {
    venueName: string;
    venueHeadline: string;
    venueDescription: string;
    venueImage: VenueImage;
    cuisines: Array<Cuisine>;
    hideOnPortal: boolean;
    updateSettings: () => void;
    updateHideOnPortal: (value: boolean) => void;
    searchDetails: SearchDetails;
    nameAddressDetails: NameAddressDetails;
    updateProgressFlag: (progressFlag: string) => void;
    setIsNavigatingFromDishCult: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
};

const DishCultActivationPage = ({
    venueName,
    venueHeadline,
    venueDescription,
    venueImage,
    cuisines,
    hideOnPortal,
    updateSettings,
    updateHideOnPortal,
    searchDetails,
    nameAddressDetails,
    updateProgressFlag,
    setIsNavigatingFromDishCult,
    isLoading,
}: DishCultActivationPageProps) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [devicePreviewSelection, setDevicePreviewSelection] = useState(DevicePreviewSelection.Desktop);
    const { isMobileView } = useScreenSize();

    const getVenueCuisines = () => {
        let primaryCuisine = cuisines.find((c) => c.id.toString() === searchDetails.primaryCuisineId);
        return primaryCuisine?.name;
    };

    const updateSettingsAndProgressFlag = () => {
        updateSettings();
        updateProgressFlag(rdfOnboardingProgressFlags.selectedDCListingStatus);
    };

    const getFormBody = () => {
        let desktopPreviewSelected = devicePreviewSelection === DevicePreviewSelection.Desktop;
        return (
            <div className="dishcult-page">
                {!isMobileView && (
                    <RadioSelectionWithButtons
                        listOfValues={[
                            {
                                value: DevicePreviewSelection.Desktop,
                                text: intl.formatMessage({ id: 'Common.Desktop' }),
                            },
                            {
                                value: DevicePreviewSelection.Mobile,
                                text: intl.formatMessage({ id: 'Common.Mobile' }),
                            },
                        ]}
                        selectedValue={devicePreviewSelection}
                        onChange={setDevicePreviewSelection}
                    />
                )}
                <div
                    className="dishcult-page-info-label"
                    style={{
                        margin: '20px 0',
                    }}
                >
                    <FormattedMessage id="OnboardingWizard.DishCultListingActivationUpdateInfoLabel" />
                </div>
                {desktopPreviewSelected && !isMobileView ? (
                    <div className="dishcult-page-preview-wrapper">
                        <div className="dishcult-page-preview">
                            {venueImage && (
                                <img
                                    className="dishcult-page-preview-image"
                                    width="568"
                                    height="250"
                                    src={venueImage.imageUrl !== '' ? venueImage.imageUrl : DefaultMainImage}
                                    alt={intl.formatMessage({
                                        id: 'OnboardingWizard.DishCultListingActivationMastheadAltText',
                                    })}
                                />
                            )}
                            <div
                                id="MainImageButton"
                                onClick={() => {
                                    setIsNavigatingFromDishCult(true);
                                    navigate(`/OnboardingWizard/${rdfOnboardingSteps.mainImagePage}`);
                                }}
                                className="dishcult-page-content-header selectable-content"
                            >
                                <div className="dishcult-page-icon-header">
                                    <DCLogoIcon />
                                </div>
                                <div className="dishcult-page-title-header">
                                    <h4>{venueName}</h4>
                                    <div className="dishcult-page-photos-reviews-wrapper">
                                        <TextWithIcon
                                            icon={<PhotosIcon />}
                                            text={`12 ${intl.formatMessage({ id: 'Common.Photos' })}`}
                                        ></TextWithIcon>
                                        <TextWithIcon
                                            icon={<StarIcon />}
                                            text={`4.7 (1248 ${intl.formatMessage({ id: 'Common.Reviews' })})`}
                                        ></TextWithIcon>
                                    </div>
                                </div>
                            </div>
                            <UncontrolledTooltip flip target="MainImageButton">
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.DishCultActivationMainImageTooltip',
                                })}
                            </UncontrolledTooltip>
                            <div
                                id="PrimaryCuisineButton"
                                onClick={() => {
                                    setIsNavigatingFromDishCult(true);
                                    navigate(`/OnboardingWizard/${rdfOnboardingSteps.cuisinesPage}`, {});
                                }}
                                className="dishcult-page-cuisine-types selectable-content"
                            >
                                {getVenueCuisines()}
                            </div>
                            <UncontrolledTooltip flip target="PrimaryCuisineButton">
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.DishCultActivationPrimaryCuisineTooltip',
                                })}
                            </UncontrolledTooltip>
                            <div className="dishcult-page-content-body">
                                <div>
                                    <div className="dishcult-page-breadcrumb">
                                        {`Home > ${nameAddressDetails.country} > ${nameAddressDetails.town} > ${venueName}`}
                                    </div>
                                    <div
                                        id="DescriptionButton"
                                        onClick={() => {
                                            setIsNavigatingFromDishCult(true);
                                            navigate(`/OnboardingWizard/${rdfOnboardingSteps.descriptionPage}`);
                                        }}
                                        className="dishcult-page-description-wrapper selectable-content"
                                    >
                                        <div className="dishcult-page-headline">{venueHeadline}</div>
                                        <div className="dishcult-page-description">{venueDescription}</div>
                                    </div>
                                    <UncontrolledTooltip flip target="DescriptionButton">
                                        {intl.formatMessage({
                                            id: 'OnboardingWizard.DishCultActivationDescriptionTooltip',
                                        })}
                                    </UncontrolledTooltip>
                                </div>
                                <div className="dishcult-page-reservation-widget">
                                    <div className="dishcult-page-reservation-header">
                                        {intl.formatMessage({
                                            id: 'OnboardingWizard.DishCultListingActivationReservationHeader',
                                        })}
                                    </div>
                                    <TextWithIcon
                                        className="test"
                                        icon={<CoversIcon />}
                                        text={`2 ${intl.formatMessage({ id: 'Common.People' })}`}
                                    ></TextWithIcon>
                                    <div className="d-flex">
                                        <TextWithIcon
                                            className=" mr-1"
                                            icon={<CalendarIcon />}
                                            text="2nd Feb '20"
                                        ></TextWithIcon>
                                        <TextWithIcon icon={<ClockIcon />} text="12:15pm"></TextWithIcon>
                                    </div>
                                    <TextWithIcon icon={<CheckedIcon />} text="Oysters Wednesday 99.90$"></TextWithIcon>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <div className="dishcult-page-preview-wrapper-mobile">
                            <div className="dishcult-page-preview-mobile">
                                {venueImage && (
                                    <img
                                        className="dishcult-page-preview-image"
                                        width="200"
                                        height="180"
                                        src={venueImage.imageUrl !== '' ? venueImage.imageUrl : DefaultMainImage}
                                        alt={intl.formatMessage({
                                            id: 'OnboardingWizard.DishCultListingActivationMastheadAltText',
                                        })}
                                    />
                                )}
                                <div
                                    id="MainImageButton"
                                    onClick={() => {
                                        setIsNavigatingFromDishCult(true);
                                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.mainImagePage}`);
                                    }}
                                    className="dishcult-page-content-header-wrapper-mobile selectable-content"
                                >
                                    <div className="dishcult-page-content-header-mobile">
                                        <h5>{venueName}</h5>
                                        <div className="dishcult-page-photos-reviews-wrapper-mobile">
                                            <TextWithIcon
                                                icon={<PhotosIcon />}
                                                text={`12 ${intl.formatMessage({ id: 'Common.Photos' })}`}
                                            ></TextWithIcon>
                                            <TextWithIcon
                                                icon={<StarIcon />}
                                                text={`4.7 (1248 ${intl.formatMessage({ id: 'Common.Reviews' })})`}
                                            ></TextWithIcon>
                                        </div>
                                    </div>
                                </div>
                                <UncontrolledTooltip flip target="MainImageButton">
                                    {intl.formatMessage({
                                        id: 'OnboardingWizard.DishCultActivationMainImageTooltip',
                                    })}
                                </UncontrolledTooltip>
                                <div
                                    id="PrimaryCuisineButton"
                                    onClick={() => {
                                        setIsNavigatingFromDishCult(true);
                                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.cuisinesPage}`);
                                    }}
                                    className="dishcult-page-cuisine-types-mobile selectable-content"
                                >
                                    {getVenueCuisines()}
                                </div>
                                <UncontrolledTooltip flip target="PrimaryCuisineButton">
                                    {intl.formatMessage({
                                        id: 'OnboardingWizard.DishCultActivationPrimaryCuisineTooltip',
                                    })}
                                </UncontrolledTooltip>
                                <div className="dishcult-page-content-body-mobile">
                                    <div>
                                        <div
                                            id="DescriptionButton"
                                            onClick={() => {
                                                setIsNavigatingFromDishCult(true);
                                                navigate(`/OnboardingWizard/${rdfOnboardingSteps.descriptionPage}`);
                                            }}
                                            className="dishcult-page-description-wrapper-mobile selectable-content"
                                        >
                                            <div className="dishcult-page-headline">{venueHeadline}</div>
                                            <div className="dishcult-page-description">{venueDescription}</div>
                                        </div>
                                        <UncontrolledTooltip flip target="DescriptionButton">
                                            {intl.formatMessage({
                                                id: 'OnboardingWizard.DishCultActivationDescriptionTooltip',
                                            })}
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                                <div className="dishcult-page-content-footer-mobile">
                                    <hr />
                                    <Button style={{ textTransform: 'uppercase' }}>
                                        {intl.formatMessage({
                                            id: 'OnboardingWizard.DishCultListingActivationReserveNowButton',
                                        })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="dishcult-page-checkboxes">
                    <div>
                        <CustomInput
                            checked={!hideOnPortal}
                            onChange={() => {
                                updateHideOnPortal(false);
                            }}
                            type="radio"
                            id={'radio_namesMatch'}
                            name={'radioNamesMatch'}
                            label={
                                <span>
                                    {intl.formatMessage({ id: 'OnboardingWizard.DishCultPageActivateCheckboxLabel' })}
                                </span>
                            }
                        />
                        <FormattedMessage tagName="p" id="OnboardingWizard.DishCultPageActivateCheckboxInfo" />
                    </div>
                    <div>
                        <CustomInput
                            checked={hideOnPortal}
                            onChange={() => {
                                updateHideOnPortal(true);
                            }}
                            type="radio"
                            id={'radio_namesDontMatch'}
                            name={'radioNamesDontMatch'}
                            label={
                                <span>
                                    {intl.formatMessage({ id: 'OnboardingWizard.DishCultPageLaterCheckboxLabel' })}
                                </span>
                            }
                        />
                        <FormattedMessage tagName="p" id="OnboardingWizard.DishCultPageLaterCheckboxInfo" />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.DishCultListingActivationInfoPanelTitle' })}
            infoPanelSubtitle={intl.formatMessage({
                id: 'OnboardingWizard.DishCultListingActivationInfoPanelSubTitle',
            })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.dishcultActivationPage}
            updateSettings={updateSettingsAndProgressFlag}
            children={getFormBody()}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfActivateDcPage}
        />
    );
};

export default DishCultActivationPage;
