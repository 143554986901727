import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import ResDiaryLogo from '../../content/images/resdiary-logo-white.png';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';
import { ReactComponent as WatchIcon } from '../../content/icons/duration.svg';
import { ReactComponent as CalendarIcon } from '../../content/icons/date.svg';
import TextWithIcon from '../common/TextWithIcon';
import { Button } from 'reactstrap';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import TrainingWidgetDatePicker from './TrainingWidgetDatePicker';
import TrainingWidgetContactDetails from './TrainingWidgetContactDetails';
import TrainingWidgetBookingSuccess from './TrainingWidgetBookingSuccess';
import TrainingWidgetBookingFailure from './TrainingWidgetBookingFailure';
import TrainingWidgetPageType from '../../enums/TrainingWidgetPageType';
import createBookingStatus from '../../enums/createBookingStatus';
import { ContactDetails } from '../../domainObjects/ContactDetails';
import { TrainingWidgetProviderDetails } from '../../domainObjects/TrainingWidgetProviderDetails';
import { addMinutes, setHours, setMinutes, format } from 'date-fns';
import useValidation from '../../hooks/useValidation';
import TrainingWidgetService from '../../services/TrainingWidgetService';
import { CountryCode } from '../../domainObjects/CountryCode';
import countryService from '../../services/CountryService';
import restaurantService from '../../services/RestaurantService';
import settingsService from '../../services/SettingsService';
import { formatInTimeZone } from 'date-fns-tz';
import { findIana } from 'windows-iana';
import SettingsHelper from '../../helpers/SettingsHelper';
import AccountSubmissionService from '../../services/AccountSubmissionService';
import trackingEvents from '../../enums/trackingEvents';

export default function TrainingWidgetPage() {
    const intl = useIntl();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();
    const [supportEmail, setSupportEmail] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<TrainingWidgetPageType>(TrainingWidgetPageType.DateAndTimeSelection);
    const [contactDetails, setContactDetails] = useState<ContactDetails>({
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PhoneCode: '',
        PhoneNumber: '',
    });
    const formValidation = useValidation();
    const [countryCodes, setCountryCodes] = useState<Array<CountryCode>>();
    const [providerDetails, setProviderDetails] = useState<TrainingWidgetProviderDetails>({
        Name: '',
        TimeZone: '',
        Iso2CountryCode: '',
    });
    const [firedPageEvent, setFiredPageEvent] = useState(false);

    useEffect(() => {
        countryService.getPhoneCountryCodes().then((countryCodes: Array<CountryCode>) => {
            setCountryCodes(countryCodes);
        });

        restaurantService.getRestaurantSetup().then((result: any) => {
            setProviderDetails((p: TrainingWidgetProviderDetails) => ({
                ...p,
                Name: result.Name as string,
                TimeZone: findIana(result.TimeZone)[0] as string,
                Iso2CountryCode: result.Iso2CountryCode as string,
            }));

            if (!firedPageEvent) {
                AnalyticsHelper.trackPageWithProperties(
                    trackingEvents.rdfTrainingWidgetPage,
                    AnalyticsHelper.getCommonTrackingProperties()
                );
                setFiredPageEvent(true);
            }
        });

        settingsService.getCurrentUser().then((userData) => {
            const splitName = userData.DisplayName.split(' ');
            const [, ...lastNames] = splitName;
            setContactDetails((c: ContactDetails) => ({
                ...c,
                FirstName: splitName[0],
                LastName: lastNames.join(' '),
                EmailAddress: userData.Username,
                PhoneCode: userData.ContactPhoneCode.toString(),
                PhoneNumber: userData.ContactPhoneNumber,
            }));
        });
    }, [firedPageEvent]);

    useEffect(() => {
        if (providerDetails.Iso2CountryCode) {
            TrainingWidgetService.getSupportEmailByAlpha2Code(providerDetails.Iso2CountryCode).then((supportEmail) => {
                setSupportEmail(supportEmail);
            });
        }
    }, [providerDetails]);

    const getCountryCodeOptions = (): Array<HTMLOptionElement | null> => {
        return countryCodes
            ? countryCodes.map((countryCode) => {
                  if (countryCode) {
                      return new Option(`${countryCode?.Name}: +${countryCode.PhoneCode}`, countryCode?.Id.toString());
                  } else {
                      return null;
                  }
              })
            : [];
    };

    const skipTraining = () => {
        AnalyticsHelper.trackClickWithPageNameAndProperties(
            trackingEvents.trainingSkipped,
            'TrainingWidget',
            AnalyticsHelper.getCommonTrackingProperties()
        );
        AnalyticsHelper.trackClickWithPageNameAndProperties(
            trackingEvents.selfOnboardingComplete,
            'TrainingWidget',
            AnalyticsHelper.getCommonTrackingProperties()
        );
        AccountSubmissionService.markDiaryOnboardingAsComplete(
            parseInt(SettingsHelper.getDeploymentId()),
            parseInt(SettingsHelper.getProviderId())
        ).then(() => {
            window.location.href = `${SettingsHelper.getLoginUrl()}/Security/Login/LoginWithAccessToken?accessToken=${SettingsHelper.getJwtToken()}&deploymentId=${SettingsHelper.getDeploymentId()}&providerId=${SettingsHelper.getProviderId()}`;
        });
    };

    function getWidgetPageContent() {
        switch (currentPage) {
            case TrainingWidgetPageType.DateAndTimeSelection:
                return (
                    <TrainingWidgetDatePicker
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedTimeSlot={selectedTimeSlot}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                        providerTimeZone={providerDetails?.TimeZone}
                        providerIso2CountryCode={providerDetails?.Iso2CountryCode}
                    />
                );
            case TrainingWidgetPageType.ContactDetails:
                return (
                    <TrainingWidgetContactDetails
                        contactDetails={contactDetails}
                        setContactDetails={setContactDetails}
                        formValidation={formValidation}
                        countryCodeOptions={getCountryCodeOptions()}
                    />
                );
            case TrainingWidgetPageType.BookingFailure:
                return <TrainingWidgetBookingFailure supportEmail={supportEmail} />;
            default:
                return (
                    <TrainingWidgetBookingSuccess
                        contactEmail={contactDetails.EmailAddress}
                        supportEmail={supportEmail}
                    />
                );
        }
    }

    function createBooking() {
        if (selectedTimeSlot && selectedDate && contactDetails && providerDetails) {
            const splitSelectedTimeSlot = selectedTimeSlot.split(':');
            const visitDateTime = setHours(
                setMinutes(selectedDate, parseInt(splitSelectedTimeSlot[1])),
                parseInt(splitSelectedTimeSlot[0])
            );
            const selectedCountryCode = countryCodes?.find((c) => c.Id === parseInt(contactDetails.PhoneCode));
            TrainingWidgetService.createBooking({
                VisitDate: format(visitDateTime, "yyyy-MM-dd'T'HH:mm"),
                PartySize: 1,
                Customer: {
                    FirstName: contactDetails.FirstName,
                    Surname: contactDetails.LastName,
                    Email: contactDetails.EmailAddress,
                    Mobile: contactDetails.PhoneNumber,
                    MobileCountryCode: selectedCountryCode?.PhoneCode.toString(),
                    CustomField: providerDetails?.Name,
                },
                Iso2CountryCode: providerDetails.Iso2CountryCode,
            }).then((result) => {
                if (result.Status === createBookingStatus.success) {
                    const trainingBookedProperties: { [key: string]: any } =
                        AnalyticsHelper.getCommonTrackingProperties();
                    trainingBookedProperties.bookingDate = visitDateTime;
                    AnalyticsHelper.trackClickWithPageNameAndProperties(
                        trackingEvents.trainingBooked,
                        'TrainingWidget',
                        trainingBookedProperties
                    );
                    setCurrentPage(TrainingWidgetPageType.Success);
                } else {
                    setCurrentPage(TrainingWidgetPageType.BookingFailure);
                }
            });
        }
    }

    function goToNextPage() {
        if (currentPage === TrainingWidgetPageType.ContactDetails) {
            formValidation.submit(createBooking);
        }
        setCurrentPage(TrainingWidgetPageType.ContactDetails);
    }

    function getTrainingBookingTimeRange() {
        if (selectedTimeSlot && providerDetails?.TimeZone) {
            const splitSelectedTimeSlot = selectedTimeSlot.split(':');
            const timeSlotDateTime = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                parseInt(splitSelectedTimeSlot[0]),
                parseInt(splitSelectedTimeSlot[1])
            );
            const startTime = formatInTimeZone(timeSlotDateTime, providerDetails?.TimeZone, 'HH:mm');
            const endTime = formatInTimeZone(addMinutes(timeSlotDateTime, 45), providerDetails?.TimeZone, 'HH:mm');
            return `${startTime} - ${endTime}`;
        }
        return null;
    }

    return (
        <div className="training-widget-page">
            <div className="resdiary-logo">
                <img src={ResDiaryLogo} alt="ResDiary logo" />
            </div>
            <div className="success-message-wrapper">
                <div className={'success-icon'}>
                    <SuccessIcon />
                </div>
                <h1>
                    <FormattedMessage id="TrainingWidget.YourDiaryHasBeenCreated"></FormattedMessage>
                </h1>
                <div>{intl.formatMessage({ id: 'TrainingWidget.BookADayMessage' })}</div>
            </div>
            <div className="training-widget">
                <div className="training-information">
                    <div className="provider-name">{providerDetails?.Name}</div>
                    <h3>{intl.formatMessage({ id: 'TrainingWidget.ResDiaryTraining' })}</h3>
                    {currentPage === TrainingWidgetPageType.DateAndTimeSelection && (
                        <div className="d-flex">
                            <div className="session-time">
                                <TextWithIcon
                                    icon={<WatchIcon />}
                                    text={intl.formatMessage(
                                        { id: `TrainingWidget.TrainingSessionLength` },
                                        {
                                            minutes: 45,
                                        }
                                    )}
                                />
                            </div>
                            <div className="virtual ml-auto">
                                <TextWithIcon
                                    icon={<InfoIcon />}
                                    text={intl.formatMessage({ id: 'TrainingWidget.Virtual' })}
                                />
                            </div>
                        </div>
                    )}
                    {currentPage !== TrainingWidgetPageType.DateAndTimeSelection ||
                        (TrainingWidgetPageType.BookingFailure && (
                            <div className="booking-info">
                                <div className="d-flex align-items-center">
                                    <CalendarIcon />
                                    {format(selectedDate, 'iiii, do MMM yyyy')}
                                </div>
                                <div className="d-flex align-items-center pt-3">
                                    <WatchIcon />
                                    {getTrainingBookingTimeRange()}
                                </div>
                            </div>
                        ))}
                </div>
                {getWidgetPageContent()}
                {currentPage !== TrainingWidgetPageType.Success && (
                    <div className="training-widget-footer d-flex align-items-center">
                        <div
                            className="link-text"
                            onClick={() => {
                                skipTraining();
                            }}
                        >
                            {intl.formatMessage({ id: 'TrainingWidget.SkipForNow' })}
                        </div>
                        <div className="ml-auto">
                            {currentPage === TrainingWidgetPageType.ContactDetails && (
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => setCurrentPage(TrainingWidgetPageType.DateAndTimeSelection)}
                                    className="mr-2"
                                >
                                    {intl.formatMessage({ id: 'Common.Back' })}
                                </Button>
                            )}
                            <Button
                                color="primary"
                                type={'button'}
                                onClick={() => goToNextPage()}
                                disabled={
                                    !selectedTimeSlot ||
                                    !selectedDate ||
                                    (formValidation && formValidation.errorCount > 0)
                                }
                            >
                                {currentPage === TrainingWidgetPageType.DateAndTimeSelection
                                    ? intl.formatMessage({ id: 'Common.Next' })
                                    : intl.formatMessage({ id: 'Common.Confirm' })}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
