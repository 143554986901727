import ApiHelper from '../helpers/ApiHelper';
import { AccountSubmissionType } from '../domainObjects/AccountSubmission';
import CreateUserForAccountSubmissionRequest from '../domainObjects/CreateUserForAccountSubmissionRequest';

class AccountSubmissionService {
    static get(accessToken: string) {
        return ApiHelper.get(`/api/AccountSubmission?accessToken=${accessToken}`).then(
            (response: { data: AccountSubmissionType }) => response.data
        );
    }

    static createUserAccount(userAccount: CreateUserForAccountSubmissionRequest) {
        return ApiHelper.postJSON(`/api/AccountSubmission/CreateUserAccount`, JSON.stringify(userAccount)).then(
            (response) => response.data
        );
    }

    static markDiaryOnboardingAsComplete(deploymentId: number, providerId: number) {
        return ApiHelper.put(
            `/api/AccountSubmission/markDiaryOnboardingAsComplete?deploymentId=${deploymentId}&providerId=${providerId}`
        ).then((response) => response.data);
    }
}

export default AccountSubmissionService;
