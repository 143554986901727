import AvailableDate, { AvailableDateSettings } from './AvailableDate';

export type AvailabilitySearchResultSettings = {
    AvailableDates: Array<AvailableDateSettings>;
};

class AvailabilitySearchResult {
    availableDates: Array<AvailableDate>;

    constructor(availabilitySearchResult: AvailabilitySearchResultSettings) {
        this.availableDates =
            availabilitySearchResult && availabilitySearchResult.AvailableDates
                ? availabilitySearchResult.AvailableDates.map((availableDate) => new AvailableDate(availableDate))
                : [];
    }
}

export default AvailabilitySearchResult;
