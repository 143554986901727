const createBookingStatus = {
    success: 0,
    customerBlocked: 1,
    noAvailability: 2,
    invalidPromotion: 3,
    invalidBooking: 4,
    paymentRequired: 5,
    creditCardRequired: 6,
    leaveTimeConfirmationRequired: 8,
    paymentFailed: 10,
    stripeAuthenticationRequired: 11,
};

export default createBookingStatus;
