import React, { Fragment } from 'react';
import TextWithIcon from '../common/TextWithIcon';
import CreationWizardProgressIconWrapper from './CreationWizardProgressIconWrapper';
import useScreenSize from '../../hooks/useScreenSize';
import CreationWizardProgressBarItem from '../../enums/CreationWizardProgressBarItem';
import { useIntl } from 'react-intl';
import SettingsHelper from '../../helpers/SettingsHelper';
import useQueryParam from '../../hooks/useQueryParam';

export type CreationWizardProgressBarProps = {
    currentPage: CreationWizardProgressBarItem;
    setCurrentPage: Function;
};

const CreationWizardProgressBar = ({ currentPage, setCurrentPage }: CreationWizardProgressBarProps) => {
    const { isTabletView } = useScreenSize();
    const intl = useIntl();
    const isPaymentMade = useQueryParam('payment') ? true : false;

    function getIcon(isComplete: boolean, notStarted: boolean) {
        return <CreationWizardProgressIconWrapper isCompleted={isComplete} notStarted={notStarted} />;
    }

    function isCurrentPage(page: CreationWizardProgressBarItem) {
        return currentPage === page;
    }

    function jwtTokenExists() {
        return SettingsHelper.getJwtToken() !== null;
    }

    return (
        <Fragment>
            <div className="d-flex rd-progress-bar">
                <div>
                    <TextWithIcon
                        text={
                            isTabletView && !isCurrentPage(CreationWizardProgressBarItem.CreateAccountPage)
                                ? ''
                                : intl.formatMessage({ id: 'CreationWizard.CreateAnAccount' })
                        }
                        icon={getIcon(
                            jwtTokenExists(),
                            !isCurrentPage(CreationWizardProgressBarItem.CreateAccountPage)
                        )}
                        className={isTabletView ? 'mob' : undefined}
                    />
                </div>
                <div className="horizontal-splitter"></div>
                <TextWithIcon
                    text={
                        isTabletView && !isCurrentPage(CreationWizardProgressBarItem.AddOnsPage)
                            ? ''
                            : intl.formatMessage({ id: 'CreationWizard.AddOns' })
                    }
                    icon={getIcon(
                        isPaymentMade || isCurrentPage(CreationWizardProgressBarItem.PaymentPage),
                        isCurrentPage(CreationWizardProgressBarItem.CreateAccountPage)
                    )}
                    className={isTabletView ? 'mob' : undefined}
                />
                <div className="horizontal-splitter"></div>

                <TextWithIcon
                    text={
                        isTabletView && !isCurrentPage(CreationWizardProgressBarItem.PaymentPage)
                            ? ''
                            : intl.formatMessage({ id: 'CreationWizard.Payment' })
                    }
                    icon={getIcon(isPaymentMade, !isCurrentPage(CreationWizardProgressBarItem.PaymentPage))}
                    className={isTabletView ? 'mob' : undefined}
                />
            </div>
        </Fragment>
    );
};

export default CreationWizardProgressBar;
