import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Label, Spinner } from 'reactstrap';
import RestaurantService, {
    RestaurantServiceOption,
    VenueServiceSettings,
} from '../../../domainObjects/RestaurantService';
import Dropdown from '../../common/ResDiaryDropdown';
import SettingsHelper from '../../../helpers/SettingsHelper';
import SettingsService from '../../../services/SettingsService';
import RestaurantServiceItem from './RestaurantServiceItem';
import ValidationMessage from '../../common/ValidationMessage';
import { InfoBarProps } from '../../common/InfoBar';
import useScreenSize from '../../../hooks/useScreenSize';
import AddServiceButton from '../../common/AddServiceButton';
import SaveCancelButtons from '../../common/SaveCancelButtons';
import TimeHelper from '../../../helpers/TimeHelper';

type RestaurantServiceListProps = {
    formValidation: any;
    isUpdating: boolean;
    setIsUpdating: (value: boolean) => void;
    venueServices: RestaurantService[] | null;
    setVenueServices: (venueServices: Array<RestaurantService>) => void;
    venueServiceOptions: RestaurantServiceOption[];
    infoBars: Array<InfoBarProps>;
    setInfoBars: (infoBars: Array<InfoBarProps>) => void;
    isLoading?: boolean;
    setContinueDisabled: (val: boolean) => void;
};

const RestaurantServiceList = ({
    formValidation,
    isUpdating,
    setIsUpdating,
    venueServices,
    setVenueServices,
    venueServiceOptions,
    infoBars,
    setInfoBars,
    isLoading,
    setContinueDisabled,
}: RestaurantServiceListProps) => {
    const intl = useIntl();

    const [isAdding, setIsAdding] = useState(false);
    const [selectedServiceOption, setSelectedServiceOption] = useState<number | null>(1);
    const [services, setServices] = useState<RestaurantService[] | null>(null);
    const { isMobileView, isTabletView } = useScreenSize();

    const getServiceStartTimeOptions = (serviceId?: number) => {
        // Fill array with all time slots
        let availableTimes: Array<Date> = getAllPossibleTimeSlots();

        // Filter out other services times
        services?.forEach((service) => {
            if (service.id === serviceId) return;
            availableTimes = availableTimes.filter((time) => time < service.timeFrom || time >= service.timeUntil);
        });

        // Filter out one time slot before other services start time
        services?.forEach((service) => {
            if (service.id === serviceId) return;
            availableTimes = availableTimes.filter(
                (time) => time.getTime() !== service.timeFrom.getTime() - 15 * 60000
            );
        });

        // Generate html option elements
        let optionElements: Array<HTMLOptionElement> = [];
        availableTimes.forEach((slot) =>
            optionElements.push(new Option(`${TimeHelper.getTimeIn24HourFormat(slot)}`, slot.toISOString()))
        );

        return optionElements;
    };

    const getServiceEndTimeOptions = (newStartTime: Date, newEndTime?: Date, serviceId?: number) => {
        newStartTime.setSeconds(0);
        newStartTime.setMilliseconds(0);
        newEndTime?.setSeconds(0);
        newEndTime?.setMilliseconds(0);

        // Fill array with all time slots
        let availableTimes: Array<Date> = getAllPossibleTimeSlots();

        // Filter out times before this service starts
        const newStartTimeIndex = availableTimes.findIndex((time) => {
            return time.getTime() === newStartTime.getTime();
        });
        if (newStartTimeIndex !== -1) {
            availableTimes = availableTimes.slice(newStartTimeIndex);
        }

        // Filter out times after preceding services start
        services?.forEach((service) => {
            if (serviceId === service.id) return;
            if (newStartTime.getTime() <= service.timeFrom.getTime()) {
                let cutOffIndex = availableTimes.findIndex((option) => {
                    return option.getTime() === service.timeFrom.getTime();
                });
                if (cutOffIndex !== -1) {
                    cutOffIndex++;
                    if (availableTimes.length > cutOffIndex) {
                        availableTimes = availableTimes.slice(0, cutOffIndex);
                    }
                }
            }
        });

        // Filter out times after preceding services start,
        // when this service's start time overlaps another service
        services?.forEach((service) => {
            if (serviceId === service.id) return;
            if (
                newEndTime !== undefined &&
                newStartTime.getTime() <= service.timeFrom.getTime() &&
                newEndTime.getTime() >= service.timeFrom.getTime()
            ) {
                let cutOffIndex = availableTimes.findIndex((option) => {
                    return option.getTime() === service.timeFrom.getTime();
                });
                if (cutOffIndex !== -1) {
                    cutOffIndex++;
                    if (availableTimes.length > cutOffIndex) {
                        availableTimes = availableTimes.slice(0, cutOffIndex);
                    }
                }
            }
        });

        // Remove first two time slots
        availableTimes.shift();
        availableTimes.shift();

        // Generate html option elements
        let optionElements: Array<HTMLOptionElement> = [];
        availableTimes.forEach((slot) =>
            optionElements.push(new Option(`${TimeHelper.getTimeIn24HourFormat(slot)}`, slot.toISOString()))
        );

        return optionElements;
    };

    const getServiceLastBookingTimeOptions = (newStartTime: Date, newEndTime: Date) => {
        newStartTime.setSeconds(0);
        newStartTime.setMilliseconds(0);
        newEndTime.setSeconds(0);
        newEndTime.setMilliseconds(0);

        // Fill array with all time slots
        let availableTimes: Array<Date> = getAllPossibleTimeSlots();

        // Filter out slots before start time
        availableTimes = availableTimes.filter((time) => time > newStartTime);

        // Filter out slots after service end time
        availableTimes = availableTimes.filter((time) => time < newEndTime);

        // Generate html option elements
        let optionElements: Array<HTMLOptionElement> = [];
        availableTimes.forEach((slot) =>
            optionElements.push(new Option(`${TimeHelper.getTimeIn24HourFormat(slot)}`, slot.toISOString()))
        );

        return optionElements;
    };

    const getAllPossibleTimeSlots = () => {
        const startTime = new Date();
        startTime.setHours(0);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);
        const endTime = new Date(startTime.getTime() + (23 * 60 + 45) * 60000);

        // Fill array with all time slots
        let availableTimes: Array<Date> = [];
        let i = startTime;
        while (i <= endTime) {
            availableTimes.push(i);
            i = new Date(i.getTime() + 15 * 60000);
        }
        return availableTimes;
    };

    const resetNewService = () => {
        const serviceTimeOptions = getServiceStartTimeOptions();
        const timeFrom = serviceTimeOptions.length > 0 ? new Date(serviceTimeOptions[0].value) : new Date();
        const closingTimeOptions = getServiceEndTimeOptions(timeFrom);
        const timeUntil = closingTimeOptions.length > 0 ? new Date(closingTimeOptions[0].value) : new Date();
        const lastBookingTime = new Date(timeUntil.getTime() - 15 * 60000);
        return new RestaurantService({
            Id: 0,
            Name: '',
            TimeFrom: timeFrom,
            TimeUntil: timeUntil,
            LastBookingTime: lastBookingTime,
        });
    };

    const [newService, setNewService] = useState<RestaurantService>(resetNewService);

    const openingTimeName = useCallback((service: RestaurantService) => {
        return `openingTime-${service.id}`;
    }, []);

    const closingTimeName = useCallback((service: RestaurantService) => {
        return `closingTime-${service.id}`;
    }, []);

    const lastBookingTimeName = useCallback((service: RestaurantService) => {
        return `lastBookingTime-${service.id}`;
    }, []);

    const getVenueServiceOptions = () => {
        return venueServiceOptions && services
            ? venueServiceOptions
                  .filter((x) => !services.map((venueService) => venueService.name).includes(x.Name))
                  .map((venueServiceOption) => {
                      if (!venueServiceOption) {
                          return false;
                      }
                      return new Option(`${venueServiceOption?.Name}`, venueServiceOption?.Id.toString());
                  })
            : venueServiceOptions.map((venueServiceOption) => {
                  if (!venueServiceOption) {
                      return false;
                  }
                  return new Option(`${venueServiceOption?.Name}`, venueServiceOption?.Id.toString());
              });
    };

    const getValidationDate = useCallback((time: Date) => {
        const date = new Date();
        return new Date(`${date.toDateString()} ${TimeHelper.getTimeIn24HourFormat(time)}`);
    }, []);

    const getServiceTimeValidationMessage = (service: RestaurantService) => {
        if (formValidation.errorCount > 0) {
            let error = '';
            if (formValidation.errors[openingTimeName(service)]) {
                error = openingTimeName(service);
            }
            if (formValidation.errors[closingTimeName(service)]) {
                error = closingTimeName(service);
            }

            if (error) {
                return <ValidationMessage isBlock={false} message={formValidation.errors[error].message} />;
            }
        }

        return null;
    };

    const getLastBookingTimeValidationMessage = (service: RestaurantService) => {
        if (formValidation.errorCount > 0) {
            let error = '';
            if (formValidation.errors[lastBookingTimeName(service)]) {
                error = lastBookingTimeName(service);
            }

            if (error) {
                return <ValidationMessage isBlock={false} message={formValidation.errors[error].message} />;
            }
        }

        return null;
    };

    const validateTimes = useCallback(
        (
            service: RestaurantService,
            startTime: Date,
            endTime: Date,
            lastBookingTime?: Date,
            timeToValidate: 'startTime' | 'endTime' | 'lastBookingTime' = 'startTime'
        ) => {
            const serviceTimes = services?.map((x) => ({
                id: x.id,
                timeFrom: getValidationDate(x.timeFrom),
                timeUntil: getValidationDate(x.timeUntil),
                lastBookingTime: getValidationDate(x.lastBookingTime),
            }));
            switch (timeToValidate) {
                case 'startTime':
                    if (startTime && startTime >= endTime) {
                        return intl.formatMessage({
                            id: 'OnboardingWizard.VenueServicesPageOpeningTimeDifferenceError',
                        });
                    }
                    // validate that start time is not inbetween the start and end times of any other service
                    if (
                        serviceTimes?.find(
                            (x) => x.id !== service.id && startTime >= x.timeFrom && startTime < x.timeUntil
                        )
                    ) {
                        return intl.formatMessage({ id: 'OnboardingWizard.VenueServicesPageTimeOverlapError' });
                    }
                    break;
                case 'endTime':
                    if (endTime && endTime <= startTime) {
                        return intl.formatMessage({
                            id: 'OnboardingWizard.VenueServicesPageClosingTimeDifferenceError',
                        });
                    }
                    // validate that end time is not inbetween the start and end times of any other service
                    if (services?.find((x) => x.id !== service.id && endTime <= x.timeUntil && endTime > x.timeFrom)) {
                        return intl.formatMessage({ id: 'OnboardingWizard.VenueServicesPageTimeOverlapError' });
                    }
                    // validate in the case when start time is earlier than another service that end time is before start time of the other service
                    if (services?.find((x) => x.id !== service.id && startTime < x.timeFrom && endTime > x.timeFrom)) {
                        return intl.formatMessage({ id: 'OnboardingWizard.VenueServicesPageTimeOverlapError' });
                    }
                    break;
            }
            if (lastBookingTime && (startTime >= lastBookingTime || lastBookingTime >= endTime)) {
                return intl.formatMessage({
                    id: 'OnboardingWizard.VenueServicesPageLastBookingTimeNotInHoursError',
                });
            }
            return true;
        },
        [intl, services, getValidationDate]
    );

    const validateServiceStartTime = useCallback(
        (value: Date, service: RestaurantService) => {
            const startTime = getValidationDate(value);
            const endTime = getValidationDate(formValidation.getValues(closingTimeName(service)));

            return validateTimes(service, startTime, endTime, undefined, 'startTime');
        },
        [formValidation, getValidationDate, closingTimeName, validateTimes]
    );

    const validateServiceEndTime = useCallback(
        (value: Date, service: RestaurantService) => {
            const endTime = getValidationDate(value);
            const startTime = getValidationDate(formValidation.getValues(openingTimeName(service)));

            return validateTimes(service, startTime, endTime, undefined, 'endTime');
        },
        [formValidation, getValidationDate, openingTimeName, validateTimes]
    );

    const validateServiceLastBookingTime = useCallback(
        (value: Date, service: RestaurantService) => {
            const startTime = getValidationDate(formValidation.getValues(openingTimeName(service)));
            const endTime = getValidationDate(formValidation.getValues(closingTimeName(service)));
            const lastBookingTime = getValidationDate(value);

            return validateTimes(service, startTime, endTime, lastBookingTime, 'lastBookingTime');
        },
        [formValidation, getValidationDate, openingTimeName, closingTimeName, validateTimes]
    );

    const setFormValidationValue = (name: string, value: Date) => {
        formValidation.setValue(name, value, {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const addService = () => {
        if (
            !services
                ?.map((x) => x.name)
                .includes(venueServiceOptions.filter((x) => x.Id === selectedServiceOption)[0].Name) &&
            newService
        ) {
            setIsUpdating(true);
            const newServiceList = services;
            const service = new RestaurantService({
                Id: 0,
                Name: venueServiceOptions.filter((x) => x.Id === selectedServiceOption)[0].Name,
                TimeFrom: newService.timeFrom,
                TimeUntil: newService.timeUntil,
                LastBookingTime: newService.lastBookingTime,
            });
            addOrUpdateService(service).then((response) => {
                service.id = response.Id;
                newServiceList?.push(service);
                SettingsService.AddEnabledRulesForService(response.Id);
                setServices([...(newServiceList as RestaurantService[])]);
                setVenueServices([...(newServiceList as RestaurantService[])]);
                setSelectedServiceOption(null);
                setNewService(resetNewService);
                setIsUpdating(false);
                setIsAdding(false);
            });
        }
    };

    const registerNewFormValidation = useCallback(
        (service: RestaurantService) => {
            formValidation.register(
                { name: openingTimeName(service) },
                {
                    validate: (value: Date) => {
                        return validateServiceStartTime(value, service);
                    },
                }
            );
            formValidation.register(
                { name: closingTimeName(service) },
                {
                    validate: (value: Date) => {
                        return validateServiceEndTime(value, service);
                    },
                }
            );
            formValidation.register(
                { name: lastBookingTimeName(service) },
                {
                    validate: (value: Date) => {
                        return validateServiceLastBookingTime(value, service);
                    },
                }
            );
            formValidation.setValue(openingTimeName(service), service.timeFrom);
            formValidation.setValue(closingTimeName(service), service.timeUntil);
            formValidation.setValue(lastBookingTimeName(service), service.lastBookingTime);
        },
        [
            closingTimeName,
            formValidation,
            lastBookingTimeName,
            openingTimeName,
            validateServiceEndTime,
            validateServiceLastBookingTime,
            validateServiceStartTime,
        ]
    );

    const updateService = (id: number, openingTime: Date, closingTime: Date, lastBookingTime: Date) => {
        if (id !== 0) {
            setIsUpdating(true);
            const newServiceList = [...(services as RestaurantService[])];
            const service = newServiceList.filter((x) => x.id === id)[0];
            service.timeFrom = openingTime;
            service.timeUntil = closingTime;
            service.lastBookingTime = lastBookingTime;
            addOrUpdateService(service).then((response) => {
                setServices([...newServiceList]);
                setVenueServices([...(newServiceList as RestaurantService[])]);
                setSelectedServiceOption(null);
                setIsUpdating(false);
            });
        }
    };

    const addOrUpdateService = (service: RestaurantService) => {
        const serviceDto = {
            Id: service.id,
            Name: service.name,
            Description: service.name,
            TimeFrom: TimeHelper.getTimeIn24HourFormat(service.timeFrom),
            TimeUntil: TimeHelper.getTimeIn24HourFormat(service.timeUntil),
            LastBookingTime: TimeHelper.getTimeIn24HourFormat(service.lastBookingTime),
            TimeSlotInterval: 0,
            MaxBookings: 0,
            MaxCovers: 0,
            SegmentId: SettingsHelper.getSegmentId(),
        };
        return SettingsService.updateVenueService(serviceDto);
    };

    const deleteService = (service: RestaurantService) => {
        if (service.id !== 0) {
            setIsUpdating(true);
            SettingsService.deleteVenueService(service.id).then(() => {
                SettingsService.getProviderServices().then((data: Array<VenueServiceSettings>) => {
                    const date = new Date();
                    const venueServices = data.map(
                        (x) =>
                            new RestaurantService({
                                Id: x.Id,
                                Name: x.Name,
                                TimeFrom: new Date(date.toDateString() + ' ' + x.TimeFrom),
                                TimeUntil: new Date(date.toDateString() + ' ' + x.TimeUntil),
                                LastBookingTime: new Date(date.toDateString() + ' ' + x.LastBookingTime),
                            })
                    );
                    setServices(venueServices ? [...venueServices] : []);
                    setVenueServices(venueServices ? [...venueServices] : []);
                    setIsUpdating(false);
                    setInfoBars([
                        ...infoBars,
                        {
                            id: infoBars.length > 0 ? infoBars[infoBars.length - 1].id + 1 : 0,
                            message: intl.formatMessage(
                                { id: 'OnboardingWizard.VenueServicesPageDeletionConfirmation' },
                                { serviceName: service.name }
                            ),
                            type: 'success',
                            onDismiss: () => {},
                        },
                    ]);
                });
            });
        }
    };

    useEffect(() => {
        if (venueServices) {
            setServices(venueServices);
        }
    }, [venueServices]);

    useEffect(() => {
        if (isAdding) {
            formValidation.register(
                { name: 'openingTime-0' },
                {
                    validate: (value: Date) => {
                        return validateServiceStartTime(value, newService);
                    },
                }
            );
            formValidation.register(
                { name: 'closingTime-0' },
                {
                    validate: (value: Date) => {
                        return validateServiceEndTime(value, newService);
                    },
                }
            );
            formValidation.register(
                { name: 'lastBookingTime-0' },
                {
                    validate: (value: Date) => {
                        return validateServiceLastBookingTime(value, newService);
                    },
                }
            );
            formValidation.setValue('openingTime-0', newService.timeFrom);
            formValidation.setValue('closingTime-0', newService.timeUntil);
            formValidation.setValue('lastBookingTime-0', newService.lastBookingTime);
        } else {
            formValidation.unregister('openingTime-0');
            formValidation.unregister('closingTime-0');
            formValidation.unregister('lastBookingTime-0');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdding]);

    const resetFormValidation = useCallback(() => {
        formValidation.reset();
    }, [formValidation]);

    const isFormValid = useCallback(() => {
        return services && services.length > 0 && !isAdding ? true : false;
    }, [services, isAdding]);

    useEffect(() => {
        resetFormValidation();
        services?.forEach((service) => {
            registerNewFormValidation(service);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services]);

    useEffect(() => {
        setContinueDisabled(!isFormValid());
    }, [services, isAdding, setContinueDisabled, isFormValid]);

    if (isLoading) {
        return (
            <div className="spinner-container">
                <Spinner color="primary" />
            </div>
        );
    }

    return (
        <div className="restaurant-service-list">
            {services
                ?.sort((x, y) => Number(x.timeFrom) - Number(y.timeFrom))
                .map((service, i) => (
                    <RestaurantServiceItem
                        key={i}
                        service={service}
                        deleteService={deleteService}
                        updateService={updateService}
                        openingTimeName={openingTimeName}
                        closingTimeName={closingTimeName}
                        lastBookingTimeName={lastBookingTimeName}
                        getTime={TimeHelper.getTimeIn24HourFormat}
                        getServiceStartTimeOptions={getServiceStartTimeOptions}
                        getServiceEndTimeOptions={getServiceEndTimeOptions}
                        getServiceLastBookingTimeOptions={getServiceLastBookingTimeOptions}
                        setFormValidationValue={setFormValidationValue}
                        getServiceTimeValidationMessage={getServiceTimeValidationMessage}
                        getLastBookingTimeValidationMessage={getLastBookingTimeValidationMessage}
                        formValidation={formValidation}
                        isUpdating={isUpdating}
                        setContinueDisabled={setContinueDisabled}
                    ></RestaurantServiceItem>
                ))}
            {isAdding && (
                <div className={`add-service-table ${isMobileView || isTabletView ? 'mobile' : ''}`}>
                    <Label className="add-service-label">
                        {intl.formatMessage({
                            id: 'OnboardingWizard.VenueServicesPageAddService',
                        })}
                    </Label>
                    <div className="venue-service-dropdown">
                        <Dropdown
                            hideRemoveItemIcon
                            options={getVenueServiceOptions()}
                            onValueChange={(value: string) => {
                                setSelectedServiceOption(parseInt(value));
                            }}
                            title={intl.formatMessage({
                                id: 'Common.Service',
                            })}
                            selectedValue={selectedServiceOption?.toString()}
                        />
                    </div>
                    <div className="venue-service-time-input-wrapper">
                        <div>
                            <Label>
                                {intl.formatMessage({
                                    id: 'Common.Hours',
                                })}
                            </Label>
                            <div className="venue-service-time-range-wrapper">
                                <Dropdown
                                    hideRemoveItemIcon
                                    options={getServiceStartTimeOptions()}
                                    defaultValue={TimeHelper.getTimeIn24HourFormat(newService?.timeFrom)}
                                    onValueChange={(value: string) => {
                                        setNewService({ ...newService, timeFrom: new Date(value) });
                                        setFormValidationValue(openingTimeName(newService), new Date(value));
                                        setFormValidationValue(closingTimeName(newService), newService.timeUntil);
                                        setFormValidationValue(
                                            lastBookingTimeName(newService),
                                            newService.lastBookingTime
                                        );
                                    }}
                                    errors={formValidation.errors}
                                    name={openingTimeName(newService)}
                                    selectedValue={newService?.timeFrom.toISOString()}
                                />
                                <Label>
                                    {intl.formatMessage({
                                        id: 'Common.To',
                                    })}
                                </Label>
                                <Dropdown
                                    hideRemoveItemIcon
                                    options={getServiceEndTimeOptions(newService.timeFrom, newService.timeUntil)}
                                    defaultValue={TimeHelper.getTimeIn24HourFormat(newService?.timeUntil)}
                                    onValueChange={(value: string) => {
                                        setNewService({ ...newService, timeUntil: new Date(value) });
                                        setFormValidationValue(closingTimeName(newService), new Date(value));
                                        setFormValidationValue(openingTimeName(newService), newService.timeFrom);
                                        setFormValidationValue(
                                            lastBookingTimeName(newService),
                                            newService.lastBookingTime
                                        );
                                    }}
                                    errors={formValidation.errors}
                                    name={closingTimeName(newService)}
                                    selectedValue={TimeHelper.getTimeIn24HourFormat(newService?.timeUntil)}
                                />
                            </div>
                            {getServiceTimeValidationMessage(newService)}
                        </div>
                        <div>
                            <Label>
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.VenueServicesPageLastBookingTime',
                                })}
                            </Label>
                            <Dropdown
                                hideRemoveItemIcon
                                options={getServiceLastBookingTimeOptions(newService.timeFrom, newService.timeUntil)}
                                defaultValue={TimeHelper.getTimeIn24HourFormat(newService?.lastBookingTime)}
                                onValueChange={(value: string) => {
                                    setNewService({ ...newService, lastBookingTime: new Date(value) });
                                    setFormValidationValue(lastBookingTimeName(newService), new Date(value));
                                }}
                                errors={formValidation.errors}
                                name={lastBookingTimeName(newService)}
                                selectedValue={newService?.lastBookingTime.toISOString()}
                            />
                            {getLastBookingTimeValidationMessage(newService)}
                        </div>
                    </div>
                    <SaveCancelButtons
                        onCancel={() => {
                            setIsAdding(false);
                        }}
                        onSave={() => addService()}
                        saveDisabled={
                            (selectedServiceOption &&
                            newService.timeFrom.toISOString() !== newService.timeUntil.toISOString() &&
                            formValidation.errorCount === 0
                                ? false
                                : true) || isUpdating
                        }
                    />
                </div>
            )}
            {getVenueServiceOptions().length > 0 && !isAdding && (
                <AddServiceButton
                    onClick={() => {
                        setIsAdding(true);
                        setNewService(resetNewService());
                        resetFormValidation();
                    }}
                    linkText={intl.formatMessage({
                        id: 'OnboardingWizard.VenueServicesPageAddService',
                    })}
                />
            )}
        </div>
    );
};

export default RestaurantServiceList;
