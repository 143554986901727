import React, { Fragment, useCallback, useEffect, useState } from 'react';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-logo.jpg';
import { ReactComponent as StatusDoneIcon } from '../../../content/icons/Icon-Status-done.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/delete.svg';
import { ReactComponent as UploadIcon } from '../../../content/icons/upload.svg';
import VenueImage from '../../../domainObjects/VenueImage';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import ImageDropzone from '../../common/ImageDropzone';
import { Image } from '../../../domainObjects/VenueImage';
import useScreenSize from '../../../hooks/useScreenSize';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import TextWithIcon from '../../common/TextWithIcon';
import settingsService from '../../../services/SettingsService';
import useValidation from '../../../hooks/useValidation';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import trackingEvents from '../../../enums/trackingEvents';
import ImageHelper from '../../../helpers/ImageHelper';

export type MainImagePageProps = {
    venueImage: VenueImage | null;
    isNavigatingFromDishCult: boolean;
    setIsNavigatingFromDishCult: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    uploadProgress: number;
    updateMainImage: (
        newImage: Image,
        currentImage: Image | null | undefined,
        isMainImage: boolean
    ) => Promise<any> | undefined;
    deleteImage: (imageIds: Array<number>) => void;
    mainImageUploadError: string;
    setMainImageUploadError: (error: string) => void;
};

const MainImagePage = ({
    venueImage,
    isNavigatingFromDishCult,
    setIsNavigatingFromDishCult,
    isLoading,
    uploadProgress,
    updateMainImage,
    deleteImage,
    mainImageUploadError,
    setMainImageUploadError,
}: MainImagePageProps) => {
    const { isMobileView, isTabletView } = useScreenSize();
    const [currentImage, setCurrentImage] = useState<Image | null | undefined>();
    const [newImage, setNewImage] = useState<Image | null | undefined>();
    const [isUploading, setIsUploading] = useState(false);
    const intl = useIntl();
    const formValidation = useValidation();

    const setImage = (file: any) => {
        file = ImageHelper.trimFilenameToDbLimit(file);
        setNewImage({
            id: 0,
            preview: '',
            name: file.name,
            size: file.size,
            type: file.type,
            file: file,
        });
    };

    const isMobileTabletView = () => {
        if (isMobileView) return 'mobile';

        if (isTabletView) return 'tablet';

        return '';
    };

    const uploadImage = useCallback(() => {
        if (newImage) {
            updateMainImage(newImage, currentImage, false)?.then(() => {
                if (mainImageUploadError) {
                    setMainImageUploadError('');
                }
                formValidation.reset();
                setIsUploading(false);
            });
        }
    }, [newImage, currentImage, updateMainImage, formValidation, mainImageUploadError, setMainImageUploadError]);

    useEffect(() => {
        uploadImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newImage]);

    useEffect(() => {
        /// TODO: error handling
        if (venueImage && venueImage.id !== 0) {
            formValidation.reset();
            if (currentImage) {
                setCurrentImage({
                    ...(currentImage as Image),
                    preview: venueImage.imageUrl,
                    id: venueImage.id,
                    name: venueImage.name,
                });
            } else {
                setCurrentImage({
                    id: venueImage.id,
                    preview: venueImage.imageUrl,
                    name: venueImage.name,
                    size: 1,
                    type: 'image/jpg',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [venueImage]);

    const setImageAsMastheadImage = () => {
        settingsService.setImageAsMastheadImage((currentImage as Image).id);
    };

    const getFormBody = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <Spinner color="primary" />
                </div>
            );
        }

        const imageDropzone =
            (!currentImage && venueImage) || isUploading ? (
                <ImageDropzone
                    setImage={setImage}
                    uploadProgress={uploadProgress}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    uploadError={mainImageUploadError}
                    setUploadError={setMainImageUploadError}
                    formValidation={formValidation}
                    isMainImage={true}
                    minWidth={1920}
                    minHeight={1080}
                />
            ) : null;

        const imagePreview =
            currentImage && currentImage.preview !== '' && !isUploading ? (
                <Fragment>
                    <div className="image-preview-wrapper">
                        <ButtonGroup className="image-preview-button-group">
                            <Button
                                id="UploadButton"
                                onClick={() => {
                                    setCurrentImage(null);
                                }}
                                className="image-preview-button"
                            >
                                <UploadIcon className="upload-icon" />
                            </Button>
                            <UncontrolledTooltip flip target="UploadButton">
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.VenueMainImageUploadButtonTooltip',
                                })}
                            </UncontrolledTooltip>
                            <Button
                                id="DeleteButton"
                                onClick={() => {
                                    deleteImage([currentImage.id]);
                                    setCurrentImage(null);
                                }}
                                className="image-preview-button"
                            >
                                <DeleteIcon />
                            </Button>
                            <UncontrolledTooltip flip target="DeleteButton">
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.VenueMainImageDeleteButtonTooltip',
                                })}
                            </UncontrolledTooltip>
                        </ButtonGroup>
                        <img src={currentImage?.preview} className="image-preview main-image" alt="cropped" />
                    </div>
                    <TextWithIcon
                        className="status-done-wrapper"
                        icon={<StatusDoneIcon />}
                        text={intl.formatMessage({
                            id: 'OnboardingWizard.AllSetLabel',
                        })}
                        iconClass="status-done-icon"
                        textClass="status-done-text"
                    ></TextWithIcon>
                </Fragment>
            ) : null;

        return (
            <div className={`logo-page-body-wrapper ${isMobileTabletView()}`}>
                {imageDropzone}
                {imagePreview}
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({
                id: 'OnboardingWizard.VenueMainImagePageTitle',
            })}
            infoPanelSubtitle={intl.formatMessage({
                id: 'OnboardingWizard.VenueMainImagePageSubtitle',
            })}
            currentStep={rdfOnboardingSteps.mainImagePage}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            children={getFormBody()}
            updateSettings={setImageAsMastheadImage}
            isUpdating={isUploading || mainImageUploadError !== ''}
            formValidation={formValidation}
            isNavigatingFromDishCult={isNavigatingFromDishCult}
            setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfDCImagePage}
        />
    );
};

export default MainImagePage;
