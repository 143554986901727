import React, { Fragment } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/delete.svg';
import { useIntl } from 'react-intl';

type TableControlsProps = {
    tableId: number;
    onEdit: () => void;
    onDelete: () => void;
};

const TableControls = ({ tableId, onEdit, onDelete }: TableControlsProps) => {
    const intl = useIntl();

    return (
        <Fragment>
            <div className="table-item-button-wrapper">
                <Button id={`EditTable-${tableId}`} className="table-item-button" onClick={() => onEdit()}>
                    <EditIcon />
                </Button>
                <UncontrolledTooltip flip target={`EditTable-${tableId}`}>
                    {intl.formatMessage({
                        id: 'Common.Edit',
                    })}
                </UncontrolledTooltip>
                <Button id={`DeleteTable-${tableId}`} className="table-item-button" onClick={() => onDelete()}>
                    <DeleteIcon />
                </Button>
                <UncontrolledTooltip flip target={`DeleteTable-${tableId}`}>
                    {intl.formatMessage({
                        id: 'Common.Delete',
                    })}
                </UncontrolledTooltip>
            </div>
        </Fragment>
    );
};

export default TableControls;
