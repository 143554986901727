import React, { Fragment, useState, useCallback, useEffect } from 'react';
import Typeahead from './Typeahead';
import useDebounceWithValue from '../../hooks/useDebounceWithValue';
// import infoBarType from '../../enums/infoBarType';
// import InfoBars from '../common/InfoBars';

export type TypeaheadWrapperProps = {
    typeaheadItemSelected: (id: string | number) => void;
    //a function to make the request for results. Should return a promise and accept a string argument (the search term)
    requestFunctionAsync: Function;
    //a function for processing the results of the request
    processResultsFunction: Function;
    allowAddNew?: boolean;
    addNewFunction?: any; // propTypeExtensions.requiredIf(PropTypes.func, (props) => props.allowAddNew),
    addNewTitle?: string; // propTypeExtensions.requiredIf(PropTypes.string, (props) => props.allowAddNew),
    addNewAlign?: 'top' | 'bottom';
    disabled?: boolean;
    useDangerouslySetHTML?: boolean;
    autoFocus?: boolean;
    addErrorBar?: Function;
    includeValidation?: boolean; // requires the below props too
    registerValidation?: Function;
    errors?: { [key: string]: any };
    placeholder?: string;
    validationMessage?: string;
    validationName?: string;
    onFocus?: Function;
    isLoading?: boolean;
};

// let _ID = 0; // TODO

function TypeaheadWrapper({
    typeaheadItemSelected,
    requestFunctionAsync,
    processResultsFunction,
    allowAddNew,
    addNewFunction,
    addNewTitle,
    addNewAlign = 'top',
    disabled,
    useDangerouslySetHTML,
    autoFocus,
    addErrorBar,
    includeValidation,
    registerValidation,
    errors,
    placeholder,
    validationName,
    validationMessage,
    onFocus,
    isLoading,
}: TypeaheadWrapperProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    // const [infoBars, setInfoBars] = useState([]);
    const debouncedSearchTerm = useDebounceWithValue(searchTerm);

    const startSearching = () => {
        setIsSearching(true);
        setResults([]);
    };

    const addErrorBarInternal = useCallback((message) => {
        // setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);

    const requestFunctionAsyncProp = requestFunctionAsync;
    const processResultsFunctionProp = processResultsFunction;
    const requestFunction = useCallback(
        (searchTerm) => {
            return requestFunctionAsyncProp(searchTerm);
        },
        [requestFunctionAsyncProp]
    );

    const processResultsFunctionInternal = useCallback(
        (response) => {
            return processResultsFunctionProp(response);
        },
        [processResultsFunctionProp]
    );

    const addErrorBarProp = addErrorBar;

    useEffect(() => {
        if (debouncedSearchTerm.length >= 3) {
            requestFunction(debouncedSearchTerm)
                .then((response: any /* TODO should this be any? */) => {
                    const results = processResultsFunctionInternal(response);
                    setResults(results);
                })
                .catch(() => {
                    if (addErrorBarProp) {
                        addErrorBarProp();
                    } else {
                        addErrorBarInternal('TODO message'); // TODO
                    }
                })
                .finally(() => {
                    setIsSearching(false);
                });
        }
    }, [
        debouncedSearchTerm,
        addErrorBarInternal,
        requestFunction,
        processResultsFunction,
        addErrorBarProp,
        processResultsFunctionInternal,
    ]);

    useEffect(() => {
        if (searchTerm.length >= 3) {
            startSearching();
        }
    }, [searchTerm]);

    return (
        <Fragment>
            <Typeahead
                results={results}
                setSelectedResult={typeaheadItemSelected}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                isSearching={isSearching}
                addNew={allowAddNew}
                addNewFunction={addNewFunction}
                addNewTitle={addNewTitle}
                registerValidation={registerValidation}
                errors={errors}
                includeValidation={includeValidation}
                placeholder={placeholder}
                validationMessage={validationMessage}
                validationName={validationName}
                disabled={disabled}
                useDangerouslySetHTML={useDangerouslySetHTML}
                autoFocus={autoFocus}
                addNewAlign={addNewAlign}
                onFocus={onFocus}
                isLoading={isLoading}
            />
        </Fragment>
    );
}

export default TypeaheadWrapper;
