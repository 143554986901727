export type RestaurantInformationSettings = {
    Headline: string;
    GeneralDescription: string;
    OpeningInformation: string;
    NewsBlog: string;
    NewsRssFeed: string;
    WebsiteUrl: string;
};

class RestaurantInformation {
    headline: string;
    generalDescription: string;
    openingInformation: string;
    newsBlog: string;
    newsRssFeed: string;
    websiteUrl: string;

    constructor(restaurantInformation: RestaurantInformationSettings | null) {
        this.headline = restaurantInformation && restaurantInformation.Headline ? restaurantInformation.Headline : '';
        this.generalDescription =
            restaurantInformation && restaurantInformation.GeneralDescription
                ? restaurantInformation.GeneralDescription
                : '';
        this.openingInformation =
            restaurantInformation && restaurantInformation.OpeningInformation
                ? restaurantInformation.OpeningInformation
                : '';
        this.newsBlog = restaurantInformation && restaurantInformation.NewsBlog ? restaurantInformation.NewsBlog : '';
        this.newsRssFeed =
            restaurantInformation && restaurantInformation.NewsRssFeed ? restaurantInformation.NewsRssFeed : '';
        this.websiteUrl =
            restaurantInformation && restaurantInformation.WebsiteUrl ? restaurantInformation.WebsiteUrl : '';
    }
}

export default RestaurantInformation;
