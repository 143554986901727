import AvailableTime, { AvailableTimeSettings } from './AvailableTime';

export type AvailableDateSettings = {
    Date: Date;
    AvailableTimes: Array<AvailableTimeSettings>;
};

class AvailableDate {
    date: Date;
    availableTimes: Array<AvailableTime>;

    constructor(availableDate: AvailableDateSettings) {
        this.date = availableDate && availableDate.Date ? new Date(availableDate.Date) : new Date();
        this.availableTimes =
            availableDate && availableDate.AvailableTimes
                ? availableDate.AvailableTimes.map((time) => new AvailableTime(time))
                : [];
    }
}

export default AvailableDate;
