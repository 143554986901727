import React, { Fragment, useState, useEffect, useCallback } from 'react';
import CreationWizardHeader from './CreationWizardHeader';
import CreationWizardFooter from './CreationWizardFooter';
import CreationWizardProgressBar from './CreationWizardProgressBar';
import { UserAccount } from '../../domainObjects/UserAccount';
import CreateAccountForm from './CreateAccountForm';
import useScreenSize from '../../hooks/useScreenSize';
import CreationWizardProgressBarItem from '../../enums/CreationWizardProgressBarItem';
import AddOnList from './AddOnList';
import PackageSummaryPanel from './PackageSummaryPanel';
import AddOnType from '../../enums/AddOnType';
import { AddOnDto } from '../../domainObjects/AddOnDto';
import PlanBillingType from '../../enums/PlanBillingType';
import PackageInformation from '../../domainObjects/PackageInformation';
import OnboardingModal from '../common/OnboardingModal';
import AddOnModal from './AddOnModal';
import { useIntl } from 'react-intl';
import useAddOns from '../../hooks/useAddOns';
import SettingsHelper from '../../helpers/SettingsHelper';
import AddOnHeading from './AddOnHeading';
import AddonsService from '../../services/AddonsService';
import getSymbolFromCurrency from 'currency-symbol-map';
import AddOn from '../../viewModels/AddOn';
import { Country } from '../../domainObjects/Country';
import countryService from '../../services/CountryService';
import authenticationService from '../../services/AuthenticationService';
import LoadingSpinner from '../common/LoadingSpinner';
import PaymentConfirmation from './PaymentConfirmation';
import { CountryCode } from '../../domainObjects/CountryCode';
import AccountSubscriptionService from '../../services/AccountSubscriptionService';
import useQueryParam from '../../hooks/useQueryParam';

const BaseCreationWizardPage = () => {
    const [currentPage, setCurrentPage] = useState<CreationWizardProgressBarItem>(
        CreationWizardProgressBarItem.CreateAccountPage
    );
    const [selectedAddOnItems, setSelectedAddOnItems] = useState<AddOnType[]>([]);
    const [isAddOnModalOpen, setIsAddOnModalOpen] = useState(false);
    const [addOns, setAddons] = useState<AddOn[]>([]);
    const [modalAddOnType, setModalAddOnType] = useState<AddOnType>();
    const [packageInformation, setPackageInformation] = useState<PackageInformation>();
    const [planBillingType, setPlanBillingType] = useState<PlanBillingType>();
    const [account, setAccount] = useState<UserAccount>();
    const [fetchedAccount, setFetchedAccount] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState<string>('£');
    const [currencyCode, setCurrencyCode] = useState<string>('GBP');
    const [countryAlpha2Code, setCountryAlpha2Code] = useState<string>();
    const [countries, setCountries] = useState<Array<Country>>();
    const [availablePlans, setAvailablePlans] = useState<PackageInformation[]>([]);
    const [countryCodes, setCountryCodes] = useState<Array<CountryCode>>();
    const intl = useIntl();
    const { isTabletView } = useScreenSize();
    const {
        getAddOnTitle,
        getAddOnDescription,
        getAddOnLayout,
        getAddOnModalDescription,
        getLogo,
        getImagesInLayout,
        getDescriptions,
        getFeatures,
    } = useAddOns(intl);

    const planSelection = useQueryParam('planID');
    const countrySelection = useQueryParam('country');
    const billingPeriodSelection = useQueryParam('billingPeriod');

    const fetchAndSetAddons = useCallback(() => {
        if (planBillingType !== undefined && account) {
            AddonsService.getAddons({
                period: 1,
                periodUnit: planBillingType,
                alpha2Code: account.CountryAlpha2Code,
            }).then((response: Array<AddOnDto>) => {
                if (response) {
                    const filteredAddOns = response.filter((_: AddOnDto) => {
                        return Object.values(AddOnType).indexOf(_.ItemId) >= 0;
                    });
                    setCurrencyCode(filteredAddOns[0].CurrencyCode);
                    setCurrencySymbol(
                        filteredAddOns.length > 0 ? getSymbolFromCurrency(filteredAddOns[0].CurrencyCode) ?? '£' : '£'
                    );
                    setAddons(
                        filteredAddOns.map((addon: AddOnDto) => {
                            return new AddOn({
                                type: addon.ItemId,
                                cost: addon.Price,
                                title: getAddOnTitle(addon.ItemId),
                                logo: getLogo(addon.ItemId),
                                shortDescription: getAddOnDescription(addon.ItemId),
                                modalDescription: getAddOnModalDescription(addon.ItemId),
                                layout: getAddOnLayout(addon.ItemId),
                                imagesInLayout: getImagesInLayout(addon.ItemId),
                                descriptions: getDescriptions(addon.ItemId),
                                features: getFeatures(addon.ItemId),
                                isRecommendedAddOn:
                                    addon.ItemId === AddOnType.SmsMarketingNotifications ||
                                    addon.ItemId === AddOnType.Vouchers ||
                                    addon.ItemId === AddOnType.OrderAndPay,
                            });
                        })
                    );
                }
            });
        }
    }, [
        account,
        getAddOnDescription,
        getAddOnLayout,
        getAddOnModalDescription,
        getAddOnTitle,
        getDescriptions,
        getFeatures,
        getImagesInLayout,
        getLogo,
        planBillingType,
    ]);

    useEffect(() => {
        countryService.getCountries().then((countries: Array<Country>) => {
            setCountries(countries);
        });

        countryService.getPhoneCountryCodes().then((countryCodes: Array<CountryCode>) => {
            setCountryCodes(countryCodes);
        });
    }, []);

    useEffect(() => {
        if (fetchedAccount) {
            let countryAlpha2Code = 'GB';
            if (account?.CountryAlpha2Code) {
                countryAlpha2Code = account?.CountryAlpha2Code;
            } else if (countrySelection) {
                countryAlpha2Code = countrySelection;
            }
            setCountryAlpha2Code(countryAlpha2Code);
        }
    }, [countrySelection, account?.CountryAlpha2Code, fetchedAccount]);

    useEffect(() => {
        if (billingPeriodSelection && parseInt(billingPeriodSelection) in PlanBillingType) {
            setPlanBillingType(parseInt(billingPeriodSelection));
        } else {
            setPlanBillingType(PlanBillingType.Monthly);
        }
    }, [billingPeriodSelection]);

    useEffect(() => {
        if (countries) {
            let jwtToken = SettingsHelper.getJwtToken();
            if (jwtToken) {
                authenticationService.isJWTTokenValid(jwtToken).then((isTokenValid) => {
                    if (isTokenValid) {
                        AccountSubscriptionService.getAccountInfoByJWTToken(jwtToken!).then((accountInfo) => {
                            let countryAlpha2Code = '';
                            const alpha2 = countries.find((_) => _.Id === accountInfo.CountryId)?.Alpha2Code;
                            countryAlpha2Code = alpha2 ?? '';
                            setAccount({
                                FirstName: accountInfo.FirstName,
                                LastName: accountInfo.LastName,
                                ProviderName: accountInfo.ProviderName,
                                CountryId: accountInfo.CountryId,
                                MobilePhoneCode: '',
                                MobilePhoneNumber: '',
                                MobilePhoneCodeCountryId: '',
                                EmailAddress: accountInfo.Email,
                                Password: '',
                                LimitedCompanyName: '',
                                CountryAlpha2Code: countryAlpha2Code ?? '',
                            });
                            setFetchedAccount(true);
                        });
                    } else {
                        window.location.replace(SettingsHelper.getLoginUrl());
                    }
                });
            } else {
                setFetchedAccount(true);
            }
        }
    }, [countries]);

    useEffect(() => {
        if (!packageInformation && planBillingType !== undefined && countryAlpha2Code && fetchedAccount) {
            let componentIsMounted = true; //Boolean flag to track if page is still mounted when promise resolves.'

            const getPlanInfoData = async () => {
                const response = await AddonsService.getPlanInformation({
                    period: 1,
                    periodUnit: planBillingType,
                    alpha2Code: countryAlpha2Code,
                    chargebeePlanId: planSelection ?? 'RDPro',
                });
                if (componentIsMounted && response) {
                    setPackageInformation({
                        name: response.Name,
                        reservationLimit: response.ReservationLimit,
                        cost: response.Price,
                        id: response.Id,
                        billingType: planBillingType,
                        setupFee: response.SetupFee,
                        discountPercentage: response.DiscountPercentage,
                    });

                    setCurrencyCode(response.CurrencyCode);
                    setCurrencySymbol(getSymbolFromCurrency(response.CurrencyCode) ?? '£');
                }
            };

            getPlanInfoData();

            return () => {
                componentIsMounted = false; //Set flag to false when component unmounts.
            };
        }
    }, [planBillingType, packageInformation, planSelection, countryAlpha2Code, fetchedAccount]);

    useEffect(() => {
        let componentIsMounted = true; //Boolean flag to track if page is still mounted when promise resolves.

        const getAvailablePlans = async () => {
            if (planBillingType !== undefined && countryAlpha2Code) {
                const response = await AddonsService.getAvailablePlans({
                    periodUnit: planBillingType,
                    alpha2Code: countryAlpha2Code,
                });
                if (componentIsMounted && response) {
                    setAvailablePlans(
                        response.map((plan) => ({
                            name: plan.Name,
                            reservationLimit: plan.ReservationLimit,
                            cost: plan.Price,
                            setupCost: 0,
                            id: plan.Id,
                            billingType: planBillingType,
                            setupFee: plan.SetupFee,
                            discountAmount: plan.DiscountPercentage,
                        }))
                    );
                    const selectedPlan = response.find((data) => data.Id === packageInformation?.id);
                    if (selectedPlan) {
                        setPackageInformation({
                            name: selectedPlan.Name,
                            reservationLimit: selectedPlan.ReservationLimit,
                            cost: selectedPlan.Price,
                            id: selectedPlan.Id,
                            billingType: planBillingType,
                            setupFee: selectedPlan.SetupFee,
                            discountPercentage: selectedPlan.DiscountPercentage,
                        });
                    }
                }
            }
        };

        getAvailablePlans();

        return () => {
            componentIsMounted = false; //Set flag to false when component unmounts.
        };
    }, [packageInformation?.id, planBillingType, countryAlpha2Code]);

    useEffect(() => {
        if (account?.CountryAlpha2Code && currentPage === CreationWizardProgressBarItem.AddOnsPage) {
            fetchAndSetAddons();
        }
    }, [account?.CountryAlpha2Code, currentPage, fetchAndSetAddons, planBillingType]);

    useEffect(() => {
        if (SettingsHelper.getJwtToken() !== null) {
            setCurrentPage(CreationWizardProgressBarItem.AddOnsPage);
        }
    }, []);

    const showMoreDetail = (type: AddOnType) => {
        setModalAddOnType(type);
        setIsAddOnModalOpen(true);
    };

    const selectedAddons = (): AddOnDto[] => {
        const selected = addOns.filter((addon) => {
            return selectedAddOnItems.some((filter) => {
                return addon.type === filter;
            });
        });
        let selecteAddOnDtos = selected.map((item) => {
            return {
                Price: item.cost,
                Name: item.title,
                ItemId: item.type,
                CurrencyCode: currencyCode,
            };
        });
        return selecteAddOnDtos;
    };

    const selectAddOnItem = (type: AddOnType) => {
        if (selectedAddOnItems.indexOf(type) === -1) {
            setSelectedAddOnItems([...selectedAddOnItems, type]);
        }
    };

    const unselectAddOnItem = (type: AddOnType) => {
        if (selectedAddOnItems.indexOf(type) !== -1) {
            setSelectedAddOnItems(selectedAddOnItems.filter((element) => element !== type));
        }
    };

    const anyAddOnSelected = (): boolean => {
        return selectedAddOnItems.length > 0;
    };

    const itemIsSelected = (type: AddOnType) => {
        return selectedAddOnItems.indexOf(type) !== -1;
    };

    function goToNextPage() {
        if (currentPage === CreationWizardProgressBarItem.AddOnsPage) {
            if (SettingsHelper.getJwtToken() === null) {
                setCurrentPage(CreationWizardProgressBarItem.CreateAccountPage);
            } else {
                setCurrentPage(CreationWizardProgressBarItem.PaymentPage);
            }
        } else if (currentPage === CreationWizardProgressBarItem.CreateAccountPage) {
            setCurrentPage(CreationWizardProgressBarItem.AddOnsPage);
        }
    }

    function isPageLoaded() {
        switch (currentPage) {
            case CreationWizardProgressBarItem.CreateAccountPage:
                return countries && packageInformation;
            case CreationWizardProgressBarItem.AddOnsPage:
                return countries && packageInformation && account && addOns;
            case CreationWizardProgressBarItem.PaymentPage:
                return packageInformation && account && addOns;
            default:
                return false;
        }
    }

    function getPageContent() {
        switch (currentPage) {
            case CreationWizardProgressBarItem.CreateAccountPage:
                return (
                    <CreateAccountForm
                        goToNextPage={goToNextPage}
                        setUserAccount={setAccount}
                        countries={countries}
                        countryCodes={countryCodes}
                    ></CreateAccountForm>
                );
            case CreationWizardProgressBarItem.AddOnsPage:
                return (
                    <Fragment>
                        <AddOnList
                            addOns={addOns}
                            itemIsSelected={itemIsSelected}
                            selectAddOnItem={selectAddOnItem}
                            unselectAddOnItem={unselectAddOnItem}
                            showMoreDetail={showMoreDetail}
                            currencySymbol={currencySymbol}
                            planBillingType={planBillingType}
                        />
                    </Fragment>
                );
            case CreationWizardProgressBarItem.PaymentPage:
                return (
                    <Fragment>
                        <PaymentConfirmation
                            account={account}
                            selectedAddons={selectedAddons()}
                            userId={SettingsHelper.getUserId()}
                            telephoneCode={
                                account
                                    ? `+${
                                          countryCodes?.find((_) => _.Id === parseInt(account.MobilePhoneCode))
                                              ?.PhoneCode
                                      } ${account.MobilePhoneNumber}`
                                    : ''
                            }
                            packageInformation={packageInformation!}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Fragment>
                );
        }
    }

    return (
        <>
            {!isPageLoaded() ? (
                <LoadingSpinner />
            ) : (
                <>
                    <CreationWizardHeader />
                    <div
                        className={
                            currentPage === CreationWizardProgressBarItem.PaymentPage || isTabletView
                                ? 'wizard-page-container mob'
                                : 'wizard-page-container'
                        }
                    >
                        <div
                            className={
                                currentPage === CreationWizardProgressBarItem.PaymentPage || isTabletView
                                    ? 'creation-wizard mob'
                                    : 'creation-wizard'
                            }
                        >
                            <CreationWizardProgressBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            {getPageContent()}
                            {currentPage !== CreationWizardProgressBarItem.CreateAccountPage &&
                                currentPage !== CreationWizardProgressBarItem.PaymentPage && (
                                    <CreationWizardFooter
                                        goToNextPage={goToNextPage}
                                        currentPage={currentPage}
                                        anyAddOnSelected={anyAddOnSelected}
                                    />
                                )}
                        </div>
                        {packageInformation && currentPage !== CreationWizardProgressBarItem.PaymentPage ? (
                            <PackageSummaryPanel
                                availablePlans={availablePlans}
                                selectedAddOnItems={selectedAddOnItems}
                                addOns={addOns}
                                unselectAddOnItem={unselectAddOnItem}
                                packageInformation={packageInformation}
                                setPackageInformation={setPackageInformation}
                                currencySymbol={currencySymbol}
                                planBillingType={planBillingType}
                                onPlanBillingTypeChange={setPlanBillingType}
                            />
                        ) : null}
                    </div>
                    <OnboardingModal
                        isModalOpen={isAddOnModalOpen}
                        setIsModalOpen={setIsAddOnModalOpen}
                        body={
                            modalAddOnType && (
                                <AddOnModal
                                    addOn={addOns.find((a) => a.type === modalAddOnType)}
                                    isSelected={itemIsSelected}
                                    selectAddOnItem={selectAddOnItem}
                                    unselectAddOnItem={unselectAddOnItem}
                                    setIsAddOnModalOpen={setIsAddOnModalOpen}
                                    setModalAddOnType={setModalAddOnType}
                                    addOns={addOns}
                                    currencySymbol={currencySymbol}
                                    planBillingType={planBillingType}
                                />
                            )
                        }
                        actionButtonText={''}
                        actionButtonPendingText={''}
                        hideFooter={true}
                        customHeader={
                            <AddOnHeading
                                addOn={addOns.find((a) => a.type === modalAddOnType)}
                                isModal
                                currencySymbol={currencySymbol}
                                planBillingType={planBillingType}
                            />
                        }
                        modalHeaderClass={isTabletView ? 'addon-modal-header mob' : 'addon-modal-header'}
                        modalBodyClass={isTabletView ? 'addon-modal-body mob' : 'addon-modal-body'}
                        isScrollable
                    />
                </>
            )}
        </>
    );
};

export default BaseCreationWizardPage;
