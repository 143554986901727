export type IntervalOptionSettings = {
    Id: number;
    TimeInMinutes: number;
    Label: string;
};
class IntervalOption {
    id: number;
    timeInMinutes: number;
    label: string;
    constructor(settings: IntervalOptionSettings) {
        this.id = settings && settings.Id ? settings.Id : 0;
        this.timeInMinutes = settings && settings.TimeInMinutes ? settings.TimeInMinutes : 0;
        this.label = settings && settings.Label ? settings.Label : '';
    }
}
export default IntervalOption;
