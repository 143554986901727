import React from 'react';
import { FormGroup, CustomInput, Label } from 'reactstrap';
import useScreenSize from '../../hooks/useScreenSize';
import ValidationMessage from './ValidationMessage';

export type CheckBoxProps = {
    isChecked: boolean;
    disabled?: boolean;
    id: string;
    option: any;
    onChange: (checked: boolean) => void;
    ariaRequired?: boolean;
    ariaLabel?: string;
    getLink?: () => React.ReactNode;
    innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    errors?: { [key: string]: any };
    name?: string;
};

const CheckBox = ({
    isChecked,
    disabled,
    id,
    option,
    onChange,
    ariaRequired,
    ariaLabel,
    getLink,
    innerRef,
    errors,
    name,
}: CheckBoxProps) => {
    const { isMobileView } = useScreenSize();

    return (
        <FormGroup check key={option.value}>
            <Label className={!isMobileView ? 'd-flex' : ''}>
                <CustomInput
                    data-testid={'checkbox-input-' + option.value}
                    disabled={disabled}
                    id={id}
                    name={name}
                    type="checkbox"
                    label={option.text}
                    onChange={() => onChange(!isChecked)}
                    checked={isChecked}
                    aria-required={ariaRequired}
                    aria-label={ariaLabel}
                    innerRef={innerRef}
                />
                {getLink && getLink()}
            </Label>
            {errors && name && errors[name] && (
                <div className="validation-input-feedback">
                    <ValidationMessage isBlock={false} message={errors[name].message} />
                </div>
            )}
        </FormGroup>
    );
};

export default CheckBox;
