import React, { Fragment, useCallback, useEffect, useState } from 'react';
import trackingEvents from '../../../enums/trackingEvents';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-logo.jpg';
import AddCoversAndIntervals from './AddCoversAndIntervals';
import { useIntl } from 'react-intl';
import useValidation from '../../../hooks/useValidation';
import RestaurantService from '../../../domainObjects/RestaurantService';
import AddServiceButton from '../../common/AddServiceButton';
import IntervalOption from '../../../domainObjects/IntervalOption';
import AverageEatDuration from '../../../domainObjects/AverageEatDuration';
import settingsService from '../../../services/SettingsService';
import SettingsHelper from '../../../helpers/SettingsHelper';
import rdfOnboardingProgressFlags from '../../../enums/rdfOnboardingProgressFlags';
import TimeHelper from '../../../helpers/TimeHelper';
import InfoBars from '../../common/InfoBars';
import { InfoBarProps } from '../../common/InfoBar';
import CoversAndIntervalsItem from './CoversAndIntervalsItem';
import useScreenSize from '../../../hooks/useScreenSize';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import { Spinner } from 'reactstrap';

type CoversAndIntervalsPageProps = {
    venueServices: Array<RestaurantService> | null;
    setVenueServices: (venueServices: Array<RestaurantService>) => void;
    updateProgressFlag: (progressFlag: string) => void;
    isLoading: boolean;
};

const CoversAndIntervalsPage = ({
    venueServices,
    setVenueServices,
    updateProgressFlag,
    isLoading,
}: CoversAndIntervalsPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const [isUpdating, setIsUpdating] = useState(false);
    const [currentService, setCurrentService] = useState(0);
    const [services, setServices] = useState<Array<RestaurantService>>([]);
    const [hasExistingSetup, setHasExistingSetup] = useState(false);
    const [infoBars, setInfoBars] = useState<Array<InfoBarProps>>([]);
    const { isMobileView, isTabletView } = useScreenSize();
    const [continueDisabled, setContinueDisabled] = useState(false);

    const updateCoversAndIntervalsProgressFlag = () => {
        updateProgressFlag(rdfOnboardingProgressFlags.addedServiceCoversAndIntervals);
    };

    const setService = (service: RestaurantService) => {
        let newServices = [...services.filter((x) => x.id !== service.id), service];
        setServices(
            newServices.sort((a, b) => {
                if (a.timeFrom.getTime() < b.timeFrom.getTime()) {
                    return -1;
                }
                if (a.timeFrom.getTime() > b.timeFrom.getTime()) {
                    return 1;
                }
                return 0;
            })
        );
    };

    const updateService = (id: number, isCovers: boolean, applyToAll: boolean) => {
        setIsUpdating(true);
        const newServiceList = [...services];
        const service = newServiceList.filter((x) => x.id === id)[0];
        if (applyToAll) {
            // apply settings to all other services
            newServiceList
                .filter((x) => x.id !== service.id)
                .forEach((newService) => {
                    newService.timeSlotInterval = service.timeSlotInterval;
                    if (isCovers) {
                        newService.maxCovers = service.maxCovers;
                        newService.maxBookings = 0;
                    }
                    if (!isCovers) {
                        newService.maxBookings = service.maxBookings;
                        newService.maxCovers = 0;
                    }
                    newService.averageEatDurations = newService.averageEatDurations.map((x) => {
                        return new AverageEatDuration({
                            Id: x.id,
                            ServiceId: x.serviceId,
                            CoversFrom: x.coversFrom,
                            CoversTo: x.coversTo,
                            AverageTime: service.averageEatDurations.filter(
                                (y) => x.coversFrom === y.coversFrom && x.coversTo === y.coversTo
                            )[0].averageTime,
                            IsCoversRuleEnabled: true,
                        });
                    });
                    const serviceDto = {
                        Id: newService.id,
                        Name: newService.name,
                        Description: newService.name,
                        TimeFrom: TimeHelper.getTimeIn24HourFormat(newService.timeFrom),
                        TimeUntil: TimeHelper.getTimeIn24HourFormat(newService.timeUntil),
                        LastBookingTime: TimeHelper.getTimeIn24HourFormat(newService.lastBookingTime),
                        TimeSlotInterval: newService.timeSlotInterval,
                        MaxBookings: !isCovers ? newService.maxBookings : null,
                        MaxCovers: isCovers ? newService.maxCovers : null,
                        SegmentId: SettingsHelper.getSegmentId(),
                    };
                    settingsService.updateVenueService(serviceDto);
                    settingsService.updateAverageEatDurations(newService.averageEatDurations, newService.id);
                });
            setServices(newServiceList);
        }
        const serviceDto = {
            Id: service.id,
            Name: service.name,
            Description: service.name,
            TimeFrom: TimeHelper.getTimeIn24HourFormat(service.timeFrom),
            TimeUntil: TimeHelper.getTimeIn24HourFormat(service.timeUntil),
            LastBookingTime: TimeHelper.getTimeIn24HourFormat(service.lastBookingTime),
            TimeSlotInterval: service.timeSlotInterval,
            MaxBookings: !isCovers ? service.maxBookings : null,
            MaxCovers: isCovers ? service.maxCovers : null,
            SegmentId: SettingsHelper.getSegmentId(),
        };
        settingsService.updateVenueService(serviceDto).then(() =>
            settingsService.updateAverageEatDurations(service.averageEatDurations, service.id).then(() => {
                setIsUpdating(false);
                setVenueServices(services);
                setCurrentService(0);
                if (applyToAll) {
                    setInfoBars([
                        ...infoBars,
                        {
                            id: infoBars.length > 0 ? infoBars[infoBars.length - 1].id + 1 : 0,
                            message: intl.formatMessage(
                                { id: 'OnboardingWizard.VenueCoversAndIntervalsApplyToAllConfirmation' },
                                { serviceName: service.name }
                            ),
                            type: 'success',
                            onDismiss: () => {},
                        },
                    ]);
                }
            })
        );
    };

    const deleteServiceSetup = (id: number) => {
        const service = services.filter((x) => x.id === id)[0];
        service.timeSlotInterval = 0;
        service.maxBookings = 0;
        service.maxCovers = 0;
        service.averageEatDurations = [];
        const serviceDto = {
            Id: service.id,
            Name: service.name,
            Description: service.name,
            TimeFrom: TimeHelper.getTimeIn24HourFormat(service.timeFrom),
            TimeUntil: TimeHelper.getTimeIn24HourFormat(service.timeUntil),
            LastBookingTime: TimeHelper.getTimeIn24HourFormat(service.lastBookingTime),
            TimeSlotInterval: service.timeSlotInterval,
            MaxBookings: service.maxBookings,
            MaxCovers: service.maxCovers,
            SegmentId: SettingsHelper.getSegmentId(),
        };
        settingsService.updateVenueService(serviceDto).then(() =>
            settingsService.updateAverageEatDurations(service.averageEatDurations, service.id).then(() => {
                setIsUpdating(false);
                setService(service);
                setVenueServices(services);
                setCurrentService(0);
                setInfoBars([
                    ...infoBars,
                    {
                        id: infoBars.length > 0 ? infoBars[infoBars.length - 1].id + 1 : 0,
                        message: intl.formatMessage(
                            { id: 'OnboardingWizard.VenueCoversAndIntervalsDeleteConfirmation' },
                            { serviceName: service.name }
                        ),
                        type: 'success',
                        onDismiss: () => {},
                    },
                ]);
            })
        );
    };

    const intervalOptions = Array<IntervalOption>(
        new IntervalOption({
            Id: 1,
            TimeInMinutes: 15,
            Label: intl.formatMessage({ id: 'Common.15MinutesTimeInterval' }),
        }),
        new IntervalOption({
            Id: 2,
            TimeInMinutes: 30,
            Label: intl.formatMessage({ id: 'Common.30MinutesTimeInterval' }),
        }),
        new IntervalOption({
            Id: 3,
            TimeInMinutes: 60,
            Label: intl.formatMessage({ id: 'Common.60MinutesTimeInterval' }),
        })
    );

    const setFormValidationValue = (name: string, value: Date) => {
        formValidation.setValue(name, value, {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const setVenueAverageEatDurations = useCallback(
        (venueServices: Array<RestaurantService>) => {
            let newServiceList = [...venueServices];
            // If array is empty, return new list of AverageEatDurations, otherwise populate state with values from api
            let newAverageEatDurations = new Array<AverageEatDuration>(
                new AverageEatDuration({
                    Id: 1,
                    ServiceId: currentService,
                    AverageTime: 30,
                    CoversFrom: 1,
                    CoversTo: 2,
                    IsCoversRuleEnabled: true,
                }),
                new AverageEatDuration({
                    Id: 2,
                    ServiceId: currentService,
                    AverageTime: 60,
                    CoversFrom: 3,
                    CoversTo: 4,
                    IsCoversRuleEnabled: true,
                }),
                new AverageEatDuration({
                    Id: 3,
                    ServiceId: currentService,
                    AverageTime: 60,
                    CoversFrom: 5,
                    CoversTo: 8,
                    IsCoversRuleEnabled: true,
                }),
                new AverageEatDuration({
                    Id: 4,
                    ServiceId: currentService,
                    AverageTime: 60,
                    CoversFrom: 9,
                    CoversTo: 12,
                    IsCoversRuleEnabled: true,
                }),
                new AverageEatDuration({
                    Id: 5,
                    ServiceId: currentService,
                    AverageTime: 120,
                    CoversFrom: 13,
                    CoversTo: 99,
                    IsCoversRuleEnabled: true,
                })
            );
            newServiceList.forEach((newService, i) => {
                newService.averageEatDurations = newAverageEatDurations.map((newAverageEatDuration) => {
                    let newServiceAverageEatDuration = newService.averageEatDurations?.filter(
                        (x) =>
                            newAverageEatDuration.coversFrom === x.coversFrom &&
                            newAverageEatDuration.coversTo === x.coversTo
                    )[0];
                    if (newServiceAverageEatDuration) {
                        // If current service has averageEatDuration with same coversTo and coversFrom as newAverageEatDuration, return it
                        newServiceAverageEatDuration.isCoversRuleEnabled = true;
                        return newServiceAverageEatDuration;
                    } else {
                        // else return the new average eat duration with default values
                        newServiceAverageEatDuration = { ...newAverageEatDuration };
                        newServiceAverageEatDuration.serviceId = newService.id;
                        return newServiceAverageEatDuration;
                    }
                });
            });
            setServices(newServiceList);
        },
        [currentService]
    );

    const isFormValid = useCallback(() => {
        for (let service of services) {
            if (service.timeSlotInterval === 0 || currentService === service.id) {
                return false;
            }
        }
        return true;
    }, [services, currentService]);

    useEffect(() => {
        if (venueServices) {
            setServices([...venueServices]);
        }
    }, [venueServices]);

    useEffect(() => {
        if (venueServices) {
            setVenueAverageEatDurations(venueServices);
        }
    }, [setVenueAverageEatDurations, venueServices]);

    useEffect(() => {
        setContinueDisabled(!isFormValid());
    }, [services, isFormValid]);

    const getFormBody = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <Spinner color="primary" />
                </div>
            );
        }
        return (
            <div className={`covers-and-intervals-container ${isMobileView || isTabletView ? 'mobile' : ''}`}>
                <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} isOnboarding />

                {services?.map((service, i) => {
                    return currentService !== service.id ? (
                        <Fragment key={i}>
                            {service.timeSlotInterval !== 0 && (
                                <CoversAndIntervalsItem
                                    service={service}
                                    setCurrentService={setCurrentService}
                                    isUpdating={isUpdating}
                                    deleteServiceSetup={deleteServiceSetup}
                                />
                            )}
                            {service.timeSlotInterval === 0 && (
                                <AddServiceButton
                                    onClick={() => {
                                        setCurrentService(service.id);
                                    }}
                                    label={service.name}
                                    linkText={intl.formatMessage({
                                        id: 'OnboardingWizard.VenueCoversAndIntervalsPageAddCoversAndIntervalsButton',
                                    })}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <AddCoversAndIntervals
                            key={i}
                            intervalOptions={intervalOptions}
                            currentInterval={service.timeSlotInterval}
                            maxPerInterval={service.maxCovers !== 0 ? service.maxCovers : service.maxBookings}
                            service={service}
                            setService={setService}
                            setCurrentService={setCurrentService}
                            updateService={updateService}
                            hasExistingSetup={hasExistingSetup}
                            setHasExistingSetup={setHasExistingSetup}
                            isUpdating={isUpdating}
                            formValidation={formValidation}
                            setFormValidationValue={setFormValidationValue}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({
                id: 'OnboardingWizard.VenueCoversAndIntervalsPageTitle',
            })}
            currentStep={rdfOnboardingSteps.coversAndIntervalsPage}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            children={getFormBody()}
            updateSettings={updateCoversAndIntervalsProgressFlag}
            isUpdating={isUpdating}
            formValidation={formValidation}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfIntervalCoversPage}
            continueDisabled={continueDisabled}
        />
    );
};

export default CoversAndIntervalsPage;
