import AddOnLayout from '../enums/AddOnLayout';
import AddOnItem from '../enums/AddOnType';
import AddOnFeature from './AddOnFeature';

export type AddOnSettings = {
    type: AddOnItem;
    cost: number;
    logo: JSX.Element;
    title: string;
    shortDescription: string;
    modalDescription: string;
    layout: AddOnLayout;
    features?: Array<AddOnFeature> | null;
    isRecommendedAddOn?: boolean;
    imagesInLayout?: string[] | null;
    descriptions?: string[] | null;
};

class AddOn {
    type: AddOnItem;
    cost: number;
    logo: JSX.Element;
    title: string;
    shortDescription: string;
    modalDescription: string;
    layout: AddOnLayout;
    features?: Array<AddOnFeature> | null;
    isRecommendedAddOn?: boolean;
    imagesInLayout?: string[] | null;
    descriptions?: string[] | null;

    constructor(addOnSettings: AddOnSettings) {
        this.type = addOnSettings.type;
        this.cost = addOnSettings && addOnSettings.cost ? addOnSettings.cost : 0;
        this.logo = addOnSettings.logo;
        this.title = addOnSettings.title;
        this.shortDescription = addOnSettings.shortDescription;
        this.modalDescription = addOnSettings.modalDescription;
        this.layout = addOnSettings.layout;
        this.features = addOnSettings ? addOnSettings.features : null;
        this.isRecommendedAddOn =
            addOnSettings && addOnSettings.isRecommendedAddOn ? addOnSettings.isRecommendedAddOn : false;
        this.imagesInLayout = addOnSettings ? addOnSettings.imagesInLayout : null;
        this.descriptions = addOnSettings ? addOnSettings.descriptions : null;
    }
}

export default AddOn;
