import React, { Fragment, useCallback, useEffect, useState } from 'react';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-logo.jpg';
import { ReactComponent as StatusDoneIcon } from '../../../content/icons/Icon-Status-done.svg';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/delete.svg';
import VenueImage from '../../../domainObjects/VenueImage';
import { Button, ButtonGroup, Label, UncontrolledTooltip } from 'reactstrap';
import ImageDropzone from '../../common/ImageDropzone';
import { Image } from '../../../domainObjects/VenueImage';
import useScreenSize from '../../../hooks/useScreenSize';
import ImageEditor from '../../common/ImageEditor';
import { Spinner } from 'reactstrap';
import { useIntl } from 'react-intl';
import TextWithIcon from '../../common/TextWithIcon';
import settingsService from '../../../services/SettingsService';
import ImageHelper from '../../../helpers/ImageHelper';
import trackingEvents from '../../../enums/trackingEvents';
import useValidation from '../../../hooks/useValidation';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';

export type LogoPageProps = {
    venueLogo: VenueImage | null;
    isLoading: boolean;
    uploadProgress: number;
    updateLogo: (newImage: Image, currentImage: Image | null | undefined, isMainImage: boolean) => Promise<any>;
    deleteImage: (imageIds: Array<number>) => void;
};

const LogoPage = ({ venueLogo, isLoading, uploadProgress, updateLogo, deleteImage }: LogoPageProps) => {
    const { isMobileView, isTabletView } = useScreenSize();
    const [currentImage, setCurrentImage] = useState<Image | null | undefined>();
    const [imageToEdit, setImageToEdit] = useState<Image | null | undefined>();
    const [originalImage, setOriginalImage] = useState<Image | null | undefined>();
    const [initialImage, setIntitialImage] = useState<Image | null | undefined>();
    const [isEditing, setIsEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const intl = useIntl();
    const [uploadError, setUploadError] = useState('');
    const formValidation = useValidation();

    const setImage = (file: any) => {
        file = ImageHelper.trimFilenameToDbLimit(file);
        setImageToEdit({
            id: 0,
            preview: '',
            name: file.name,
            size: file.size,
            type: file.type,
            file: file,
        });
        setOriginalImage({
            id: 0,
            preview: '',
            name: file.name,
            size: file.size,
            type: file.type,
            file: file,
        });
        setIsEditing(true);
    };

    const isMobileTabletView = () => {
        if (isMobileView) {
            return 'mobile';
        }

        if (isTabletView) return 'tablet';
    };

    const uploadImage = useCallback(async () => {
        if (imageToEdit) {
            await updateLogo(imageToEdit, currentImage, true);
        }
    }, [imageToEdit, currentImage, updateLogo]);

    const setImageFile = (blob: Blob, previewUrl: string) => {
        setIsUploading(true);
        setImageToEdit({
            ...(imageToEdit as Image),
            file: new File([blob], (imageToEdit as Image).name),
            preview: previewUrl,
        });
        formValidation.reset();
        setIsEditing(false);
    };

    const resetImageToEdit = () => {
        setImageToEdit({
            ...(initialImage as Image),
        });
    };

    useEffect(() => {
        uploadImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageToEdit]);

    useEffect(() => {
        /// TODO: error handling

        if (venueLogo && venueLogo.id !== 0) {
            setUploadError('');
            setIsUploading(false);
            formValidation.reset();
            if (currentImage) {
                setCurrentImage({
                    ...(currentImage as Image),
                    preview: venueLogo.imageUrl,
                    id: venueLogo.id,
                    name: venueLogo.name,
                });
                setOriginalImage({
                    ...(currentImage as Image),
                });
            } else {
                setCurrentImage({
                    id: venueLogo.id,
                    preview: venueLogo.imageUrl,
                    name: venueLogo.name,
                    size: 1,
                    type: 'image/jpg',
                });
                setOriginalImage({
                    id: venueLogo.id,
                    preview: venueLogo.imageUrl,
                    name: venueLogo.name,
                    size: 1,
                    type: 'image/jpg',
                });
                setIntitialImage(originalImage);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [venueLogo]);

    const setImageAsMainImage = () => {
        settingsService.setImageAsMainImage((currentImage as Image).id);
    };

    const getFormBody = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <Spinner color="primary" />
                </div>
            );
        }

        const imageDropzone =
            (!currentImage && !imageToEdit && venueLogo) || isUploading ? (
                <ImageDropzone
                    setImage={setImage}
                    uploadProgress={uploadProgress}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    uploadError={uploadError}
                    setUploadError={setUploadError}
                    formValidation={formValidation}
                    isMainImage={false}
                    minWidth={1000}
                    minHeight={1000}
                />
            ) : null;

        //TODO: test in feature environment whether editing an existing logo works
        const imageEditor =
            ((imageToEdit && imageToEdit.file) || (!imageToEdit?.file && currentImage && currentImage.preview)) &&
            isEditing &&
            !isUploading ? (
                <Fragment>
                    <Label className="image-editor-label">
                        {intl.formatMessage({
                            id: 'OnboardingWizard.DragToRepositionLabel',
                        })}
                    </Label>
                    <ImageEditor
                        file={
                            imageToEdit && imageToEdit.file
                                ? imageToEdit.file
                                : currentImage && currentImage.preview
                                ? currentImage.preview
                                : ''
                        }
                        setImageFile={setImageFile}
                        resetImage={() => {
                            setImageToEdit(null);
                            setCurrentImage(null);
                            setIsEditing(false);
                        }}
                        resetImageText={intl.formatMessage({
                            id: 'OnboardingWizard.UploadNewLogoLabel',
                        })}
                        disableControls={isUploading}
                        formValidation={formValidation}
                        scroll={true}
                    ></ImageEditor>
                </Fragment>
            ) : null;

        const imagePreview =
            currentImage && currentImage.preview !== '' && !isEditing && !isUploading ? (
                <Fragment>
                    <div className="image-preview-wrapper">
                        <ButtonGroup className="image-preview-button-group">
                            <Button
                                id="EditButton"
                                onClick={() => {
                                    resetImageToEdit();
                                    setIsEditing(true);
                                }}
                                className="image-preview-button"
                            >
                                <EditIcon />
                            </Button>
                            <UncontrolledTooltip flip target="EditButton">
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.VenueLogoEditButtonTooltip',
                                })}
                            </UncontrolledTooltip>
                            <Button
                                id="DeleteButton"
                                onClick={() => {
                                    deleteImage([currentImage.id]);
                                    setCurrentImage(null);
                                    setImageToEdit(null);
                                }}
                                className="image-preview-button"
                            >
                                <DeleteIcon />
                            </Button>
                            <UncontrolledTooltip flip target="DeleteButton">
                                {intl.formatMessage({
                                    id: 'OnboardingWizard.VenueLogoDeleteButtonTooltip',
                                })}
                            </UncontrolledTooltip>
                        </ButtonGroup>
                        {/* Math.random() added to end of image links to avoid caching when going back and forth between editor and image preview */}
                        <img
                            src={`${currentImage?.preview}?${Math.random()}`}
                            className="image-preview"
                            alt="cropped"
                        />
                    </div>
                    <TextWithIcon
                        className="status-done-wrapper"
                        icon={<StatusDoneIcon />}
                        text={intl.formatMessage({
                            id: 'OnboardingWizard.AllSetLabel',
                        })}
                        iconClass="status-done-icon"
                        textClass="status-done-text"
                    ></TextWithIcon>
                </Fragment>
            ) : null;

        return (
            <div className={`logo-page-body-wrapper ${isMobileTabletView()}`}>
                {imageDropzone}
                {imageEditor}
                {imagePreview}
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({
                id: 'OnboardingWizard.VenueLogoPageTitle',
            })}
            currentStep={rdfOnboardingSteps.logoPage}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            children={getFormBody()}
            updateSettings={setImageAsMainImage}
            isUpdating={isUploading || uploadError !== ''}
            formValidation={formValidation}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfVenueLogoPage}
            shouldExcludeFormPadding={!isMobileView}
        />
    );
};

export default LogoPage;
