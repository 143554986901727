import React, { ChangeEventHandler, Fragment } from 'react';
import { Input, InputGroup, DropdownMenu, DropdownItem, InputGroupAddon } from 'reactstrap';
import { ReactComponent as CloseIcon } from '../../content/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../content/icons/search.svg';

export type DropdownMenuWrapperProps = {
    endEdit?: ChangeEventHandler<any>;
    filterSearch: (value: string) => void;
    hasDefaultValue?: string | boolean;
    isSearchable?: boolean;
    flip?: boolean;
    options: Array<any>;
    optionGroups?: Array<any>;
    searchTerm?: string;
    className?: string;
    onValueChange: (value: any) => void;
    selectedValue?: string | number;
    hideRemoveItemIcon?: boolean;
    innerRef?: React.Ref<HTMLDivElement>;
};

const DropdownMenuWrapper = ({
    endEdit,
    filterSearch,
    hasDefaultValue,
    isSearchable,
    flip,
    options,
    optionGroups,
    searchTerm,
    className,
    onValueChange,
    selectedValue,
    hideRemoveItemIcon,
    innerRef,
}: DropdownMenuWrapperProps) => {
    const getDropdownEntry = (option: {
        value: React.Key | null | undefined;
        text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    }) => {
        const isCurrentValue = selectedValue === option.value;
        return (
            <Fragment key={option.value}>
                <div className="dropdown-item-wrapper" ref={isCurrentValue ? innerRef : null}>
                    <DropdownItem
                        className={isCurrentValue ? 'dropdown-item-selected' : ''}
                        onClick={() => {
                            if (!isCurrentValue) onValueChange(option.value);
                        }}
                    >
                        {option.text}
                    </DropdownItem>
                    {isCurrentValue && hasDefaultValue && !hideRemoveItemIcon && (
                        <div>
                            <CloseIcon onClick={(e) => removeSelectedValue(e, option.value)} />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    };

    const getDropdownOptions = () => {
        // Prioritise optionGroups if both those and options have been passed as props
        if (optionGroups) {
            const optionGroupsWithOptions = optionGroups.filter((g) => g.options.length > 0);
            return optionGroupsWithOptions.map((optionGroup, index) => {
                return (
                    <Fragment key={index}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <DropdownItem header>{optionGroup.label}</DropdownItem>
                        </div>
                        {getFilteredOptions(optionGroup.options)}
                    </Fragment>
                );
            });
        } else if (options) {
            return getFilteredOptions(options);
        } else {
            return null;
        }
    };

    const getFilteredOptions = (options: any[]) => {
        // Filter results if required, if not just return the options as usual
        return searchTerm !== '' && isSearchable
            ? options.reduce((filtered: JSX.Element[], option: { value: any; text: string }) => {
                  if (option.text.toLowerCase().indexOf(searchTerm!) > -1) {
                      filtered.push(getDropdownEntry(option));
                  }
                  return filtered;
              }, [])
            : options.map((option: any) => getDropdownEntry(option));
    };

    const removeSelectedValue = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, value: any) => {
        // Prevent further event bubbling
        e.stopPropagation();
        onValueChange(value);
    };

    // Include search bar in the dropdown menu or not
    const dropdownOptions = isSearchable
        ? [
              <InputGroup key={1}>
                  <InputGroupAddon addonType="prepend">
                      <SearchIcon />
                  </InputGroupAddon>
                  <Input
                      autoFocus
                      type="text"
                      onBlur={endEdit}
                      onClick={(e) => {
                          e.stopPropagation();
                      }}
                      placeholder={'Type to search'}
                      onChange={(e) => filterSearch(e.target.value)}
                  />
              </InputGroup>,
          ]
        : [];
    dropdownOptions.push(getDropdownOptions());
    return (
        <DropdownMenu right flip={flip} className={className}>
            {dropdownOptions}
        </DropdownMenu>
    );
};

export default DropdownMenuWrapper;
