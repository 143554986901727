import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import InfoBars from '../common/InfoBars';
import { InfoBarProps } from '../common/InfoBar';
import LoadingSpinner from '../common/LoadingSpinner';
import useAccountSubmissionPollRequest from '../../hooks/useAccountSubmissionPollRequest';
import SettingsHelper from '../../helpers/SettingsHelper';
import SettingsService from '../../services/SettingsService';

function CreatingAccountPage() {
    const intl = useIntl();
    const [infoBars, setInfoBars] = useState<Array<InfoBarProps>>([]);
    const [userName, setUserName] = useState<string>('');
    const { accountSubmission, pollRequestTimedOut } = useAccountSubmissionPollRequest(encodeURIComponent(userName));

    useEffect(() => {
        const jwtToken = SettingsHelper.getJwtToken();
        if (!jwtToken) {
            window.location.replace(SettingsHelper.getLoginUrl());
        }

        SettingsService.getCurrentUser().then((userData) => {
            setUserName(userData.Username);
        });
    }, []);

    useEffect(() => {
        if (accountSubmission) {
            window.location.href = '/OnboardingWizard/Welcome';
        }
    }, [accountSubmission]);

    return (
        <div className="creating-account-page">
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} isOnboarding={false} />
            {!pollRequestTimedOut && (
                <LoadingSpinner loadingText={intl.formatMessage({ id: 'CreationWizard.CreatingAccount' })} />
            )}
            {pollRequestTimedOut && (
                <div className="diary-deploy-timeout-message">
                    <h2>
                        <FormattedMessage id="CreationWizard.DiaryDeployTimeoutHeading" />
                    </h2>
                    <FormattedMessage id="CreationWizard.DiaryDeployTimeoutMessage" />
                </div>
            )}
        </div>
    );
}

export default CreatingAccountPage;
