import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

type AddToAllServicesModalProps = {
    id: number;
    isCovers: boolean;
    applyToAllServices: boolean;
    isUpdating: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    updateService: (id: number, isCovers: boolean, applyToAll: boolean) => void;
};

const AddToAllServicesModal = ({
    id,
    isCovers,
    applyToAllServices,
    isUpdating,
    setIsModalOpen,
    updateService,
}: AddToAllServicesModalProps) => {
    const intl = useIntl();

    return (
        <div className="service-modal">
            <div className="service-modal-header">
                <p className="service-modal-title">
                    {intl.formatMessage({
                        id: 'OnboardingWizard.VenueCoversAndIntervalsApplyToAllServiceConfirmationTitle',
                    })}
                </p>
                <p>
                    {intl.formatMessage({
                        id: 'OnboardingWizard.VenueCoversAndIntervalsApplyToAllServiceConfirmationText',
                    })}
                </p>
                <Button
                    close
                    color="primary"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="service-modal-close-button"
                />
            </div>
            <div className="service-modal-body">
                <Button
                    color="primary"
                    outline={true}
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                >
                    {intl.formatMessage({ id: 'Common.Cancel' })}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        updateService(id, isCovers, applyToAllServices);
                        setIsModalOpen(false);
                    }}
                    disabled={isUpdating}
                >
                    {intl.formatMessage({ id: 'Common.Confirm' })}
                </Button>
            </div>
        </div>
    );
};

export default AddToAllServicesModal;
