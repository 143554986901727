import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import TrainingWidgetPage from './components/trainingWidget/TrainingWidgetPage';
import OnboardingWizardPage from './components/onboardingWizard/OnboardingWizardPage';
import CreateAccountPage from './components/creationWizard/CreateAccountPage';
import CreatingAccountPage from './components/creationWizard/CreatingAccountPage';
import NotFoundPage from './components/common/404NotFoundPage';
import LoadingSpinner from './components/common/LoadingSpinner';
import BaseCreationWizardPage from './components/creationWizard/BaseCreationWizardPage';

export default function App({ isLoading }: { isLoading: boolean }) {
    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <Layout>
            <Routes>
                <Route path="/" element={<BaseCreationWizardPage />} />
                <Route path="/TrainingWidget" element={<TrainingWidgetPage />} />
                <Route path="/OnboardingWizard/*" element={<OnboardingWizardPage />} />
                <Route path="/OnboardingWizard" element={<Navigate to="/OnboardingWizard/WelcomePage" replace />} />
                <Route path="/CreateAccount" element={<CreateAccountPage />} />
                <Route path="/CreatingAccount" element={<CreatingAccountPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Layout>
    );
}
