import AverageEatDuration from './AverageEatDuration';

export type VenueServiceSettings = {
    Id?: number;
    Name?: string;
    TimeFrom?: string;
    TimeUntil?: string;
    LastBookingTime?: string;
    TimeSlotInterval?: number;
    MaxBookings?: number;
    MaxCovers?: number;
    AverageEatDurations?: Array<AverageEatDuration>;
};

export type RestaurantServiceSettings = {
    Id?: number;
    Name?: string;
    Description?: string;
    TimeFrom?: Date;
    TimeUntil?: Date;
    LastBookingTime?: Date;
    TimeSlotInterval?: number;
    MaxBookings?: number;
    MaxCovers?: number;
    AverageEatDurations?: Array<AverageEatDuration>;
};
class RestaurantService {
    id: number;
    name: string;
    timeFrom: Date;
    timeUntil: Date;
    lastBookingTime: Date;
    timeSlotInterval: number;
    maxBookings: number;
    maxCovers: number;
    averageEatDurations: Array<AverageEatDuration>;

    constructor(settings: RestaurantServiceSettings) {
        this.id = settings && settings.Id ? settings.Id : 0;
        this.name = settings && settings.Name ? settings.Name : '';
        this.timeFrom = settings && settings.TimeFrom ? settings.TimeFrom : new Date('0');
        this.timeUntil = settings && settings.TimeUntil ? settings.TimeUntil : new Date('0');
        this.lastBookingTime = settings && settings.LastBookingTime ? settings.LastBookingTime : new Date('0');
        this.timeSlotInterval = settings && settings.TimeSlotInterval ? settings.TimeSlotInterval : 0;
        this.maxBookings = settings && settings.MaxBookings ? settings.MaxBookings : 0;
        this.maxCovers = settings && settings.MaxCovers ? settings.MaxCovers : 0;
        this.averageEatDurations = settings && settings.AverageEatDurations ? settings.AverageEatDurations : [];
    }
}
export default RestaurantService;

export type RestaurantServiceOption = {
    Id: number;
    Name: string;
};
