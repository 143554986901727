import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Label } from 'reactstrap';
import Table from '../../../domainObjects/Table';
import useDebounce from '../../../hooks/useDebounce';

type TableSizeInputProps = {
    table: Table;
    addOrUpdateTable: (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => void;
};

const TableSizeInput = ({ table, addOrUpdateTable }: TableSizeInputProps) => {
    const [tableSize, setTableSize] = useState<number>(table ? table.capacity : 2);

    useDebounce(
        () => {
            addOrUpdateTable({ ...table, capacity: tableSize }, false, () => {});
        },
        500,
        [tableSize]
    );

    return (
        <div className="table-size-input">
            <Button
                color="primary"
                disabled={tableSize < 2}
                className="table-size-button btn-primary"
                onClick={() => {
                    setTableSize(tableSize - 1);
                }}
            >
                <Label>&minus;</Label>
            </Button>
            <Label className="table-size-label">
                {tableSize} {tableSize === 1 && <FormattedMessage id="Common.Person" />}
                {tableSize > 1 && <FormattedMessage id="Common.People" />}
            </Label>
            <Button
                color="primary"
                className="table-size-button"
                onClick={() => {
                    setTableSize(tableSize + 1);
                }}
            >
                <Label>&#43;</Label>
            </Button>
        </div>
    );
};

export default TableSizeInput;
