import BackgroundImageSource from '../../../content/images/bg-tables.jpg';
import { useIntl } from 'react-intl';
import useScreenSize from '../../../hooks/useScreenSize';
import InfoBars from '../../common/InfoBars';
import { useEffect, useState } from 'react';
import { InfoBarProps } from '../../common/InfoBar';
import Area from '../../../domainObjects/Area';
import VenueTableItem from './TableItem';
import Table from '../../../domainObjects/Table';
import rdfOnboardingProgressFlags from '../../../enums/rdfOnboardingProgressFlags';
import useValidation from '../../../hooks/useValidation';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import { Spinner } from 'reactstrap';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import trackingEvents from '../../../enums/trackingEvents';
import TextWithIcon from '../../common/TextWithIcon';
import { ReactComponent as WarningIcon } from '../../../content/icons/Icon-Alert.svg';

export type TablesPageProps = {
    updateProgressFlag: (progressFlag: string) => void;
    venueAreas: Array<Area>;
    venueTables: Array<Table>;
    addOrUpdateTable: (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => void;
    addOrUpdateArea: (area: Area, updateOnlyState: boolean, callback: (success: boolean) => void) => void;
    deleteTable: (tableToDelete: Table, callback: (success: boolean) => void) => void;
    isUpdating: boolean;
    isLoading: boolean;
};

const TablesPage = ({
    updateProgressFlag,
    venueAreas,
    venueTables,
    addOrUpdateTable,
    addOrUpdateArea,
    deleteTable,
    isUpdating,
    isLoading,
}: TablesPageProps) => {
    const intl = useIntl();
    const [infoBars, setInfoBars] = useState<Array<InfoBarProps>>([]);
    const [areas, setAreas] = useState<Array<Area>>([]);
    const formValidation = useValidation();

    const { isMobileView, isTabletView } = useScreenSize();
    const [continueDisabled, setContinueDisabled] = useState(false);

    const updateSettings = () => {
        // Update the Max Covers value of each area
        // now that tables have been finalised.
        venueAreas.forEach((area) => {
            let maxAreaCovers = 0;
            venueTables.forEach((t) => {
                if (t.areaId === area.id) {
                    maxAreaCovers = maxAreaCovers + t.capacity;
                }
            });
            area.maxCovers = maxAreaCovers;
            addOrUpdateArea(area, false, () => {});
        });

        // Also updates added_tables flag in the same call
        updateProgressFlag(rdfOnboardingProgressFlags.wizardComplete);
    };

    const showSelectInfoBar = (
        select: 'saveSuccess' | 'saveFail' | 'deleteSuccess' | 'deleteFail' | 'alreadyExists' | 'isEmpty',
        tableNumber: string
    ) => {
        switch (select) {
            case 'saveSuccess': {
                showInfoBar('OnboardingWizard.VenueTableSavedSuccessfullyInfoMessage', tableNumber, 'success');
                break;
            }
            case 'saveFail': {
                showInfoBar('OnboardingWizard.VenueTableSaveFailedInfoMessage', tableNumber, 'danger');
                break;
            }
            case 'deleteSuccess': {
                showInfoBar('OnboardingWizard.VenueTableDeletedSuccessfullyInfoMessage', tableNumber, 'success');
                break;
            }
            case 'deleteFail': {
                showInfoBar('OnboardingWizard.VenueTableDeleteFailedInfoMessage', tableNumber, 'danger');
                break;
            }
            case 'isEmpty': {
                showInfoBar('OnboardingWizard.VenueTableNumberRequiredWarningLabel', tableNumber, 'danger');
                break;
            }
            case 'alreadyExists': {
                showInfoBar('OnboardingWizard.VenueTableNumberAlreadyExistsWarningMessage', tableNumber, 'danger');
                break;
            }
        }
    };

    const showInfoBar = (id: string, tableNumber: string, type: 'success' | 'danger') => {
        setInfoBars((prevState: InfoBarProps[]) => [
            ...prevState,
            {
                id: infoBars.length > 0 ? infoBars[infoBars.length - 1].id + 1 : 0,
                message: intl.formatMessage({ id }, { tableNumber }),
                type: type,
                onDismiss: () => {},
            },
        ]);
    };

    const validateName = (table: Table, showInfoBar: boolean = true): boolean => {
        // Name is required (cannot be empty)
        if (table.number.length <= 0) {
            if (showInfoBar) showSelectInfoBar('isEmpty', table.number);
            return false;
        }

        // Name must be unique
        const noMatchingName = venueTables.every((t: Table) => {
            return t.id === table.id || t.number !== table.number;
        });

        if (noMatchingName) {
            return true;
        } else {
            if (showInfoBar) showSelectInfoBar('alreadyExists', table.number);
            return false;
        }
    };

    useEffect(() => {
        setAreas(venueAreas);
    }, [venueAreas]);

    const areasWithoutTablesExist = () => {
        const areasWithoutTables = areas.filter((area: Area) => {
            const tableInArea = venueTables.find((table: Table) => {
                return table.areaId === area.id;
            });

            return !tableInArea;
        });
        return areasWithoutTables.length > 0;
    };

    const getFormBody = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <Spinner color="primary" />
                </div>
            );
        }
        return (
            <div className={`venue-tables-page-wrapper ${isMobileView || isTabletView ? 'mobile' : ''}`}>
                <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} isOnboarding />
                <div className={`venue-tables-table ${isMobileView || isTabletView ? 'mobile' : ''}`}>
                    {areas?.map((area, i) => {
                        return (
                            <VenueTableItem
                                key={i}
                                area={area}
                                tables={venueTables.filter((x) => x.areaId === area.id)}
                                addOrUpdateTable={addOrUpdateTable}
                                deleteTable={deleteTable}
                                formValidation={formValidation}
                                validateName={validateName}
                                isUpdating={isUpdating}
                                showSelectInfoBar={showSelectInfoBar}
                                setContinueDisabled={setContinueDisabled}
                            />
                        );
                    })}
                </div>
                {areasWithoutTablesExist() && (
                    <div className="venue-tables-empty-areas-warning">
                        <TextWithIcon
                            icon={<WarningIcon />}
                            text={intl.formatMessage({ id: 'OnboardingWizard.EmptyAreasWarning' })}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.VenueTablesPageTitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.tablesPage}
            children={getFormBody()}
            formValidation={formValidation}
            updateSettings={updateSettings}
            isUpdating={
                isUpdating ||
                // Can't continue if any table numbers are empty strings
                venueTables.filter((x) => x.number === '').length > 0 ||
                // Can't continue if any areas don't have tables
                venueAreas.filter((x) => !venueTables.map((x) => x.areaId).includes(x.id)).length > 0
            }
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfTableManagementPage}
            continueDisabled={continueDisabled}
        />
    );
};

export default TablesPage;
