import React, { Fragment, useState, useRef, useLayoutEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Label } from 'reactstrap';
import { useIntl } from 'react-intl';
import useScreenSize from '../../hooks/useScreenSize';
import ValidationMessage from './ValidationMessage';

type ImageEditorProps = {
    file: File | string;
    setImageFile: (blob: any, previewUrl: string) => void;
    resetImage: () => void;
    resetImageText: string;
    disableControls: boolean;
    formValidation: any;
    scroll?: boolean;
};

const ImageEditor = ({
    file,
    setImageFile,
    resetImage,
    resetImageText,
    disableControls,
    formValidation,
    scroll,
}: ImageEditorProps) => {
    const { isMobileView, isTabletView } = useScreenSize();
    const [scale, setScale] = useState(1);
    const rdWebBlue = '#00b7f0';
    const midGrey3 = '#d8d8d8';
    const [rangeStyle, setRangeStyle] = useState({
        background: `linear-gradient(to right, ${rdWebBlue} 0%, ${rdWebBlue} 0%, ${midGrey3} 0%, ${midGrey3} 100%)`,
    });
    const intl = useIntl();
    const scrollTo = useRef<HTMLDivElement>(null);

    const adjustScale = (newScale: React.ChangeEvent<HTMLInputElement> | number) => {
        let scale = 0;
        if (typeof newScale === 'number') {
            scale = newScale;
        } else {
            scale = parseFloat(newScale.target.value);
        }

        const value = ((scale - 1) / (2 - 1)) * 100;
        setRangeStyle({
            background: `linear-gradient(to right, ${rdWebBlue} 0%, ${rdWebBlue} ${value}%, ${midGrey3} ${value}%, ${midGrey3} 100%)`,
        });
        setScale(scale);
    };

    let editor: any = null;
    const setEditorRef = (editorRef: any) => (editor = editorRef);

    useLayoutEffect(() => {
        const scrollToBottom = () => {
            if (scroll && Object.keys(formValidation.errors).length > 0) {
                scrollTo.current?.scrollIntoView({ behavior: 'smooth' });
            }
        };
        scrollToBottom();
    });

    return (
        <Fragment>
            <AvatarEditor
                className={'image-editor-wrapper'}
                ref={setEditorRef}
                image={file}
                width={isMobileView ? 310 : 280}
                height={isMobileView ? 310 : 280}
                border={isMobileView ? [32, 32] : [160, 29]}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
                rotate={0}
            />
            <div
                className={'image-editor-controls-wrapper ' + (isMobileView ? 'mobile' : isTabletView ? 'tablet' : '')}
            >
                <Button
                    color="primary"
                    disabled={scale <= 1}
                    className="image-editor-button btn-primary"
                    onClick={() => {
                        if (scale > 1) {
                            adjustScale(scale - 0.1);
                        }
                    }}
                >
                    <Label>&minus;</Label>
                </Button>
                <input
                    type="range"
                    className={'image-editor-range ' + (isMobileView ? 'mobile' : isTabletView ? 'tablet' : '')}
                    min={1}
                    max={2}
                    step={0.1}
                    value={scale}
                    onChange={adjustScale}
                    style={rangeStyle}
                />
                <Button
                    color="primary"
                    disabled={scale >= 2}
                    className="image-editor-button right"
                    onClick={() => {
                        if (scale < 2) {
                            adjustScale(scale + 0.1);
                        }
                    }}
                >
                    <Label>&#43;</Label>
                </Button>
            </div>
            <div
                className={
                    'image-editor-save-button-wrapper ' + (isMobileView ? 'mobile' : isTabletView ? 'tablet' : '')
                }
                ref={scrollTo}
            >
                <Button
                    color="outline-primary"
                    onClick={() => {
                        resetImage();
                    }}
                    disabled={disableControls}
                >
                    {resetImageText}
                </Button>
                <Button
                    color="primary"
                    {...formValidation.register('editRequired', { required: true })}
                    onClick={() => {
                        editor.getImageScaledToCanvas().toBlob((blob: any) => {
                            setImageFile(blob, editor.getImageScaledToCanvas().toDataURL());
                        });
                    }}
                    disabled={disableControls}
                >
                    {intl.formatMessage({
                        id: 'Common.Save',
                    })}
                </Button>
            </div>
            {formValidation.errors.editRequired && (
                <ValidationMessage
                    isBlock={false}
                    message={intl.formatMessage({ id: 'OnboardingWizard.VenueEditRequiredError' })}
                />
            )}
        </Fragment>
    );
};

export default ImageEditor;
