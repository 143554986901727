import ApiHelper from '../helpers/ApiHelper';

class placesService {
    static getSearchResults(searchText: string) {
        return ApiHelper.get(`/api/places/search?searchText=${searchText}`).then(
            (response: { data: any }) => response.data
        );
    }

    static getPlaceDetails(placeId: string) {
        return ApiHelper.get(`/api/places/details?placeId=${placeId}`).then((response: { data: any }) => response.data);
    }

    static getTimeZone(lat: string, lng: string) {
        return ApiHelper.get(`/api/places/timezone?lat=${lat}&lng=${lng}`).then(
            (response: { data: any }) => response.data
        );
    }
}

export default placesService;
