import React, { Fragment, useEffect, useState } from 'react';
import AverageEatDuration from '../../../domainObjects/AverageEatDuration';
import { ReactComponent as TwoCovers } from '../../../content/icons/2 covers.svg';
import { ReactComponent as ThreeToFour } from '../../../content/icons/3-4 covers.svg';
import { ReactComponent as FiveToEight } from '../../../content/icons/5-8 covers.svg';
import { ReactComponent as NineToTwelve } from '../../../content/icons/9-12 covers.svg';
import { ReactComponent as ThirteenPlus } from '../../../content/icons/13 covers.svg';
import { Input, Label } from 'reactstrap';
import { useIntl } from 'react-intl';
import ValidationMessage from '../../common/ValidationMessage';

type TableCoversProps = {
    averageEatDurations: Array<AverageEatDuration> | null;
    setAverageEatDuration: (averageEatDuration: AverageEatDuration) => void;
    formValidation: any;
    setFormValidationValue: (name: string, value: any) => void;
};

const getCoverIcon = (coversFrom: number, coversTo: number) => {
    if (coversTo <= 2) {
        return <TwoCovers />;
    }

    if (coversFrom >= 3 && coversTo <= 4) {
        return <ThreeToFour />;
    }

    if (coversFrom >= 5 && coversTo <= 8) {
        return <FiveToEight />;
    }

    if (coversFrom >= 9 && coversTo <= 12) {
        return <NineToTwelve />;
    }

    if (coversFrom >= 13) {
        return <ThirteenPlus />;
    }
};

const TableCovers = ({
    averageEatDurations,
    setAverageEatDuration,
    formValidation,
    setFormValidationValue,
}: TableCoversProps) => {
    const intl = useIntl();
    const [initialised, setInitialised] = useState(false);

    const getTableCoverValidationMessage = (id: number) => {
        if (formValidation.errorCount > 0) {
            let error = '';
            if (formValidation.errors[`averageEatDuration${id}`]) {
                error = `averageEatDuration${id}`;
            }

            if (error) {
                return <ValidationMessage isBlock={false} message={formValidation.errors[error].message} />;
            }
        }
        return null;
    };

    useEffect(() => {
        if (!initialised && averageEatDurations && averageEatDurations.length > 0) {
            averageEatDurations?.forEach((averageEatDuration) => {
                formValidation.register(
                    { name: `averageEatDuration${averageEatDuration.id}` },
                    {
                        validate: (value: number) => {
                            return value > 0
                                ? true
                                : intl.formatMessage({
                                      id: 'OnboardingWizard.VenueCoversAndIntervalsAverageTablePerCoverError',
                                  });
                        },
                    }
                );
                setFormValidationValue(`averageEatDuration${averageEatDuration.id}`, averageEatDuration.averageTime);
            });
            setInitialised(true);
        }
    }, [averageEatDurations, formValidation, initialised, setFormValidationValue, intl]);

    return (
        <div className="table-cover-container d-flex flex-column">
            <Label>{intl.formatMessage({ id: 'OnboardingWizard.VenueCoversAndIntervalsPageTableCoversLabel' })}</Label>
            {averageEatDurations?.map((averageEatDuration, i) => (
                <Fragment key={i}>
                    <div className="table-cover d-flex flex-row justify-content-between">
                        <Label className="table-cover-label">{averageEatDuration.label}</Label>
                        <div className="table-cover-icon">
                            {getCoverIcon(averageEatDuration.coversFrom, averageEatDuration.coversTo)}
                        </div>
                        <div className="table-cover-input-container d-flex align-items-center">
                            <Input
                                type="number"
                                id={averageEatDuration.id.toString()}
                                onChange={(event) => {
                                    averageEatDuration.averageTime = parseInt(event.target.value);
                                    setAverageEatDuration(averageEatDuration);
                                    setFormValidationValue(
                                        `averageEatDuration${averageEatDuration.id}`,
                                        parseInt(event.target.value)
                                    );
                                }}
                                defaultValue={averageEatDuration.averageTime}
                            />
                            <Label>{intl.formatMessage({ id: 'Common.Mins' })}</Label>
                        </div>
                    </div>
                    {getTableCoverValidationMessage(averageEatDuration.id)}
                </Fragment>
            ))}
        </div>
    );
};

export default TableCovers;
