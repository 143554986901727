import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

type SaveCancelButtonsProps = {
    onSave: () => void;
    onCancel: () => void;
    saveDisabled: boolean;
};

const SaveCancelButtons = ({ onSave, onCancel, saveDisabled }: SaveCancelButtonsProps) => {
    const intl = useIntl();
    return (
        <div className="save-cancel-button-wrapper">
            <Button color="primary" outline={true} onClick={onCancel}>
                {intl.formatMessage({ id: 'Common.Cancel' })}
            </Button>
            <Button color="primary" onClick={onSave} disabled={saveDisabled}>
                {intl.formatMessage({ id: 'Common.Save' })}
            </Button>
        </div>
    );
};

export default SaveCancelButtons;
