import { useIntl } from 'react-intl';
import AddOn from '../../viewModels/AddOn';
import { Button } from 'reactstrap';
import useScreenSize from '../../hooks/useScreenSize';

export type AddOnModalActionsProps = {
    isSelected: Function;
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    setIsAddOnModalOpen: Function;
    addOn: AddOn;
};

const AddOnModalActions = ({
    isSelected,
    selectAddOnItem,
    unselectAddOnItem,
    setIsAddOnModalOpen,
    addOn,
}: AddOnModalActionsProps) => {
    const intl = useIntl();
    const { isTabletView } = useScreenSize();

    return (
        <div className={isTabletView ? 'addon-modal-actions mob' : 'addon-modal-actions'}>
            <Button
                onClick={() => {
                    isSelected(addOn?.type) ? unselectAddOnItem(addOn?.type) : selectAddOnItem(addOn?.type);
                    setIsAddOnModalOpen(false);
                }}
            >
                {isSelected(addOn?.type)
                    ? intl.formatMessage({ id: 'Common.Remove' })
                    : intl.formatMessage({ id: 'Common.Add' })}{' '}
                {addOn?.title}
            </Button>
        </div>
    );
};

export default AddOnModalActions;
