enum DevicePreviewSelection {
    addedContactDetails = 'AddedContactDetails',
    addedCuisines = 'AddedCuisines',
    addedAddress = 'AddedAddress',
    addedVenueDetails = 'AddedVenueDetails',
    addedLogoImage = 'AddedLogoImage',
    addedMastheadImage = 'AddedMastheadImage',
    selectedDCListingStatus = 'SelectedDCListingStatus',
    addedServices = 'AddedServices',
    addedServiceCoversAndIntervals = 'AddedServiceCoversAndIntervals',
    addedAreas = 'AddedAreas',
    wizardComplete = 'WizardComplete',
    addedBranding = 'AddedBranding',
}

export default DevicePreviewSelection;
