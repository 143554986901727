import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Label } from 'reactstrap';
import IntervalOption from '../../../domainObjects/IntervalOption';
import useScreenSize from '../../../hooks/useScreenSize';

type IntervalOptionsProps = {
    intervalOptions: Array<IntervalOption>;
    selectedIntervalOption: number;
    setSelectedIntervalOption: (intervalOption: IntervalOption) => void;
    setFormValidationValue: (name: string, value: any) => void;
    getIntervalOptionsValidationMessage: () => JSX.Element | null;
};

const IntervalOptions = ({
    intervalOptions,
    selectedIntervalOption,
    setSelectedIntervalOption,
    setFormValidationValue,
    getIntervalOptionsValidationMessage,
}: IntervalOptionsProps) => {
    const intl = useIntl();
    const { isMobileView, isTabletView } = useScreenSize();
    return (
        <div className={`interval-options-container ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <Label>
                {intl.formatMessage({ id: 'OnboardingWizard.VenueCoversAndIntervalsPageIntervalOptionsLabel' })}
            </Label>
            <div className="interval-options-buttons-wrapper d-flex">
                {intervalOptions?.map((intervalOption, i) => {
                    return (
                        <Button
                            key={i}
                            className={`interval-options-button d-flex justify-content-center align-items-center ${
                                selectedIntervalOption === intervalOption.timeInMinutes ? 'selected' : ''
                            }`}
                            onClick={() => {
                                setSelectedIntervalOption(intervalOption);
                                setFormValidationValue('intervalOption', intervalOption.timeInMinutes);
                            }}
                        >
                            {intervalOption.label}
                        </Button>
                    );
                })}
            </div>
            {getIntervalOptionsValidationMessage()}
        </div>
    );
};

export default IntervalOptions;
