import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddOn from '../../viewModels/AddOn';
import useScreenSize from '../../hooks/useScreenSize';
import AddOnItem from './AddOnItem';
import PlanBillingType from '../../enums/PlanBillingType';

export type AddOnListProps = {
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    itemIsSelected: Function;
    showMoreDetail: Function;
    addOns: Array<AddOn>;
    currencySymbol: string;
    planBillingType?: PlanBillingType;
};

const AddOnList = ({
    selectAddOnItem,
    unselectAddOnItem,
    itemIsSelected,
    showMoreDetail,
    addOns,
    currencySymbol,
    planBillingType,
}: AddOnListProps) => {
    const { isMobileView } = useScreenSize();

    return (
        <div className={isMobileView ? 'add-on-list mob' : 'add-on-list'}>
            <h2 className="title">
                <FormattedMessage id="CreationWizard.AddOnListTitle"></FormattedMessage>
            </h2>
            <div className={isMobileView ? 'add-on-list-wrapper mobile' : 'add-on-list-wrapper'}>
                {addOns.map((addOn: AddOn) => {
                    return (
                        <AddOnItem
                            key={addOn.type}
                            addOn={addOn}
                            isSelected={itemIsSelected}
                            showMoreDetail={showMoreDetail}
                            selectAddOnItem={selectAddOnItem}
                            unselectAddOnItem={unselectAddOnItem}
                            currencySymbol={currencySymbol}
                            planBillingType={planBillingType}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default AddOnList;
