import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import AnimationWrapper from '../../common/AnimationWrapper';
import { ReactComponent as WatchIcon } from '../../../content/icons/duration.svg';
import { ReactComponent as DownIcon } from '../../../content/icons/down.svg';
import { useNavigate } from 'react-router-dom';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import trackingEvents from '../../../enums/trackingEvents';
import useScreenSize from '../../../hooks/useScreenSize';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import { useState } from 'react';
// import BackgroundAnimationSource from '../../content/animation/Welcome_bg.json';

export type WelcomePageProps = {
    venueName: string;
    isLoading: boolean;
};

const WelcomePage = ({ venueName, isLoading }: WelcomePageProps) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { isMobileView } = useScreenSize();
    let venueNameString = venueName;
    const [firedPageEvent, setFiredPageEvent] = useState(false);

    const navigateToChecklist = () => {
        navigate(`/OnboardingWizard/${rdfOnboardingSteps.checklistPage}`);
    };

    if (!isLoading && !firedPageEvent) {
        AnalyticsHelper.trackPageWithProperties(
            trackingEvents.rdfWelcomeSetupPage,
            AnalyticsHelper.getCommonTrackingProperties()
        );
        setFiredPageEvent(true);
    }

    const trackGetStartedAndNavigateToPage = () => {
        AnalyticsHelper.trackClickWithPageNameAndProperties(
            trackingEvents.selfOnboardingStarted,
            'WelcomePage',
            AnalyticsHelper.getCommonTrackingProperties()
        );
        navigate(`/OnboardingWizard/${rdfOnboardingSteps.getStartedPage}`);
    };

    return (
        <div className="welcome-page row">
            <AnimationWrapper dataSource={'test'} />
            <div className="d-flex col-md-12">
                <div className="welcome-page-wrapper" style={{ padding: isMobileView ? '0px 50px' : '' }}>
                    <h1>
                        {intl.formatMessage(
                            { id: 'OnboardingWizard.WelcomePageHeadingTitle' },
                            { venueName: venueNameString }
                        )}
                    </h1>
                    <FormattedMessage tagName="div" id="OnboardingWizard.WelcomePageHeadingSubTitleOne" />
                    <FormattedMessage tagName="div" id="OnboardingWizard.WelcomePageHeadingSubTitleTwo" />
                    <div className="welcome-page-estimate-wrapper">
                        <WatchIcon />
                        <FormattedMessage tagName="div" id="OnboardingWizard.EstimatedTimeToCompleteLabel" />
                    </div>
                    <div className={`welcome-page-button-wrapper ${isMobileView ? 'mobile' : ''}`}>
                        <Button
                            onClick={navigateToChecklist}
                            className={isMobileView ? 'mb-3' : 'mr-3'}
                            outline
                            color="primary"
                        >
                            {intl.formatMessage({ id: 'OnboardingWizard.ViewChecklistButtonLabel' })}
                            <DownIcon />
                        </Button>
                        <Button onClick={trackGetStartedAndNavigateToPage} color="primary">
                            {intl.formatMessage({ id: 'OnboardingWizard.StartSettingUpButtonLabel' })}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;
