import { Container } from 'reactstrap';

export type LayoutProps = {
    children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
    return <Container fluid>{children}</Container>;
};

export default Layout;
