import React, { useState, useEffect } from 'react';
import { Button, Label, UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/delete.svg';
import RestaurantService from '../../../domainObjects/RestaurantService';
import Dropdown from '../../common/ResDiaryDropdown';
import { useIntl } from 'react-intl';
import OnboardingModal from '../../common/OnboardingModal';
import DeleteServiceModal from './DeleteServiceModal';
import useScreenSize from '../../../hooks/useScreenSize';
import SaveCancelButtons from '../../common/SaveCancelButtons';

type RestaurantServiceItemProps = {
    service: RestaurantService;
    deleteService: (service: RestaurantService) => void;
    updateService: (id: number, openingTime: Date, closingTime: Date, lastBookingTime: Date) => void;
    openingTimeName: (service: RestaurantService) => string;
    closingTimeName: (service: RestaurantService) => string;
    lastBookingTimeName: (service: RestaurantService) => string;
    getTime: (time: Date) => string;
    getServiceStartTimeOptions: (serviceId?: number) => HTMLOptionElement[];
    getServiceEndTimeOptions: (newStartTime: Date, newEndTime?: Date, serviceId?: number) => HTMLOptionElement[];
    getServiceLastBookingTimeOptions: (newStartTime: Date, newEndTime: Date) => HTMLOptionElement[];
    setFormValidationValue: (name: string, time: Date) => void;
    getServiceTimeValidationMessage: (service: RestaurantService) => JSX.Element | null;
    getLastBookingTimeValidationMessage: (service: RestaurantService) => JSX.Element | null;
    formValidation: any;
    isUpdating: boolean;
    setContinueDisabled: (val: boolean) => void;
};

const RestaurantServiceItem = ({
    service,
    deleteService,
    updateService,
    openingTimeName,
    closingTimeName,
    lastBookingTimeName,
    getTime,
    getServiceStartTimeOptions,
    getServiceEndTimeOptions,
    getServiceLastBookingTimeOptions,
    setFormValidationValue,
    getServiceTimeValidationMessage,
    getLastBookingTimeValidationMessage,
    formValidation,
    isUpdating,
    setContinueDisabled,
}: RestaurantServiceItemProps) => {
    const intl = useIntl();

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openingTime, setOpeningTime] = useState(service.timeFrom ? service.timeFrom : new Date());
    const [closingTime, setClosingTime] = useState(service.timeUntil ? service.timeUntil : new Date());
    const [lastBookingTime, setLastBookingTime] = useState(
        service.lastBookingTime ? service.lastBookingTime : new Date()
    );
    const { isMobileView, isTabletView } = useScreenSize();

    const editService = () => {
        setOpeningTime(service.timeFrom ? service.timeFrom : new Date());
        setClosingTime(service.timeUntil ? service.timeUntil : new Date());
        setLastBookingTime(service.lastBookingTime ? service.lastBookingTime : new Date());
        setIsEditing(true);
    };

    useEffect(() => {
        setContinueDisabled(isEditing);
    }, [isEditing, setContinueDisabled]);

    useEffect(() => {
        if (formValidation.errorCount > 0) {
            if (
                formValidation.errors['openingTime-' + service.id] ||
                formValidation.errors['closingTime-' + service.id] ||
                formValidation.errors['lastBookingTime-' + service.id]
            ) {
                setIsEditing(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidation.errorCount]);

    const cancelEditService = () => {
        setOpeningTime(service.timeFrom);
        setClosingTime(service.timeUntil);
        setLastBookingTime(service.lastBookingTime);

        setFormValidationValue(openingTimeName(service), service.timeFrom);
        setFormValidationValue(closingTimeName(service), service.timeUntil);
        setFormValidationValue(lastBookingTimeName(service), service.lastBookingTime);

        setIsEditing(false);
    };

    const serviceView = (
        <div className={`restaurant-service-item ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <div className="restaurant-service-item-cell">{service.name.toString()}</div>
            <div className="restaurant-service-item-cell">
                {getTime(service.timeFrom)} - {getTime(service.timeUntil)}
            </div>
            <div className="restaurant-service-item-cell">
                {intl.formatMessage({
                    id: 'OnboardingWizard.VenueServicesPageLastBookingTime',
                })}
                : {getTime(service.lastBookingTime)}
            </div>
            <div className="restaurant-service-item-cell restaurant-service-item-button-wrapper">
                <Button id={`EditService-${service.id}`} onClick={editService}>
                    <EditIcon />
                </Button>
                <UncontrolledTooltip flip target={`EditService-${service.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Edit',
                    })}
                </UncontrolledTooltip>
                <Button
                    id={`DeleteService-${service.id}`}
                    onClick={() => {
                        setIsDeleting(true);
                    }}
                >
                    <DeleteIcon />
                </Button>
                <UncontrolledTooltip flip target={`DeleteService-${service.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Delete',
                    })}
                </UncontrolledTooltip>
            </div>
            <OnboardingModal
                isModalOpen={isDeleting}
                setIsModalOpen={setIsDeleting}
                body={
                    <DeleteServiceModal
                        service={service}
                        setIsDeleting={setIsDeleting}
                        deleteService={deleteService}
                        isUpdating={isUpdating}
                    />
                }
                nonDismissible
                hideLayout
            />
        </div>
    );

    const serviceEdit = (
        <div className={`restaurant-service-item edit ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <Label className="service-name-label">{service.name.toString()}</Label>
            <div className="venue-service-time-input-wrapper">
                <div>
                    <Label>
                        {intl.formatMessage({
                            id: 'Common.Hours',
                        })}
                    </Label>
                    <div className="venue-service-time-range-wrapper">
                        <Dropdown
                            options={getServiceStartTimeOptions(service.id)}
                            defaultValue={getTime(openingTime)}
                            onValueChange={(e: string) => {
                                setOpeningTime(new Date(e));
                                setFormValidationValue(openingTimeName(service), new Date(e));
                                setFormValidationValue(closingTimeName(service), closingTime);
                                setFormValidationValue(lastBookingTimeName(service), lastBookingTime);
                            }}
                            errors={formValidation.errors}
                            name={openingTimeName(service)}
                            selectedValue={openingTime.toISOString()}
                        />
                        <Label>
                            {intl.formatMessage({
                                id: 'Common.To',
                            })}
                        </Label>
                        <Dropdown
                            options={getServiceEndTimeOptions(openingTime, closingTime, service.id)}
                            defaultValue={getTime(closingTime)}
                            onValueChange={(e: string) => {
                                setClosingTime(new Date(e));
                                setFormValidationValue(closingTimeName(service), new Date(e));
                                setFormValidationValue(openingTimeName(service), openingTime);
                                setFormValidationValue(lastBookingTimeName(service), lastBookingTime);
                            }}
                            errors={formValidation.errors}
                            name={closingTimeName(service)}
                            selectedValue={closingTime.toISOString()}
                        />
                    </div>
                    {getServiceTimeValidationMessage(service)}
                </div>
                <div>
                    <Label>
                        {intl.formatMessage({
                            id: 'OnboardingWizard.VenueServicesPageLastBookingTime',
                        })}
                    </Label>
                    <Dropdown
                        options={getServiceLastBookingTimeOptions(openingTime, closingTime)}
                        defaultValue={getTime(lastBookingTime)}
                        onValueChange={(e: string) => {
                            setLastBookingTime(new Date(e));
                            setFormValidationValue(lastBookingTimeName(service), new Date(e));
                        }}
                        errors={formValidation.errors}
                        name={lastBookingTimeName(service)}
                        selectedValue={lastBookingTime.toISOString()}
                    />
                    {getLastBookingTimeValidationMessage(service)}
                </div>
            </div>
            <SaveCancelButtons
                onCancel={() => cancelEditService()}
                onSave={() => {
                    updateService(service.id, openingTime, closingTime, lastBookingTime);
                    setIsEditing(false);
                }}
                saveDisabled={formValidation.errorCount > 0 || isUpdating}
            />
        </div>
    );

    return isEditing ? serviceEdit : serviceView;
};

export default RestaurantServiceItem;
