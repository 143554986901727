export type VenueImageSettings = {
    Id: number;
    ImageUrl: string;
    IsEnabled: boolean;
    IsMainImage: boolean;
    IsMasthead: boolean;
    Name: string;
    UploadedDate: string;
};

export type Image = {
    id: number;
    preview: string;
    name: string;
    size: number;
    type: string;
    file?: File;
};

class VenueImage {
    id: number;
    imageUrl: string;
    isEnabled: boolean;
    isMainImage: boolean;
    isMasthead: boolean;
    name: string;
    uploadedDate: string;

    constructor(image: VenueImageSettings | null) {
        this.id = image && image.Id ? image.Id : 0;
        this.imageUrl = image && image.ImageUrl ? image.ImageUrl : '';
        this.isEnabled = image && image.IsEnabled ? image.IsEnabled : false;
        this.isMainImage = image && image.IsMainImage ? image.IsMainImage : false;
        this.isMasthead = image && image.IsMasthead ? image.IsMasthead : false;
        this.name = image && image.Name ? image.Name : '';
        this.uploadedDate = image && image.UploadedDate ? image.UploadedDate : '0000-00-00 00:00:00';
    }
}

export default VenueImage;
