import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export type AnimationWrapperProps = {
    dataSource: string;
};

// TODO - Fix animation source passing as a prop / multiple render without the condition check
const AnimationWrapper = ({ dataSource }: AnimationWrapperProps) => {
    const anime: any = useRef(null);
    useEffect(() => {
        if (anime.current.children.length === 0) {
            lottie.loadAnimation({
                container: anime.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../content/animation/welcome_bg.json'),
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            });
        }
    });
    return <div style={{ height: '100%', width: '100%', position: 'absolute' }} ref={anime}></div>;
};

export default AnimationWrapper;
