import { FormattedMessage, useIntl } from 'react-intl';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-checklist.jpg';
import OriginalImageSource from '../../../content/images/checklist/originalImage.png';
import MobileImagePreviewSource from '../../../content/images/checklist/mobilePreview.png';
import DesktopImagePreviewSource from '../../../content/images/checklist/desktopPreview.png';
import ImageFocusSource from '../../../content/images/checklist/imageFocus.png';
import Accordion from '../../common/Accordion';
import { Fragment } from 'react';
import useScreenSize from '../../../hooks/useScreenSize';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import trackingEvents from '../../../enums/trackingEvents';

export type ChecklistPageProps = {
    isLoading: boolean;
};

const ChecklistPage = ({ isLoading }: ChecklistPageProps) => {
    const intl = useIntl();
    const { isMobileView } = useScreenSize();

    const getFormBody = () => {
        const venueEmailAndPhoneNumberContent = (
            <Fragment>
                <FormattedMessage tagName="h6" id="Common.EmailAddress" />
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueEmailAndPhoneNumberContentText1" />
                <FormattedMessage tagName="h6" id="Common.PhoneNumber" />
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueEmailAndPhoneNumberContentText2" />
            </Fragment>
        );
        const venueAddressContent = <FormattedMessage tagName="p" id="OnboardingWizard.VenueAddressContentText1" />;
        const venueDescriptionAndPriceRangeContent = (
            <Fragment>
                <FormattedMessage tagName="h6" id="Common.VenueDescription" />
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueDescriptionContentText1" />
                <ul>
                    <FormattedMessage tagName="li" id="OnboardingWizard.VenueDescriptionContentListItem1" />
                    <FormattedMessage tagName="li" id="OnboardingWizard.VenueDescriptionContentListItem2" />
                </ul>
                <FormattedMessage tagName="h6" id="Common.PriceRange" />
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueDescriptionContentText1" />
            </Fragment>
        );
        const venueLogoContent = (
            <Fragment>
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueLogoContentText1" />
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueLogoContentText2" />
                <FormattedMessage tagName="p" id="OnboardingWizard.VenueLogoContentText3" />
            </Fragment>
        );

        const venueMainImageContent = (
            <div>
                <FormattedMessage tagName="div" id="OnboardingWizard.VenueMainImageContentLabel" />
                <ul>
                    <FormattedMessage
                        tagName="li"
                        id="OnboardingWizard.VenueImageDimensionRequirements"
                        values={{ width: 1920, height: 1080 }}
                    />
                    <FormattedMessage tagName="li" id="OnboardingWizard.VenueMainImageContentListItem2" />
                    <FormattedMessage tagName="li" id="OnboardingWizard.VenueMainImageContentListItem3" />
                    <FormattedMessage tagName="li" id="OnboardingWizard.VenueMainImageContentListItem4" />
                </ul>
                <div className="checklist-page-venue-main-image-wrapper">
                    <div>
                        <FormattedMessage tagName="div" id="OnboardingWizard.OriginalImageLabel" />
                        <img className="w-75" src={OriginalImageSource} alt="original" />
                        <FormattedMessage tagName="p" id="OnboardingWizard.VenueMainImageImageInfoText" />
                    </div>
                    <div>
                        <FormattedMessage tagName="div" id="OnboardingWizard.PreviewMobileDesktopLabel" />
                        <div className="d-flex align-items-start pb-5">
                            <img className="w-25" src={MobileImagePreviewSource} alt="original" />
                            <img className="pl-2 w-75" src={DesktopImagePreviewSource} alt="original" />
                        </div>
                        {<img className="w-100" src={ImageFocusSource} alt="original" />}
                    </div>
                </div>
            </div>
        );

        const checklistItems: { title: string; content: JSX.Element }[] = [
            {
                title: intl.formatMessage({ id: 'OnboardingWizard.EmailAndPhoneNumberLabel' }),
                content: venueEmailAndPhoneNumberContent,
            },
            { title: intl.formatMessage({ id: 'OnboardingWizard.AddressLabel' }), content: venueAddressContent },
            {
                title: intl.formatMessage({ id: 'OnboardingWizard.VenueDescriptionAndPriceRangeLabel' }),
                content: venueDescriptionAndPriceRangeContent,
            },
            { title: intl.formatMessage({ id: 'OnboardingWizard.VenueLogoLabel' }), content: venueLogoContent },
            {
                title: intl.formatMessage({ id: 'OnboardingWizard.VenueMainImageLabel' }),
                content: venueMainImageContent,
            },
        ];

        return (
            <Accordion
                style={{ padding: isMobileView ? '0px 20px' : '', alignSelf: 'center', justifySelf: 'center' }}
                accordionItems={checklistItems}
            />
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.ChecklistPageInfoPanelTitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.checklistPage}
            children={getFormBody()}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfAcctCreationChecklistPage}
        />
    );
};

export default ChecklistPage;
