import React from 'react';
import { Navbar, NavbarBrand, Nav } from 'reactstrap';
import { ReactComponent as ResDiaryLogo } from '../../content/icons/RDBlack.svg';
const CreationWizardHeader = () => {
    return (
        <Navbar expand="md" className="fixed-top">
            <Nav className="mr-auto align-center" navbar>
                <div className="navbar-brand-container">
                    <NavbarBrand href={'https://sales.resdiary.com/'}>
                        <ResDiaryLogo />
                    </NavbarBrand>
                </div>
            </Nav>
        </Navbar>
    );
};

export default CreationWizardHeader;
