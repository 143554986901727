import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Label } from 'reactstrap';

export type SkeletonLabelProps = {
    id?: string;
    label?: string;
    for?: string;
    className?: string;
    isLoading?: boolean;
    children?: React.ReactNode;
    width?: number | string;
    skeletonWidth?: number | string;
};

const SkeletonLabel = ({
    id,
    label,
    for: forProp,
    isLoading,
    className,
    children,
    width,
    skeletonWidth,
}: SkeletonLabelProps) => {
    return (
        <Label
            id={id}
            style={width ? { width: width } : { width: '100%' }}
            label={label}
            for={forProp}
            className={className}
        >
            {isLoading ? <Skeleton width={skeletonWidth} /> : children}
        </Label>
    );
};

export default SkeletonLabel;
