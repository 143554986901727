import React, { Fragment, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { Image as ImageFile } from '../../domainObjects/VenueImage';
import { ReactComponent as ImageUploadIcon } from '../../content/icons/image-upload.svg';
import { Label } from 'reactstrap';
import useScreenSize from '../../hooks/useScreenSize';
import { useIntl } from 'react-intl';
import ValidationMessage from './ValidationMessage';

export type ImageDropzoneProps = {
    setImage: (image: ImageFile) => void;
    uploadProgress: number;
    isUploading: boolean;
    setIsUploading: (isUploading: boolean) => void;
    uploadError: string;
    setUploadError: (error: string) => void;
    formValidation: any;
    isMainImage: boolean;
    minWidth: number;
    minHeight: number;
};

const ImageDropzone = ({
    setImage,
    uploadProgress,
    isUploading,
    setIsUploading,
    uploadError,
    setUploadError,
    formValidation,
    isMainImage,
    minWidth,
    minHeight,
}: ImageDropzoneProps) => {
    const { isMobileView, isTabletView } = useScreenSize();
    const intl = useIntl();

    const isMobileTabletView = () => {
        if (isMobileView) return 'mobile';

        if (isTabletView) return 'tablet';

        return '';
    };

    const onDrop = useCallback(
        (acceptedFiles: any, rejectedFiles: any) => {
            if (rejectedFiles.length > 0) {
                switch (rejectedFiles[0].errors[0].code) {
                    case 'file-too-large':
                        setUploadError(intl.formatMessage({ id: 'OnboardingWizard.ImageFileSizeError' }));
                        break;
                    case 'file-invalid-type':
                        setUploadError(intl.formatMessage({ id: 'OnboardingWizard.ImageFileTypeError' }));
                        break;
                }
                return;
            }
            if (acceptedFiles.length > 1) {
                setUploadError(intl.formatMessage({ id: 'OnboardingWizard.ImageFileQuantityError' }));
                return;
            }

            acceptedFiles.map((file: any) => {
                const reader = new FileReader();
                const objectURL = URL.createObjectURL(file);
                reader.onload = () => {
                    const img = new Image();
                    file.arrayBuffer().then((buffer: any) => {
                        img.src = objectURL;
                        img.onload = () => {
                            if (img.width < minWidth && img.height < minHeight) {
                                setUploadError(
                                    intl.formatMessage(
                                        { id: 'OnboardingWizard.ImageFileDimensionsError' },
                                        { width: minWidth, height: minHeight }
                                    )
                                );
                                return;
                            }
                            setImage(file);
                            setIsUploading(true);
                        };
                    });
                };
                reader.readAsArrayBuffer(file);
                return true;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setImage]
    );
    const dropzone = (
        <Dropzone onDrop={onDrop} accept=".jpg, .png" maxSize={5000000}>
            {({ getRootProps, getInputProps }) => (
                <section className={`${isMobileTabletView()}  ${isMainImage ? 'main-image' : ''}`}>
                    <div className="image-dropzone" {...getRootProps()}>
                        <input
                            {...getInputProps()}
                            name={'dropzone-input'}
                            {...formValidation.register('imageRequired', { required: true })}
                        ></input>
                        <div
                            className={`dropzone-labels label-container ${isMobileTabletView()} ${
                                isMainImage ? 'main-image' : ''
                            }`}
                        >
                            <ImageUploadIcon className="upload-logo" />
                            <div className="label-container upload-image-text">
                                {isMainImage && !isMobileView && (
                                    <Label>
                                        {intl.formatMessage({
                                            id: 'OnboardingWizard.DesktopUploadVenueMainImageLabel',
                                        })}
                                    </Label>
                                )}

                                {!isMainImage && !isMobileView && (
                                    <Label>
                                        {intl.formatMessage({
                                            id: 'OnboardingWizard.DesktopUploadImageLabel',
                                        })}
                                    </Label>
                                )}

                                <Label className="upload-from-your-device-label">
                                    {intl.formatMessage({
                                        id: 'OnboardingWizard.UploadFromYourDeviceLabel',
                                    })}
                                </Label>
                            </div>
                            <div className="label-container">
                                {isMainImage && (
                                    <Fragment>
                                        <Label>
                                            {intl.formatMessage(
                                                {
                                                    id: 'OnboardingWizard.VenueImageDimensionRequirements',
                                                },
                                                { width: minWidth, height: minHeight }
                                            )}
                                        </Label>
                                        <Label>
                                            {intl.formatMessage({
                                                id: 'OnboardingWizard.VenueMainImageContentListItem2',
                                            })}
                                        </Label>
                                        <Label>
                                            {intl.formatMessage({
                                                id: 'OnboardingWizard.VenueMainImageContentListItem3',
                                            })}
                                        </Label>
                                    </Fragment>
                                )}

                                {!isMainImage && (
                                    <Fragment>
                                        <Label>
                                            {intl.formatMessage(
                                                {
                                                    id: 'OnboardingWizard.VenueImageDimensionRequirements',
                                                },
                                                { width: minWidth, height: minHeight }
                                            )}
                                        </Label>
                                        <Label>
                                            {intl.formatMessage({
                                                id: 'OnboardingWizard.ImageSizeRequirementsLabel',
                                            })}
                                        </Label>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                    {uploadError && <ValidationMessage isBlock={false} message={uploadError} />}
                    {formValidation.errors.imageRequired && (
                        <ValidationMessage
                            isBlock={false}
                            message={
                                isMainImage
                                    ? intl.formatMessage({ id: 'OnboardingWizard.VenueMainImageRequiredError' })
                                    : intl.formatMessage({ id: 'OnboardingWizard.VenueLogoRequiredError' })
                            }
                        />
                    )}
                </section>
            )}
        </Dropzone>
    );

    const progressBar = (
        <section className={`${isMobileTabletView()}  ${isMainImage ? 'main-image' : ''}`}>
            <div className="image-dropzone">
                <div className={`dropzone-labels progress ${isMobileTabletView()} ${isMainImage ? 'main-image' : ''}`}>
                    <ImageUploadIcon className="upload-logo large" />
                    <div className="progress-bar-wrapper">
                        <div className="progress-bar">
                            <div style={{ width: `${uploadProgress}%` }} className="progress-bar-inner"></div>
                        </div>
                        <Label className="progress-text">
                            {intl.formatMessage(
                                { id: 'OnboardingWizard.UploadingPercentageLabel' },
                                { percentage: uploadProgress }
                            )}
                        </Label>
                    </div>
                </div>
            </div>
            {uploadError && <ValidationMessage isBlock={false} message={uploadError} />}
        </section>
    );
    return !isUploading ? dropzone : progressBar;
};

export default ImageDropzone;
