import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-cuisines.jpg';
import { useIntl } from 'react-intl';
import useValidation from '../../../hooks/useValidation';
import SearchDetails from '../../../domainObjects/SearchDetails';
import { FormGroup } from 'reactstrap';
import ValidationDropdown from '../../common/ValidationDropdown';
import trackingEvents from '../../../enums/trackingEvents';
import useScreenSize from '../../../hooks/useScreenSize';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import { useState, useEffect, useCallback } from 'react';

type Cuisine = {
    id: string;
    name: string;
};

export type CuisinesPageProps = {
    searchDetails: SearchDetails;
    updateSearchDetails: (searchDetails: SearchDetails) => void;
    updateSettings: () => void;
    isUpdating: boolean;
    isLoading: boolean;
    cuisines: Array<Cuisine>;
    isNavigatingFromDishCult: boolean;
    setIsNavigatingFromDishCult: React.Dispatch<React.SetStateAction<boolean>>;
};
const CuisinesPage = ({
    searchDetails,
    updateSearchDetails,
    updateSettings,
    isUpdating,
    isLoading,
    cuisines,
    isNavigatingFromDishCult,
    setIsNavigatingFromDishCult,
}: CuisinesPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const { isMobileView } = useScreenSize();
    const [continueDisabld, setContinueDisabled] = useState(false);

    const isFormValid = useCallback(() => {
        if (
            !searchDetails.primaryCuisineId ||
            !searchDetails.primaryCuisineId.length ||
            searchDetails.primaryCuisineId.length === 0
        ) {
            return false;
        }
        if (
            !searchDetails.secondaryCuisineIds ||
            !searchDetails.secondaryCuisineIds.length ||
            searchDetails.secondaryCuisineIds.length === 0
        ) {
            return false;
        }
        return true;
    }, [searchDetails]);

    useEffect(() => {
        setContinueDisabled(!isFormValid());
    }, [searchDetails, isFormValid]);

    const getCuisineOptions = (): Array<HTMLOptionElement> => {
        return cuisines.map((cuisine) => new Option(cuisine.name, cuisine.id));
    };

    const updatePrimaryCuisineId = (cuisine: string) => {
        const updatedSearchDetails = {
            ...searchDetails,
            primaryCuisineId: cuisine,
        };
        updateSearchDetails(updatedSearchDetails);
    };

    const updateSecondaryCuisineIds = (cuisine: string) => {
        const secondaryCuisineIds = [...searchDetails.secondaryCuisineIds];
        secondaryCuisineIds.push(cuisine);
        const updatedSearchDetails = {
            ...searchDetails,
            secondaryCuisineIds: secondaryCuisineIds,
        };
        updateSearchDetails(updatedSearchDetails);
    };

    const removeSecondaryCuisine = (cuisine: string) => {
        const secondaryCuisineIds = [...searchDetails.secondaryCuisineIds];
        const updatedCuisineIds = secondaryCuisineIds.filter((cuisineId) => {
            return cuisineId !== cuisine;
        });
        const updatedSearchDetails = {
            ...searchDetails,
            secondaryCuisineIds: updatedCuisineIds,
        };
        updateSearchDetails(updatedSearchDetails);
    };

    const clearSecondaryCuisines = () => {
        const updatedSearchDetails = {
            ...searchDetails,
            secondaryCuisineIds: [],
        };
        updateSearchDetails(updatedSearchDetails);
    };

    const getFormBody = () => {
        return (
            <div className={'cuisines-page-form-wrapper ' + (isMobileView ? 'mobile' : '')}>
                <FormGroup>
                    <ValidationDropdown
                        isLoading={isLoading || isUpdating}
                        title={intl.formatMessage({ id: 'OnboardingWizard.PrimaryCuisineLabel' })}
                        selectedValue={searchDetails.primaryCuisineId}
                        onChange={updatePrimaryCuisineId}
                        options={getCuisineOptions()}
                        defaultValue={intl.formatMessage({ id: 'OnboardingWizard.PrimaryCuisineDefaultText' })}
                        inputGroupClassName="primary-cuisine-dropdown"
                        isSearchable
                        name="primary-cuisine-dropdown"
                        formValidation={formValidation}
                        validationRules={{
                            validate: (value: any) => {
                                if (!value || !value.length || value.length === 0)
                                    return intl.formatMessage({ id: 'OnboardingWizard.PrimaryCuisineRequiredLabel' });
                            },
                        }}
                    ></ValidationDropdown>
                </FormGroup>

                <FormGroup>
                    <ValidationDropdown
                        isLoading={isLoading || isUpdating}
                        title={intl.formatMessage({ id: 'OnboardingWizard.SecondaryCuisineLabel' })}
                        multiSelect
                        selectedValues={searchDetails.secondaryCuisineIds}
                        onChange={updateSecondaryCuisineIds}
                        onMultiSelectRemoved={removeSecondaryCuisine}
                        onClearSelectedValues={clearSecondaryCuisines}
                        options={getCuisineOptions()}
                        defaultValue={intl.formatMessage({ id: 'OnboardingWizard.SecondaryCuisineDefaultText' })}
                        inputGroupClassName="secondary-cuisine-dropdown"
                        isSearchable
                        isDropdownDisabled={searchDetails.secondaryCuisineIds.length > 1}
                        name="secondary-cuisines-dropdown"
                        formValidation={formValidation}
                        validationRules={{
                            validate: (value: any) => {
                                if (!value || !value.length || value.length === 0)
                                    return intl.formatMessage({ id: 'OnboardingWizard.SecondaryCuisineRequiredLabel' });
                            },
                        }}
                    ></ValidationDropdown>
                </FormGroup>
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.CuisineSelectionInfoPanelTitle' })}
            infoPanelSubtitle={intl.formatMessage({ id: 'OnboardingWizard.CuisineSelectionInfoPanelSubtitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.cuisinesPage}
            children={getFormBody()}
            updateSettings={updateSettings}
            isUpdating={isUpdating}
            formValidation={formValidation}
            isNavigatingFromDishCult={isNavigatingFromDishCult}
            setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfCuisinesPage}
            continueDisabled={continueDisabld}
        />
    );
};

export default CuisinesPage;
