import React, { useState, useEffect, useReducer } from 'react';
import SettingsHelper from '../../helpers/SettingsHelper';
import ValidationHelper from '../../helpers/ValidationHelper';
import { Label, FormGroup, CustomInput, Tooltip } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import PhoneNumberInput from '../common/PhoneNumberInput';
import CheckBox from '../common/CheckBox';
import { UserAccount } from '../../domainObjects/UserAccount';
import { UserSession } from '../../domainObjects/UserSession';
import { Country } from '../../domainObjects/Country';
import { CountryCode } from '../../domainObjects/CountryCode';
import countryService from '../../services/CountryService';
import accountSubmissionService from '../../services/AccountSubmissionService';
import accountSubscriptionService from '../../services/AccountSubscriptionService';
import { useIntl, FormattedMessage } from 'react-intl';
import ClickableText from '../common/ClickableText';
import useScreenSize from '../../hooks/useScreenSize';
import { ReactComponent as ShowPasswordIcon } from '../../content/icons/password-hide.svg';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';
import useValidation from '../../hooks/useValidation';
import ResDiaryDropdown from '../common/ResDiaryDropdown';
import InfoPanelPage from '../common/InfoPanelPage';
import LoadingSpinner from '../common/LoadingSpinner';
import NoPermissionPanel from '../common/NoPermissionPanel';
import { useNavigate } from 'react-router-dom';
import useQueryParam from '../../hooks/useQueryParam';
import CreateUserForAccountSubmissionRequest from '../../domainObjects/CreateUserForAccountSubmissionRequest';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import trackingEvents from '../../enums/trackingEvents';
import TrainingWidgetService from '../../services/TrainingWidgetService';

const CreateAccountPage = () => {
    const [showPassword, toggleShowPassword] = useReducer(toggle, false);
    const [tooltipOpen, setTooltipOpen] = useReducer(toggle, false);
    const [emailTooltipOpen, setEmailTooltipOpen] = useReducer(toggle, false);
    const [hasFiredPageEvent, setHasFiredPageEvent] = useState(false);

    if (!hasFiredPageEvent) {
        AnalyticsHelper.trackPage(trackingEvents.rdfAccountCreationPage);
        setHasFiredPageEvent(true);
    }

    const [account, setAccount] = useState<UserAccount>({
        FirstName: '',
        LastName: '',
        ProviderName: '',
        CountryId: 0,
        MobilePhoneCode: '',
        MobilePhoneNumber: '',
        EmailAddress: '',
        Password: '',
        LimitedCompanyName: '',
        CountryAlpha2Code: '',
        MobilePhoneCodeCountryId: '',
    });

    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState<boolean>(false);
    const [limitedCompanyMatchesVenueName, setLimitedCompanyMatchesVenueName] = useState<boolean>(true);
    const [isFetchingAccountSubmission, setIsFetchingAccountSubmission] = useState<boolean>(true);
    const [hasFetchedAccountSubmission, setHasFetchedAccountSubmission] = useState<boolean>(false);
    const [canCreateAccount, setCanCreateAccount] = useState<boolean | null>(null);
    const [countries, setCountries] = useState<Array<Country>>();
    const [countryCodes, setCountryCodes] = useState<Array<CountryCode>>();
    const [accountDetails, setAccountDetails] = useState<any | null>(null);
    const [supportEmail, setSupportEmail] = useState<string>('support@resdiary.com');
    const intl = useIntl();
    const navigate = useNavigate();
    const { isMobileView } = useScreenSize();
    const formValidation = useValidation();
    const accessToken = useQueryParam('accessToken');

    useEffect(() => {
        countryService.getCountries().then((countries: Array<Country>) => {
            setCountries(countries);
        });
        countryService.getPhoneCountryCodes().then((countryCodes: Array<CountryCode>) => {
            setCountryCodes(countryCodes);
        });
    }, []);

    useEffect(() => {
        const country = countryCodes?.find((country) => country.Id === accountDetails?.OnboardingCountryId);
        const Alpha2Code = country?.IsoTwoLetterCode ? country.IsoTwoLetterCode : '';
        TrainingWidgetService.getSupportEmailByAlpha2Code(Alpha2Code).then((supportEmail) => {
            setSupportEmail(supportEmail);
        });
    }, [accountDetails, countryCodes, supportEmail]);

    useEffect(() => {
        if (accessToken && !hasFetchedAccountSubmission) {
            setIsFetchingAccountSubmission(true);
            accountSubmissionService
                .get(accessToken)
                .then((response) => {
                    if (
                        response.UseNewOnboardingProcess &&
                        response.DiaryId === null &&
                        response.OnboardingUserId === null
                    ) {
                        setCanCreateAccount(true);
                    } else if (
                        response.UseNewOnboardingProcess &&
                        response.DiaryId !== null &&
                        response.OnboardingUserId !== null
                    ) {
                        window.location.replace(SettingsHelper.getLoginUrl());
                    } else if (
                        response.UseNewOnboardingProcess &&
                        response.DiaryId === null &&
                        response.OnboardingUserId !== null
                    ) {
                        setAccountDetails(response);
                        setIsCreating(true);
                    } else {
                        setCanCreateAccount(false);
                    }
                })
                .catch(() => {
                    setCanCreateAccount(false);
                })
                .finally(() => {
                    setIsFetchingAccountSubmission(false);
                    setHasFetchedAccountSubmission(true);
                });
        } else if (!accessToken) {
            setCanCreateAccount(false);
        }
    }, [accessToken, hasFetchedAccountSubmission]);

    const getCountryOptions = (): Array<HTMLOptionElement> => {
        return countries
            ? countries.map((country) => {
                  return new Option(country?.Name, country?.Id.toString());
              })
            : [];
    };

    const getCountryCodeOptions = (): Array<HTMLOptionElement | null> => {
        return countryCodes
            ? countryCodes.map((countryCode) => {
                  if (countryCode) {
                      return new Option(`${countryCode?.Name}: +${countryCode.PhoneCode}`, countryCode?.Id.toString());
                  } else {
                      return null;
                  }
              })
            : [];
    };

    function toggle(value: boolean) {
        return !value;
    }

    function validatePassword(password: string) {
        if (password.length < 6 || !hasUpperCase(password)) {
            return intl.formatMessage({ id: 'CreationWizard.PasswordValidationMessage' });
        }
        return true;
    }

    function hasUpperCase(string: string) {
        return string.toLowerCase() !== string;
    }

    const onCountryChange = (value: any) => {
        const countryCode = countryCodes?.find((x) => x.Id === parseInt(value));
        setAccount({
            ...account,
            CountryId: parseInt(value),
            MobilePhoneCodeCountryId: value,
            MobilePhoneCode: countryCode ? countryCode.PhoneCode.toString() : '',
        });
    };

    const onCountryCodeChange = (value: any) => {
        const countryCode = countryCodes?.find((x) => x.Id === parseInt(value));
        if (countryCode != null) {
            const updatedAccountDetails = {
                ...account,
                MobilePhoneCode: countryCode.PhoneCode.toString(),
                MobilePhoneCodeCountryId: countryCode.Id.toString(),
            };
            setAccount(updatedAccountDetails);
        }
    };

    function createAccount() {
        if (!canCreateAccount) return;
        setIsCreating(true);
        const request = new CreateUserForAccountSubmissionRequest(account, accessToken);
        accountSubmissionService
            .createUserAccount(request)
            .then((result: UserSession) => {
                if (result && result.Token && result.TokenExpiryUtc) {
                    SettingsHelper.setJwtToken(result.Token);
                    navigate(`/CreatingAccount`);
                }
                setIsCreating(false);
            })
            .catch(() => {
                setIsCreating(false);
            });
    }

    function isUsernameUnique(value: string) {
        return accountSubscriptionService.usernameIsValid(value).then((result: boolean) => {
            if (!result) {
                return intl.formatMessage({ id: 'CreationWizard.ProvideAUniqueEmailAddress' });
            } else {
                return true;
            }
        });
    }

    function getFormBody() {
        if (canCreateAccount === false) {
            return <NoPermissionPanel errorMessage={`Common.NoPermissionToViewThisPage`} />;
        }
        if (accountDetails) {
            return (
                <NoPermissionPanel
                    errorMessage={
                        <FormattedMessage
                            id={`Common.HelpMessage`}
                            values={{
                                supportLink: (
                                    <ClickableText
                                        text={intl.formatMessage({ id: `Common.Support` })}
                                        redirectUrl={`mailto:${supportEmail}?subject=${intl.formatMessage({
                                            id: `Common.CantAccessScheduledDiary`,
                                        })}`}
                                        className="d-inline"
                                    />
                                ),
                            }}
                        />
                    }
                    redirect={true}
                    buttonMessage={`Common.LoginRedirect`}
                    onClick={() => {
                        return window.location.replace(SettingsHelper.getLoginUrl());
                    }}
                />
            );
        }

        return (
            <div className={isMobileView ? 'creation-wizard-form-wrapper mob' : 'creation-wizard-form-wrapper'}>
                <FormGroup className="account-details-form-group">
                    <div className="d-flex">
                        <div className="flex-fill mr-4">
                            <Label for="firstName" id="firstName">
                                {intl.formatMessage({ id: 'CreationWizard.FirstName' })}
                            </Label>
                            <ValidationInput
                                type="text"
                                name="firstName"
                                value={account?.FirstName}
                                onChange={(e) => setAccount({ ...account, FirstName: e.toString() })}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage(
                                        { id: 'Common.FieldRequiredGenericMessage' },
                                        { fieldName: intl.formatMessage({ id: 'CreationWizard.FirstName' }) }
                                    ),
                                })}
                                errors={formValidation.errors}
                                ariaLabelledBy={'firstName'}
                                ariaRequired
                            />
                        </div>
                        <div className="flex-fill">
                            <Label for="lastName" id="lastName">
                                {intl.formatMessage({ id: 'CreationWizard.LastName' })}
                            </Label>
                            <ValidationInput
                                type="text"
                                name="lastName"
                                value={account?.LastName}
                                onChange={(e) => setAccount({ ...account, LastName: e.toString() })}
                                innerRef={formValidation.register({
                                    required: intl.formatMessage(
                                        { id: 'Common.FieldRequiredGenericMessage' },
                                        { fieldName: intl.formatMessage({ id: 'CreationWizard.LastName' }) }
                                    ),
                                })}
                                errors={formValidation.errors}
                                ariaLabelledBy={'lastName'}
                                ariaRequired
                            />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup className="account-details-form-group">
                    <Label for="venueName" id="venueName">
                        {intl.formatMessage({ id: 'CreationWizard.VenueName' })}
                    </Label>
                    <ValidationInput
                        type="text"
                        name="venueName"
                        value={account?.ProviderName}
                        onChange={(e) => setAccount({ ...account, ProviderName: e.toString() })}
                        innerRef={formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Common.FieldRequiredGenericMessage' },
                                { fieldName: intl.formatMessage({ id: 'CreationWizard.VenueName' }) }
                            ),
                        })}
                        errors={formValidation.errors}
                        ariaLabelledBy={'venueName'}
                        ariaRequired
                    />
                </FormGroup>
                <FormGroup className="account-details-form-group">
                    <div className="d-flex">
                        <div>
                            {intl.formatMessage({ id: 'CreationWizard.DoesLimitedCompanyNameMatchVenueName' })}
                            <InfoIcon id="LimitedCompanyNameTooltip" className="ltd-company-info-icon" />
                        </div>
                        <Tooltip
                            innerClassName="tooltip-style"
                            placement="top"
                            isOpen={tooltipOpen}
                            target="LimitedCompanyNameTooltip"
                            toggle={setTooltipOpen}
                        >
                            <FormattedMessage id="CreationWizard.LimitedCompanyNameTooltipText" />
                        </Tooltip>
                    </div>
                    <div className="checkbox-container">
                        <CustomInput
                            checked={limitedCompanyMatchesVenueName}
                            onChange={() => setLimitedCompanyMatchesVenueName(true)}
                            type="radio"
                            id={'radio_namesMatch'}
                            name={'radioNamesMatch'}
                            label={intl.formatMessage({ id: 'Common.Yes' })}
                        />
                        <CustomInput
                            checked={!limitedCompanyMatchesVenueName}
                            onChange={() => setLimitedCompanyMatchesVenueName(false)}
                            type="radio"
                            id={'radio_namesDontMatch'}
                            name={'radioNamesDontMatch'}
                            className={'ml-4'}
                            label={intl.formatMessage({ id: 'Common.No' })}
                        />
                    </div>
                </FormGroup>
                {!limitedCompanyMatchesVenueName && (
                    <FormGroup className="account-details-form-group">
                        <Label for="limitedCompanyName" id="limitedCompanyName">
                            {intl.formatMessage({ id: 'CreationWizard.LimitedCompanyName' })}
                        </Label>
                        <ValidationInput
                            type="text"
                            name="limitedCompanyName"
                            value={account?.LimitedCompanyName}
                            onChange={(e) => setAccount({ ...account, LimitedCompanyName: e.toString() })}
                            innerRef={formValidation.register({
                                validate: (value: string) => {
                                    if (!value && !limitedCompanyMatchesVenueName)
                                        return intl.formatMessage(
                                            { id: 'Common.FieldRequiredGenericMessage' },
                                            {
                                                fieldName: intl.formatMessage({
                                                    id: 'CreationWizard.LimitedCompanyName',
                                                }),
                                            }
                                        );
                                },
                            })}
                            errors={formValidation.errors}
                            ariaLabelledBy={'limitedCompanyName'}
                            ariaRequired
                        />
                    </FormGroup>
                )}
                <FormGroup className="account-details-form-group">
                    <Label for="countryDropdown" id="countryDropdown">
                        {intl.formatMessage({ id: 'CreationWizard.Country' })}
                    </Label>
                    <ResDiaryDropdown
                        options={getCountryOptions()}
                        onValueChange={onCountryChange}
                        selectedValue={account?.CountryId.toString()}
                        isSearchable
                        defaultValue={intl.formatMessage({ id: 'Common.PleaseSelect' })}
                        hideRemoveItemIcon
                        name={'countryDropdown'}
                    />
                </FormGroup>
                <FormGroup className="account-details-form-group">
                    <PhoneNumberInput
                        onChange={(e) => setAccount({ ...account, MobilePhoneNumber: e.toString() })}
                        onCountryCodeChange={onCountryCodeChange}
                        selectedValue={account?.MobilePhoneCodeCountryId}
                        inputValue={account?.MobilePhoneNumber}
                        optionGroups={getCountryCodeOptions()}
                        name="VenuePhoneNumberField"
                        innerRef={formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Common.FieldRequiredGenericMessage' },
                                { fieldName: intl.formatMessage({ id: 'CreationWizard.PhoneNumber' }) }
                            ),
                            pattern: {
                                value: /(^[+(\d]{1})(([\d/+() -.]){5,16})([\d]{1}$)/,
                                message: intl.formatMessage({
                                    id: 'CreationWizard.ProvideAValidPhoneNumber',
                                }),
                            },
                        })}
                        errors={formValidation.errors}
                        includeValidation
                        label={intl.formatMessage({ id: 'CreationWizard.PhoneNumber' })}
                        inputClassName={'create-account-phone-input'}
                    />
                </FormGroup>
                <FormGroup className="account-details-form-group">
                    <Label for="emailAddress" id="emailAddress">
                        {intl.formatMessage({ id: 'CreationWizard.EmailAddress' })}
                    </Label>
                    <InfoIcon id="EmailAddressTooltip" className="ltd-company-info-icon" />
                    <Tooltip
                        innerClassName="tooltip-style"
                        placement="right"
                        isOpen={emailTooltipOpen}
                        target="EmailAddressTooltip"
                        toggle={setEmailTooltipOpen}
                    >
                        <FormattedMessage id="CreationWizard.EmailAddressTooltipText" />
                    </Tooltip>
                    <ValidationInput
                        type="text"
                        name="emailAddress"
                        value={account?.EmailAddress}
                        onChange={(e) => setAccount({ ...account, EmailAddress: e.toString() })}
                        innerRef={formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Common.FieldRequiredGenericMessage' },
                                { fieldName: intl.formatMessage({ id: 'CreationWizard.EmailAddress' }) }
                            ),
                            validate: (value) => isUsernameUnique(value),
                            pattern: {
                                value: ValidationHelper.getEmailValidationRegex(),
                                message: intl.formatMessage({
                                    id: 'CreationWizard.ProvideAValidEmailAddress',
                                }),
                            },
                        })}
                        errors={formValidation.errors}
                        ariaLabelledBy={'emailAddress'}
                        ariaRequired
                    />
                </FormGroup>
                <FormGroup className="account-details-form-group password">
                    <Label for="password" id="password">
                        {intl.formatMessage({ id: 'CreationWizard.Password' })}
                    </Label>
                    <ValidationInput
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={account?.Password}
                        onChange={(e) => setAccount({ ...account, Password: e.toString() })}
                        innerRef={formValidation.register({
                            validate: (value) => validatePassword(value),
                        })}
                        errors={formValidation.errors}
                        ariaLabelledBy={'password'}
                        ariaRequired
                        hideValidationIcon
                    />
                    {!formValidation.errors['password'] && (
                        <div className="password-restrictions-info">
                            <InfoIcon />
                            <div className="ml-1">
                                <FormattedMessage id="CreationWizard.PasswordValidationMessage" />
                            </div>
                        </div>
                    )}
                    <div className="toggle-password-visible" onClick={() => toggleShowPassword()}>
                        <ShowPasswordIcon />
                    </div>
                </FormGroup>
                <FormGroup className="account-details-form-group password">
                    <CheckBox
                        id={'rd-terms-and-conditions-checkbox'}
                        name="termsAndCo"
                        option={{
                            text: intl.formatMessage(
                                { id: 'CreationWizard.TermsAndConditionsCheckboxLabel' },
                                {
                                    termsAndConditions: (
                                        <ClickableText
                                            text={intl.formatMessage({ id: 'CreationWizard.TermsAndConditions' })}
                                            redirectUrl={SettingsHelper.getTermsConditionsUrl()}
                                            className="d-inline"
                                            openInNewTab
                                        />
                                    ),
                                    privacyPolicy: (
                                        <ClickableText
                                            text={intl.formatMessage({ id: 'CreationWizard.PrivacyPolicy' })}
                                            redirectUrl={SettingsHelper.getPrivacyPolicyUrl()}
                                            className="d-inline"
                                            openInNewTab
                                        />
                                    ),
                                }
                            ),
                            value: 0,
                        }}
                        onChange={(e) => setTermsAndConditionsChecked(e)}
                        isChecked={termsAndConditionsChecked}
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'CreationWizard.TermsAndCoValidationMessage' }),
                        })}
                        errors={formValidation.errors}
                    />
                </FormGroup>
            </div>
        );
    }

    if (isFetchingAccountSubmission && !hasFetchedAccountSubmission) {
        return (
            <div className="create-account-page">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="create-account-page">
            <InfoPanelPage
                infoPanelTitle={intl.formatMessage({ id: 'CreationWizard.CreateAnAccount' })}
                currentStep={'CreateAccount'}
                children={getFormBody()}
                isUpdating={isCreating}
                formValidation={formValidation}
                infoPanelClassName="create-account-background"
                nextStep={createAccount}
                isChecklistPage={false}
                hideProgressBar
            />
        </div>
    );
};

export default CreateAccountPage;
