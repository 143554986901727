import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Label } from 'reactstrap';
import Table from '../../../domainObjects/Table';
import ValidationInput from '../../common/ValidationInput';

type TableNameInputProps = {
    table: Table;
    addOrUpdateTable: (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => void;
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    formValidation: any;
    validateName: (table: Table, showInfoBar: boolean) => boolean;
    isUpdating: boolean;
    showSelectInfoBar: (
        select: 'saveSuccess' | 'saveFail' | 'deleteSuccess' | 'deleteFail' | 'alreadyExists' | 'isEmpty',
        tableNumber: string
    ) => void;
};

const TableNameInput = ({
    table,
    addOrUpdateTable,
    isEditing,
    setIsEditing,
    formValidation,
    validateName,
    isUpdating,
    showSelectInfoBar,
}: TableNameInputProps) => {
    const [tableName, setTableName] = useState<string>('');
    const intl = useIntl();

    useEffect(() => {
        if (table) {
            setTableName(table.number);
        }
    }, [table]);

    return (
        <Fragment>
            {isEditing && (
                <div className="table-name">
                    <div className="table-name-wrapper">
                        <ValidationInput
                            className="table-name-input"
                            onChange={(name: any) => {
                                setTableName(name);
                            }}
                            name={`table-${table?.id ? table.id : 0}`}
                            value={tableName}
                            innerRef={
                                formValidation &&
                                validateName &&
                                formValidation.register({
                                    validate: () => {
                                        table.number = tableName;
                                        validateName(table as Table, true);
                                    },
                                })
                            }
                            placeholder={intl.formatMessage({ id: 'OnboardingWizard.VenueTableNumberPlaceholder' })}
                            errors={formValidation.errors}
                            maxLength={10}
                            displayCharacterCount={!isUpdating && tableName.length > 0}
                        />
                    </div>
                    <Button
                        className="table-name-button"
                        color="primary"
                        disabled={!tableName || isUpdating || formValidation.errorCount !== 0}
                        onClick={() => {
                            table.number = tableName;
                            if (validateName(table, false)) {
                                setIsEditing(false);
                                addOrUpdateTable(table, false, (success) => {
                                    if (!success) {
                                        setIsEditing(true);
                                        showSelectInfoBar('saveFail', table.number);
                                    } else {
                                        showSelectInfoBar('saveSuccess', table.number);
                                    }
                                });
                            }
                        }}
                    >
                        <FormattedMessage id="Common.Save" />
                    </Button>
                </div>
            )}
            {!isEditing && (
                <div className="table-name">
                    <Label className="table-name-label">{table?.number}</Label>
                </div>
            )}
        </Fragment>
    );
};

export default TableNameInput;
