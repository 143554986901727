export type AvailableTimeSettings = {
    TimeSlot: string;
    AvailableAreaIds: Array<number>;
    ServiceId: number;
};

class AvailableTime {
    timeSlot: string;
    availableAreaIds: Array<number>;
    serviceId: number;

    constructor(availableTime: AvailableTimeSettings) {
        this.timeSlot = availableTime && availableTime.TimeSlot ? (availableTime.TimeSlot as string) : '';
        this.availableAreaIds = availableTime && availableTime.AvailableAreaIds ? availableTime.AvailableAreaIds : [];
        this.serviceId = availableTime && availableTime.ServiceId ? availableTime.ServiceId : 0;
    }
}

export default AvailableTime;
