import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/delete.svg';
import Area from '../../../domainObjects/Area';
import { Button, CustomInput, FormGroup, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import ValidationInput from '../../common/ValidationInput';
import { useEffect, useState } from 'react';
import useScreenSize from '../../../hooks/useScreenSize';

export type VenueAreaItemProps = {
    area: Area;
    onClickSave: (area: Area) => void;
    onClickDelete: (area: Area) => void;
    onChangeName: (area: Area, newName: string) => void;
    onToggleIsAvailableOnline: (area: Area) => void;
    editOnInit?: boolean;
    formValidation?: any;
    validateName?: (area: Area) => boolean;
    setContinueDisabled?: (val: boolean) => void;
};

const VenueAreaItem = ({
    area,
    onClickSave,
    onClickDelete,
    onChangeName,
    onToggleIsAvailableOnline,
    editOnInit = false,
    formValidation,
    validateName,
    setContinueDisabled,
}: VenueAreaItemProps) => {
    const intl = useIntl();
    const [isEditing, setIsEditing] = useState<boolean>(editOnInit);
    const { isMobileView, isTabletView } = useScreenSize();

    useEffect(() => {
        if (setContinueDisabled) {
            setContinueDisabled(isEditing);
        }
    }, [isEditing, setContinueDisabled]);

    const areaEdit = (
        <div className={`area-item edit ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <div className="area-item-cell">
                <FormGroup>
                    <ValidationInput
                        name={`areaItem${area.id}`}
                        onChange={(newName) => {
                            typeof newName === 'string' && onChangeName(area, newName);
                        }}
                        value={area.name}
                        errors={formValidation && formValidation.errors}
                        hideValidationMessage
                    />
                    <Button
                        color="primary"
                        onClick={() => {
                            if (validateName) {
                                const validated = validateName(area);
                                if (validated) {
                                    setIsEditing(false);
                                    onClickSave(area);
                                }
                            }
                        }}
                        disabled={formValidation && formValidation.errors[`areaItem${area.id}`]}
                    >
                        <FormattedMessage id={'Common.Save'} />
                    </Button>
                </FormGroup>
            </div>
            <div className="area-item-cell">
                <CustomInput
                    checked={area.isAvailableOnline}
                    onChange={() => {
                        onToggleIsAvailableOnline(area);
                    }}
                    type="checkbox"
                    id={area.id}
                    label={intl.formatMessage({ id: 'OnboardingWizard.AcceptOnlineReservationCheckboxLabel' })}
                />
            </div>
            <div className="area-item-cell area-item-button-wrapper">
                <Button id={`EditArea-${area.id}`} onClick={() => {}}>
                    <EditIcon />
                </Button>
                <UncontrolledTooltip flip target={`EditArea-${area.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Edit',
                    })}
                </UncontrolledTooltip>
                <Button
                    id={`DeleteArea-${area.id}`}
                    onClick={() => {
                        onClickDelete(area);
                    }}
                >
                    <DeleteIcon />
                </Button>
                <UncontrolledTooltip flip target={`DeleteArea-${area.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Delete',
                    })}
                </UncontrolledTooltip>
            </div>
        </div>
    );

    const areaView = (
        <div className={`area-item ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <div className="area-item-cell">{area.name}</div>
            <div className="area-item-cell">
                <CustomInput
                    checked={area.isAvailableOnline}
                    onChange={() => {
                        onToggleIsAvailableOnline(area);
                    }}
                    type="checkbox"
                    id={area.id}
                    label={intl.formatMessage({ id: 'OnboardingWizard.AcceptOnlineReservationCheckboxLabel' })}
                />
            </div>
            <div className="area-item-cell area-item-button-wrapper">
                <Button
                    id={`EditArea-${area.id}`}
                    onClick={() => {
                        setIsEditing(true);
                    }}
                >
                    <EditIcon />
                </Button>
                <UncontrolledTooltip flip target={`EditArea-${area.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Edit',
                    })}
                </UncontrolledTooltip>
                <Button
                    id={`DeleteArea-${area.id}`}
                    onClick={() => {
                        onClickDelete(area);
                    }}
                >
                    <DeleteIcon />
                </Button>
                <UncontrolledTooltip flip target={`DeleteArea-${area.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Delete',
                    })}
                </UncontrolledTooltip>
            </div>
        </div>
    );

    return isEditing ? areaEdit : areaView;
};

export default VenueAreaItem;
