import React, { useState } from 'react';
import TableNameInput from './TableNameInput';
import TableSizeInput from './TableSizeInput';
import Table from '../../../domainObjects/Table';
import useScreenSize from '../../../hooks/useScreenSize';
import TableControls from './TableControls';

type VenueTableProps = {
    table: Table;
    addOrUpdateTable: (table: Table, setOnlyState: boolean, callback: (success: boolean) => void) => void;
    deleteTable: (tableToDelete: Table, callback: (success: boolean) => void) => void;
    formValidation: any;
    validateName: (table: Table, showInfoBar: boolean) => boolean;
    isUpdating: boolean;
    showSelectInfoBar: (
        select: 'saveSuccess' | 'saveFail' | 'deleteSuccess' | 'deleteFail' | 'alreadyExists' | 'isEmpty',
        tableNumber: string
    ) => void;
};

const VenueTable = ({
    table,
    addOrUpdateTable,
    deleteTable,
    formValidation,
    validateName,
    isUpdating,
    showSelectInfoBar,
}: VenueTableProps) => {
    const [isEditing, setIsEditing] = useState(table.number === '');
    const { isMobileView, isTabletView } = useScreenSize();

    return (
        <div className={`table-input-wrapper ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <TableNameInput
                table={table}
                addOrUpdateTable={addOrUpdateTable}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                formValidation={formValidation}
                validateName={validateName}
                isUpdating={isUpdating}
                showSelectInfoBar={showSelectInfoBar}
            />
            <TableSizeInput table={table} addOrUpdateTable={addOrUpdateTable} />
            <TableControls
                tableId={table.id}
                onEdit={() => setIsEditing(!isEditing)}
                onDelete={() => {
                    deleteTable(table, () => {
                        showSelectInfoBar('deleteSuccess', table.number);
                    });
                }}
            />
        </div>
    );
};

export default VenueTable;
