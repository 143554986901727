import React, { ChangeEventHandler, Key, useState, useRef, useEffect } from 'react';
import { InputGroup, Button, DropdownToggle, InputGroupButtonDropdown } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import DropdownMenuWrapper from './DropdownMenuWrapper';
import { ReactComponent as CloseIcon } from '../../content/icons/close.svg';
import SkeletonLabel from './SkeletonLabel';

export type ResDiaryDropdownProps = {
    customDropdownToggle?: boolean;
    customDropdownIcon?: string;
    defaultValue?: string;
    displaySearchableText?: boolean;
    isDropdownDisabled?: boolean;
    isLoading?: boolean;
    isSearchable?: boolean;
    isCountryCodeDropdown?: boolean;
    onBlur?: ChangeEventHandler<any>;
    onValueChange: (value: any) => void;
    optionGroups?: Array<any>;
    options: Array<any>;
    parentOverriding?: boolean;
    selectedValue?: string | number;
    selectedValues?: Array<any>;
    skeletonWidth?: number;
    multiSelect?: boolean;
    flip?: boolean;
    title?: string;
    // Check if these two can be required ONLY IF multiSelect is true
    onMultiSelectRemoved?: ChangeEventHandler<any>;
    onClearSelectedValues?: (value?: any) => void;
    inputGroupClassName?: string;
    dropdownMenuClassName?: string;
    hideRemoveItemIcon?: boolean;
    errors?: { [key: string]: any };
    name?: string;
    ariaLabel?: string;
    ariaRequired?: boolean;
    isDropdownUsedAsHeader?: boolean;
};

const ResDiaryDropdown = ({
    customDropdownToggle,
    customDropdownIcon,
    defaultValue,
    displaySearchableText,
    isDropdownDisabled,
    isLoading,
    isSearchable,
    isCountryCodeDropdown,
    onBlur,
    onValueChange,
    optionGroups,
    options,
    parentOverriding,
    selectedValue,
    selectedValues,
    skeletonWidth,
    multiSelect,
    flip,
    title,
    // Check if these two can be required ONLY IF multiSelect is true
    onMultiSelectRemoved,
    onClearSelectedValues,
    inputGroupClassName,
    dropdownMenuClassName,
    hideRemoveItemIcon,
    errors,
    name,
    ariaLabel,
    ariaRequired,
    isDropdownUsedAsHeader,
}: ResDiaryDropdownProps) => {
    const [isHovering, setIsHovering] = useState(false);
    const [splitButtonOpen, setSplitbuttonOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef<HTMLDivElement>(null);

    const filterSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm.toLowerCase());
    };

    const getSelectedText = () => {
        let selectedOption;
        if (!selectedValue && selectedValue !== 0) {
            return defaultValue;
        } else if (optionGroups) {
            for (let optionGroup of optionGroups) {
                for (let option of optionGroup.options) {
                    if (option.value === selectedValue) {
                        selectedOption = option;
                    }
                }
            }
            return selectedOption ? selectedOption.text : defaultValue;
        } else if (options) {
            selectedOption = options.find((option) => option.value === selectedValue);
            if (isCountryCodeDropdown) {
                return selectedOption ? selectedOption.text.split(':')[1] : defaultValue;
            }
            return selectedOption ? selectedOption.text : defaultValue;
        } else {
            // Given the unlikely case the dropdown has been passed no options whatsoever
            return defaultValue;
        }
    };

    const getSelectedMultiValues = () => {
        return selectedValues && selectedValues.length > 0 ? (
            selectedValues.map((selectedValue) => {
                let selectedOption: { text: Key | null | undefined; value: any } = { text: null, value: null };
                if (optionGroups) {
                    for (let optionGroup of optionGroups) {
                        selectedOption = optionGroup.options.find(
                            (option: { value: any }) => option.value === selectedValue
                        );
                        if (selectedOption) {
                            break;
                        }
                    }
                } else if (options) {
                    selectedOption = options.find((option) => option.value === selectedValue);
                }
                return selectedOption ? (
                    <div key={selectedOption.text} className="btn-dark flexbox">
                        {selectedOption.text}
                        <button
                            type="button"
                            className="close-button"
                            onClick={(e) => {
                                onMultiSelectRemovedInternal(e, selectedOption.value);
                            }}
                            aria-label="Close"
                        >
                            <div>{<CloseIcon />}</div>
                        </button>
                    </div>
                ) : (
                    ''
                );
            })
        ) : (
            <div className="multi-default-value">{defaultValue}</div>
        );
    };

    const getUnselectedOptions = () => {
        return selectedValues && options ? options.filter((option) => !selectedValues.includes(option.value)) : options;
    };

    const isDropdownDisabledInternal = (unselectedOptions: string | any[] | undefined) => {
        return isDropdownDisabled || (options && unselectedOptions && unselectedOptions.length === 0) ? true : false;
    };

    const hasLabelDefault = () => {
        return displaySearchableText && !isHovering && !parentOverriding && !splitButtonOpen;
    };

    const onMultiSelectRemovedInternal = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        value: React.ChangeEvent<any>
    ) => {
        // Prevent further event bubbling
        e.stopPropagation();
        onMultiSelectRemoved!(value);
    };

    const toggleSplit = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        // Prevent further event bubbling
        e.stopPropagation();
        setSplitbuttonOpen(!splitButtonOpen);
        setSearchTerm('');
    };

    const onMouseOver = () => {
        setIsHovering(true);
    };

    const onMouseLeave = () => {
        setIsHovering(false);
    };

    const onValueChangeInternal = (changeValue: string | number | undefined): void => {
        setSplitbuttonOpen(!splitButtonOpen);
        setSearchTerm('');
        setIsHovering(false);
        // If the newly selected value matches the value currently selected, deselect the value
        // UPDATE THIS DESELECT METHOD (HOW CAN WE PASS NULL?)
        changeValue === selectedValue ? onValueChange(null) : onValueChange(changeValue);
    };

    const getDropDownHeader = () => {
        return (
            (multiSelect || title) && (
                <div className={`${title ? 'd-flex justify-content-between' : 'd-flex justify-content-end'} w-100`}>
                    {title && (
                        <div className="d-flex justify-content-start">
                            <SkeletonLabel isLoading={isLoading}>{title} </SkeletonLabel>
                        </div>
                    )}
                    {!isLoading && multiSelect && (
                        <div
                            className={`${
                                selectedValues && selectedValues.length > 0
                                    ? 'd-flex justify-content-end mb-1'
                                    : 'hidden'
                            }`}
                        >
                            <Button color="link" aria-label="Clear all" onClick={() => onClearSelectedValues!()}>
                                {'Clear all'}
                            </Button>
                        </div>
                    )}
                </div>
            )
        );
    };

    const getDropdownClasses = (): string => {
        const classes = [];
        if (hasLabelDefault()) {
            classes.push('rd-dropdown-text');
        } else {
            classes.push('rd-dropdown');
        }
        if (isDropdownUsedAsHeader) {
            classes.push('header');
        }
        return classes.join(' ');
    };

    useEffect(() => {
        if (splitButtonOpen && !multiSelect && dropdownRef?.current?.parentElement) {
            dropdownRef.current.parentElement.scrollTop = dropdownRef.current.offsetTop;
        }
    }, [splitButtonOpen, multiSelect]);

    const unselectedOptions = getUnselectedOptions();
    const isDropdownDisabledOptions = isDropdownDisabledInternal(unselectedOptions);
    const dropDownClasses = [];
    if (inputGroupClassName) {
        dropDownClasses.push(inputGroupClassName);
    }
    const invalidCssClass = errors && name && errors[name] ? 'is-invalid' : '';

    return (
        <div
            className={getDropdownClasses()}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            aria-label={ariaLabel}
        >
            {getDropDownHeader()}
            {(!isLoading && (
                <InputGroup className={dropDownClasses.join(' ')}>
                    <InputGroupButtonDropdown addonType="prepend" isOpen={splitButtonOpen} toggle={toggleSplit}>
                        {!multiSelect && (
                            <Button
                                onClick={(e) => toggleSplit(e)}
                                color="light"
                                className={`dropdown-btn-left text-left ${invalidCssClass} selected-value`}
                                disabled={isDropdownDisabledOptions}
                                onBlur={onBlur}
                            >
                                {getSelectedText()}
                            </Button>
                        )}
                        {multiSelect && (
                            <div
                                onClick={!isDropdownDisabledOptions ? (e) => toggleSplit(e) : undefined}
                                className={`dropdown-btn-left text-left d-flex multi-select-dropdown ${invalidCssClass}`}
                            >
                                {getSelectedMultiValues()}
                            </div>
                        )}
                        {customDropdownToggle ? (
                            <DropdownToggle
                                color="light"
                                className={`dropdown-btn-right dropdown-custom-toggle ${
                                    multiSelect ? 'multi' : ''
                                } ${invalidCssClass}`}
                                disabled={isDropdownDisabledOptions}
                            >
                                {customDropdownIcon}
                            </DropdownToggle>
                        ) : (
                            <DropdownToggle
                                color="light"
                                className={`dropdown-btn-right ${multiSelect ? 'multi' : ''} ${invalidCssClass}`}
                                disabled={isDropdownDisabledOptions}
                                split
                            />
                        )}
                        <DropdownMenuWrapper
                            isSearchable={isSearchable}
                            hasDefaultValue={defaultValue}
                            filterSearch={filterSearch}
                            searchTerm={searchTerm}
                            selectedValue={selectedValue}
                            onValueChange={onValueChangeInternal}
                            options={!multiSelect ? options : unselectedOptions}
                            optionGroups={optionGroups}
                            flip={flip}
                            hideRemoveItemIcon={hideRemoveItemIcon}
                            className={dropdownMenuClassName}
                            innerRef={dropdownRef}
                        />
                    </InputGroupButtonDropdown>
                </InputGroup>
            )) || (
                <div className="input-skeleton-height">
                    {' '}
                    <Skeleton style={{ lineHeight: 3 }} width={skeletonWidth} />
                </div>
            )}
        </div>
    );
};

export default ResDiaryDropdown;
