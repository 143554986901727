import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Alert } from 'reactstrap';
import { ReactComponent as ConfirmIcon } from '../../content/icons/confirm.svg';
import { ReactComponent as ErrorIcon } from '../../content/icons/Icon-Alert.svg';

export type InfoBarProps = {
    id: number;
    type: 'success' | 'danger';
    onDismiss: (id: number) => void;
    message: string;
};

const InfoBar = ({ id, type, onDismiss, message }: InfoBarProps) => {
    const intl = useIntl();

    useEffect(() => {
        if (type === 'success') {
            setTimeout(() => onDismiss(id), 4000);
        } else {
            setTimeout(() => onDismiss(id), 10000);
        }
    }, [id, type, onDismiss]);

    return (
        <Alert className="info-bar animated slideInDown" color={type} isOpen={true} toggle={() => onDismiss(id)}>
            <div className="d-flex align-items-center">
                <div>
                    {type === 'success' && <ConfirmIcon />}
                    {type !== 'success' && <ErrorIcon />}
                </div>
                <div className="info-bar-message">
                    {message ? message : intl.formatMessage({ id: 'Common.DefaultInfoBarMessage' })}
                </div>
            </div>
        </Alert>
    );
};

export default InfoBar;
