export type AreaSettings = {
    Id: number;
    SegmentId: number;
    MaxCovers: number;
    Name: string;
    IsAvailableOnline: boolean;
};

class Area {
    id: number;
    segmentId: number;
    maxCovers: number;
    name: string;
    isAvailableOnline: boolean;

    constructor(areaSettings: AreaSettings | null) {
        this.id = areaSettings?.Id ?? 0;
        this.segmentId = areaSettings?.SegmentId ?? 0;
        this.maxCovers = areaSettings?.MaxCovers ?? 0;
        this.name = areaSettings?.Name ?? '';
        this.isAvailableOnline = areaSettings?.IsAvailableOnline ?? false;
    }
}

export default Area;
