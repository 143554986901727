import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-cuisines.jpg';
import { FormattedMessage, useIntl } from 'react-intl';
import TypeaheadWrapper from '../../common/TypeaheadWrapper';
import ValidationInput from '../../common/ValidationInput';
import ValidationButton from '../../common/ValidationButton';
import useValidation from '../../../hooks/useValidation';
import SettingsService from '../../../services/SettingsService';
import { CountryCode } from '../../../domainObjects/CountryCode';
import ResDiaryDropdown from '../../common/ResDiaryDropdown';
import MapContainer from '../../common/MapContainer';
import PlacesService from '../../../services/PlacesService';
import PlaceDetailsResponse, { PlaceDetailsResponseSettings } from '../../../domainObjects/PlaceDetailsResponse';
import { PlaceSearchResponseSettings } from '../../../domainObjects/PlaceSearchResponse';
import PlacesComponentTypes from '../../../enums/PlacesComponentTypes';
import { AddressComponent } from '../../../domainObjects/PlaceResult';
import Location from '../../../domainObjects/Location';
import NameAddressDetails from '../../../domainObjects/NameAddressDetails';
import trackingEvents from '../../../enums/trackingEvents';
import useScreenSize from '../../../hooks/useScreenSize';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import SkeletonLabel from '../../common/SkeletonLabel';
import InfoBars from '../../common/InfoBars';
import { InfoBarProps } from '../../common/InfoBar';

export type LocationPageProps = {
    step?: 'search' | 'form' | 'map';
    nameAddressDetails: NameAddressDetails;
    updateNameAddressDetails: Function;
    updateSettings: Function;
    isLoading: boolean;
    isUpdating?: boolean;
};

const LocationPage = ({
    step = 'search',
    nameAddressDetails,
    updateNameAddressDetails,
    updateSettings,
    isLoading,
    isUpdating,
}: LocationPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const [countryCodes, setCountryCodes] = useState<Array<CountryCode>>();
    const [currentStep, setCurrentStep] = useState(step);
    const { isMobileView } = useScreenSize();
    const searchFormRef = useRef<HTMLDivElement>(null);
    const [selectedCountryId, setSelectedCountryId] = useState<string>('1');
    const [canVerifyLocation, setCanVerifyLocation] = useState<boolean>(false);
    const [locationVerified, setLocationVerified] = useState<boolean>(false);
    const [infoBars, setInfoBars] = useState<Array<InfoBarProps>>([]);

    // Get country codes from the database
    useEffect(() => {
        let isMounted = true;
        SettingsService.getCountryCodes().then((providerCountryCodes: any) => {
            if (isMounted) {
                setCountryCodes(providerCountryCodes);
                // Set default country code from provider location
            }
        });
        return () => {
            isMounted = false;
        };
    }, [setCountryCodes]);

    // Select the country from dropdown based on what we got from the database
    useEffect(() => {
        const storedCountryIdFromName =
            countryCodes?.find((countryCode) => countryCode.Name === nameAddressDetails.country)?.Id.toString() ?? '1';
        setSelectedCountryId(storedCountryIdFromName);
    }, [countryCodes, nameAddressDetails.country]);

    // Track when should we allow verifying of location
    useEffect(() => {
        if (
            nameAddressDetails.streetLine1 &&
            nameAddressDetails.country &&
            nameAddressDetails.town &&
            nameAddressDetails.postcode
        ) {
            setCanVerifyLocation(true);
        } else {
            setCanVerifyLocation(false);
        }
    }, [nameAddressDetails]);

    // Keep track when location is verified, basically allows to progress to next page.
    useEffect(() => {
        if (currentStep === 'map') {
            setLocationVerified(true);
            formValidation.reset();
        } else {
            setLocationVerified(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    // If the location is set, there's no point in going through search again.
    useEffect(() => {
        if (currentStep === 'search' && nameAddressDetails.longitude && nameAddressDetails.latitude) {
            setCurrentStep('map');
        }
    }, [canVerifyLocation, currentStep, nameAddressDetails.latitude, nameAddressDetails.longitude]);

    const scrollToSearch = () => searchFormRef.current?.scrollIntoView();

    const onAddressLine1Change = (value: any) => {
        const updatedNameAddressDetails = { ...nameAddressDetails, streetLine1: value };
        updateNameAddressDetails(updatedNameAddressDetails);
    };

    const onAddressLine2Change = (value: any) => {
        const updatedNameAddressDetails = { ...nameAddressDetails, streetLine2: value };
        updateNameAddressDetails(updatedNameAddressDetails);
    };

    const onTownOrCityChange = (value: any) => {
        const updatedNameAddressDetails = { ...nameAddressDetails, town: value };
        updateNameAddressDetails(updatedNameAddressDetails);
    };

    const onPostcodeChange = (value: any) => {
        const updatedNameAddressDetails = { ...nameAddressDetails, postcode: value };
        updateNameAddressDetails(updatedNameAddressDetails);
    };

    const onCountryChange = (value: string) => {
        const countryName = countryCodes?.find((c) => c.Id === parseInt(value))?.Name ?? '';
        const updatedNameAddressDetails = { ...nameAddressDetails, country: countryName };
        updateNameAddressDetails(updatedNameAddressDetails);
    };

    const onLatLngChange = (value: { lat: number; lng: number }) => {
        const updatedNameAddressDetails = { ...nameAddressDetails, latitude: value.lat, longitude: value.lng };
        updateNameAddressDetails(updatedNameAddressDetails);
    };

    const makeSearchRequest = useCallback((searchTerm: string) => {
        return PlacesService.getSearchResults(searchTerm);
    }, []);

    const processSearchResponse = useCallback((response: PlaceSearchResponseSettings) => {
        if (response && response.Status === 'OK') {
            const results = response.Places.map((place) => ({
                id: place.PlaceId,
                display: place.FormattedAddress,
            }));
            return results;
        } else {
            return [];
        }
    }, []);

    const onSelectSearchItem = (placeId: string | number) => {
        PlacesService.getPlaceDetails(placeId.toString()).then((response: PlaceDetailsResponseSettings) => {
            const placeDetailsResponse = new PlaceDetailsResponse(response);
            if (placeDetailsResponse.Status === 'OK') {
                const addressComponents = placeDetailsResponse.Result.addressComponents;
                const parsedResult = parseAddressComponents(addressComponents);
                const updatedNameAddressDetails = { ...nameAddressDetails, ...parsedResult };
                updateNameAddressDetails(updatedNameAddressDetails);
            }
            setCurrentStep('form');
        });
    };

    const parseAddressComponents = (components: AddressComponent[]) => {
        const streetLine1 =
            findComponentByType(components, [PlacesComponentTypes.StreetAddress, PlacesComponentTypes.StreetNumber])
                ?.LongName ?? '';
        const streetLine2 = findComponentByType(components, [PlacesComponentTypes.Route])?.LongName ?? '';
        const town =
            findComponentByType(components, [PlacesComponentTypes.PostalTown, PlacesComponentTypes.Locality])
                ?.LongName ?? '';
        const postcode = findComponentByType(components, [PlacesComponentTypes.PostalCode])?.LongName ?? '';
        const countryComponent = findComponentByType(components, [PlacesComponentTypes.Country]);
        const country = getCountryCodeByName(countryComponent?.LongName ?? '')?.Name ?? '';

        return { streetLine1, streetLine2, town, postcode, country };
    };

    const findComponentByType = (addressComponents: AddressComponent[], typeToFind: PlacesComponentTypes[]) => {
        return addressComponents.find((component) =>
            component.Types.find((type) => typeToFind.find((findType) => findType === type))
        );
    };

    const getCountryCodeByName = (countryName: string) => {
        // Handle mismatches from google api and our db
        if (countryName === 'United States') {
            countryName = 'United States of America';
        }
        // Find country
        return countryCodes?.find((c) => c.Name === countryName);
    };

    const getCountryCodeOptions = (): Array<any> => {
        return countryCodes
            ? countryCodes.map((countryCode) => {
                  if (countryCode) {
                      return new Option(countryCode.Name, countryCode.Id.toString());
                  } else {
                      return null;
                  }
              })
            : [];
    };

    const getFormattedAddress = (): string => {
        return `${nameAddressDetails.streetLine1} ${nameAddressDetails.streetLine2}, ${nameAddressDetails.town} ${nameAddressDetails.postcode}, ${nameAddressDetails.country}`;
    };

    const showInfoBar = (id: string, type: 'success' | 'danger') => {
        setInfoBars((prevState: InfoBarProps[]) => [
            ...prevState,
            {
                id: infoBars.length > 0 ? infoBars[infoBars.length - 1].id + 1 : 0,
                message: intl.formatMessage({ id }),
                type: type,
                onDismiss: () => {},
            },
        ]);
    };

    const onVerifyClicked = (): void => {
        const formattedAddress = getFormattedAddress();
        PlacesService.getSearchResults(formattedAddress).then((response: PlaceSearchResponseSettings) => {
            if (response && response.Status === 'OK' && response.Places.length > 0) {
                const location = new Location(response.Places[0].Geometry.Location);
                onLatLngChange(location);
                setCurrentStep('map');
            } else {
                showInfoBar('OnboardingWizard.LocationNotFound', 'danger');
            }
        });
    };

    const getSearchField = () => {
        return (
            <div
                className={`location-page-search-form-wrapper ${isMobileView ? 'mobile' : 'desktop'}`}
                ref={searchFormRef}
            >
                <FormGroup>
                    <SkeletonLabel isLoading={isLoading || isUpdating}>
                        <FormattedMessage id={'OnboardingWizard.LocationSearchLabel'} />
                    </SkeletonLabel>
                    <TypeaheadWrapper
                        typeaheadItemSelected={onSelectSearchItem}
                        requestFunctionAsync={makeSearchRequest}
                        processResultsFunction={processSearchResponse}
                        allowAddNew
                        addNewTitle={intl.formatMessage({ id: 'OnboardingWizard.CreateLocationButton' })}
                        addNewFunction={(searchTerm: string) => setCurrentStep('form')}
                        addNewAlign={'bottom'}
                        placeholder={intl.formatMessage({ id: 'OnboardingWizard.LocationSearchPlaceholder' })}
                        onFocus={scrollToSearch}
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
            </div>
        );
    };

    const getAddressFields = () => {
        return (
            <div className={`location-page-address-form-wrapper ${isMobileView ? 'mobile' : 'desktop'}`}>
                <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} isOnboarding />
                <FormGroup>
                    <SkeletonLabel for="addressLine1" id="addressLine1" isLoading={isLoading || isUpdating}>
                        <FormattedMessage id="OnboardingWizard.AddressLineOneLabel" />
                    </SkeletonLabel>
                    <ValidationInput
                        testId="addressLine1"
                        type="text"
                        name="addressLine1"
                        value={nameAddressDetails.streetLine1}
                        onChange={onAddressLine1Change}
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'OnboardingWizard.AddressLineOneRequiredWarningLabel' }),
                        })}
                        errors={formValidation.errors}
                        maxLength={50}
                        ariaRequired
                        aria-labelledby={'addressLine1'}
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
                <FormGroup>
                    <SkeletonLabel for="addressLine2" id="addressLine2" isLoading={isLoading || isUpdating}>
                        <FormattedMessage id="OnboardingWizard.AddressLineTwoLabel" />
                    </SkeletonLabel>
                    <ValidationInput
                        type="text"
                        name="addressLine2"
                        value={nameAddressDetails.streetLine2}
                        onChange={onAddressLine2Change}
                        maxLength={50}
                        aria-labelledby={'addressLine2'}
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
                <FormGroup>
                    <SkeletonLabel for="townOrCity" id="city" isLoading={isLoading || isUpdating}>
                        <FormattedMessage id="OnboardingWizard.TownOrCityLabel" />
                    </SkeletonLabel>
                    <ValidationInput
                        testId="townOrCity"
                        type="text"
                        name="townOrCity"
                        value={nameAddressDetails.town}
                        onChange={onTownOrCityChange}
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'OnboardingWizard.TownOrCityRequiredWarningLabel' }),
                        })}
                        errors={formValidation.errors}
                        maxLength={50}
                        ariaRequired
                        aria-labelledby="townOrCity"
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
                <FormGroup>
                    <SkeletonLabel for="country" id="country" isLoading={isLoading || isUpdating}>
                        <FormattedMessage id="OnboardingWizard.CountryLabel" />
                    </SkeletonLabel>
                    <ResDiaryDropdown
                        onValueChange={onCountryChange}
                        selectedValue={selectedCountryId}
                        options={getCountryCodeOptions()}
                        isSearchable
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
                <FormGroup>
                    <SkeletonLabel for="postcode" id="postcode" isLoading={isLoading || isUpdating}>
                        <FormattedMessage id="OnboardingWizard.PostcodeLabel" />
                    </SkeletonLabel>
                    <ValidationInput
                        testId="postcode"
                        type="text"
                        name="postcode"
                        value={nameAddressDetails.postcode}
                        onChange={onPostcodeChange}
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'OnboardingWizard.PostcodeRequiredWarningLabel' }),
                        })}
                        errors={formValidation.errors}
                        maxLength={20}
                        ariaRequired
                        aria-labelledby="postcode"
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
                <FormGroup>
                    <ValidationButton
                        name={'hiddenvalidator'}
                        id={`OnboardingWizard.VerifyLocationButton`}
                        className={`btn btn-outline-primary verify`}
                        errorMessage={intl.formatMessage({ id: 'OnboardingWizard.VerifyLocationValidationMessage' })}
                        disabled={!canVerifyLocation}
                        onClick={onVerifyClicked}
                        errors={canVerifyLocation ? formValidation.errors : []}
                    />
                </FormGroup>
            </div>
        );
    };

    const getMapContainer = () => {
        return (
            <div className={`location-page-map-wrapper ${isMobileView ? 'mobile' : 'desktop'}`}>
                <MapContainer
                    latitude={nameAddressDetails.latitude}
                    longitude={nameAddressDetails.longitude}
                    onCloseClick={() => setCurrentStep('form')}
                    showInfoBox={true}
                    infoWindowContent={getFormattedAddress()}
                    showMarker={true}
                    onDragEnd={onLatLngChange}
                />
            </div>
        );
    };

    const getPageValidator = () => {
        return (
            <FormGroup hidden>
                <ValidationInput
                    name="hiddenvalidator"
                    type="text"
                    value={locationVerified}
                    onChange={() => {}}
                    innerRef={formValidation.register({
                        required: true,
                        validate: () => locationVerified,
                    })}
                    errors={formValidation.errors}
                />
            </FormGroup>
        );
    };

    const getFormBody = () => {
        return (
            <Fragment>
                {currentStep === 'search' && getSearchField()}
                {currentStep === 'form' && getAddressFields()}
                {currentStep === 'map' && getMapContainer()}
                {getPageValidator()}
            </Fragment>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.LocationPageInfoPanelTitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.locationPage}
            isUpdating={false}
            children={getFormBody()}
            formValidation={formValidation}
            updateSettings={updateSettings}
            shouldExcludeFormPadding
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfVenueAddressPage}
        />
    );
};

export default LocationPage;
