export type AddOnFeatureSettings = {
    logo: JSX.Element;
    title: string;
    description: string;
};

class AddOnFeature {
    logo: JSX.Element;
    title: string;
    shortDescription: string;

    constructor(addOnFeatureSettings: AddOnFeatureSettings) {
        this.logo = addOnFeatureSettings.logo;
        this.title = addOnFeatureSettings.title;
        this.shortDescription = addOnFeatureSettings.description;
    }
}

export default AddOnFeature;
