import React from 'react';
import { ReactComponent as InfoIcon } from '../../../content/icons/Icon-Info.svg';
import { useIntl } from 'react-intl';

const ServicesNote = () => {
    const intl = useIntl();

    return (
        <div className="services-note">
            <InfoIcon />
            <header>{intl.formatMessage({ id: 'OnboardingWizard.VenueServicesPageOverlappingHeader' })}</header>
            <p>{intl.formatMessage({ id: 'OnboardingWizard.VenueServicesPageOverlappingNote' })}</p>
        </div>
    );
};

export default ServicesNote;
