import { useIntl } from 'react-intl';

export type TrainingWidgetBookingFailureProps = {
    supportEmail: string;
};

export default function TrainingWidgetBookingFailure({ supportEmail }: TrainingWidgetBookingFailureProps) {
    const intl = useIntl();

    return (
        <div className="booking-response-container">
            <div className="title">{intl.formatMessage({ id: 'TrainingWidget.Error' })}</div>
            <div>{intl.formatMessage({ id: 'TrainingWidget.ProblemBookingTrainingSessionMessage' })}</div>
            <div>
                {intl.formatMessage(
                    { id: `TrainingWidget.IfProblemPersistsMessage` },
                    {
                        contactUs: (
                            <a className="contact-us ml-1" href={`mailto:${supportEmail}`}>
                                {supportEmail}
                            </a>
                        ),
                    }
                )}
            </div>
        </div>
    );
}
