import React, { useState, useRef, Fragment } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../content/icons/search.svg';
import { FormattedMessage } from 'react-intl';
import ValidationInput from './ValidationInput';
import Skeleton from 'react-loading-skeleton';

export type TypeaheadProps = {
    setSelectedResult: (id: string | number) => void;
    setSearchTerm: (searchTerm: string) => void;
    results: Array<{ id: string | number; display: string; alternateDisplay?: string }>;
    isSearching: boolean;
    searchTerm: string;
    addNew?: boolean;
    addNewFunction?: (searchTerm: string) => void; // propTypeExtensions.requiredIf(PropTypes.func, (props) => props.addNew),
    addNewTitle?: string; // propTypeExtensions.requiredIf(PropTypes.string, (props) => props.addNew),
    addNewAlign?: 'top' | 'bottom';
    disabled?: boolean;
    useDangerouslySetHTML?: boolean;
    autoFocus?: boolean;
    includeValidation?: boolean;
    registerValidation?: any;
    errors?: { [key: string]: any };
    placeholder?: string;
    validationMessage?: string;
    validationName?: string;
    onFocus?: Function;
    isLoading?: boolean;
};

function Typeahead({
    results,
    setSelectedResult,
    searchTerm,
    setSearchTerm,
    isSearching,
    addNew,
    addNewFunction,
    addNewTitle,
    addNewAlign = 'top',
    disabled,
    useDangerouslySetHTML,
    autoFocus,
    includeValidation,
    registerValidation,
    errors,
    placeholder,
    validationName,
    validationMessage,
    onFocus,
    isLoading,
}: TypeaheadProps) {
    const [displayResults, setDisplayResults] = useState(false);

    const typeaheadInput = useRef<HTMLInputElement>(null);

    const handleSearchTermChange = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        if (searchTerm.length >= 3) {
            setDisplayResults(true);
        } else {
            setDisplayResults(false);
        }
    };

    const handleResultSelected = (id: string | number, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();
        setDisplayResults(false);
        setSelectedResult(id);
    };

    let getResults = () => {
        if (results.length > 0) {
            return results.map((result, index) => {
                return (
                    <li
                        onMouseDown={(event) => handleResultSelected(result.id, event)}
                        key={result.id}
                        data-testid={`typeahead-list-option-${index}`}
                    >
                        {!useDangerouslySetHTML && (
                            <div data-testid={`typeahead-list-option-${index}-display-value`}>{result.display}</div>
                        )}
                        {useDangerouslySetHTML && (
                            <div
                                data-testid={`typeahead-list-option-${index}-display-value`}
                                dangerouslySetInnerHTML={{ __html: `<div>${result.display}</div>` }}
                            ></div>
                        )}
                        <div data-testid={`typeahead-list-option-${index}-alt-display-value`}>
                            {result.alternateDisplay}
                        </div>
                    </li>
                );
            });
        }
        return (
            <div data-testid="typeahead-list-no-results" className="no-results">
                <FormattedMessage id={isSearching ? 'Common.Searching' : 'Common.NoResults'} />
            </div>
        );
    };

    let getAddNew = () => {
        return (
            <li className="add-new" onMouseDown={() => addNewFunction != null && addNewFunction(searchTerm)}>
                {addNewTitle}
            </li>
        );
    };

    const isInvalid = includeValidation && errors && validationName ? errors[validationName] : false;
    return (
        <Fragment>
            {isLoading ? (
                <Skeleton style={{ lineHeight: 3 }} />
            ) : (
                <InputGroup
                    className={
                        isInvalid
                            ? `input-group-with-addon is-invalid search-dropdown-addon`
                            : `input-group-with-addon search-dropdown-addon`
                    }
                >
                    {
                        <InputGroupAddon
                            addonType="prepend"
                            onClick={() => typeaheadInput.current != null && typeaheadInput.current.focus()}
                        >
                            <InputGroupText>
                                <SearchIcon />
                            </InputGroupText>
                        </InputGroupAddon>
                    }
                    {includeValidation && validationName && (
                        <ValidationInput
                            placeholder={placeholder}
                            type="search"
                            value={searchTerm}
                            onChange={(e) => handleSearchTermChange(e.target.value)}
                            innerRef={registerValidation({
                                required: validationMessage,
                            })}
                            name={validationName}
                            errors={errors}
                            testId="typeahead-input"
                            autoFocus={autoFocus}
                        />
                    )}
                    {!includeValidation && (
                        <Input
                            placeholder={placeholder}
                            type="search"
                            value={searchTerm}
                            onChange={(e) => handleSearchTermChange(e.target.value)}
                            onBlur={() => {
                                setDisplayResults(false);
                            }}
                            onFocus={() => {
                                if (searchTerm.length >= 3) {
                                    setDisplayResults(true);
                                }
                                onFocus && onFocus();
                            }}
                            innerRef={typeaheadInput}
                            data-testid="typeahead-input"
                            disabled={disabled}
                            autoFocus={autoFocus}
                        />
                    )}
                    {displayResults && (
                        <ul data-testid="typeahead-list" className="search-dropdown-results">
                            {addNew && addNewAlign === 'top' && getAddNew()}
                            {getResults()}
                            {addNew && addNewAlign === 'bottom' && getAddNew()}
                        </ul>
                    )}
                </InputGroup>
            )}
        </Fragment>
    );
}

export default Typeahead;
