import React from 'react';

export type ClickableTextProps = {
    text: string;
    redirectUrl: string;
    className?: string;
    openInNewTab?: boolean;
};

const ClickableText = ({ text, redirectUrl, className, openInNewTab }: ClickableTextProps) => {
    return (
        <a
            className={className ? 'clickable clickable-text ' + className : 'clickable clickable-text'}
            href={redirectUrl}
            target={openInNewTab ? '_blank' : '_self'}
            rel="noreferrer"
        >
            {text}
        </a>
    );
};

export default ClickableText;
