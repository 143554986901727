const trackingEvents = {
    rdfAccountCreationPage: 'RDF Account Creation Page',
    rdfAcctCreationChecklistPage: 'RDF AcctCreation Checklist Page',
    rdfActivateDcPage: 'RDF Activate DC Page',
    rdfAreaManagementPage: 'RDF Area Management Page',
    rdfCuisinesPage: 'RDF Cuisines Page',
    rdfGetStartedPage: 'RDF Get Started Page',
    rdfFinalSetupPage: 'RDF Final Setup Page',
    rdfIntervalCoversPage: 'RDF Interval Covers Page',
    rdfVenueAddressPage: 'RDF Venue Address Page',
    rdfDCImagePage: 'RDF DC Image Page',
    rdfServiceHoursPage: 'RDF Service Hours Page',
    rdfTableManagementPage: 'RDF Table Management Page',
    rdfTrainingWidgetPage: 'RDF Training Widget Page',
    rdfVenueDescriptionPage: 'RDF Venue Description Page',
    rdfVenueLogoPage: 'RDF Venue Logo Page',
    rdfWelcomeSetupPage: 'RDF Welcome Setup Page',
    selfOnboardingStarted: 'Self-Onboarding Started',
    selfOnboardingComplete: 'Self-Onboarding Complete',
    trainingSkipped: 'Training Skipped',
    trainingBooked: 'Training Booked',
    logoSkipped: 'Logo Skipped',
    mainImageSkipped: 'Main Image Skipped',
    dishcultListingActivate: 'Dishcult Listing Activated',
    dishcultListingLater: 'Dishcult Listing Later',
    selfServiceSubscriptionAccountCreated: 'Account Created',
    selfServiceSubscriptionCheckoutStarted: 'Checkout Started',
    selfServiceSubscriptionCheckoutComplete: 'Checkout Complete',
};

export default trackingEvents;
