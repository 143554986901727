import React, { useEffect, useState } from 'react';
import IntervalOption from '../../../domainObjects/IntervalOption';
import TableCovers from '../Tables/TableCovers';
import IntervalOptions from './IntervalOptions';
import MaxPerInterval from './MaxPerInterval';
import { Input, Label } from 'reactstrap';
import SaveCancelButtons from '../../common/SaveCancelButtons';
import { useIntl } from 'react-intl';
import RestaurantService from '../../../domainObjects/RestaurantService';
import AverageEatDuration from '../../../domainObjects/AverageEatDuration';
import OnboardingModal from '../../common/OnboardingModal';
import AddToAllServicesModal from '../Services/AddToAllServicesModal';
import ValidationMessage from '../../common/ValidationMessage';
import useScreenSize from '../../../hooks/useScreenSize';

type AddCoversAndIntervalsProps = {
    intervalOptions: Array<IntervalOption>;
    currentInterval: number;
    maxPerInterval: number;
    service: RestaurantService;
    setService: (service: RestaurantService) => void;
    setCurrentService: (id: number) => void;
    updateService: (id: number, isCovers: boolean, applyToAll: boolean) => void;
    hasExistingSetup: boolean;
    setHasExistingSetup: (hasExistingSetup: boolean) => void;
    isUpdating: boolean;
    formValidation: any;
    setFormValidationValue: (name: string, value: any) => void;
};

const AddCoversAndIntervals = ({
    intervalOptions,
    currentInterval,
    maxPerInterval,
    service,
    setService,
    setCurrentService,
    updateService,
    hasExistingSetup,
    setHasExistingSetup,
    isUpdating,
    formValidation,
    setFormValidationValue,
}: AddCoversAndIntervalsProps) => {
    const [applyToAllServices, setApplyToAllServices] = useState(false);
    const [isCovers, setIsCovers] = useState(service.maxCovers !== 0 || service.maxCovers === service.maxBookings);
    const [initialised, setInitialised] = useState(false);
    const [originalService, setOriginalService] = useState<RestaurantService | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const intl = useIntl();
    const { isMobileView, isTabletView } = useScreenSize();

    const setMaxPerInterval = (maxPerInterval: number) => {
        if (isCovers) {
            setService({ ...service, maxCovers: maxPerInterval, maxBookings: 0 });
        }
        if (!isCovers) {
            setService({ ...service, maxBookings: maxPerInterval, maxCovers: 0 });
        }
    };

    const setSelectedIntervalOption = (intervalOption: IntervalOption) => {
        setService({ ...service, timeSlotInterval: intervalOption.timeInMinutes });
    };

    const setAverageEatDuration = (averageEatDuration: AverageEatDuration) => {
        let newAverageEatDurations = [
            ...service.averageEatDurations.filter((x) => x.id !== averageEatDuration.id),
            averageEatDuration,
        ];
        service.averageEatDurations = newAverageEatDurations.sort((a, b) => {
            if (a.coversFrom < b.coversFrom) {
                return -1;
            }
            if (a.coversFrom > b.coversFrom) {
                return 1;
            }
            return 0;
        });
        setService(service);
    };

    const getIntervalOptionsValidationMessage = () => {
        if (formValidation.errorCount > 0) {
            let error = '';
            if (formValidation.errors['intervalOption']) {
                error = 'intervalOption';
            }

            if (error) {
                return <ValidationMessage isBlock={false} message={formValidation.errors[error].message} />;
            }
        }
        return null;
    };

    const getMaxIntervalValidationMessage = () => {
        if (formValidation.errorCount > 0) {
            let error = '';
            if (formValidation.errors['maxPerInterval']) {
                error = 'maxPerInterval';
            }

            if (error) {
                return <ValidationMessage isBlock={false} message={formValidation.errors[error].message} />;
            }
        }
        return null;
    };

    useEffect(() => {
        setMaxPerInterval(maxPerInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCovers]);

    useEffect(() => {
        if (!initialised) {
            setOriginalService(service);
            formValidation.register(
                { name: 'intervalOption' },
                {
                    validate: (value: number) => {
                        return value > 0
                            ? true
                            : intl.formatMessage({
                                  id: 'OnboardingWizard.VenueCoversAndIntervalsIntervalOptionError',
                              });
                    },
                }
            );
            setFormValidationValue('intervalOption', service.timeSlotInterval);
            formValidation.register(
                { name: 'maxPerInterval' },
                {
                    validate: (value: number) => {
                        return value > 0 && value <= 999
                            ? true
                            : intl.formatMessage({
                                  id: 'OnboardingWizard.VenueCoversAndIntervalsMaxPerIntervalError',
                              });
                    },
                }
            );
            setFormValidationValue('maxPerInterval', service.maxCovers !== 0 ? service.maxCovers : service.maxBookings);
            setInitialised(true);
        }
    }, [service, initialised, formValidation, setFormValidationValue, intl]);

    return (
        <div className={`add-covers-and-intervals-container ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <Label className="add-covers-and-intervals-header">{service.name}</Label>
            <div className="add-covers-and-intervals-intervals-container">
                <IntervalOptions
                    intervalOptions={intervalOptions}
                    selectedIntervalOption={currentInterval}
                    setSelectedIntervalOption={setSelectedIntervalOption}
                    setFormValidationValue={setFormValidationValue}
                    getIntervalOptionsValidationMessage={getIntervalOptionsValidationMessage}
                />
                <MaxPerInterval
                    maxPerInterval={maxPerInterval}
                    isCovers={isCovers}
                    setMaxPerInterval={setMaxPerInterval}
                    setIsCovers={setIsCovers}
                    setFormValidationValue={setFormValidationValue}
                    getMaxIntervalValidationMessage={getMaxIntervalValidationMessage}
                />
            </div>
            <TableCovers
                averageEatDurations={service.averageEatDurations}
                setAverageEatDuration={setAverageEatDuration}
                formValidation={formValidation}
                setFormValidationValue={setFormValidationValue}
            />
            <div className="add-covers-and-intervals-apply-all-container">
                <Label>{intl.formatMessage({ id: 'OnboardingWizard.VenueCoversAndIntervalsPageApplyAllLabel' })}</Label>
                <div className="add-covers-and-intervals-radio-button-container d-flex">
                    <Label check className="add-covers-and-intervals-radio-button d-flex align-items-center">
                        <Input
                            type="radio"
                            name="yes"
                            checked={applyToAllServices}
                            onChange={() => setApplyToAllServices(true)}
                        />
                        {intl.formatMessage({ id: 'Common.Yes' })}
                    </Label>
                    <Label check className="add-covers-and-intervals-radio-button d-flex align-items-center">
                        <Input
                            type="radio"
                            name="no"
                            checked={!applyToAllServices}
                            onChange={() => setApplyToAllServices(false)}
                        />
                        {intl.formatMessage({ id: 'Common.No' })}
                    </Label>
                </div>
            </div>
            <div className="add-covers-and-intervals-controls-container d-flex justify-content-end">
                <SaveCancelButtons
                    onCancel={() => {
                        setCurrentService(0);
                        if (originalService != null) {
                            setService(originalService);
                        }
                    }}
                    onSave={() => {
                        if (applyToAllServices && hasExistingSetup) {
                            setIsModalOpen(true);
                        } else {
                            updateService(service.id, isCovers, applyToAllServices);
                        }
                        setHasExistingSetup(true);
                    }}
                    saveDisabled={isUpdating || formValidation.errorCount > 0}
                />
            </div>
            <OnboardingModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                body={
                    <AddToAllServicesModal
                        id={service.id}
                        isCovers={isCovers}
                        applyToAllServices={applyToAllServices}
                        isUpdating={isUpdating}
                        setIsModalOpen={setIsModalOpen}
                        updateService={updateService}
                    />
                }
                nonDismissible
                hideLayout
            />
        </div>
    );
};

export default AddCoversAndIntervals;
