import ApiHelper from '../helpers/ApiHelper';
import { UserAccount } from '../domainObjects/UserAccount';

class AccountSubscriptionService {
    static createUserAccount(account: UserAccount) {
        return ApiHelper.postJSON(`/api/AccountSubscription/CreateUserAccount`, JSON.stringify(account)).then(
            (response) => response.data
        );
    }

    static usernameIsValid(username: string) {
        return ApiHelper.get(`/api/AccountSubscription/UsernameIsValid?username=${encodeURIComponent(username)}`).then(
            (response) => response.data
        );
    }

    static checkoutSubscription(account: UserAccount) {
        return ApiHelper.postJSON(`/api/AccountSubscription/checkoutSubscription`, JSON.stringify(account)).then(
            (response) => response.data
        );
    }

    static getAccountInfoByJWTToken(jwtToken: string) {
        return ApiHelper.get(`/api/AccountSubscription/GetAccountInfo?token=${jwtToken}`).then(
            (response) => response.data
        );
    }
}

export default AccountSubscriptionService;
