export type LocationSettings = {
    Lat: number;
    Lng: number;
};

class Location {
    lat: number;
    lng: number;

    constructor(locationSettings: LocationSettings | null) {
        this.lat = locationSettings?.Lat ? locationSettings.Lat : 0;
        this.lng = locationSettings?.Lng ? locationSettings.Lng : 0;
    }
}

export default Location;
