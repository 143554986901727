import React, { ChangeEventHandler, Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import ValidationMessage from './ValidationMessage';

export type ValidationInputProps = {
    className?: string;
    onInput?: ChangeEventHandler<any>;
    onChange: ChangeEventHandler<any>;
    value: any;
    disabled?: boolean;
    testId?: string;
    innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    errors?: { [key: string]: any };
    name: string;
    type?: InputType;
    placeholder?: string;
    rows?: string;
    maxLength?: number;
    autoFocus?: boolean;
    displayCharacterCount?: boolean;
    hideValidationMessage?: boolean;
    min?: string;
    max?: string;
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
    ariaLabelledBy?: string;
    ariaRequired?: boolean | 'true' | 'false';
    hideValidationIcon?: boolean;
    isLoading?: boolean;
};

const ValidationInput = ({
    className,
    value,
    onChange,
    onInput,
    disabled,
    testId,
    innerRef,
    name,
    errors,
    type,
    placeholder,
    rows,
    maxLength,
    autoFocus,
    displayCharacterCount,
    hideValidationMessage,
    min,
    max,
    onKeyUp,
    ariaLabelledBy,
    ariaRequired,
    hideValidationIcon,
    isLoading,
}: ValidationInputProps) => {
    const getClassNamesString = () => {
        if (errors && errors[name] && !disabled) {
            const classes = ['is-invalid'];
            if (hideValidationIcon) {
                classes.push('hide-icon');
            }
            if (className) {
                classes.push(className);
            }
            return classes.join(' ');
        } else {
            if (className) return className;
            else return undefined;
        }
    };

    const getInputLengthClass = () => {
        return value.length === maxLength ? 'validation-input-count warning' : 'validation-input-count';
    };

    const onChangeInternal = (event: { target: { value: any } }) => {
        onChange(event.target.value);
    };

    return (
        <Fragment>
            {isLoading ? (
                <Skeleton style={{ lineHeight: parseInt(rows ?? '2') * 1.5 }} />
            ) : (
                <Input
                    className={getClassNamesString()}
                    defaultValue={value}
                    onInput={onInput}
                    onChange={onChangeInternal}
                    data-testid={testId}
                    innerRef={!disabled ? innerRef : null}
                    name={name}
                    type={type || 'text'}
                    placeholder={placeholder}
                    rows={rows}
                    maxLength={maxLength}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    min={min}
                    max={max}
                    onKeyUp={onKeyUp}
                    aria-labelledby={ariaLabelledBy}
                    aria-required={ariaRequired}
                />
            )}
            {!hideValidationMessage && (
                <div className="validation-input-feedback">
                    {errors && errors[name] && <ValidationMessage isBlock={false} message={errors[name].message} />}
                    {displayCharacterCount && (
                        <div className={getInputLengthClass()}>
                            {value.length}/{maxLength}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default ValidationInput;
