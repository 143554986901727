import React, { useState, useEffect, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import ResDiaryDropdown from '../common/ResDiaryDropdown';
import TrainingWidgetService from '../../services/TrainingWidgetService';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, setHours, setMinutes, format, isSameDay, endOfMonth, isSameMonth } from 'date-fns';
import AvailabilitySearchResult from '../../domainObjects/AvailabilitySearchResult';
import AvailableTime, { AvailableTimeSettings } from '../../domainObjects/AvailableTime';
import AvailableDate from '../../domainObjects/AvailableDate';
import { formatInTimeZone } from 'date-fns-tz';

export type TrainingWidgetDatePickerProps = {
    selectedDate: Date;
    selectedTimeSlot?: string;
    providerTimeZone?: string;
    providerIso2CountryCode?: string;
    setSelectedTimeSlot: Function;
    setSelectedDate: Function;
};

export default function TrainingWidgetDatePicker({
    selectedDate,
    selectedTimeSlot,
    setSelectedTimeSlot,
    setSelectedDate,
    providerTimeZone,
    providerIso2CountryCode,
}: TrainingWidgetDatePickerProps) {
    const [availability, setAvailability] = useState<AvailabilitySearchResult>();
    const [timeSlots, setTimeSlots] = useState<Array<Date>>();
    const numberOfDaysToFetchAvailabilityForInNextMonth = 6;

    useEffect(() => {
        // Gets initial block of availability and sets today's timeslot options.
        if (providerIso2CountryCode) {
            TrainingWidgetService.getAvailabilityForDateRange({
                DateFrom: new Date(),
                DateTo: addDays(endOfMonth(new Date()), numberOfDaysToFetchAvailabilityForInNextMonth),
                PartySize: 1,
                Iso2CountryCode: providerIso2CountryCode,
            }).then((availabilityResult) => {
                if (availabilityResult && availabilityResult.AvailableDates) {
                    const todaysAvailability = availabilityResult.AvailableDates[0];
                    setTimeSlots(
                        todaysAvailability.AvailableTimes.map((at: AvailableTimeSettings) => {
                            const splitTimeSlot = at.TimeSlot.split(':');
                            return setHours(
                                setMinutes(new Date(todaysAvailability.Date), parseInt(splitTimeSlot[1])),
                                parseInt(splitTimeSlot[0])
                            );
                        })
                    );
                    setAvailability(new AvailabilitySearchResult(availabilityResult));
                }
            });
        }
    }, [providerIso2CountryCode]);

    useEffect(() => {
        // Updates time dropdown with selected dates timeSlots when date changes.
        if (availability) {
            const dayAvailability = availability.availableDates.find((a: AvailableDate) =>
                isSameDay(a.date, selectedDate)
            );
            if (dayAvailability) {
                setTimeSlots(
                    dayAvailability.availableTimes.map((at: AvailableTime) => {
                        const splitTimeSlot = at.timeSlot.split(':');
                        return setHours(
                            setMinutes(new Date(dayAvailability.date), parseInt(splitTimeSlot[1])),
                            parseInt(splitTimeSlot[0])
                        );
                    })
                );
            }
        }
    }, [selectedDate, availability]);

    useEffect(() => {
        if (timeSlots && timeSlots.length > 0) {
            setSelectedTimeSlot(format(timeSlots[0], 'HH:mm'));
        }
    }, [timeSlots, setSelectedTimeSlot]);

    function getIncludedDates() {
        if (availability) {
            return availability.availableDates.map((ad: AvailableDate) => ad.date);
        }
        return [];
    }

    const getTimeSlotOptions = (): Array<HTMLOptionElement> => {
        return timeSlots && timeSlots.length > 0 && providerTimeZone
            ? timeSlots.map((timeSlot) => {
                  const localisedTmeSlot = formatInTimeZone(timeSlot, providerTimeZone, 'HH:mm');
                  return new Option(localisedTmeSlot, format(timeSlot, 'HH:mm'));
              })
            : [];
    };

    function handleOnMonthChange(date: Date) {
        const endOfMonthDate = endOfMonth(date);
        // Only fetch more availability if we haven't fetched for that month already.
        if (availability && !availability.availableDates.some((ad) => isSameDay(endOfMonthDate, ad.date) as any)) {
            const availabilityStartDate: AvailableDate =
                availability.availableDates[availability.availableDates.length - 1];
            TrainingWidgetService.getAvailabilityForDateRange({
                DateFrom: addDays(availabilityStartDate.date, 1),
                DateTo: addDays(endOfMonthDate, numberOfDaysToFetchAvailabilityForInNextMonth),
                PartySize: 1,
                Iso2CountryCode: providerIso2CountryCode,
            }).then((availabilityResult) => {
                const availCopy = { ...availability };
                const typedAvailabilityResult = new AvailabilitySearchResult(availabilityResult);
                availCopy.availableDates = availCopy.availableDates.concat(typedAvailabilityResult.availableDates);
                setAvailability(availCopy);
            });
        }
    }

    function handleOnDateChange(date: Date) {
        // This handles selecting a date from the preview of the next month, as we wont have fetched availability barring the first 6 days of the month.
        if (!isSameMonth(date, selectedDate)) {
            handleOnMonthChange(date);
        }
        setSelectedDate(date);
    }

    return (
        <Fragment>
            <DatePicker
                selected={selectedDate}
                inline
                onChange={(date: Date) => handleOnDateChange(date)}
                minDate={new Date()} // exclude all dates in the past
                includeDates={getIncludedDates()}
                onMonthChange={(month) => handleOnMonthChange(month)}
            />
            <ResDiaryDropdown
                options={getTimeSlotOptions()}
                onValueChange={(e: string) => setSelectedTimeSlot(e)}
                selectedValue={selectedTimeSlot}
                hideRemoveItemIcon
                name={'timeslotDropdown'}
            />
        </Fragment>
    );
}
