import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, UncontrolledTooltip } from 'reactstrap';
import RestaurantService from '../../../domainObjects/RestaurantService';
import { ReactComponent as EditIcon } from '../../../content/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../content/icons/delete.svg';
import OnboardingModal from '../../common/OnboardingModal';
import DeleteServiceSetupModal from '../Services/DeleteServiceSetupModal';
import useScreenSize from '../../../hooks/useScreenSize';

type CoversAndIntervalsItemProps = {
    service: RestaurantService;
    setCurrentService: (id: number) => void;
    isUpdating: boolean;
    deleteServiceSetup: (id: number) => void;
};

const CoversAndIntervalsItem = ({
    service,
    setCurrentService,
    isUpdating,
    deleteServiceSetup,
}: CoversAndIntervalsItemProps) => {
    const intl = useIntl();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isMobileView, isTabletView } = useScreenSize();
    return (
        <div className={`covers-and-intervals-item  ${isMobileView || isTabletView ? 'mobile' : ''}`}>
            <div className="covers-and-intervals-cell">{service.name.toString()}</div>
            <div className="covers-and-intervals-cell">
                {service.timeSlotInterval} {intl.formatMessage({ id: 'Common.Mins' })}
            </div>
            <div className="covers-and-intervals-cell">
                {service.maxCovers !== 0
                    ? `${service.maxCovers} ${intl.formatMessage({ id: 'Common.Covers' })}`
                    : `${service.maxBookings} ${intl.formatMessage({
                          id: 'Common.Reservations',
                      })}`}
            </div>
            <div className="covers-and-intervals-cell covers-and-intervals-button-wrapper">
                <Button id={`EditCoversAndIntervals-${service.id}`} onClick={() => setCurrentService(service.id)}>
                    <EditIcon />
                </Button>
                <UncontrolledTooltip flip target={`EditCoversAndIntervals-${service.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Edit',
                    })}
                </UncontrolledTooltip>
                <Button
                    id={`DeleteCoversAndIntervals-${service.id}`}
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    <DeleteIcon />
                </Button>
                <UncontrolledTooltip flip target={`DeleteCoversAndIntervals-${service.id}`}>
                    {intl.formatMessage({
                        id: 'Common.Delete',
                    })}
                </UncontrolledTooltip>
            </div>
            <OnboardingModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                body={
                    <DeleteServiceSetupModal
                        isUpdating={isUpdating}
                        id={service.id}
                        serviceName={service.name}
                        setIsModalOpen={setIsModalOpen}
                        deleteServiceSetup={deleteServiceSetup}
                    />
                }
                nonDismissible
                hideLayout
            />
        </div>
    );
};

export default CoversAndIntervalsItem;
