import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class RestaurantService {
    static getRestaurantSetup() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}`
        ).then((response: { data: any }) => response.data);
    }

    static getSystemTimeZones() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/GetTimeZones`
        ).then((response: { data: any }) => response.data);
    }
}

export default RestaurantService;
