import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import RestaurantInformation from '../../domainObjects/RestaurantInformation';
import ProviderDetails, { ProviderDetailsSettings } from '../../domainObjects/ProviderDetails';
import CuisinesPage from './Cuisines/CuisinesPage';
import GetStartedPage from './GetStarted/GetStartedPage';
import LocationPage from './Location/LocationPage';
import DescriptionPage from './Description/DescriptionPage';
import SettingsService from '../../services/SettingsService';
import SettingsHelper from '../../helpers/SettingsHelper';
import NameAddressDetails from '../../domainObjects/NameAddressDetails';
import SearchDetails from '../../domainObjects/SearchDetails';
import WelcomePage from './Welcome/WelcomePage';
import ChecklistPage from './Checklist/ChecklistPage';
import DishCultActivationPage from './DishCultActivation/DishCultActivationPage';
import LogoPage from './Logo/LogoPage';
import VenueImage, { Image, VenueImageSettings } from '../../domainObjects/VenueImage';
import MainImagePage from './MainImage/MainImagePage';
import ServicesPage from './Services/ServicesPage';
import RestaurantService, { VenueServiceSettings } from '../../domainObjects/RestaurantService';
import { RestaurantDetailsSettings } from '../../domainObjects/RestaurantDetails';
import CoversAndIntervalsPage from './CoversAndIntervals/CoversAndIntervalsPage';
import AverageEatDuration, { AverageEatDurationSettings } from '../../domainObjects/AverageEatDuration';
import settingsService from '../../services/SettingsService';
import TablesPage from './Tables/TablesPage';
import AreasPage from './Areas/AreasPage';
import Area, { AreaSettings } from '../../domainObjects/Area';
import Table, { TableSettings } from '../../domainObjects/Table';
import { OnboardingProgressFlagsDto } from '../../domainObjects/OnboardingProgressFlags';
import NotFoundPage from '../common/404NotFoundPage';
import LoadingSpinner from '../common/LoadingSpinner';
import rdfOnboardingSteps from '../../enums/rdfOnboardingSteps';
import useQueryParam from '../../hooks/useQueryParam';
import PlacesService from '../../services/PlacesService';
import ApiRestaurantService from '../../services/RestaurantService';
import { findWindows } from 'windows-iana';
import { TimeZoneSettings } from '../../domainObjects/TimeZone';
import TimeZoneResponse, { TimeZoneResponseSettings } from '../../domainObjects/TimeZoneResponse';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import UserService from '../../services/UserService';
import { useIntl } from 'react-intl';
import trackingEvents from '../../enums/trackingEvents';

const OnboardingWizardPage = () => {
    const [isFetchingProgressFlags, setIsFetchingProgressFlags] = useState(true);
    const [isFetchingProviderDetails, setIsFetchingProviderDetails] = useState(true);
    const [isFetchingVenueImages, setIsFetchingVenueImages] = useState(true);
    const [isFetchingServices, setIsFetchingServices] = useState(true);
    const [isFetchingAreas, setIsFetchingAreas] = useState(true);
    const [isFetchingTables, setIsFetchingTables] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [providerDetails, setProviderDetails] = useState(new ProviderDetails(null));
    const [logo, setLogo] = useState<VenueImage | null>(null);
    const [mainImage, setMainImage] = useState<VenueImage | null>(null);
    const [mainImageUploadError, setMainImageUploadError] = useState('');
    const [venueServices, setVenueServices] = useState<RestaurantService[] | null>(null);
    const [areas, setAreas] = useState<Area[]>([]);
    const [tables, setTables] = useState<Table[]>([]);
    const [isNavigatingFromDishCult, setIsNavigatingFromDishCult] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const adminProviderId = useQueryParam('providerId');
    const adminDeploymentId = useQueryParam('deploymentId');
    const deploymentId = SettingsHelper.getDeploymentId();
    const providerId = SettingsHelper.getProviderId();
    let navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        const jwtToken = SettingsHelper.getJwtTokenFromCookie();
        const localJwtToken = SettingsHelper.getJwtToken();
        if (jwtToken && jwtToken !== localJwtToken) {
            SettingsHelper.setJwtToken(jwtToken);
        }

        SettingsService.getCurrentUser().then((userData) => {
            SettingsHelper.setRestaurantData(userData, adminProviderId, adminDeploymentId);
            if (SettingsHelper.getProviderId()) {
                SettingsService.getProviderSettings().then((providerDetails: ProviderDetailsSettings) => {
                    setIsFetchingProviderDetails(false);
                    if (
                        providerDetails.NameAddressDetails.Email === (null || '') ||
                        providerDetails.NameAddressDetails.CountryCode === (null || '' || '0') ||
                        providerDetails.NameAddressDetails.PhoneNumber === (null || '')
                    ) {
                        UserService.getCurrentUser().then((response) => {
                            providerDetails.NameAddressDetails.Email = response.data.Username;
                            providerDetails.NameAddressDetails.CountryCode = response.data.ContactPhoneCode;
                            providerDetails.NameAddressDetails.PhoneNumber = response.data.ContactPhoneNumber;
                            setProviderDetails(new ProviderDetails(providerDetails));
                        });
                    } else {
                        setProviderDetails(new ProviderDetails(providerDetails));
                    }
                    SettingsService.getProviderImages().then((data: Array<VenueImageSettings>) => {
                        const venueLogo = data.find((image) => {
                            return image.IsMainImage;
                        });
                        setLogo(new VenueImage(venueLogo ? venueLogo : null));

                        const venueImage = data.find((image) => {
                            return image.IsMasthead;
                        });
                        setMainImage(new VenueImage(venueImage ? venueImage : null));

                        setIsFetchingVenueImages(false);
                    });
                });
            }

            const userFullName = userData.DisplayName.split(' ');
            const identifyProperties = {
                firstname: userFullName[0],
                lastname: userFullName[userFullName.length - 1],
                email: userData.Username,
            };
            AnalyticsHelper.identifyUserIdWithProperties(userData.Id, identifyProperties);
            AnalyticsHelper.trackGroupWithProperties(userData.Id, {});
        });
    }, [adminProviderId, adminDeploymentId]);

    useEffect(() => {
        if (deploymentId && providerId) {
            SettingsService.getRestaurantSettings().then((restaurantDetails: RestaurantDetailsSettings) => {
                SettingsHelper.setSegmentId(restaurantDetails.Segments[0].Id.toString());
                SettingsService.getProviderServices().then((data: Array<VenueServiceSettings>) => {
                    const date = new Date();
                    const venueServices = data.map(
                        (x) =>
                            new RestaurantService({
                                Id: x.Id,
                                Name: x.Name,
                                TimeFrom: new Date(date.toDateString() + ' ' + x.TimeFrom),
                                TimeUntil: new Date(date.toDateString() + ' ' + x.TimeUntil),
                                LastBookingTime: new Date(date.toDateString() + ' ' + x.LastBookingTime),
                                TimeSlotInterval: x.TimeSlotInterval,
                                MaxBookings: x.MaxBookings,
                                MaxCovers: x.MaxCovers,
                            })
                    );
                    // // TODO: Update get average eat durations to accept provider id to reduce number of calls
                    venueServices.forEach((service) => {
                        SettingsService.getAverageEatDurations(service.id).then(
                            (data: Array<AverageEatDurationSettings>) => {
                                const venueAverageEatDurations: Array<AverageEatDuration> = data.map(
                                    (x) =>
                                        new AverageEatDuration({
                                            Id: x.Id,
                                            ServiceId: x.ServiceId,
                                            CoversFrom: x.CoversFrom,
                                            CoversTo: x.CoversTo,
                                            AverageTime: x.AverageTime,
                                            IsCoversRuleEnabled: x.IsCoversRuleEnabled,
                                        })
                                );
                                service.averageEatDurations = venueAverageEatDurations;
                            }
                        );
                    });
                    setIsFetchingServices(false);
                    setVenueServices(venueServices ? [...venueServices] : []);
                });
                SettingsService.getProviderAreas().then((data: Array<AreaSettings>) => {
                    const providerAreas = data.map(
                        (a) =>
                            new Area({
                                Id: a.Id,
                                SegmentId: a.SegmentId,
                                MaxCovers: a.MaxCovers,
                                Name: a.Name,
                                IsAvailableOnline: a.IsAvailableOnline,
                            })
                    );
                    setIsFetchingAreas(false);
                    setAreas(providerAreas ? [...providerAreas] : []);
                });
                SettingsService.getProviderTables().then((data: Array<TableSettings>) => {
                    const providerTables = data
                        // tables come over network in last updated order, which can be confusing so sorting by id
                        .sort((a, b) => {
                            if (a.Id < b.Id) {
                                return -1;
                            }
                            if (a.Id > b.Id) {
                                return 1;
                            }
                            return 0;
                        })
                        .map(
                            (t) =>
                                new Table({
                                    Id: t.Id,
                                    Number: t.Number,
                                    Description: t.Description,
                                    IsEnabled: t.IsEnabled,
                                    Capacity: t.Capacity,
                                    Priority: t.Priority,
                                    AreaId: t.AreaId,
                                    ProviderId: t.ProviderId,
                                    TableType: t.TableType,
                                    SegmentId: t.SegmentId,
                                })
                        );
                    setIsFetchingTables(false);
                    setTables(providerTables ? [...providerTables] : []);
                });
            });
        }
    }, [deploymentId, providerId]);

    useEffect(() => {
        if (deploymentId && providerId && isFetchingProgressFlags) {
            SettingsService.getProgressFlagsForProvider().then((progressFlags: OnboardingProgressFlagsDto) => {
                setIsFetchingProgressFlags(false);
                if (progressFlags) {
                    if (progressFlags.AddedTables) navigate(`/OnboardingWizard/${rdfOnboardingSteps.tablesPage}`);
                    else if (progressFlags.AddedAreas) navigate(`/OnboardingWizard/${rdfOnboardingSteps.areasPage}`);
                    else if (progressFlags.AddedServiceCoversAndIntervals)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.coversAndIntervalsPage}`);
                    else if (progressFlags.AddedServices)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.servicesPage}`);
                    else if (progressFlags.SelectedDCListingStatus)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.dishcultActivationPage}`);
                    else if (progressFlags.AddedMastheadImage)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.mainImagePage}`);
                    else if (progressFlags.AddedLogoImage) navigate(`/OnboardingWizard/${rdfOnboardingSteps.logoPage}`);
                    else if (progressFlags.AddedVenueDetails)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.descriptionPage}`);
                    else if (progressFlags.AddedAddress)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.locationPage}`);
                    else if (progressFlags.AddedCuisines)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.cuisinesPage}`);
                    else if (progressFlags.AddedContactDetails)
                        navigate(`/OnboardingWizard/${rdfOnboardingSteps.getStartedPage}`);
                    else navigate(`/OnboardingWizard/${rdfOnboardingSteps.welcomePage}`);
                }
            });
        }
    }, [deploymentId, providerId, isFetchingProgressFlags, navigate]);

    const isFetchingData = () => {
        return (
            isFetchingServices ||
            isFetchingAreas ||
            isFetchingTables ||
            isFetchingProviderDetails ||
            isFetchingVenueImages
        );
    };

    const updateNameAddressDetails = (nameAddressDetails: NameAddressDetails) => {
        setProviderDetails((prevState: ProviderDetails) => ({
            ...prevState,
            nameAddressDetails: nameAddressDetails,
        }));
    };

    const updateRestaurantInformation = (restaurantInformation: RestaurantInformation) => {
        setProviderDetails((prevState: ProviderDetails) => ({
            ...prevState,
            restaurantInformation: restaurantInformation,
        }));
    };

    const updatePricePoint = (pricePoint: number) => {
        setProviderDetails((prevState: ProviderDetails) => ({
            ...prevState,
            searchDetails: {
                ...prevState.searchDetails,
                pricePoint: pricePoint,
            },
        }));
    };

    const updateAddressAndContactDetails = () => {
        setIsUpdating(true);
        const addressAndContactDetails = {
            ...providerDetails.nameAddressDetails,
        };
        SettingsService.updateAddressAndContactDetails(addressAndContactDetails).then(() => {
            const groupProperties = {
                email: addressAndContactDetails.email,
                phone: `+${addressAndContactDetails.countryCode}${addressAndContactDetails.phoneNumber}`,
                websiteUrl: addressAndContactDetails.websiteUrl,
            };
            AnalyticsHelper.trackGroupWithProperties(SettingsHelper.getUserId(), groupProperties);
            setIsUpdating(false);
        });
    };

    const updateAddressAndContactDetailsWithState = (details: NameAddressDetails) => {
        setIsUpdating(true);
        updateNameAddressDetails(details);
        SettingsService.updateAddressAndContactDetails(details).then(() => {
            const groupProperties = {
                email: details.email,
                phone: `+${details.countryCode}${details.phoneNumber}`,
                timezone: details.timeZone,
                address: details.streetLine1,
                address2: details.streetLine2,
                city: details.town,
                zip: details.postcode,
                siteUrl: details.siteUrl,
                websiteUrl: details.websiteUrl,
            };
            AnalyticsHelper.trackGroupWithProperties(SettingsHelper.getUserId(), groupProperties);
            setIsUpdating(false);
        });
    };

    const updateListingDetails = () => {
        setIsUpdating(true);
        const listingDetails = {
            ...providerDetails.restaurantInformation,
            ...providerDetails.searchDetails,
            ...providerDetails.socialNetworkDetails,
            isReserveWithGoogleEnabled: providerDetails.isReserveWithGoogleEnabled,
            hideOnPortal: providerDetails.hideOnPortal,
            isoCurrencySymbol: providerDetails.nameAddressDetails.isoCurrencySymbol,
        };
        SettingsService.updateListingDetails(listingDetails).then(() => {
            if (!listingDetails.hideOnPortal) {
                AnalyticsHelper.trackClickWithPageNameAndProperties(
                    trackingEvents.dishcultListingActivate,
                    'DishCultActivation',
                    AnalyticsHelper.getCommonTrackingProperties()
                );
            }
            if (listingDetails.hideOnPortal) {
                AnalyticsHelper.trackClickWithPageNameAndProperties(
                    trackingEvents.dishcultListingLater,
                    'DishCultActivation',
                    AnalyticsHelper.getCommonTrackingProperties()
                );
            }
            setIsUpdating(false);
        });
    };

    const updateSearchDetails = (searchDetails: SearchDetails) => {
        setProviderDetails((prevState: ProviderDetails) => ({
            ...prevState,
            searchDetails: searchDetails,
        }));
    };

    const updateImage = (newImage: Image, currentImage: Image | null | undefined, isLogoImage: boolean) => {
        const data = new FormData();
        data.append('file', newImage.file as File);
        return SettingsService.uploadProviderImage(data, currentImage?.id ? currentImage.id : 0, (progress: number) => {
            setUploadProgress(progress);
        }).then((response: VenueImageSettings) => {
            if (response) {
                const image = new VenueImage({
                    Id: response.Id,
                    ImageUrl: response.ImageUrl,
                    IsEnabled: response.IsEnabled,
                    IsMainImage: response.IsMainImage,
                    IsMasthead: response.IsMasthead,
                    Name: response.Name,
                    UploadedDate: response.UploadedDate,
                });
                if (isLogoImage) {
                    if (logo != null && logo.id !== 0 && logo.id !== image.id) {
                        // Delete old image
                        SettingsService.deleteProviderImages([logo.id]);
                    }
                    SettingsService.setImageAsMainImage(image.id);
                    setLogo(image);
                }
                if (!isLogoImage) {
                    if (mainImage != null && mainImage.id !== 0 && mainImage.id !== image.id) {
                        // Delete old image
                        SettingsService.deleteProviderImages([mainImage.id]);
                    }
                    SettingsService.setImageAsMastheadImage(image.id);
                    setMainImage(image);
                }
            } else {
                setMainImageUploadError(intl.formatMessage({ id: 'OnboardingWizard.ImageFileUploadError' }));
            }
        });
    };

    const deleteImage = (imageIds: Array<number>) => {
        SettingsService.deleteProviderImages(imageIds);
    };

    const updateHideOnPortal = (hideOnPortalValue: boolean) => {
        setProviderDetails((prevState: ProviderDetails) => ({
            ...prevState,
            hideOnPortal: hideOnPortalValue,
        }));
    };

    const addOrUpdateArea = (area: Area, setOnlyState: boolean = false, callback: (success: boolean) => void) => {
        setIsUpdating(true);
        const currentAreas = areas.filter((a) => a.id !== area.id);
        if (setOnlyState) {
            setAreas([...currentAreas, area]);
            setIsUpdating(false);
        } else {
            SettingsService.addOrUpdateArea(area)
                .then((returnedArea: AreaSettings) => {
                    const newArea = new Area(returnedArea);
                    setAreas([...currentAreas, newArea]);
                    callback(true);
                    setIsUpdating(false);
                })
                .catch(() => {
                    callback(false);
                    setIsUpdating(false);
                });
        }
    };

    const deleteArea = (areaToDelete: Area, callback: (success: boolean) => void) => {
        setIsUpdating(true);
        SettingsService.deleteArea(areaToDelete.id)
            .then(() => {
                setAreas((prevState: Area[]) => prevState.filter((a) => a !== areaToDelete));
                callback(true);
                setIsUpdating(false);
            })
            .catch(() => {
                callback(false);
                setIsUpdating(false);
            });
    };

    const addOrUpdateTable = (table: Table, setOnlyState: boolean = false, callback: (success: boolean) => void) => {
        setIsUpdating(true);
        const currentTables = tables.filter((a) => a.id !== table.id);
        if (setOnlyState) {
            setIsUpdating(false);
            setTables([...currentTables, table]);
        } else {
            SettingsService.addOrUpdateTable(table)
                .then((returnedTable: TableSettings) => {
                    const newTable = new Table(returnedTable);
                    const newTables = [...currentTables, newTable];
                    setTables(
                        newTables.sort((a, b) => {
                            if (a.id < b.id) {
                                return -1;
                            }
                            if (a.id > b.id) {
                                return 1;
                            }
                            return 0;
                        })
                    );
                    setIsUpdating(false);
                    callback(true);
                })
                .catch(() => {
                    setIsUpdating(false);
                    callback(false);
                });
        }
    };

    const deleteTable = (tableToDelete: Table, callback: (success: boolean) => void) => {
        SettingsService.deleteTable(tableToDelete.id)
            .then(() => {
                setTables((prevState: Table[]) => prevState.filter((t) => t !== tableToDelete));
                callback(true);
            })
            .catch(() => {
                callback(false);
            });
    };

    const updateTimeZone = () => {
        setIsUpdating(true);
        ApiRestaurantService.getSystemTimeZones().then((windowsTimeZones: TimeZoneSettings[]) => {
            let userIANATimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Europe/London'; // fallback from browser or if not available set to Europe/London

            PlacesService.getTimeZone(
                providerDetails.nameAddressDetails.latitude.toString(),
                providerDetails.nameAddressDetails.longitude.toString()
            )
                .then((data: TimeZoneResponseSettings) => new TimeZoneResponse(data))
                .then((timeZoneResponse: TimeZoneResponse) => {
                    if (timeZoneResponse.status === 'OK') {
                        userIANATimeZone = timeZoneResponse.timeZoneId;
                    }
                })
                .finally(() => {
                    setIsUpdating(false);
                    const windowsZone = findWindows(userIANATimeZone)[0];
                    const matchIndex = windowsTimeZones.findIndex((timeZone) => timeZone.Value === windowsZone);
                    const updatedNameAddressDetails: NameAddressDetails = {
                        ...providerDetails.nameAddressDetails,
                    };
                    if (matchIndex >= 0) {
                        updatedNameAddressDetails.timeZone = windowsZone;
                    }
                    updateAddressAndContactDetailsWithState(updatedNameAddressDetails);
                });
        });
    };

    const updateProgressFlag = (progressFlag: string) => {
        settingsService.setProgressFlag(progressFlag);
    };

    if (isFetchingProgressFlags) {
        return <LoadingSpinner />;
    }

    const isLoading = isFetchingData();

    return (
        <Routes>
            <Route
                path={rdfOnboardingSteps.welcomePage}
                element={<WelcomePage venueName={providerDetails.nameAddressDetails.name} isLoading={isLoading} />}
            />
            <Route path={rdfOnboardingSteps.checklistPage} element={<ChecklistPage isLoading={isLoading} />} />
            <Route
                path={rdfOnboardingSteps.getStartedPage}
                element={
                    <GetStartedPage
                        restaurantInformation={providerDetails.restaurantInformation}
                        updateNameAddressDetails={updateNameAddressDetails}
                        updateRestaurantInformation={updateRestaurantInformation}
                        updateSettings={updateAddressAndContactDetails}
                        nameAddressDetails={providerDetails.nameAddressDetails}
                        isUpdating={isUpdating}
                        isLoading={isLoading}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.cuisinesPage}
                element={
                    <CuisinesPage
                        searchDetails={providerDetails.searchDetails}
                        updateSearchDetails={updateSearchDetails}
                        updateSettings={updateListingDetails}
                        isUpdating={isUpdating}
                        isLoading={isLoading}
                        cuisines={providerDetails.cuisines}
                        isNavigatingFromDishCult={isNavigatingFromDishCult}
                        setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.locationPage}
                element={
                    <LocationPage
                        nameAddressDetails={providerDetails.nameAddressDetails}
                        updateNameAddressDetails={updateNameAddressDetails}
                        updateSettings={updateTimeZone}
                        isLoading={isLoading}
                        isUpdating={isUpdating}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.descriptionPage}
                element={
                    <DescriptionPage
                        restaurantInformation={providerDetails.restaurantInformation}
                        updateRestaurantInformation={updateRestaurantInformation}
                        updateSettings={updateListingDetails}
                        pricePoint={providerDetails.searchDetails.pricePoint}
                        updatePricePoint={updatePricePoint}
                        isoCurrencySymbol={providerDetails.nameAddressDetails.isoCurrencySymbol}
                        currencies={providerDetails.currencies}
                        isUpdating={isUpdating}
                        isNavigatingFromDishCult={isNavigatingFromDishCult}
                        setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
                        isLoading={isLoading}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.logoPage}
                element={
                    <LogoPage
                        venueLogo={logo}
                        isLoading={isLoading}
                        uploadProgress={uploadProgress}
                        updateLogo={updateImage}
                        deleteImage={deleteImage}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.mainImagePage}
                element={
                    <MainImagePage
                        venueImage={mainImage}
                        isNavigatingFromDishCult={isNavigatingFromDishCult}
                        setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
                        isLoading={isLoading}
                        uploadProgress={uploadProgress}
                        updateMainImage={updateImage}
                        deleteImage={deleteImage}
                        mainImageUploadError={mainImageUploadError}
                        setMainImageUploadError={setMainImageUploadError}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.dishcultActivationPage}
                element={
                    <DishCultActivationPage
                        venueName={providerDetails.nameAddressDetails.name}
                        venueHeadline={providerDetails.restaurantInformation.headline}
                        venueDescription={providerDetails.restaurantInformation.generalDescription}
                        venueImage={mainImage as VenueImage}
                        cuisines={providerDetails.cuisines}
                        hideOnPortal={providerDetails.hideOnPortal}
                        searchDetails={providerDetails.searchDetails}
                        nameAddressDetails={providerDetails.nameAddressDetails}
                        updateSettings={updateListingDetails}
                        updateHideOnPortal={updateHideOnPortal}
                        updateProgressFlag={updateProgressFlag}
                        setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
                        isLoading={isLoading}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.servicesPage}
                element={
                    <ServicesPage
                        venueServices={venueServices}
                        setVenueServices={setVenueServices}
                        updateProgressFlag={updateProgressFlag}
                        isLoading={isLoading}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.coversAndIntervalsPage}
                element={
                    <CoversAndIntervalsPage
                        venueServices={venueServices}
                        setVenueServices={setVenueServices}
                        updateProgressFlag={updateProgressFlag}
                        isLoading={isLoading}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.areasPage}
                element={
                    <AreasPage
                        areas={areas}
                        addOrUpdateArea={addOrUpdateArea}
                        deleteArea={deleteArea}
                        updateProgressFlag={updateProgressFlag}
                        isUpdating={isUpdating}
                        isLoading={isLoading}
                    />
                }
            />
            <Route
                path={rdfOnboardingSteps.tablesPage}
                element={
                    <TablesPage
                        venueAreas={areas}
                        venueTables={tables}
                        addOrUpdateTable={addOrUpdateTable}
                        addOrUpdateArea={addOrUpdateArea}
                        updateProgressFlag={updateProgressFlag}
                        deleteTable={deleteTable}
                        isUpdating={isUpdating}
                        isLoading={isLoading}
                    />
                }
            />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default OnboardingWizardPage;
