import useScreenSize from '../../hooks/useScreenSize';
import { useIntl } from 'react-intl';
import { Button, Form } from 'reactstrap';
import ResDiaryLogo from '../../content/images/resdiary-logo-white.png';
import { useEffect, useState } from 'react';
import rdfOnboardingSteps from '../../enums/rdfOnboardingSteps';

export type InfoPanelPageProps = {
    infoPanelTitle: string;
    infoPanelSubtitle?: string;
    infoPanelClassName?: string;
    infoPanelBackgroundImageSource?: string;
    currentStep: string;
    //TODO make updateSettings and isUpdating mandatory when they've been implemented in the other pages
    isUpdating?: boolean;
    children?: React.ReactNode;
    formValidation?: any; // TODO this is implemented as if it is required; gives error if not provided in other pages.
    shouldExcludeFormPadding?: boolean;
    nextStep: Function;
    previousStep?: Function;
    isChecklistPage: boolean;
    hideProgressBar?: boolean;
    steps?: string[] | undefined;
    continueDisabled?: boolean;
};

const InfoPanelPage = ({
    infoPanelTitle,
    infoPanelSubtitle,
    infoPanelClassName,
    infoPanelBackgroundImageSource,
    currentStep,
    isUpdating,
    children,
    formValidation,
    shouldExcludeFormPadding,
    nextStep,
    previousStep,
    hideProgressBar,
    steps,
    continueDisabled,
}: InfoPanelPageProps) => {
    const { isMobileView } = useScreenSize();
    const intl = useIntl();

    const [progressBarDoneIndicatorWidth, setProgressBarDoneIndicatorWidth] = useState(0);
    const [progressBarInProgressIndicatorWidth, setProgressBarInProgressIndicatorWidth] = useState(0);

    const isChecklistPage = () => {
        return currentStep === rdfOnboardingSteps.checklistPage;
    };

    const isVenueMainImagePageOrVenueLogoPage = () => {
        return currentStep === rdfOnboardingSteps.logoPage ? true : currentStep === rdfOnboardingSteps.mainImagePage;
    };

    useEffect(() => {
        const currentStepIndex = steps ? steps?.indexOf(currentStep) : 0;
        const nextStepIndex = steps ? steps?.indexOf(currentStep) + 1 : 0;

        setProgressBarDoneIndicatorWidth(steps ? (100 / steps?.length) * currentStepIndex : 0);
        setProgressBarInProgressIndicatorWidth(steps ? (100 / steps?.length) * nextStepIndex : 0);
    }, [currentStep, steps]);

    return (
        <div className={`onboarding-info-panel-page row ${isMobileView ? 'mobile' : ''}`}>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation ? formValidation.submit(nextStep) : nextStep();
                }}
                className="w-100"
            >
                <div
                    className={`${isMobileView ? '' : 'onboarding-info-panel'} bg-white`}
                    style={{
                        height: '100%',
                    }}
                >
                    <div
                        className={
                            infoPanelClassName
                                ? `onboarding-info-panel col-md-4 ${infoPanelClassName} ${isMobileView ? 'mob' : ''}`
                                : 'onboarding-info-panel col-md-4'
                        }
                    >
                        {infoPanelBackgroundImageSource && (
                            <img
                                className="onboarding-info-panel-background-image"
                                src={infoPanelBackgroundImageSource}
                                alt="background placeholder"
                            />
                        )}
                        {ResDiaryLogo && !isMobileView && (
                            <img
                                className="onboarding-info-panel-resdiary-logo"
                                style={{
                                    margin: isMobileView ? '30px 0px 0px 27.5px' : '40px 0px 0px 40px',
                                }}
                                src={ResDiaryLogo}
                                alt="ResDiary logo"
                            />
                        )}

                        <div className="onboarding-info-panel-title-wrapper">
                            <h1 style={{ margin: isMobileView ? '27.5px' : '40px' }}>{infoPanelTitle}</h1>
                            {infoPanelSubtitle && (
                                <p style={{ margin: isMobileView ? '27.5px' : '40px' }}>{infoPanelSubtitle}</p>
                            )}
                        </div>
                    </div>
                    <div
                        className={`onboarding-page-form col-md-8 ${isMobileView ? 'mobile' : 'desktop'} ${
                            shouldExcludeFormPadding ? '' : 'include-form-padding'
                        }`}
                    >
                        {children}
                    </div>
                    <div className="onboarding-page-progress-bar col-md-8">
                        {!hideProgressBar && (
                            <div className="onboarding-page-progress-bar-indicator">
                                <div
                                    className="onboarding-page-progress-bar-indicator-done"
                                    style={{ width: `${progressBarDoneIndicatorWidth}%` }}
                                ></div>
                                <div
                                    className="onboarding-page-progress-bar-indicator-in-progress"
                                    style={{ width: `${progressBarInProgressIndicatorWidth}%` }}
                                ></div>
                            </div>
                        )}
                        <div
                            className={
                                hideProgressBar
                                    ? 'onboarding-page-progress-bar-button-wrapper show-border'
                                    : 'onboarding-page-progress-bar-button-wrapper'
                            }
                        >
                            {previousStep && (
                                <div
                                    onClick={() => {
                                        previousStep();
                                    }}
                                    className="onboarding-page-progress-bar-button-wrapper-back"
                                >
                                    {!isChecklistPage()
                                        ? intl.formatMessage({ id: 'Common.Back' })
                                        : intl.formatMessage({ id: 'OnboardingWizard.BackToTopButtonLabel' })}
                                </div>
                            )}
                            {isVenueMainImagePageOrVenueLogoPage() && (
                                <div
                                    onClick={() => {
                                        nextStep({ skipThisStep: true });
                                    }}
                                    className="onboarding-page-progress-bar-button-wrapper-back"
                                >
                                    {intl.formatMessage({ id: 'Common.Skip' })}
                                </div>
                            )}
                            <div className="ml-auto d-flex align-items-center">
                                {!isChecklistPage() && !hideProgressBar && (
                                    <div>{intl.formatMessage({ id: 'OnboardingWizard.ProgressSaved' })}</div>
                                )}
                                <Button
                                    type="submit"
                                    disabled={Boolean(
                                        isUpdating ||
                                            (formValidation &&
                                                formValidation.errors &&
                                                formValidation.errorCount &&
                                                formValidation.errorCount > 0) ||
                                            (continueDisabled ?? (formValidation && !formValidation.formState.isValid))
                                    )}
                                    color="primary"
                                >
                                    {!isChecklistPage()
                                        ? intl.formatMessage({ id: 'Common.Continue' })
                                        : intl.formatMessage({ id: 'OnboardingWizard.StartSettingUpButtonLabel' })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default InfoPanelPage;
