import { UserAccount } from '../domainObjects/UserAccount';

class CreateUserForAccountSubmissionRequest {
    FirstName: string;
    LastName: string;
    ProviderName: string;
    CountryId: number;
    MobilePhoneCode: string;
    MobilePhoneNumber: string;
    EmailAddress: string;
    Password: string;
    LtdCompanyName: string | undefined;
    AccessToken: string;

    constructor(userAccount: UserAccount, accessToken: string | null) {
        this.FirstName = userAccount ? userAccount.FirstName : '';
        this.LastName = userAccount ? userAccount.LastName : '';
        this.ProviderName = userAccount ? userAccount.ProviderName : '';
        this.CountryId = userAccount ? userAccount.CountryId : 0;
        this.MobilePhoneCode = userAccount ? userAccount.MobilePhoneCode : '';
        this.MobilePhoneNumber = userAccount ? userAccount.MobilePhoneNumber : '';
        this.EmailAddress = userAccount ? userAccount.EmailAddress : '';
        this.Password = userAccount ? userAccount.Password : '';
        this.LtdCompanyName = userAccount ? userAccount.LimitedCompanyName : '';
        this.AccessToken = accessToken ? accessToken : '';
    }
}

export default CreateUserForAccountSubmissionRequest;
