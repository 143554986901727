import TagManager from 'react-gtm-module';

class GoogleTagManagerHelper {
    static loadTagManager(gtmId) {
        if (!gtmId || gtmId === 'null') return;

        const tagManagerArgs = {
            gtmId: gtmId,
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs);
    }
}
export default GoogleTagManagerHelper;
