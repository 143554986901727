export type TimeZoneResponseSettings = {
    TimeZoneId: string;
    Status: string;
};

class TimeZoneResponse {
    timeZoneId: string;
    status: string;

    constructor(timeZoneResponseSettings: TimeZoneResponseSettings | null) {
        this.timeZoneId = timeZoneResponseSettings?.TimeZoneId ? timeZoneResponseSettings.TimeZoneId : '';
        this.status = timeZoneResponseSettings?.Status ? timeZoneResponseSettings.Status : '';
    }
}

export default TimeZoneResponse;
