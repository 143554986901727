import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export type OnboardingModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: (e: boolean) => void;
    action?: (e: {}) => void;
    title?: string;
    body: React.ReactNode;
    actionButtonText?: string;
    actionButtonPendingText?: string;
    hideCloseButton?: boolean;
    nonDismissible?: boolean;
    hideFooter?: boolean;
    modalClass?: string;
    modalHeaderClass?: string;
    customFooter?: React.ReactElement;
    dismissActionButtonText?: string;
    hideLayout?: boolean;
    customHeader?: React.ReactElement;
    modalBodyClass?: string;
    isScrollable?: boolean;
};

function OnboardingModal({
    isModalOpen,
    setIsModalOpen,
    action,
    title,
    body,
    actionButtonText,
    actionButtonPendingText,
    hideCloseButton = false,
    nonDismissible = false,
    hideFooter = false,
    modalClass,
    modalHeaderClass,
    customFooter,
    dismissActionButtonText,
    hideLayout,
    customHeader,
    modalBodyClass,
    isScrollable,
}: OnboardingModalProps) {
    const [isProcessing, setIsProcessing] = useState(false);

    function getCloseButton() {
        if (nonDismissible) {
            return null;
        }

        return (
            <button className="close" onClick={() => setIsModalOpen(false)}>
                &times;
            </button>
        );
    }

    function process() {
        if (action != null) {
            setIsProcessing(true);
            action(actionFailedCallback);
        }
    }

    function actionFailedCallback() {
        setIsProcessing(false);
    }

    function getActionButtonText() {
        if (isProcessing) {
            return actionButtonPendingText ? actionButtonPendingText : actionButtonText;
        }

        return actionButtonText;
    }

    return (
        <Modal
            zIndex={1072}
            size="lg"
            centered
            isOpen={isModalOpen}
            className={modalClass ? 'modal ' + modalClass : 'modal'}
            scrollable={isScrollable}
        >
            <Fragment>
                {!hideLayout && (
                    <Fragment>
                        <Fragment>
                            <ModalHeader
                                className={modalHeaderClass}
                                close={getCloseButton()}
                                tag={customHeader ? 'div' : 'h6'}
                            >
                                {title}
                                {customHeader ?? { customHeader }}
                            </ModalHeader>
                            <ModalBody className={`d-flex flex-column ${modalBodyClass ? modalBodyClass : ''}`}>
                                <div>{body}</div>
                            </ModalBody>
                        </Fragment>
                        <Fragment>
                            {!hideFooter && !customFooter && (
                                <ModalFooter>
                                    <div>
                                        {!hideCloseButton && !nonDismissible && (
                                            <Button
                                                className="mr-2"
                                                outline
                                                color="primary"
                                                onClick={() => setIsModalOpen(false)}
                                                disabled={isProcessing}
                                            >
                                                {dismissActionButtonText ? (
                                                    dismissActionButtonText
                                                ) : (
                                                    <FormattedMessage id="Common.Close" />
                                                )}
                                            </Button>
                                        )}

                                        <Button color="primary" onClick={process} disabled={isProcessing}>
                                            {getActionButtonText()}
                                        </Button>
                                    </div>
                                </ModalFooter>
                            )}
                            {customFooter}
                        </Fragment>
                    </Fragment>
                )}
                {hideLayout && <Fragment>{body}</Fragment>}
            </Fragment>
        </Modal>
    );
}

export default OnboardingModal;
