import { useCallback } from 'react';
import AddOnType from '../enums/AddOnType';
import { ReactComponent as OrderAndPayLogo } from '../content/icons/addOns/OrderAndPay.svg';
import { ReactComponent as VouchersLogo } from '../content/icons/addOns/Vouchers.svg';
import { ReactComponent as EventManagerLogo } from '../content/icons/addOns/EventManager.svg';
import { ReactComponent as SmsNotificationsLogo } from '../content/icons/addOns/SmsNotifications.svg';
import { ReactComponent as ResPhoneLogo } from '../content/icons/addOns/ResPhone.svg';
import { ReactComponent as GroupCentralReservationsLogo } from '../content/icons/addOns/GroupCentralReservations.svg';
import { ReactComponent as EposIntegrationLogo } from '../content/icons/addOns/EposIntegration.svg';
import { ReactComponent as PmsIntegrationLogo } from '../content/icons/addOns/PmsIntegration.svg';
import { ReactComponent as CrmIntegrationLogo } from '../content/icons/addOns/CrmIntegration.svg';
import { ReactComponent as OrderAndPayCol1 } from '../content/icons/addOns/modal/OrderPay1.svg';
import { ReactComponent as OrderAndPayCol2 } from '../content/icons/addOns/modal/OrderPay2.svg';
import { ReactComponent as EventsCol1 } from '../content/icons/addOns/modal/Events1.svg';
import { ReactComponent as EventsCol2 } from '../content/icons/addOns/modal/Events2.svg';
import { ReactComponent as EventsCol3 } from '../content/icons/addOns/modal/Events3.svg';
import { ReactComponent as VouchersCol1 } from '../content/icons/addOns/modal/Vouchers1.svg';
import { ReactComponent as VouchersCol2 } from '../content/icons/addOns/modal/Vouchers2.svg';
import { ReactComponent as VouchersCol3 } from '../content/icons/addOns/modal/Vouchers3.svg';
import AddOnFeature from '../viewModels/AddOnFeature';
import EPOSImagesUK from '../helpers/imageHelpers/EPOSImagesUK';
import PMSImagesUK from '../helpers/imageHelpers/PMSImagesUK';
import AddOnLayout from '../enums/AddOnLayout';

function useAddOns(intl: any) {
    const getAddOnTitle = useCallback(
        (type: AddOnType) => {
            return intl.formatMessage({ id: `CreationWizard.${AddOnType[type]}Title` });
        },
        [intl]
    );

    const getAddOnDescription = useCallback(
        (type: AddOnType) => {
            return intl.formatMessage({ id: `CreationWizard.${AddOnType[type]}ShortDescription` });
        },
        [intl]
    );

    const getAddOnModalDescription = useCallback(
        (type: AddOnType) => {
            return intl.formatMessage({ id: `CreationWizard.${AddOnType[type]}ModalDescription` });
        },
        [intl]
    );

    const getLogo = useCallback((addOnType: AddOnType): JSX.Element => {
        switch (addOnType) {
            case AddOnType.OrderAndPay:
                return <OrderAndPayLogo />;
            case AddOnType.EposIntegration:
                return <EposIntegrationLogo />;
            case AddOnType.CrmAdvancedMarketingIntegration:
                return <CrmIntegrationLogo />;
            case AddOnType.EventsManager:
                return <EventManagerLogo />;
            case AddOnType.GroupCentralReservations:
                return <GroupCentralReservationsLogo />;
            case AddOnType.PmsIntegration:
                return <PmsIntegrationLogo />;
            case AddOnType.ResPhone:
                return <ResPhoneLogo />;
            case AddOnType.SmsMarketingNotifications:
                return <SmsNotificationsLogo />;
            case AddOnType.Vouchers:
                return <VouchersLogo />;
            default:
                return <OrderAndPayLogo />;
        }
    }, []);

    const getAddOnLayout = useCallback((addOnType: AddOnType): AddOnLayout => {
        switch (addOnType) {
            case AddOnType.OrderAndPay:
            case AddOnType.Vouchers:
            case AddOnType.EventsManager:
                return AddOnLayout.Column;
            case AddOnType.SmsMarketingNotifications:
            case AddOnType.ResPhone:
            case AddOnType.GroupCentralReservations:
                return AddOnLayout.Description;
            case AddOnType.EposIntegration:
            case AddOnType.PmsIntegration:
            case AddOnType.CrmAdvancedMarketingIntegration:
                return AddOnLayout.Image;
            default:
                return AddOnLayout.Column;
        }
    }, []);

    const getImagesInLayout = useCallback((addOnType: AddOnType): Array<string> | null => {
        switch (addOnType) {
            case AddOnType.EposIntegration:
                return EPOSImagesUK;
            case AddOnType.PmsIntegration:
                return PMSImagesUK;
            default:
                return null;
        }
    }, []);

    const getDescriptions = useCallback(
        (addOnType: AddOnType): Array<string> | null => {
            switch (addOnType) {
                case AddOnType.SmsMarketingNotifications:
                    return [
                        intl.formatMessage({ id: 'CreationWizard.SmsMarketingNotificationsDescriptionParagraph1' }),
                        intl.formatMessage({ id: 'CreationWizard.SmsMarketingNotificationsDescriptionParagraph2' }),
                    ];
                case AddOnType.ResPhone:
                    return [
                        intl.formatMessage({ id: 'CreationWizard.ResPhoneDescriptionParagraph1' }),
                        intl.formatMessage({ id: 'CreationWizard.ResPhoneDescriptionParagraph2' }),
                    ];
                case AddOnType.GroupCentralReservations:
                    return [
                        intl.formatMessage({ id: 'CreationWizard.GroupCentralReservationsDescriptionParagraph1' }),
                        intl.formatMessage({ id: 'CreationWizard.GroupCentralReservationsDescriptionParagraph2' }),
                    ];
                default:
                    return null;
            }
        },
        [intl]
    );

    const getFeatures = useCallback(
        (addOnType: AddOnType): Array<AddOnFeature> | null => {
            switch (addOnType) {
                case AddOnType.OrderAndPay:
                    return [
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.OrderAndPayModalCol1Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.OrderAndPayModalCol1Description' }),
                            logo: <OrderAndPayCol1 />,
                        }),
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.OrderAndPayModalCol2Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.OrderAndPayModalCol2Description' }),
                            logo: <OrderAndPayCol2 />,
                        }),
                    ];
                case AddOnType.Vouchers:
                    return [
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.VouchersCol1Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.VouchersCol1Description' }),
                            logo: <VouchersCol1 />,
                        }),
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.VouchersCol2Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.VouchersCol2Description' }),
                            logo: <VouchersCol2 />,
                        }),
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.VouchersCol3Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.VouchersCol3Description' }),
                            logo: <VouchersCol3 />,
                        }),
                    ];
                case AddOnType.EventsManager:
                    return [
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.EventsManagerCol1Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.EventsManagerCol1Description' }),
                            logo: <EventsCol1 />,
                        }),
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.EventsManagerCol2Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.EventsManagerCol2Description' }),
                            logo: <EventsCol2 />,
                        }),
                        new AddOnFeature({
                            title: intl.formatMessage({ id: 'CreationWizard.EventsManagerCol3Title' }),
                            description: intl.formatMessage({ id: 'CreationWizard.EventsManagerCol3Description' }),
                            logo: <EventsCol3 />,
                        }),
                    ];
                default:
                    return null;
            }
        },
        [intl]
    );

    return {
        getAddOnTitle,
        getAddOnDescription,
        getAddOnModalDescription,
        getLogo,
        getAddOnLayout,
        getImagesInLayout,
        getDescriptions,
        getFeatures,
    };
}

export default useAddOns;
