import React from 'react';
import { Button } from 'reactstrap';
import useScreenSize from '../../hooks/useScreenSize';
import ClickableText from '../common/ClickableText';
import { useIntl, FormattedMessage } from 'react-intl';
import CreationWizardProgressBarItem from '../../enums/CreationWizardProgressBarItem';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import SettingsHelper from '../../helpers/SettingsHelper';

export type CreationWizardFooterProps = {
    currentPage: CreationWizardProgressBarItem;
    goToNextPage?: Function;
    anyAddOnSelected?: Function;
    buttonDisabled?: boolean;
};

const CreationWizardFooter = ({
    currentPage,
    goToNextPage,
    anyAddOnSelected,
    buttonDisabled,
}: CreationWizardFooterProps) => {
    const intl = useIntl();
    const { isTabletView } = useScreenSize();

    const getButtonText = () => {
        if (
            currentPage === CreationWizardProgressBarItem.PaymentPage ||
            currentPage === CreationWizardProgressBarItem.AddOnsPage
        ) {
            return intl.formatMessage({ id: 'CreationWizard.ConfirmSubscription' });
        }
        return intl.formatMessage({ id: 'CreationWizard.Continue' });
    };

    const trackClick = () => {
        if (currentPage === CreationWizardProgressBarItem.AddOnsPage) {
            const trackingProperties = {
                ...AnalyticsHelper.getCommonTrackingProperties(),
                selectedPlan: 'TODO',
            };
            AnalyticsHelper.trackClickWithProperties('Web RDF Add-on Continue', trackingProperties);
        }
    };

    return (
        <div className={isTabletView ? 'creation-wizard-footer-wrapper mob' : 'creation-wizard-footer-wrapper'}>
            <div className={isTabletView ? 'creation-wizard-footer mob' : 'creation-wizard-footer'}>
                <div className="footer-heading">
                    <FormattedMessage id="CreationWizard.NeedHelp" />
                    <ClickableText
                        text={intl.formatMessage({ id: 'CreationWizard.ContactUs' })}
                        redirectUrl="https://sales.resdiary.com/resdiary-demo/"
                        className="ml-1"
                    />
                    <span className="ml-1 mr-1">|</span>
                    <FormattedMessage id="CreationWizard.AlreadyHaveAnAccount" />
                    <ClickableText
                        text={intl.formatMessage({ id: 'CreationWizard.LogIn' })}
                        redirectUrl={SettingsHelper.getLoginUrl()}
                        className="ml-1"
                    />
                    <Button
                        color="primary"
                        type={currentPage === CreationWizardProgressBarItem.CreateAccountPage ? 'submit' : 'button'}
                        onClick={() => {
                            if (currentPage !== CreationWizardProgressBarItem.CreateAccountPage && goToNextPage) {
                                trackClick();
                                goToNextPage();
                            }
                        }}
                        disabled={buttonDisabled}
                    >
                        <div>{getButtonText()}</div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreationWizardFooter;
