import React, { Fragment } from 'react';
import AddOnModalColumn from '../AddOnModalColumn';
import useScreenSize from '../../../hooks/useScreenSize';
import AddOn from '../../../viewModels/AddOn';
import AddOnFeature from '../../../viewModels/AddOnFeature';
import AddOnModalActions from '../AddOnModalActions';

export type ColumnLayoutProps = {
    isSelected: Function;
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    setIsAddOnModalOpen: Function;
    addOn: AddOn;
};

const ColumnLayout = ({
    isSelected,
    selectAddOnItem,
    unselectAddOnItem,
    setIsAddOnModalOpen,
    addOn,
}: ColumnLayoutProps) => {
    const { isTabletView } = useScreenSize();

    return (
        <Fragment>
            <div className="addon-modal-body-header-description">
                <h6>{addOn?.modalDescription}</h6>
            </div>
            <div className={isTabletView ? 'addon-modal-columns mob' : 'addon-modal-columns'}>
                {addOn?.features?.map((feature: AddOnFeature, index: number) => (
                    <AddOnModalColumn
                        logo={feature.logo}
                        title={feature.title}
                        description={feature.shortDescription}
                        key={index}
                    />
                ))}
            </div>
            <AddOnModalActions
                isSelected={isSelected}
                selectAddOnItem={selectAddOnItem}
                unselectAddOnItem={unselectAddOnItem}
                setIsAddOnModalOpen={setIsAddOnModalOpen}
                addOn={addOn}
            />
        </Fragment>
    );
};

export default ColumnLayout;
