import { Label } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import PhoneNumberInput from '../common/PhoneNumberInput';
import { useIntl } from 'react-intl';
import { ContactDetails } from '../../domainObjects/ContactDetails';
import ValidationHelper from '../../helpers/ValidationHelper';

export type TrainingWidgetContactDetailsProps = {
    contactDetails: ContactDetails;
    setContactDetails: Function;
    countryCodeOptions: Array<HTMLOptionElement | null>;
    formValidation: any;
};

export default function TrainingWidgetContactDetails({
    contactDetails,
    setContactDetails,
    countryCodeOptions,
    formValidation,
}: TrainingWidgetContactDetailsProps) {
    const intl = useIntl();

    return (
        <div className="contact-details-section">
            <div className="title-container">
                <div className="title">{intl.formatMessage({ id: 'TrainingWidget.ContactDetails' })}</div>
                <div className="description">
                    {intl.formatMessage({ id: 'TrainingWidget.ContactDetailsDescription' })}
                </div>
            </div>
            <div className="contact-details-field-container">
                <div className="name-fields-container d-flex">
                    <div>
                        <Label for="firstName" id="firstName">
                            {intl.formatMessage({ id: 'CreationWizard.FirstName' })}
                        </Label>
                        <ValidationInput
                            type="text"
                            name="firstName"
                            value={contactDetails.FirstName}
                            onChange={(e) => setContactDetails({ ...contactDetails, FirstName: e.toString() })}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.FieldRequiredGenericMessage' },
                                    { fieldName: intl.formatMessage({ id: 'CreationWizard.FirstName' }) }
                                ),
                            })}
                            errors={formValidation.errors}
                            ariaLabelledBy={'firstName'}
                            ariaRequired
                        />
                    </div>
                    <div className="ml-auto">
                        <Label for="lastName" id="lastName">
                            {intl.formatMessage({ id: 'CreationWizard.LastName' })}
                        </Label>
                        <ValidationInput
                            type="text"
                            name="lastName"
                            value={contactDetails.LastName}
                            onChange={(e) => setContactDetails({ ...contactDetails, LastName: e.toString() })}
                            innerRef={formValidation.register({
                                required: intl.formatMessage(
                                    { id: 'Common.FieldRequiredGenericMessage' },
                                    { fieldName: intl.formatMessage({ id: 'CreationWizard.LastName' }) }
                                ),
                            })}
                            errors={formValidation.errors}
                            ariaLabelledBy={'lastName'}
                            ariaRequired
                        />
                    </div>
                </div>
                <Label for="emailAddress" id="emailAddress">
                    {intl.formatMessage({ id: 'CreationWizard.EmailAddress' })}
                </Label>
                <ValidationInput
                    type="text"
                    name="emailAddress"
                    value={contactDetails.EmailAddress}
                    onChange={(e) => setContactDetails({ ...contactDetails, EmailAddress: e.toString() })}
                    innerRef={formValidation.register({
                        required: intl.formatMessage(
                            { id: 'Common.FieldRequiredGenericMessage' },
                            { fieldName: intl.formatMessage({ id: 'CreationWizard.EmailAddress' }) }
                        ),
                        pattern: {
                            value: ValidationHelper.getEmailValidationRegex(),
                            message: intl.formatMessage({
                                id: 'CreationWizard.ProvideAValidEmailAddress',
                            }),
                        },
                    })}
                    errors={formValidation.errors}
                    ariaLabelledBy={'emailAddress'}
                    ariaRequired
                />
                <div className="phone-number-input-wrapper">
                    <PhoneNumberInput
                        onChange={(e) => setContactDetails({ ...contactDetails, PhoneNumber: e.toString() })}
                        onCountryCodeChange={(e) => setContactDetails({ ...contactDetails, PhoneCode: e.toString() })}
                        selectedValue={contactDetails?.PhoneCode}
                        inputValue={contactDetails?.PhoneNumber}
                        optionGroups={countryCodeOptions}
                        name="VenuePhoneNumberField"
                        innerRef={formValidation.register({
                            required: intl.formatMessage(
                                { id: 'Common.FieldRequiredGenericMessage' },
                                { fieldName: intl.formatMessage({ id: 'CreationWizard.PhoneNumber' }) }
                            ),
                            pattern: {
                                value: /^[0-9]*$/,
                                message: intl.formatMessage({
                                    id: 'CreationWizard.ProvideAValidPhoneNumber',
                                }),
                            },
                        })}
                        errors={formValidation.errors}
                        includeValidation
                        label={intl.formatMessage({ id: 'CreationWizard.PhoneNumber' })}
                        inputClassName={'training-widget-phone-input'}
                    />
                </div>
            </div>
        </div>
    );
}
