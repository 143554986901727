import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-getStarted.jpg';
import { FormGroup } from 'reactstrap';
import ValidationInput from '../../common/ValidationInput';
import { useIntl, FormattedMessage } from 'react-intl';
import PhoneNumberInput from '../../common/PhoneNumberInput';
import { Ref, useEffect, useState } from 'react';
import { CountryCode } from '../../../domainObjects/CountryCode';
import SettingsService from '../../../services/SettingsService';
import useValidation from '../../../hooks/useValidation';
import validator from 'validator';
import NameAddressDetails from '../../../domainObjects/NameAddressDetails';
import trackingEvents from '../../../enums/trackingEvents';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import SkeletonLabel from '../../common/SkeletonLabel';
import RestaurantInformation from '../../../domainObjects/RestaurantInformation';

export type GetStartedPageProps = {
    restaurantInformation: RestaurantInformation;
    updateRestaurantInformation: Function;
    nameAddressDetails: NameAddressDetails;
    updateNameAddressDetails: Function;
    updateSettings: Function;
    isUpdating: boolean;
    isLoading: boolean;
};

const GetStartedPage = ({
    restaurantInformation,
    updateRestaurantInformation,
    updateNameAddressDetails,
    nameAddressDetails,
    updateSettings,
    isUpdating,
    isLoading,
}: GetStartedPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const [countryCodes, setCountryCodes] = useState<Array<CountryCode>>();

    useEffect(() => {
        SettingsService.getCountryCodes().then((providerCountryCodes) => {
            setCountryCodes(providerCountryCodes);
            // Set default country code from provider location
        });
    }, []);

    const getCountryCodeOptions = (): Array<any> => {
        return countryCodes
            ? countryCodes.map((countryCode) => {
                  if (countryCode) {
                      return new Option(`${countryCode?.Name}: +${countryCode.PhoneCode}`, countryCode?.Id.toString());
                  } else {
                      return null;
                  }
              })
            : [];
    };

    const onEmailAddressChange = (value: any) => {
        const updatedNameAddress = { ...nameAddressDetails, email: value };
        updateNameAddressDetails(updatedNameAddress);
    };

    const onPhoneNumberChange = (value: any) => {
        const updatedNameAddress = { ...nameAddressDetails, phoneNumber: value };
        updateNameAddressDetails(updatedNameAddress);
    };

    const onCountryCodeChange = (value: any) => {
        const countryCode = countryCodes?.find((x) => x.Id === parseInt(value));
        if (countryCode != null) {
            const updatedNameAddress = {
                ...nameAddressDetails,
                countryCode: countryCode.PhoneCode.toString(),
                country: countryCode.Name,
            };
            updateNameAddressDetails(updatedNameAddress);
        }
    };

    const onSiteUrlChange = (value: any) => {
        const updatedNameAddressDetails = { ...nameAddressDetails, websiteUrl: value };
        updateNameAddressDetails(updatedNameAddressDetails);

        const updatedRestaurantInformation = { ...restaurantInformation, websiteUrl: value };
        updateRestaurantInformation(updatedRestaurantInformation);
    };

    const getSelectedCountryCode = () => {
        const countryCode = countryCodes?.find(
            (x) => x.PhoneCode === parseInt(nameAddressDetails.countryCode) && x.Name === nameAddressDetails.country
        );
        if (countryCode != null) {
            return countryCode.Id.toString();
        } else {
            // fallback to using just the country code if the country name doesn't match the given country code
            return countryCodes?.find((x) => x.PhoneCode === parseInt(nameAddressDetails.countryCode))?.Id.toString();
        }
    };

    const checkValidEmail = (email: string) => {
        if (email && !validator.isEmail(email)) {
            return intl.formatMessage({
                id: 'OnboardingWizard.VenueEmailAddressWarningLabel',
            });
        }
    };

    const checkValidUrl = (url: string) => {
        if (url && !validator.isURL(url)) {
            return intl.formatMessage({
                id: 'OnboardingWizard.VenueWebsiteURLWarningLabel',
            });
        }
    };

    const getFormBody = () => {
        return (
            <div className="get-started-page">
                <FormGroup>
                    <SkeletonLabel for="VenueEmailAddressField" isLoading={isLoading}>
                        <FormattedMessage id="OnboardingWizard.VenueEmailAddressLabel" />
                    </SkeletonLabel>
                    <ValidationInput
                        onChange={onEmailAddressChange}
                        value={nameAddressDetails?.email}
                        name="VenueEmailAddressField"
                        errors={formValidation.errors}
                        innerRef={
                            formValidation.register({
                                required: intl.formatMessage({
                                    id: 'OnboardingWizard.VenueEmailAddressWarningLabel',
                                }),
                                validate: (value) => checkValidEmail(value),
                            }) as Ref<HTMLInputElement | HTMLTextAreaElement> | undefined
                        }
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
                <PhoneNumberInput
                    onChange={onPhoneNumberChange}
                    onCountryCodeChange={onCountryCodeChange}
                    selectedValue={getSelectedCountryCode()}
                    inputValue={nameAddressDetails?.phoneNumber}
                    optionGroups={getCountryCodeOptions()}
                    name="VenuePhoneNumberField"
                    errors={formValidation.errors}
                    innerRef={formValidation.register({
                        required: intl.formatMessage({ id: 'OnboardingWizard.VenuePhoneNumberWarningLabel' }),
                    })}
                    includeValidation
                    label={intl.formatMessage({ id: 'OnboardingWizard.VenuePhoneNumberLabel' })}
                    isLoading={isLoading || isUpdating}
                />
                <FormGroup>
                    <SkeletonLabel for="VenueWebsiteURLField" isLoading={isLoading}>
                        <FormattedMessage id="OnboardingWizard.VenueWebsiteURL" />
                    </SkeletonLabel>
                    <ValidationInput
                        onChange={onSiteUrlChange}
                        value={restaurantInformation?.websiteUrl}
                        name="VenueWebsiteURLField"
                        errors={formValidation.errors}
                        innerRef={
                            formValidation.register({
                                required: intl.formatMessage({
                                    id: 'OnboardingWizard.VenueWebsiteURLWarningLabel',
                                }),
                                validate: (value) => checkValidUrl(value),
                            }) as Ref<HTMLInputElement | HTMLTextAreaElement> | undefined
                        }
                        isLoading={isLoading || isUpdating}
                    />
                </FormGroup>
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.GetStartedInfoPanelTitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.getStartedPage}
            children={getFormBody()}
            isUpdating={isUpdating}
            formValidation={formValidation}
            updateSettings={updateSettings}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfGetStartedPage}
        />
    );
};

export default GetStartedPage;
