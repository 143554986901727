import React, { useState, useEffect, useCallback } from 'react';
import { CustomInput } from 'reactstrap';
import AddOn from '../../viewModels/AddOn';
import PackageInformation from '../../domainObjects/PackageInformation';
import PlanBillingType from '../../enums/PlanBillingType';
import { useIntl } from 'react-intl';
import useScreenSize from '../../hooks/useScreenSize';
import AddOnType from '../../enums/AddOnType';
import ResDiaryDropdown from '../common/ResDiaryDropdown';

export type PackageSummaryProps = {
    availablePlans: PackageInformation[];
    unselectAddOnItem: Function;
    packageInformation?: PackageInformation;
    setPackageInformation: Function;
    selectedAddOnItems: AddOnType[];
    addOns: AddOn[];
    planBillingType?: PlanBillingType;
    currencySymbol: string;
    onPlanBillingTypeChange: Function;
};

const PackageSummaryPanel = ({
    availablePlans,
    unselectAddOnItem,
    addOns,
    packageInformation,
    setPackageInformation,
    selectedAddOnItems,
    currencySymbol,
    planBillingType,
    onPlanBillingTypeChange,
}: PackageSummaryProps) => {
    const intl = useIntl();

    const { isTabletView } = useScreenSize();

    const [cartAddOns, setCartAddOns] = useState<AddOn[]>([]);
    const [subTotal, setSubtotal] = useState<number>(0);
    const calculateDiscountAmount = useCallback(() => {
        return packageInformation?.discountPercentage
            ? (packageInformation?.discountPercentage / 100) * packageInformation?.cost
            : 0;
    }, [packageInformation]);
    useEffect(() => {
        let selectedCartItems: AddOn[] = [];
        selectedAddOnItems.forEach((addOnType) => {
            const addOn = addOns.find((a: AddOn) => a.type === addOnType);
            if (addOn != null) {
                selectedCartItems.push(addOn);
            }
        });
        setCartAddOns(selectedCartItems);
    }, [addOns, selectedAddOnItems]);

    useEffect(() => {
        if (packageInformation) {
            let cartItemsCost: number = 0;
            if (cartAddOns.length > 0) {
                cartItemsCost = cartAddOns.map((ci) => ci.cost).reduce((x, y) => x + y);
            }
            setSubtotal(
                packageInformation.cost + cartItemsCost + packageInformation.setupFee - calculateDiscountAmount()
            );
        }
    }, [cartAddOns, packageInformation, calculateDiscountAmount]);

    const getAnnuallyRadioButtonLabel = () => {
        return (
            <div>
                {intl.formatMessage({ id: `Common.Annually` })}{' '}
                <span className="two-months-free">{intl.formatMessage({ id: `CreationWizard.2MonthsFree` })}</span>
            </div>
        );
    };

    const getPlanOptions = () => {
        return availablePlans
            ? availablePlans.map((plan) => {
                  return new Option(plan?.name, plan?.id);
              })
            : [];
    };

    const handlePlanSelectionChange = useCallback(
        (planId: string) => {
            if (availablePlans && planId) {
                const selectedPlan = availablePlans.find((ap) => ap.id === planId);
                if (selectedPlan) {
                    setPackageInformation(selectedPlan);
                }
            }
        },
        [availablePlans, setPackageInformation]
    );

    return (
        <>
            {packageInformation && (
                <div className={isTabletView ? 'package-summary-wrapper mob' : 'package-summary-wrapper'}>
                    <div className="package-summary-panel">
                        <div className="checkbox-container">
                            <CustomInput
                                checked={planBillingType === PlanBillingType.Monthly}
                                onChange={() => onPlanBillingTypeChange(PlanBillingType.Monthly)}
                                type="radio"
                                id={'radio_monthly'}
                                name={'radio_monthly'}
                                label={intl.formatMessage({ id: `Common.Monthly` })}
                            />
                            <CustomInput
                                checked={planBillingType === PlanBillingType.Yearly}
                                onChange={() => onPlanBillingTypeChange(PlanBillingType.Yearly)}
                                type="radio"
                                id={'radio_annually'}
                                name={'radio_annually'}
                                className={'ml-4'}
                                label={getAnnuallyRadioButtonLabel()}
                            />
                        </div>
                        <ResDiaryDropdown
                            options={getPlanOptions()}
                            onValueChange={(e: string) => handlePlanSelectionChange(e)}
                            selectedValue={packageInformation.id}
                            hideRemoveItemIcon
                            name={'plan-dropdown'}
                            isDropdownUsedAsHeader
                        />
                        <div className="plan-reservation-info d-flex">
                            <div className="title">
                                {intl.formatMessage(
                                    { id: 'CreationWizard.NumberOfReservations' },
                                    {
                                        numberOfReservations: packageInformation.reservationLimit
                                            ? packageInformation.reservationLimit
                                            : intl.formatMessage({ id: 'CreationWizard.Unlimited' }),
                                    }
                                )}
                            </div>
                            <div className="cost ml-auto">
                                {currencySymbol}
                                {packageInformation.cost.toFixed(2)}
                            </div>
                        </div>
                        {cartAddOns.length > 0 && (
                            <div className="add-ons-section">
                                <div className="title">{intl.formatMessage({ id: `CreationWizard.AddOns` })}</div>
                                <div className="add-ons-summary-item-container">
                                    {cartAddOns.map((ci) => (
                                        <div className="add-on-summary-item" key={ci.type}>
                                            <div className="d-flex">
                                                <div className="title">
                                                    {ci.title}{' '}
                                                    <span
                                                        className="remove-selected-addon pl-1"
                                                        onClick={() => unselectAddOnItem(ci.type)}
                                                    >
                                                        {intl.formatMessage({ id: `Common.Remove` }).toLowerCase()}
                                                    </span>{' '}
                                                </div>
                                                <div className="cost ml-auto">
                                                    {currencySymbol}
                                                    {ci.cost.toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="sub-total-section">
                            <div className="d-flex sub-total-item">
                                <div className="title">{intl.formatMessage({ id: `CreationWizard.SetupFee` })}</div>
                                <div className="cost ml-auto">
                                    {currencySymbol}
                                    {packageInformation.setupFee.toFixed(2)}
                                </div>
                            </div>
                            {packageInformation.discountPercentage && (
                                <div className="d-flex">
                                    <div className="title">
                                        {intl.formatMessage({ id: `CreationWizard.AnnualDiscountCoupon` })}{' '}
                                        {packageInformation.discountPercentage}%
                                    </div>
                                    <div className="cost ml-auto">
                                        -{currencySymbol}
                                        {calculateDiscountAmount().toFixed(2)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="package-summary-footer">
                        <div className="d-flex package-total-cost">
                            <div className="text-uppercase">
                                {intl.formatMessage({ id: `CreationWizard.TotalDueToday` })}
                            </div>
                            <div className="ml-auto">
                                {currencySymbol}
                                {subTotal.toFixed(2)}
                            </div>
                        </div>
                        <div className="subject-to-vat-text">
                            {intl.formatMessage({ id: `CreationWizard.SubjectToVat` })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PackageSummaryPanel;
