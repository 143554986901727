import React from 'react';
import { useIntl } from 'react-intl';
import { Input, Label } from 'reactstrap';

type MaxPerIntervalProps = {
    maxPerInterval: number;
    isCovers: boolean;
    setMaxPerInterval: (maxPerInterval: number) => void;
    setIsCovers: (isCovers: boolean) => void;
    setFormValidationValue: (name: string, value: any) => void;
    getMaxIntervalValidationMessage: () => JSX.Element | null;
};

const MaxPerInterval = ({
    maxPerInterval,
    isCovers,
    setMaxPerInterval,
    setIsCovers,
    setFormValidationValue,
    getMaxIntervalValidationMessage,
}: MaxPerIntervalProps) => {
    const intl = useIntl();
    return (
        <div className="max-per-interval-container d-flex flex-column">
            <Label>{intl.formatMessage({ id: 'OnboardingWizard.VenueCoversAndIntervalsPageMaxIntervalsLabel' })}</Label>
            <div className="max-per-interval-input-wrapper d-flex flex-row justify-content-between">
                <div className="max-per-interval-input">
                    <Input
                        type="number"
                        onChange={(event) => {
                            setMaxPerInterval(parseInt(event.target.value));
                            setFormValidationValue('maxPerInterval', parseInt(event.target.value));
                        }}
                        defaultValue={maxPerInterval}
                    />
                </div>
                <Label check className="max-per-interval-radio-button d-flex align-items-center">
                    <Input type="radio" name="covers" checked={isCovers} onChange={() => setIsCovers(true)} />
                    {intl.formatMessage({ id: 'OnboardingWizard.VenueCoversAndIntervalsPageCoversLabel' })}
                </Label>
                <Label check className="max-per-interval-radio-button d-flex align-items-center">
                    <Input type="radio" name="reservations" checked={!isCovers} onChange={() => setIsCovers(false)} />
                    {intl.formatMessage({ id: 'OnboardingWizard.VenueCoversAndIntervalsPageReservationsLabel' })}
                </Label>
            </div>
            {getMaxIntervalValidationMessage()}
        </div>
    );
};

export default MaxPerInterval;
