import storageItem from '../enums/StorageItem';
import { UserAccount } from '../domainObjects/UserAccount';

export type UserRestaurant = {
    Id: number;
    Name: string;
    ProviderGroupId: number;
    DeploymentId: number;
};
export type UserDetails = {
    Id: number;
    Username: string;
    DisplayName: string;
    DefaultRestaurantId: number;
    IsAdmin: boolean;
    CanAccessRDL: boolean;
    Privileges: Array<string>;
    Restaurants: Array<UserRestaurant>;
};

class SettingsHelper {
    static setApiUrl(url: string) {
        sessionStorage.setItem(storageItem.apiUrl, url);
    }

    static getApiUrl() {
        return sessionStorage.getItem(storageItem.apiUrl);
    }

    static setLoginUrl(url: string) {
        sessionStorage.setItem(storageItem.loginUrl, url);
    }

    static getLoginUrl() {
        const url = sessionStorage.getItem(storageItem.loginUrl);
        return url ? url : '';
    }

    static setSegmentKey(key: string) {
        sessionStorage.setItem(storageItem.segmentKey, key);
    }

    static getSegmentKey() {
        return sessionStorage.getItem(storageItem.segmentKey);
    }

    static getPrivacyPolicyUrl() {
        const url = sessionStorage.getItem(storageItem.privacyPolicyUrl);
        return url ? url : '';
    }

    static setPrivacyPolicyUrl(url: string) {
        sessionStorage.setItem(storageItem.privacyPolicyUrl, url);
    }

    static getTermsConditionsUrl() {
        const url = sessionStorage.getItem(storageItem.termsConditionsUrl);
        return url ? url : '';
    }

    static setTermsConditionsUrl(url: string) {
        sessionStorage.setItem(storageItem.termsConditionsUrl, url);
    }

    static setGoogleApiBrowserKey(key: string) {
        sessionStorage.setItem(storageItem.googleApiBrowserKey, key);
    }

    static getGoogleApiBrowserKey() {
        return sessionStorage.getItem(storageItem.googleApiBrowserKey);
    }

    static getProviderId() {
        const id = sessionStorage.getItem(storageItem.providerId);
        return id ? id : '';
    }

    static getDeploymentId() {
        const id = sessionStorage.getItem(storageItem.deploymentId);
        return id ? id : '';
    }

    static setSegmentId(id: string) {
        sessionStorage.setItem(storageItem.segmentId, id);
    }

    static getSegmentId() {
        const id = sessionStorage.getItem(storageItem.segmentId);
        return id ? id : '';
    }

    static setGoogleTagManagerContainerId(id: string) {
        sessionStorage.setItem(storageItem.googleTagManagerContainerId, id);
    }

    static getGoogleTagManagerContainerId() {
        const id = sessionStorage.getItem(storageItem.googleTagManagerContainerId);
        return id ? id : '';
    }

    static getUserId() {
        const id = sessionStorage.getItem(storageItem.userId);
        return id ? id : '';
    }

    static getUserName() {
        const username = sessionStorage.getItem(storageItem.username);
        return username ? username : '';
    }

    static getJwtTokenFromCookie() {
        const jwtTokenCookie = document.cookie.split('; ').find((cookie) => {
            return cookie.startsWith(storageItem.jwtToken);
        });
        if (jwtTokenCookie) {
            return jwtTokenCookie.split('=')[1];
        } else {
            return null;
        }
    }

    static setJwtToken(token: string | null) {
        if (token) {
            localStorage.setItem(storageItem.jwtToken, token);
        }
    }

    static setUserID(userID: string | null) {
        if (userID) {
            sessionStorage.setItem(storageItem.userId, userID);
        }
    }

    static getJwtToken() {
        let token = localStorage.getItem(storageItem.jwtToken);
        if (!token) {
            token = this.getJwtTokenFromCookie();
        }
        return token;
    }

    static clearJwtToken() {
        localStorage.removeItem(storageItem.jwtToken);
    }

    static clearUserAccountCountryId() {
        localStorage.removeItem(storageItem.userAccountCountryId);
    }

    static getProviderName() {
        const providerName = sessionStorage.getItem(storageItem.providerName);
        return providerName ? providerName : '';
    }

    static setRestaurantData(
        userData: UserDetails,
        adminProviderId: string | null | undefined,
        adminDeploymentId: string | null | undefined
    ) {
        let deploymentId = adminDeploymentId;
        let providerId = adminProviderId;
        let providerName = '';
        const defaultRestaurant = userData.Restaurants.find((restaurant: UserRestaurant) => {
            return restaurant.Id === userData.DefaultRestaurantId;
        });
        if (defaultRestaurant) {
            deploymentId = defaultRestaurant.DeploymentId.toString();
            providerId = defaultRestaurant.Id.toString();
            providerName = defaultRestaurant.Name;
        }
        if (deploymentId && providerId) {
            sessionStorage.setItem(storageItem.deploymentId, deploymentId);
            sessionStorage.setItem(storageItem.providerId, providerId);
            sessionStorage.setItem(storageItem.userId, userData.Id.toString());
            sessionStorage.setItem(storageItem.username, userData.Username);
            sessionStorage.setItem(storageItem.providerName, providerName);
        }
    }

    static setUserAccount(userAccount: UserAccount) {
        sessionStorage.setItem(storageItem.providerName, userAccount.ProviderName);
        sessionStorage.setItem(storageItem.username, userAccount.EmailAddress);
        sessionStorage.setItem(storageItem.mobilePhoneCode, userAccount.MobilePhoneCode);
        sessionStorage.setItem(storageItem.mobilePhoneNumber, userAccount.MobilePhoneNumber);
        sessionStorage.setItem(storageItem.firstName, userAccount.FirstName);
        sessionStorage.setItem(storageItem.lastName, userAccount.LastName);
        sessionStorage.setItem(storageItem.countryAlpha2Code, userAccount.CountryAlpha2Code);
    }

    static getUserAccount = (): UserAccount => {
        const providerName = sessionStorage.getItem(storageItem.providerName);
        const email = sessionStorage.getItem(storageItem.username);
        const firstName = sessionStorage.getItem(storageItem.firstName);
        const lastName = sessionStorage.getItem(storageItem.lastName);
        const mobilePhoneCode = sessionStorage.getItem(storageItem.mobilePhoneCode);
        const mobilePhoneNumber = sessionStorage.getItem(storageItem.mobilePhoneNumber);
        const countryAlpha2Code = sessionStorage.getItem(storageItem.countryAlpha2Code);
        return {
            ProviderName: providerName,
            EmailAddress: email,
            FirstName: firstName,
            LastName: lastName,
            MobilePhoneCode: mobilePhoneCode,
            MobilePhoneNumber: mobilePhoneNumber,
            CountryAlpha2Code: countryAlpha2Code,
        } as UserAccount;
    };
}

export default SettingsHelper;
