import React, { MouseEventHandler } from 'react';
import { FormGroup, Button } from 'reactstrap';
import ValidationMessage from './ValidationMessage';
import { FormattedMessage } from 'react-intl';

export type ValidationButtonProps = {
    name?: string;
    id?: string;
    className?: string;
    errorMessage?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    errors?: { [key: string]: any };
};

const ValidationButton = ({ name, id, className, disabled, errorMessage, onClick, errors }: ValidationButtonProps) => {
    const showError = errors && name && errors[name];
    return (
        <FormGroup>
            <Button
                name={name}
                className={showError ? className + ' validate' : className}
                disabled={disabled}
                onClick={onClick}
            >
                <FormattedMessage id={id} />
            </Button>
            {showError && (
                <div className="validation-input-feedback">
                    <ValidationMessage isBlock={false} message={errorMessage} />
                </div>
            )}
        </FormGroup>
    );
};

export default ValidationButton;
