import Location, { LocationSettings } from './Location';

export type GeometrySettings = {
    Location: LocationSettings;
};

class Geometry {
    location: Location;

    constructor(geometrySettings: GeometrySettings | null) {
        this.location = geometrySettings?.Location ? new Location(geometrySettings.Location) : new Location(null);
    }
}

export default Geometry;
