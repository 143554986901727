import React, { ChangeEventHandler, Fragment } from 'react';
import { InputGroup, FormGroup, Input } from 'reactstrap';
import Dropdown from './ResDiaryDropdown';
import SkeletonLabel from './SkeletonLabel';
import ValidationInput from './ValidationInput';

export type PhoneNumberInputProps = {
    optionGroups: Array<any>;
    onCountryCodeChange: ChangeEventHandler<any>;
    onChange: ChangeEventHandler<HTMLInputElement>;
    inputValue: string;
    selectedValue?: string | number;
    label?: string;
    includeValidation?: boolean;
    innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    errors?: { [key: string]: any };
    name: string;
    inputClassName?: string;
    isLoading?: boolean;
};

const PhoneNumberInput = ({
    optionGroups,
    onCountryCodeChange,
    onChange,
    inputValue,
    selectedValue,
    label,
    includeValidation,
    innerRef,
    errors,
    name,
    inputClassName,
    isLoading,
}: PhoneNumberInputProps) => {
    const onCountryCodeChangeInternal = (countryCode: React.ChangeEvent<any>) => {
        onCountryCodeChange(countryCode);
    };

    const onInputChangeInternal = (inputValue: React.ChangeEvent<any>) => {
        onChange(inputValue);
    };

    return (
        <Fragment>
            <FormGroup>
                <SkeletonLabel isLoading={isLoading}>{label}</SkeletonLabel>
                <InputGroup className="phone-number">
                    <Dropdown
                        options={optionGroups}
                        onValueChange={onCountryCodeChangeInternal}
                        selectedValue={selectedValue}
                        isSearchable
                        isCountryCodeDropdown
                        dropdownMenuClassName="child-full-width"
                        isLoading={isLoading}
                    />
                    <div style={{ width: 'inherit' }}>
                        {includeValidation && (
                            <ValidationInput
                                name={name}
                                type="tel"
                                value={inputValue}
                                onChange={(e) => onChange(e)}
                                innerRef={innerRef}
                                errors={errors}
                                className={`validation-phone-number-input ${inputClassName ? inputClassName : ''}`}
                                isLoading={isLoading}
                            />
                        )}

                        {!includeValidation && (
                            <Input
                                type="tel"
                                className="ml-2"
                                maxLength={30}
                                onChange={onInputChangeInternal}
                                value={inputValue}
                            />
                        )}
                    </div>
                </InputGroup>
            </FormGroup>
        </Fragment>
    );
};

export default PhoneNumberInput;
