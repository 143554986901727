import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Label, Button } from 'reactstrap';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-description.jpg';
import ValidationInput from '../../common/ValidationInput';
import RestaurantInformation from '../../../domainObjects/RestaurantInformation';
import { Currency } from '../../../domainObjects/ProviderDetails';
import useValidation from '../../../hooks/useValidation';
import useScreenSize from '../../../hooks/useScreenSize';
import trackingEvents from '../../../enums/trackingEvents';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import SkeletonLabel from '../../common/SkeletonLabel';
import Skeleton from 'react-loading-skeleton';

export type DescriptionPageProps = {
    restaurantInformation: RestaurantInformation;
    updateRestaurantInformation: (restaurantInformation: RestaurantInformation) => void;
    updateSettings: () => void;
    pricePoint: number;
    updatePricePoint: (value: number) => void;
    isoCurrencySymbol: string;
    currencies: Array<Currency>;
    isUpdating: boolean;
    isNavigatingFromDishCult: boolean;
    setIsNavigatingFromDishCult: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
};

const DescriptionPage = ({
    restaurantInformation,
    updateRestaurantInformation,
    updateSettings,
    pricePoint,
    updatePricePoint,
    isoCurrencySymbol,
    currencies,
    isUpdating,
    isNavigatingFromDishCult,
    setIsNavigatingFromDishCult,
    isLoading,
}: DescriptionPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const { isMobileView, isTabletView } = useScreenSize();

    const updateHeadline = (newValue: any) => {
        const updatedRestaurantInformation = { ...restaurantInformation, headline: newValue };
        updateRestaurantInformation(updatedRestaurantInformation);
    };

    const updateDescription = (newValue: any) => {
        const updatedRestaurantInformation = { ...restaurantInformation, generalDescription: newValue };
        updateRestaurantInformation(updatedRestaurantInformation);
    };

    const updateOpeningInformation = (newValue: any) => {
        const updatedRestaurantInformation = { ...restaurantInformation, openingInformation: newValue };
        updateRestaurantInformation(updatedRestaurantInformation);
    };

    const decodeCurrencySymbol = (text: string | undefined) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text ? text : '';
        return textArea.value;
    };

    const getPricePointSelector = () => {
        const providerCurrency = currencies.find((currency) => currency.Id === isoCurrencySymbol);
        const providerCurrencySymbol = decodeCurrencySymbol(providerCurrency?.Symbol);
        return (
            <Fragment>
                <SkeletonLabel className="price-point-label" isLoading={isLoading || isUpdating} skeletonWidth={'30%'}>
                    <FormattedMessage id={'OnboardingWizard.PriceRangeLabel'} />
                </SkeletonLabel>

                {isLoading || isUpdating ? (
                    <Fragment>
                        <Skeleton
                            style={{
                                lineHeight: 3,
                                maxWidth: 80,
                                display: 'inline-block',
                                flexDirection: 'column',
                                marginRight: 10,
                            }}
                            count={5}
                            inline
                        />
                    </Fragment>
                ) : (
                    <div className="price-point-selector">
                        <div className="price-point">
                            <Button
                                className={
                                    'price-point-button ' +
                                    (isMobileView ? 'mobile ' : '') +
                                    (pricePoint === 1 ? 'selected' : '')
                                }
                                onClick={() => {
                                    updatePricePoint(1);
                                }}
                            >
                                {providerCurrencySymbol}
                            </Button>
                            {pricePoint === 1 && (
                                <Label className="price-point-label">
                                    {intl.formatMessage({ id: 'OnboardingWizard.UpToXPerPersonLabel' })}
                                </Label>
                            )}
                        </div>
                        <div className="price-point">
                            <Button
                                className={
                                    'price-point-button ' +
                                    (isMobileView ? 'mobile ' : '') +
                                    (pricePoint === 2 ? 'selected' : '')
                                }
                                onClick={() => {
                                    updatePricePoint(2);
                                }}
                            >
                                {providerCurrencySymbol.repeat(2)}
                            </Button>
                            {pricePoint === 2 && (
                                <Label className="price-point-label">
                                    {intl.formatMessage(
                                        { id: 'OnboardingWizard.XPerPersonLabel' },
                                        { pricePointSymbol: 'X-XX' }
                                    )}
                                </Label>
                            )}
                        </div>
                        <div className="price-point">
                            <Button
                                className={
                                    'price-point-button ' +
                                    (isMobileView ? 'mobile ' : '') +
                                    (pricePoint === 3 ? 'selected' : '')
                                }
                                onClick={() => {
                                    updatePricePoint(3);
                                }}
                            >
                                {providerCurrencySymbol.repeat(3)}
                            </Button>
                            {pricePoint === 3 && (
                                <Label className="price-point-label">
                                    {intl.formatMessage(
                                        { id: 'OnboardingWizard.XPerPersonLabel' },
                                        { pricePointSymbol: 'XX-XXX' }
                                    )}
                                </Label>
                            )}
                        </div>
                        <div className="price-point">
                            <Button
                                className={
                                    'price-point-button ' +
                                    (isMobileView ? 'mobile ' : '') +
                                    (pricePoint === 4 ? 'selected' : '')
                                }
                                onClick={() => {
                                    updatePricePoint(4);
                                }}
                            >
                                {providerCurrencySymbol.repeat(4)}
                            </Button>
                            {pricePoint === 4 && (
                                <Label className="price-point-label">
                                    {intl.formatMessage(
                                        { id: 'OnboardingWizard.XPerPersonLabel' },
                                        { pricePointSymbol: 'XXX-XXXX' }
                                    )}
                                </Label>
                            )}
                        </div>
                        <div className="price-point">
                            <Button
                                className={
                                    'price-point-button ' +
                                    (isMobileView ? 'mobile ' : '') +
                                    (pricePoint === 5 ? 'selected' : '')
                                }
                                onClick={() => {
                                    updatePricePoint(5);
                                }}
                            >
                                {providerCurrencySymbol.repeat(5)}
                            </Button>
                            {pricePoint === 5 && (
                                <Label className="price-point-label">
                                    {intl.formatMessage(
                                        { id: 'OnboardingWizard.XPerPersonLabel' },
                                        { pricePointSymbol: 'XXXX-XXXXX' }
                                    )}
                                </Label>
                            )}
                        </div>
                    </div>
                )}
            </Fragment>
        );
    };

    const getFormBody = () => {
        return (
            <div className={'description-page-body-wrapper ' + (isMobileView || isTabletView ? 'mobile' : '')}>
                <FormGroup>
                    <SkeletonLabel isLoading={isLoading || isUpdating}>
                        <FormattedMessage id={'OnboardingWizard.HeadlineLabel'} />
                    </SkeletonLabel>
                    <ValidationInput
                        name={'headline-input'}
                        value={restaurantInformation?.headline}
                        onChange={updateHeadline}
                        type="textarea"
                        maxLength={125}
                        displayCharacterCount={!isLoading && !isUpdating}
                        className="headline-input"
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'Common.ThisFieldIsRequired' }),
                        })}
                        errors={formValidation.errors}
                        isLoading={isLoading || isUpdating}
                        rows={'2'}
                    />
                </FormGroup>
                <FormGroup>
                    <SkeletonLabel isLoading={isLoading || isUpdating}>
                        <FormattedMessage id={'Common.Description'} />
                    </SkeletonLabel>
                    <ValidationInput
                        name={'general-description-input'}
                        value={restaurantInformation?.generalDescription}
                        onChange={updateDescription}
                        type="textarea"
                        maxLength={2000}
                        displayCharacterCount={!isLoading && !isUpdating}
                        className="description-input"
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'Common.ThisFieldIsRequired' }),
                        })}
                        errors={formValidation.errors}
                        isLoading={isLoading || isUpdating}
                        rows={'4'}
                    />
                </FormGroup>
                <FormGroup>{getPricePointSelector()}</FormGroup>
                <FormGroup>
                    <SkeletonLabel isLoading={isLoading || isUpdating}>
                        <FormattedMessage id={'Common.OpeningInformation'} />
                    </SkeletonLabel>
                    <ValidationInput
                        name={'opening-information-input'}
                        value={restaurantInformation?.openingInformation}
                        onChange={updateOpeningInformation}
                        type="textarea"
                        maxLength={2000}
                        displayCharacterCount={!isLoading && !isUpdating}
                        className="opening-information-input"
                        innerRef={formValidation.register({
                            required: intl.formatMessage({ id: 'Common.ThisFieldIsRequired' }),
                        })}
                        errors={formValidation.errors}
                        isLoading={isLoading || isUpdating}
                        rows={'4'}
                    />
                </FormGroup>
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({ id: 'OnboardingWizard.VenueDescriptionInfoPanelTitle' })}
            infoPanelSubtitle={intl.formatMessage({ id: 'OnboardingWizard.VenueDescriptionInfoPanelSubtitle' })}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            currentStep={rdfOnboardingSteps.descriptionPage}
            children={getFormBody()}
            updateSettings={updateSettings}
            isUpdating={isUpdating}
            formValidation={formValidation}
            isNavigatingFromDishCult={isNavigatingFromDishCult}
            setIsNavigatingFromDishCult={setIsNavigatingFromDishCult}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfVenueDescriptionPage}
        />
    );
};

export default DescriptionPage;
