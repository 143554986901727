import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import trackingEvents from '../../enums/trackingEvents';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import SettingsHelper from '../../helpers/SettingsHelper';
import AccountSubmissionService from '../../services/AccountSubmissionService';

export type TrainingWidgetBookingSuccessProps = {
    contactEmail: string;
    supportEmail: string;
};

export default function TrainingWidgetBookingSuccess({
    contactEmail,
    supportEmail,
}: TrainingWidgetBookingSuccessProps) {
    const intl = useIntl();

    function completeOnboarding() {
        AnalyticsHelper.trackClickWithPageNameAndProperties(
            trackingEvents.selfOnboardingComplete,
            'TrainingWidgetBookingSuccess',
            AnalyticsHelper.getCommonTrackingProperties()
        );
        AccountSubmissionService.markDiaryOnboardingAsComplete(
            parseInt(SettingsHelper.getDeploymentId()),
            parseInt(SettingsHelper.getProviderId())
        ).then(() => {
            window.location.href = `${SettingsHelper.getLoginUrl()}/Security/Login/LoginWithAccessToken?accessToken=${SettingsHelper.getJwtToken()}&deploymentId=${SettingsHelper.getDeploymentId()}&providerId=${SettingsHelper.getProviderId()}`;
        });
    }

    return (
        <div className="booking-response-container">
            <div className="title">{intl.formatMessage({ id: 'TrainingWidget.TrainingBookingSuccessTitle' })}</div>
            <div className="email-confirmation-message">
                {intl.formatMessage(
                    { id: `TrainingWidget.TrainingBookingEmailConfirmationMessage` },
                    {
                        contactEmail: contactEmail,
                    }
                )}
            </div>
            <Button color="primary" className="start-using-rd-button" onClick={completeOnboarding}>
                {intl.formatMessage({ id: 'TrainingWidget.StartUsingResDiary' })}
            </Button>
            <div className="no-email-message d-flex">
                <div>{intl.formatMessage({ id: 'TrainingWidget.TrainingBookingNoEmailMessage' })}</div>
                {supportEmail && (
                    <a className="contact-us ml-1" href={`mailto:${supportEmail}`}>
                        {intl.formatMessage({ id: 'TrainingWidget.ContactUs' })}
                    </a>
                )}
            </div>
        </div>
    );
}
