import usePollRequest from './usePollRequest';
import requestType from '../enums/requestType';

function useAccountSubmissionPollRequest(userName: string) {
    const [state, update, pollRequestTimedOut] = usePollRequest(
        `/api/AccountSubmission/IsDiaryDeployed?userName=${userName}`,
        5000,
        60000
    );

    const accountSubmission = state.responseData;
    const isFetching = state.isFetching;
    const isPollingRequest = state.requestType === requestType.poll;
    return { accountSubmission, isFetching, update, isPollingRequest, pollRequestTimedOut };
}
export default useAccountSubmissionPollRequest;
