import { ReactNode } from 'react';
import { FormFeedback } from 'reactstrap';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';

export type ValidationMessageProps = {
    isBlock: boolean;
    message: ReactNode;
};

const ValidationMessage = ({ isBlock, message }: ValidationMessageProps) => {
    return (
        <FormFeedback className={isBlock ? 'validation-message block' : 'validation-message'}>
            <WarningIcon />
            <div className="validation-message-text" data-testid="validation-message-text">
                {message}
            </div>
        </FormFeedback>
    );
};

export default ValidationMessage;
