import React, { useState } from 'react';
import axios from 'axios';
import requestType from '../enums/requestType';
import requestReducer from '../reducers/requestReducer';

function usePollRequest(url: string, pollInterval: number, timeoutLimit: number) {
    const [totalPollTime, setTotalPollTime] = useState(0);
    const [pollRequestTimedOut, setPollRequestTimedOut] = useState(false);

    const [state, dispatch] = React.useReducer(requestReducer, {
        url,
        defaultUrl: url,
        responseData: null,
        isFetching: true,
        error: null,
        requestId: 1,
        requestType: requestType.updateUrlAndFetch,
    });

    if (url !== state.defaultUrl) {
        dispatch({ type: requestType.updateUrlAndFetch, payload: url });
    }

    React.useEffect(() => {
        if (timeoutLimit && totalPollTime >= timeoutLimit) {
            setPollRequestTimedOut(true);
        }
    }, [totalPollTime, timeoutLimit]);

    React.useEffect(() => {
        if (!state.isFetching) return;
        const source = axios.CancelToken.source();
        axios
            .get(url, {
                cancelToken: source.token,
            })
            .then((response) => {
                dispatch({ type: requestType.fetched, payload: response.data });
            })
            .catch((error) => {
                dispatch({
                    type: requestType.error,
                    payload: error,
                });
            });
        return source.cancel;
    }, [url, state.isFetching]);

    const update = React.useCallback(
        (url) => {
            dispatch({ type: requestType.fetchManually, payload: url });
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (timeoutLimit && pollInterval > 0) {
            const timeoutId = setTimeout(() => {
                setTotalPollTime(totalPollTime + pollInterval);
            }, pollInterval);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [pollInterval, timeoutLimit, totalPollTime]);

    React.useEffect(() => {
        if (pollInterval > 0 && !state.isFetching && !pollRequestTimedOut) {
            const timeoutId = setTimeout(() => {
                dispatch({ type: requestType.poll });
            }, pollInterval);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [state.isFetching, pollInterval, pollRequestTimedOut]);

    return [state, update, pollRequestTimedOut];
}
export default usePollRequest;
