import React, { Fragment } from 'react';
import useScreenSize from '../../../hooks/useScreenSize';
import AddOn from '../../../viewModels/AddOn';
import AddOnModalActions from '../AddOnModalActions';

export type DescriptionLayoutProps = {
    isSelected: Function;
    selectAddOnItem: Function;
    unselectAddOnItem: Function;
    setIsAddOnModalOpen: Function;
    addOn: AddOn;
};

const DescriptionLayout = ({
    isSelected,
    selectAddOnItem,
    unselectAddOnItem,
    setIsAddOnModalOpen,
    addOn,
}: DescriptionLayoutProps) => {
    const { isTabletView } = useScreenSize();

    return (
        <Fragment>
            <div className={isTabletView ? 'addon-modal-description mob' : 'addon-modal-description'}>
                <div className="addon-modal-body-header-description">
                    <h6>{addOn?.modalDescription}</h6>
                </div>
                {addOn?.descriptions?.map((description: string, index: number) => (
                    <div className="addon-modal-description-paragraph" key={index}>
                        {description}
                    </div>
                ))}
            </div>
            <AddOnModalActions
                isSelected={isSelected}
                selectAddOnItem={selectAddOnItem}
                unselectAddOnItem={unselectAddOnItem}
                setIsAddOnModalOpen={setIsAddOnModalOpen}
                addOn={addOn}
            />
        </Fragment>
    );
};

export default DescriptionLayout;
