export type NameAddressDetailsSettings = {
    Name: string;
    MicrositeName: string;
    Email: string;
    CountryCode: string;
    PhoneNumber: string;
    SiteUrl: string;
    StreetLine1: string;
    StreetLine2: string;
    StreetLine3: string;
    StreetLine4: string;
    Town: string;
    Country: string;
    Postcode: string;
    Latitude: number;
    Longitude: number;
    MapUrl: string;
    IsoCurrencySymbol: string;
    WebsiteUrl: string;
    TimeZone: string;
};

class NameAddressDetails {
    name: string;
    micrositeName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    siteUrl: string;
    streetLine1: string;
    streetLine2: string;
    streetLine3: string;
    streetLine4: string;
    town: string;
    country: string;
    postcode: string;
    latitude: number;
    longitude: number;
    mapUrl: string;
    isoCurrencySymbol: string;
    websiteUrl: string;
    timeZone: string;

    constructor(nameAddressDetails: NameAddressDetailsSettings | null) {
        this.name = nameAddressDetails && nameAddressDetails.Name ? nameAddressDetails.Name : '';
        this.micrositeName =
            nameAddressDetails && nameAddressDetails.MicrositeName ? nameAddressDetails.MicrositeName : '';
        this.email = nameAddressDetails && nameAddressDetails.Email ? nameAddressDetails.Email : '';
        this.countryCode = nameAddressDetails && nameAddressDetails.CountryCode ? nameAddressDetails.CountryCode : '';
        this.phoneNumber = nameAddressDetails && nameAddressDetails.PhoneNumber ? nameAddressDetails.PhoneNumber : '';
        this.siteUrl = nameAddressDetails && nameAddressDetails.SiteUrl ? nameAddressDetails.SiteUrl : '';
        this.streetLine1 = nameAddressDetails && nameAddressDetails.StreetLine1 ? nameAddressDetails.StreetLine1 : '';
        this.streetLine2 = nameAddressDetails && nameAddressDetails.StreetLine2 ? nameAddressDetails.StreetLine2 : '';
        this.streetLine3 = nameAddressDetails && nameAddressDetails.StreetLine3 ? nameAddressDetails.StreetLine3 : '';
        this.streetLine4 = nameAddressDetails && nameAddressDetails.StreetLine4 ? nameAddressDetails.StreetLine4 : '';
        this.town = nameAddressDetails && nameAddressDetails.Town ? nameAddressDetails.Town : '';
        this.country = nameAddressDetails && nameAddressDetails.Country ? nameAddressDetails.Country : '';
        this.postcode = nameAddressDetails && nameAddressDetails.Postcode ? nameAddressDetails.Postcode : '';
        this.latitude = nameAddressDetails && nameAddressDetails.Latitude ? nameAddressDetails.Latitude : 0.0;
        this.longitude = nameAddressDetails && nameAddressDetails.Longitude ? nameAddressDetails.Longitude : 0.0;
        this.mapUrl = nameAddressDetails && nameAddressDetails.MapUrl ? nameAddressDetails.MapUrl : '';
        this.isoCurrencySymbol =
            nameAddressDetails && nameAddressDetails.IsoCurrencySymbol ? nameAddressDetails.IsoCurrencySymbol : '';
        this.websiteUrl = nameAddressDetails && nameAddressDetails.WebsiteUrl ? nameAddressDetails.WebsiteUrl : '';
        this.timeZone = nameAddressDetails && nameAddressDetails.TimeZone ? nameAddressDetails.TimeZone : '';
    }
}

export default NameAddressDetails;
