import React from 'react';
import useScreenSize from '../../hooks/useScreenSize';
import InfoBar, { InfoBarProps } from './InfoBar';

type InfoBarsProps = {
    infoBars: Array<InfoBarProps>;
    setInfoBars: (infoBars: React.SetStateAction<InfoBarProps[]>) => void;
    isOnboarding: boolean;
};

const InfoBars = ({ infoBars, setInfoBars, isOnboarding }: InfoBarsProps) => {
    const onDismiss = (id: number) => {
        setInfoBars((prevState: Array<InfoBarProps>) => {
            return prevState.filter((m) => m.id !== id);
        });
    };

    const { isMobileView, isTabletView } = useScreenSize();

    return (
        <div
            className={`info-bar-wrapper ${isMobileView ? 'mobile' : ''} ${isTabletView ? 'tablet' : ''} ${
                isOnboarding ? 'onboarding' : ''
            } `}
        >
            {infoBars.slice(0, 5).map((infoBar) => (
                <InfoBar
                    key={infoBar.id}
                    id={infoBar.id}
                    message={infoBar.message}
                    type={infoBar.type}
                    onDismiss={onDismiss}
                />
            ))}
        </div>
    );
};

export default InfoBars;
