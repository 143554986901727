import React, { useEffect } from 'react';
import PaymentService from '../../services/PaymentService';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import trackingEvents from '../../enums/trackingEvents';
import { UserAccount } from '../../domainObjects/UserAccount';
import { AddOnDto } from '../../domainObjects/AddOnDto';
import PackageInformation from '../../domainObjects/PackageInformation';
import CreationWizardProgressBarItem from '../../enums/CreationWizardProgressBarItem';
import { useNavigate } from 'react-router-dom';
export type PaymentConfirmationProps = {
    account?: UserAccount;
    selectedAddons: AddOnDto[];
    userId: string;
    telephoneCode: string;
    packageInformation: PackageInformation;
    currentPage: CreationWizardProgressBarItem;
    setCurrentPage: Function;
};

declare let Chargebee: any;

const PaymentConfirmation = ({
    account,
    selectedAddons,
    userId,
    telephoneCode,
    packageInformation,
    currentPage,
    setCurrentPage,
}: PaymentConfirmationProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (currentPage === CreationWizardProgressBarItem.PaymentPage && account) {
            const addOns = selectedAddons;

            PaymentService.getChargebeeSite().then((chargebeeSite: string) => {
                let cbInstance = Chargebee.init({
                    site: chargebeeSite,
                });
                cbInstance = Chargebee.getInstance();
                const trackingProperties = {
                    userId: userId,
                    email: account.EmailAddress,
                    telephone: `+${telephoneCode} ${account.MobilePhoneNumber}`,
                    timeStamp: new Date().toISOString(),
                    productType: `${packageInformation!.name}; ${addOns.map((_) => _.Name).join('; ')}`,
                };
                AnalyticsHelper.trackClickWithPageNameAndProperties(
                    trackingEvents.selfServiceSubscriptionCheckoutStarted,
                    'PaymentPage',
                    trackingProperties
                );

                cbInstance.openCheckout({
                    hostedPage: function () {
                        const paymentRequest = {
                            UserAccount: account,
                            PackageInformation: packageInformation!,
                            AddOns: addOns,
                        };
                        return PaymentService.checkout(paymentRequest).then((hp: any) => hp);
                    },
                    success(hostedPageId: string) {
                        trackingProperties.timeStamp = new Date().toISOString();
                        AnalyticsHelper.trackClickWithPageNameAndProperties(
                            trackingEvents.selfServiceSubscriptionCheckoutComplete,
                            'PaymentPage',
                            trackingProperties
                        );
                        navigate(`/CreatingAccount`);
                    },
                    close() {
                        setCurrentPage(CreationWizardProgressBarItem.AddOnsPage);
                    },
                    step(step: any) {
                        //segment stuff here if they exit the checkout process
                    },
                });
            });
        }
    });

    return <div className="welcome-page row"></div>;
};

export default PaymentConfirmation;
