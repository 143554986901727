import AverageEatDuration from '../domainObjects/AverageEatDuration';
import ApiHelper from '../helpers/ApiHelper';
import SettingsHelper from '../helpers/SettingsHelper';

class settingsService {
    static getConfigurationSettings() {
        return ApiHelper.get('/api/configuration').then((response: { data: any }) => response.data);
    }

    static getProviderSettings() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/DetailsSettings`
        ).then((response: { data: any }) => response.data);
    }

    static getRestaurantSettings() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}`
        ).then((response: { data: any }) => response.data);
    }

    static getCurrentUser() {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Tablet/v1/CurrentUser`).then(
            (response: { data: any }) => response.data
        );
    }

    static getCountryCodes() {
        return ApiHelper.get(`${SettingsHelper.getApiUrl()}/api/Tablet/v1/Country/CountryCodes`).then(
            (response) => response.data
        );
    }

    static updateListingDetails(listingDetailsDto: any) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/DetailsSettings/ListingSettings`,
            listingDetailsDto
        ).then((response: { data: any }) => response.data);
    }

    static updateAddressAndContactDetails(addressAndContactDetailsDto: any) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/DetailsSettings/AddressAndContactDetails`,
            addressAndContactDetailsDto
        ).then((response) => response.data);
    }

    static getProviderImages() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Images/GetMicrositeImagesForProvider`
        ).then((response) => response.data);
    }

    static uploadProviderImage(data: any, imageId: number, setUploadProgress: any) {
        return ApiHelper.postImage(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Images/AddOrUpdateProviderImage/?imageId=${imageId}`,
            data,
            setUploadProgress
        ).then((response) => response.data);
    }

    static deleteProviderImages(imageIds: Array<number>) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Images/Delete`,
            imageIds
        );
    }

    static setImageAsMainImage(imageId: number) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Images/SetAsMainImage?imageId=${imageId}`
        ).then((response) => response.data);
    }

    static setImageAsMastheadImage(imageId: number) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Images/SetAsMastheadImage?imageId=${imageId}`
        ).then((response) => response.data);
    }

    static getProviderServices() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Service/GetAll/${SettingsHelper.getSegmentId()}`
        ).then((response) => response.data);
    }

    static updateVenueService(data: any) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Service/Update`,
            data
        ).then((response) => response.data);
    }

    static deleteVenueService(serviceId: number) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Service/Delete/${serviceId}`
        );
    }

    static getProviderAreas() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Area/${SettingsHelper.getSegmentId()}`
        ).then((response) => response.data);
    }

    static addOrUpdateArea(updateAreaRequestDto: any) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Area/AddOrUpdate`,
            updateAreaRequestDto
        ).then((response) => response.data);
    }

    static deleteArea(areaId: number) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Area/${areaId}/Delete`
        );
    }

    static getProviderTables() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Table/GetAll`
        ).then((response) => response.data);
    }

    static addOrUpdateTable(updateTableRequestDto: any) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Table/AddOrUpdate`,
            updateTableRequestDto
        ).then((response) => response.data);
    }

    static deleteTable(tableId: number) {
        return ApiHelper.deleteRequest(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Table/${tableId}/Delete`
        );
    }

    static getAverageEatDurations(serviceId: number) {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Service/${serviceId}/AverageEatDurations`
        ).then((response) => response.data);
    }

    static updateAverageEatDurations(data: Array<AverageEatDuration>, serviceId: number) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/Service/${serviceId}/AverageEatDurations`,
            data
        ).then((response) => response.data);
    }

    static setProgressFlag(progressFlag: string) {
        return ApiHelper.put(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/SetRdfOnboardingProgressFlag?progressFlag=${progressFlag}`
        ).then((response) => response.data);
    }

    static getProgressFlagsForProvider() {
        return ApiHelper.get(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/GetRDFProviderProgressFlags`
        ).then((response) => response.data);
    }

    static AddEnabledRulesForService(serviceId: number) {
        return ApiHelper.post(
            `${SettingsHelper.getApiUrl()}/api/Tablet/v1/Restaurant/${SettingsHelper.getDeploymentId()}/${SettingsHelper.getProviderId()}/AddEnabledRulesForService/${serviceId}`
        );
    }
}

export default settingsService;
