import React, { useState, useEffect } from 'react';
import useTranslations from './hooks/useTranslations';
import { IntlProvider } from 'react-intl';
import App from './App';
import SettingsService from './services/SettingsService';
import SettingsHelper from './helpers/SettingsHelper';
import interceptor from './services/interceptor';
import AnalyticsHelper from './helpers/AnalyticsHelper';
import GoogleTagManagerHelper from './helpers/GoogleTagManagerHelper';

export type ConfigurationSettings = {
    ApiUrl: string;
    LoginUrl: string;
    SegmentKey: string;
    GoogleApiBrowserKey: string;
    UsePseudoCulture: boolean;
    PrivacyPolicyUrl: string;
    GoogleTagManagerContainerId: string;
    TermsConditionsUrl: string;
};

export default function LocalisedApp() {
    const [isLoading, setIsLoading] = useState(true);
    const [usePseudoCulture, setUsePseudoCulture] = useState(false);
    useEffect(() => {
        if (isLoading) {
            SettingsService.getConfigurationSettings().then((settings: ConfigurationSettings) => {
                SettingsHelper.setApiUrl(settings.ApiUrl);
                SettingsHelper.setLoginUrl(settings.LoginUrl);
                SettingsHelper.setSegmentKey(settings.SegmentKey);
                SettingsHelper.setGoogleApiBrowserKey(settings.GoogleApiBrowserKey);
                SettingsHelper.setPrivacyPolicyUrl(settings.PrivacyPolicyUrl);
                SettingsHelper.setGoogleTagManagerContainerId(settings.GoogleTagManagerContainerId);
                SettingsHelper.setTermsConditionsUrl(settings.TermsConditionsUrl);
                setUsePseudoCulture(settings.UsePseudoCulture);
                AnalyticsHelper.loadAnalytics(SettingsHelper.getSegmentKey());
                GoogleTagManagerHelper.loadTagManager(SettingsHelper.getGoogleTagManagerContainerId());

                interceptor.setUpInterceptors();

                setIsLoading(false);
            });
        }
    }, [isLoading]);
    const { language, messages } = useTranslations(usePseudoCulture);
    return (
        <IntlProvider key={language} locale={language} messages={messages}>
            <App isLoading={isLoading} />
        </IntlProvider>
    );
}
