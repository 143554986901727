import React, { useState } from 'react';
import OnboardingInfoPanelPage from '../OnboardingInfoPanelPage';
import BackgroundImageSource from '../../../content/images/bg-services.jpg';
import { useIntl } from 'react-intl';
import trackingEvents from '../../../enums/trackingEvents';
import useValidation from '../../../hooks/useValidation';
import RestaurantServiceList from './RestaurantServiceList';
import RestaurantService, { RestaurantServiceOption } from '../../../domainObjects/RestaurantService';
import InfoBars from '../../common/InfoBars';
import { InfoBarProps } from '../../common/InfoBar';
import useScreenSize from '../../../hooks/useScreenSize';
import rdfOnboardingProgressFlags from '../../../enums/rdfOnboardingProgressFlags';
import rdfOnboardingSteps from '../../../enums/rdfOnboardingSteps';
import ServicesNote from './ServicesNote';

export type ServicesPageProps = {
    venueServices: RestaurantService[] | null;
    setVenueServices: (venueServices: Array<RestaurantService>) => void;
    updateProgressFlag: (progressFlag: string) => void;
    isLoading: boolean;
};

const ServicesPage = ({ venueServices, setVenueServices, updateProgressFlag, isLoading }: ServicesPageProps) => {
    const intl = useIntl();
    const formValidation = useValidation();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [infoBars, setInfoBars] = useState<Array<InfoBarProps>>([]);
    const { isMobileView, isTabletView } = useScreenSize();
    const [continueDisabled, setContinueDisabled] = useState(false);

    const updateServicesProgressFlag = () => {
        //This can be changed later if services are updated in one go when continue is clicked
        updateProgressFlag(rdfOnboardingProgressFlags.addedServices);
    };

    const venueServiceOptions = Array<RestaurantServiceOption>(
        { Id: 1, Name: 'Breakfast' },
        { Id: 2, Name: 'Brunch' },
        { Id: 3, Name: 'Lunch' },
        { Id: 4, Name: 'Afternoon Tea' },
        { Id: 5, Name: 'Dinner' }
    );

    const getFormBody = () => {
        return (
            <div className="services-page-wrapper">
                <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} isOnboarding />
                <div className={`venue-services-table ${isMobileView || isTabletView ? 'mobile' : ''}`}>
                    <ServicesNote />

                    <RestaurantServiceList
                        formValidation={formValidation}
                        isUpdating={isUpdating}
                        setIsUpdating={setIsUpdating}
                        venueServices={venueServices}
                        setVenueServices={setVenueServices}
                        venueServiceOptions={venueServiceOptions}
                        infoBars={infoBars}
                        setInfoBars={setInfoBars}
                        isLoading={isLoading}
                        setContinueDisabled={setContinueDisabled}
                    ></RestaurantServiceList>
                </div>
            </div>
        );
    };

    return (
        <OnboardingInfoPanelPage
            infoPanelTitle={intl.formatMessage({
                id: 'OnboardingWizard.VenueServicesPageTitle',
            })}
            currentStep={rdfOnboardingSteps.servicesPage}
            infoPanelBackgroundImageSource={BackgroundImageSource}
            children={getFormBody()}
            updateSettings={updateServicesProgressFlag}
            isUpdating={isUpdating}
            formValidation={formValidation}
            isLoading={isLoading}
            pageEvent={trackingEvents.rdfServiceHoursPage}
            continueDisabled={continueDisabled}
        />
    );
};

export default ServicesPage;
