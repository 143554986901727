export type SearchDetailsSettings = {
    PrimaryCuisineId: number;
    SecondaryCuisineIds: Array<number>;
    DressCodeIds: Array<number>;
    FacilityIds: Array<number>;
    PricePoint: number;
};

class SearchDetails {
    primaryCuisineId: string;
    secondaryCuisineIds: Array<string>;
    dressCodeIds: Array<number>;
    facilityIds: Array<number>;
    pricePoint: number;

    constructor(searchDetails: SearchDetailsSettings | null) {
        this.primaryCuisineId =
            searchDetails && searchDetails.PrimaryCuisineId ? '' + searchDetails.PrimaryCuisineId : '';
        this.secondaryCuisineIds =
            searchDetails && searchDetails.SecondaryCuisineIds
                ? searchDetails.SecondaryCuisineIds.map((cuisineId) => '' + cuisineId)
                : [];
        this.dressCodeIds = searchDetails && searchDetails.DressCodeIds ? searchDetails.DressCodeIds : [];
        this.facilityIds = searchDetails && searchDetails.FacilityIds ? searchDetails.FacilityIds : [];
        this.pricePoint = searchDetails && searchDetails.PricePoint ? searchDetails.PricePoint : 1;
    }
}

export default SearchDetails;
