import EP1 from '../../content/images/EPOS_UK/3POS.png';
import EP2 from '../../content/images/EPOS_UK/Bepoz.png';
import EP3 from '../../content/images/EPOS_UK/Bleep.png';
import EP4 from '../../content/images/EPOS_UK/Bizzon.png';
import EP5 from '../../content/images/EPOS_UK/CESSoftware.png';
import EP6 from '../../content/images/EPOS_UK/Idealpos.png';
import EP7 from '../../content/images/EPOS_UK/MaitreD.png';
import EP8 from '../../content/images/EPOS_UK/Newbridge.png';
import EP9 from '../../content/images/EPOS_UK/OracleHospitality.png';
import EP10 from '../../content/images/EPOS_UK/Ordermate.png';
import EP11 from '../../content/images/EPOS_UK/Par.png';
import EP12 from '../../content/images/EPOS_UK/PointOne.png';
import EP13 from '../../content/images/EPOS_UK/Samtouch.png';
import EP14 from '../../content/images/EPOS_UK/Silverware.png';
import EP15 from '../../content/images/EPOS_UK/Sumup.png';
import EP16 from '../../content/images/EPOS_UK/SwiftPOS.png';
import EP17 from '../../content/images/EPOS_UK/Tapa.png';
import EP18 from '../../content/images/EPOS_UK/Tevalis.png';
import EP19 from '../../content/images/EPOS_UK/Triniteq.png';
import EP20 from '../../content/images/EPOS_UK/WRS.png';

const EPOSImagesUK: string[] = [
    EP1,
    EP2,
    EP3,
    EP4,
    EP5,
    EP6,
    EP7,
    EP8,
    EP9,
    EP10,
    EP11,
    EP12,
    EP13,
    EP14,
    EP15,
    EP16,
    EP17,
    EP18,
    EP19,
    EP20,
];
export default EPOSImagesUK;
