import React, { Fragment } from 'react';
import { Label, NavLink } from 'reactstrap';
import { ReactComponent as PlusIcon } from '../../content/icons/plus.svg';
import { ReactComponent as DropdownIcon } from '../../content/icons/down.svg';
import { ReactComponent as DropdownUpIcon } from '../../content/icons/up.svg';
import useScreenSize from '../../hooks/useScreenSize';

type AddServiceButtonProps = {
    onClick: () => void;
    label?: string | null;
    subLabel?: string | null;
    linkText: string;
    isDropdown?: boolean;
    dropdownOpen?: boolean;
    alignRight?: boolean;
    splitOnMobile?: boolean;
};

const AddServiceButton = ({
    onClick,
    label,
    linkText,
    isDropdown,
    dropdownOpen,
    subLabel,
    alignRight,
    splitOnMobile,
}: AddServiceButtonProps) => {
    const { isMobileView, isTabletView } = useScreenSize();

    return (
        <NavLink
            className={`add-service-button ${isMobileView || isTabletView ? 'mobile' : ''} ${
                alignRight ? 'align-right' : ''
            } ${label ? 'has-label' : ''} ${splitOnMobile === false ? 'no-split' : ''}`}
            role="button"
            onClick={onClick}
        >
            {label && (
                <Label className="add-service-button-label d-flex align-items-center">
                    {label}
                    {subLabel && (
                        <Label className="add-service-button-sub-label d-flex align-items-center">{subLabel}</Label>
                    )}
                    {isDropdown && (
                        <Fragment>
                            {dropdownOpen && <DropdownUpIcon />}
                            {!dropdownOpen && <DropdownIcon />}
                        </Fragment>
                    )}
                </Label>
            )}
            {!isDropdown && (
                <div className={`add-service-button-link-container d-flex align-items-center`}>
                    <PlusIcon />
                    <Label>{linkText}</Label>
                </div>
            )}
        </NavLink>
    );
};

export default AddServiceButton;
