enum OnboardingSteps {
    welcomePage = 'Welcome',
    checklistPage = 'Checklist',
    getStartedPage = 'GetStarted',
    cuisinesPage = 'Cuisines',
    locationPage = 'Location',
    descriptionPage = 'Description',
    logoPage = 'Logo',
    mainImagePage = 'MainImage',
    dishcultActivationPage = 'DishCultActivation',
    servicesPage = 'Services',
    coversAndIntervalsPage = 'CoversAndIntervals',
    areasPage = 'Areas',
    tablesPage = 'Tables',
}

export default OnboardingSteps;
