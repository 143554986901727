import ApiHelper from '../helpers/ApiHelper';
import PaymentRequest from '../domainObjects/PaymentRequest';

class PaymentService {
    static checkout(paymentRequest: PaymentRequest) {
        return ApiHelper.postJSON(`/api/Payment/checkoutSubscription`, JSON.stringify(paymentRequest)).then(
            (response: { data: any }) => response.data
        );
    }

    static getChargebeeSite() {
        return ApiHelper.get(`/api/Chargebee/GetChargebeeSite`).then((response: { data: string }) => response.data);
    }
}

export default PaymentService;
