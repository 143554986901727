import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import trackingEvents from '../../enums/trackingEvents';
import InfoPanelPage from '../common/InfoPanelPage';
import rdfOnboardingSteps from '../../enums/rdfOnboardingSteps';

export type OnboardingInfoPanelPageProps = {
    infoPanelTitle: string;
    infoPanelSubtitle?: string;
    infoPanelBackgroundImageSource?: string;
    currentStep: string;
    //TODO make updateSettings and isUpdating mandatory when they've been implemented in the other pages
    updateSettings?: any;
    isUpdating?: boolean;
    children?: React.ReactNode;
    formValidation?: any; // TODO this is implemented as if it is required; gives error if not provided in other pages.
    shouldExcludeFormPadding?: boolean;
    isNavigatingFromDishCult?: boolean;
    setIsNavigatingFromDishCult?: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    pageEvent: string;
    continueDisabled?: boolean;
};

const OnboardingInfoPanelPage = ({
    infoPanelTitle,
    infoPanelSubtitle,
    infoPanelBackgroundImageSource,
    currentStep,
    updateSettings,
    isUpdating,
    children,
    formValidation,
    shouldExcludeFormPadding,
    isNavigatingFromDishCult,
    setIsNavigatingFromDishCult,
    isLoading,
    pageEvent,
    continueDisabled,
}: OnboardingInfoPanelPageProps) => {
    const navigate = useNavigate();
    const steps = Object.values(rdfOnboardingSteps);
    const currentStepStringIndex: number = Object.values<string>(rdfOnboardingSteps).indexOf(currentStep);
    const [hasFiredPageEvent, setHasFiredPageEvent] = useState(false);

    if (!isLoading && !hasFiredPageEvent) {
        AnalyticsHelper.trackPageWithProperties(pageEvent, AnalyticsHelper.getCommonTrackingProperties());
        setHasFiredPageEvent(true);
    }

    const callSupportWidget = (param: string) => {
        (window as { [key: string]: any })['freshworksWidget'](param) as string;
    };

    const isChecklistPage = () => {
        return currentStep === rdfOnboardingSteps.checklistPage;
    };

    const isLogoPage = () => {
        return currentStep === rdfOnboardingSteps.logoPage;
    };

    const isMainImagePage = () => {
        return currentStep === rdfOnboardingSteps.mainImagePage;
    };

    if (!isChecklistPage()) {
        callSupportWidget('show');
    }
    const nextStep = (skipThisStep?: boolean) => {
        callSupportWidget('close');

        if (!skipThisStep) {
            if (updateSettings) {
                updateSettings();
            }

            if (isChecklistPage()) {
                AnalyticsHelper.trackClickWithPageNameAndProperties(
                    trackingEvents.selfOnboardingStarted,
                    'ChecklistPage',
                    AnalyticsHelper.getCommonTrackingProperties()
                );
            }
        }

        if (isLogoPage() && skipThisStep) {
            AnalyticsHelper.trackClickWithPageNameAndProperties(
                trackingEvents.logoSkipped,
                'LogoPage',
                AnalyticsHelper.getCommonTrackingProperties()
            );
        }

        if (isMainImagePage() && skipThisStep) {
            AnalyticsHelper.trackClickWithPageNameAndProperties(
                trackingEvents.mainImageSkipped,
                'MainImagePage',
                AnalyticsHelper.getCommonTrackingProperties()
            );
        }

        if (isNavigatingFromDishCult) {
            if (setIsNavigatingFromDishCult) {
                setIsNavigatingFromDishCult(false);
            }
            navigate(`/OnboardingWizard/${rdfOnboardingSteps.dishcultActivationPage}`);
            return;
        }
        if (currentStep === rdfOnboardingSteps.tablesPage) {
            navigate(`/TrainingWidget`);
        } else {
            navigate(`/OnboardingWizard/${steps[currentStepStringIndex + 1]}`);
        }
    };

    const previousStep = () => {
        callSupportWidget('close');
        if (isChecklistPage()) {
            navigate(`/OnboardingWizard/${rdfOnboardingSteps.welcomePage}`);
        }
        if ((formValidation && !formValidation.submitDisabled) || !formValidation) {
            navigate(`/OnboardingWizard/${steps[currentStepStringIndex - 1]}`);
        }
    };

    return (
        <InfoPanelPage
            infoPanelTitle={infoPanelTitle}
            infoPanelSubtitle={infoPanelSubtitle}
            infoPanelBackgroundImageSource={infoPanelBackgroundImageSource}
            currentStep={currentStep}
            children={children}
            isUpdating={isUpdating}
            formValidation={formValidation}
            nextStep={nextStep}
            previousStep={previousStep}
            shouldExcludeFormPadding={shouldExcludeFormPadding}
            isChecklistPage={isChecklistPage()}
            hideProgressBar={isChecklistPage()}
            steps={steps}
            continueDisabled={continueDisabled}
        />
    );
};

export default OnboardingInfoPanelPage;
