import NameAddressDetails, { NameAddressDetailsSettings } from './NameAddressDetails';
import RestaurantInformation, { RestaurantInformationSettings } from './RestaurantInformation';
import SearchDetails, { SearchDetailsSettings } from './SearchDetails';

export type Cuisine = {
    Id: number;
    Name: string;
};

export type Culture = {
    CultureCode: string;
    Description: string;
    IsProviderCulture: boolean;
};

export type Currency = {
    Id: string;
    Name: string;
    Symbol: string;
};

export type DressCode = {
    Id: number;
    DisplayName: string;
    Value: string;
};

export type Facility = {
    Id: number;
    Name: string;
    NameResource: string;
};

export type ProviderMenu = {
    Id: number;
    Name: string;
    Type: number;
    FileName: string;
    MenuUrl: string;
    CultureCode: string;
    IsEnabled: boolean;
    DisplayOnlineFrom: string;
    DisplayOnlineUntil: string;
    UploadedDate: string;
};

export type SocialNetworkDetails = {
    FacebookUrl: string;
    GooglePlusUrl: string;
    InstagramUrl: string;
    TwitterUrl: string;
    YoutubeUrl: string;
};

export type ProviderDetailsSettings = {
    NameAddressDetails: NameAddressDetailsSettings;
    RestaurantInformation: RestaurantInformationSettings;
    SearchDetails: SearchDetailsSettings;
    SocialNetworkDetails: SocialNetworkDetails;
    ProviderMenus: Array<ProviderMenu>;
    HideOnPortal: boolean;
    IsCustomerRetentionPolicyEnabled: boolean;
    IsReserveWithGoogleEnabled: boolean;
    PrimaryCuisineId: number;
    Cuisines: Array<Cuisine>;
    Cultures: Array<Culture>;
    Currencies: Array<Currency>;
    DressCodes: Array<DressCode>;
    Facilities: Array<Facility>;
    MenuTypes: Array<string>;
};

class ProviderDetails {
    nameAddressDetails: NameAddressDetails;
    restaurantInformation: RestaurantInformation;
    searchDetails: SearchDetails;
    hideOnPortal: boolean;
    cuisines: Array<any>;
    currencies: Array<Currency>;
    socialNetworkDetails: SocialNetworkDetails | null;
    providerMenus: Array<ProviderMenu>;
    isCustomerRetentionPolicyEnabled: boolean;
    isReserveWithGoogleEnabled: boolean;

    constructor(providerDetailsSettings: ProviderDetailsSettings | null) {
        this.nameAddressDetails =
            providerDetailsSettings && providerDetailsSettings.NameAddressDetails
                ? new NameAddressDetails(providerDetailsSettings.NameAddressDetails)
                : new NameAddressDetails(null);
        this.restaurantInformation =
            providerDetailsSettings && providerDetailsSettings.RestaurantInformation
                ? new RestaurantInformation(providerDetailsSettings.RestaurantInformation)
                : new RestaurantInformation(null);
        this.searchDetails =
            providerDetailsSettings && providerDetailsSettings.SearchDetails
                ? new SearchDetails(providerDetailsSettings.SearchDetails)
                : new SearchDetails(null);
        this.hideOnPortal =
            providerDetailsSettings && providerDetailsSettings.HideOnPortal
                ? providerDetailsSettings.HideOnPortal
                : false;
        this.cuisines =
            providerDetailsSettings && providerDetailsSettings.Cuisines
                ? providerDetailsSettings.Cuisines.map((cuisine) => {
                      return { id: cuisine.Id, name: cuisine.Name };
                  })
                : [];
        this.currencies =
            providerDetailsSettings && providerDetailsSettings.Currencies ? providerDetailsSettings.Currencies : [];
        this.socialNetworkDetails =
            providerDetailsSettings && providerDetailsSettings.SocialNetworkDetails
                ? providerDetailsSettings.SocialNetworkDetails
                : null;
        this.providerMenus =
            providerDetailsSettings && providerDetailsSettings.ProviderMenus
                ? providerDetailsSettings.ProviderMenus
                : [];
        this.isCustomerRetentionPolicyEnabled =
            providerDetailsSettings && providerDetailsSettings.IsCustomerRetentionPolicyEnabled
                ? providerDetailsSettings.IsCustomerRetentionPolicyEnabled
                : false;
        this.isReserveWithGoogleEnabled =
            providerDetailsSettings && providerDetailsSettings.IsReserveWithGoogleEnabled
                ? providerDetailsSettings.IsReserveWithGoogleEnabled
                : false;
    }
}

export default ProviderDetails;
