import ApiHelper from '../helpers/ApiHelper';

class TrainingWidgetService {
    static getAvailabilityForDateRange(availabilityRequest: any) {
        return ApiHelper.postJSON(
            `/api/TrainingWidget/AvailabilityForDateRange`,
            JSON.stringify(availabilityRequest)
        ).then((response: { data: any }) => response.data);
    }

    static createBooking(createBookingRequest: any) {
        return ApiHelper.postJSON(`/api/TrainingWidget/CreateBooking`, JSON.stringify(createBookingRequest)).then(
            (response: { data: any }) => response.data
        );
    }

    static getSupportEmailByAlpha2Code(alpha2Code: string) {
        return ApiHelper.get(`/api/TrainingWidget/GetSupportEmailByAlpha2Code?alpha2Code=${alpha2Code}`).then(
            (response: { data: string }) => response.data
        );
    }
}

export default TrainingWidgetService;
