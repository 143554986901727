import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import RestaurantService from '../../../domainObjects/RestaurantService';

type DeleteServiceModalProps = {
    service: RestaurantService;
    setIsDeleting: (isDeleting: boolean) => void;
    deleteService: (service: RestaurantService) => void;
    isUpdating: boolean;
};

const DeleteServiceModal = ({ service, setIsDeleting, deleteService, isUpdating }: DeleteServiceModalProps) => {
    const intl = useIntl();

    return (
        <div className="service-modal">
            <div className="service-modal-header">
                <p className="service-modal-title">
                    {intl.formatMessage(
                        { id: 'OnboardingWizard.VenueServicesPageDeleteServiceConfirmationTitle' },
                        { serviceName: service.name }
                    )}
                </p>
                <p>
                    {intl.formatMessage(
                        { id: 'OnboardingWizard.VenueServicesPageDeleteServiceConfirmationText' },
                        { serviceName: service.name }
                    )}
                </p>
                <Button
                    close
                    color="primary"
                    onClick={() => {
                        setIsDeleting(false);
                    }}
                    className="service-modal-close-button"
                />
            </div>
            <div className="service-modal-body">
                <Button
                    color="primary"
                    outline={true}
                    onClick={() => {
                        setIsDeleting(false);
                    }}
                >
                    {intl.formatMessage({ id: 'Common.Cancel' })}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        deleteService(service);
                        setIsDeleting(false);
                    }}
                    disabled={isUpdating}
                >
                    {intl.formatMessage({ id: 'Common.Confirm' })}
                </Button>
            </div>
        </div>
    );
};

export default DeleteServiceModal;
