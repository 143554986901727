enum AddOnType {
    OrderAndPay = 'OrderPay' as any,
    Vouchers = 'GiftVoucher' as any,
    EventsManager = 'EventManager' as any,
    SmsMarketingNotifications = 'SMS' as any,
    ResPhone = 'Resphone' as any,
    GroupCentralReservations = 'GroupCentral' as any,
    EposIntegration = 'EPOSIntegration' as any,
    PmsIntegration = 'PMSIntegration' as any,
    CrmAdvancedMarketingIntegration = 'CRMAdvancedMarketingIntegration' as any,
}

export default AddOnType;
